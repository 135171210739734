import moment from "moment-timezone";
import timezones from "../timezone_map.json";

class Formatter {
    static numberWithCommas(x=0, nullCheck=true) {
        if (!nullCheck && x == 0) {
            return;
        }
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static formatPhoneNumber(phoneNumberString="") {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    static createLocalDateTime(datetime, msTimezone="") {
        let timezone = timezones.find((e) => e.other.toLowerCase() == msTimezone.toLowerCase())?.type
        return moment.utc(datetime).tz(timezone)
    }

    static convertCamelToSpaces(inputString) {
        if (!inputString) return;
        return inputString.replace(/([A-Z])/g, ' $1').trim();
    }

    static getTImeFromUTC(datetime, subtractHours) {
        if (!datetime) return;
        let time = moment.utc(datetime)
        if (subtractHours) {
            time = time.subtract(subtractHours, "hours")
        }
        return time.format("HH:mm:ss")
    }

    static formatHour(hour) {
        return hour < 10 ? `0${hour}` : `${hour}`;
    }

    static shortFormatNumber(num) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return num.toString();
    }
}

export default Formatter
