import { Axios } from "axios";
import constants from "../constants";

class TerpPositionService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async getTerpPositionCalls(filter, terp, sorter, offset, limit) {
        try {
            console.log("getTerpPositionCalls filter", filter);
            let response = await this.request.post(
                `/terp-position/language/by-date/${terp}?offset=${offset}&limit=${limit}`,
                {
                    ...filter, 
                    sorter,
                },
                { filter: true }
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getTerpPositionCallsTotal(filter, terp) {
        try {
            console.log("getTerpPositionCallsTotal filter", filter);
            let response = await this.request.post(
                `/terp-position/language/by-date/${terp}/total`,
                {
                    ...filter, 
                },
                { filter: true }
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

}

export default TerpPositionService;
