import { useEffect, useState } from "react";
import {
    Table,
    theme,
    Row,
    Col,
    Spin,
    Card,
    Select,
    Typography,
    Button
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import GetColumnSearchProps from "../../../components/table/search";
import "jspdf-autotable";
import Formatter from "../../../utils/Formatter";
import { getTopCustomer } from "../../../store/actions/analytics.action";
import MemoizedCustomerTreeMap from "./customer.treemap";
import jsPDF from "jspdf";
import exportAsCSV from "../../../services/exportToCVS.service"
import DropDown from '../../../components/dropdown';
import ReactECharts from 'echarts-for-react'
import MemoizedTopCustomerExpandedRowRender from "./top.customer.expand";
import PerformanceLineChart from "./performance.line";

import LocalCustomFilter from '../../transactions/localCustomFilter'
import { exportToExcel } from "../../../services/exportToExcel";

const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

const TopCustomer = () => {
    const {
        token: { colorText, colorBgLayout },
    } = theme.useToken();

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const companyName = state?.company?.selected?.CompanyName
    const id = state?.company?.selected?._id;
    const [loading, setLoading] = useState(true);
    const data = useSelector((state) => state?.analytics?.topCustomer)
    const [disableTreeMap, setDisableTreeMap] = useState(companyName !== undefined)
  
    const { Option } = Select;
    const { Text } = Typography;

    const [sortKey, setSortKey] = useState('TotalServiceMinutes');
    const [downloadType, setDownloadType] = useState("EXCEL");

    useEffect(() => {
        setLoading(true);
        dispatch(getTopCustomer( { [sortKey]: 'desc'}))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        setDisableTreeMap(companyName !== undefined)
    }, [state.filter?.date, state.company?.selected, state.company?.client, sortKey]);

    const handleSortChange = value => {
        setSortKey(value);
    };

    const prepareDataForTable = (dataSource) => {
        return dataSource?.map((item) => {
            const usageData = item.monthlyBreakdown.slice(1, 4).reverse()?.map(m => ({
                name: `${m.Month}/${m.Year}`,
                value: m.TotalServiceMinutes
            }));
            const revenueData = item.monthlyBreakdown.slice(1, 4).reverse()?.map(m => ({
                name: `${m.Month}/${m.Year}`,
                value: m.Revenue
            }));
    
            const usageGrowth = usageData.length > 1 ? (usageData[usageData.length - 1].value - usageData[0].value) : 0;
            const revenueGrowth = revenueData.length > 1 ? (revenueData[revenueData.length - 1].value - revenueData[0].value) : 0;
           

            const usagePercentageChange = usageData.length > 1 && usageData[0].value !== 0 ? 
                ((usageGrowth / usageData[0].value) * 100).toFixed(2) : 0;
            const revenuePercentageChange = revenueData.length > 1 && revenueData[0].value !== 0 ? 
                ((revenueGrowth / revenueData[0].value) * 100).toFixed(2) : 0;
            
            return {
                ...item,
                usagePercentageChange: parseFloat(usagePercentageChange),
                revenuePercentageChange: parseFloat(revenuePercentageChange)
            };
        });
    };
    
    const processedData = prepareDataForTable(data);

    // Define the columns for the table
    const columns = [
        {
            title: "Company Name",
            dataIndex: "CompanyName",
            key: "CompanyName",
            ...LocalCustomFilter({
                dataIndex: 'CompanyName',
                dataSource: processedData,
                // emptySearch: true
              }),
        },
        
        {
            title: "Usage Minutes",
            dataIndex: "TotalServiceMinutes",
            key: "TotalServiceMinutes",
            sorter: (a, b) => a.TotalServiceMinutes - b.TotalServiceMinutes,
            render: (text) => Formatter.numberWithCommas(text, false) || '-'

        },
        
        {
            title: "Revenue",
            dataIndex: "Revenue",
            key: "Revenue",
            sorter: (a, b) => a.Revenue - b.Revenue,
            render: (text) => text != null && text != 0 ? `$${Formatter.numberWithCommas(text, false)}` : '-'
        },
        {
            title: "Total Minutes %",
            dataIndex: "ServiceMinutesPercentage",
            key: "ServiceMinutesPercentage",
            sorter: (a, b) => a.ServiceMinutesPercentage - b.ServiceMinutesPercentage,
            render: (text) => Formatter.numberWithCommas(Math.ceil(text), false) || '-'
        },
        {
            title: "Total Revenue %",
            dataIndex: "RevenuePercentage",
            key: "RevenuePercentage",
            sorter: (a, b) => a.RevenuePercentage - b.RevenuePercentage,
            render: (text) => Formatter.numberWithCommas(Math.ceil(text), false) || '-'

        },
        {
            title: "Last 3 Months Usage",
            dataIndex: "usagePercentageChange",
            key: "LastThreeMonthUsage",
            sorter: (a, b) => a.usagePercentageChange - b.usagePercentageChange,

            
            render: (value, record) => {
                const { monthlyBreakdown } = record;
                const data = monthlyBreakdown.slice(1, 4).reverse()?.map(item => ({
                    name: `${item.Month}/${item.Year}`,
                    value: item.TotalServiceMinutes
                }));
                const color = value > 0 ? '#67C23A' : value < 0 ? '#F56C6C' : '#808080';
                const arrow = value > 0 ? '↑' : value < 0 ? '↓' : '~';
        
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PerformanceLineChart 
                            data={data}
                            colorScheme={color}
                            unit='minutes'
                            style={{ height: '54px', width: '100px' }}
                            notMerge={true}
                            lazyUpdate={true}
                            theme={"theme_name"}
                        />
                        <span style={{ color: color }}>{value}% {arrow}</span>
                    </div>
                );
            }
        },
        {
            title: "Last 3 Months Revenue",
            dataIndex: "revenuePercentageChange",
            key: "LastThreeMonthRevenue",
            sorter: (a, b) => a.revenuePercentageChange - b.revenuePercentageChange,
            render: (value, record) => {
                const { monthlyBreakdown } = record;
                const data = monthlyBreakdown.slice(1, 4).reverse().map(item => ({
                    name: `${item.Month}/${item.Year}`,
                    value: item.Revenue
                }));
                const color = value > 0 ? '#67C23A' : value < 0 ? '#F56C6C' : '#808080';
                const arrow = value > 0 ? '↑' : value < 0 ? '↓' : '~';
    
               
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                       
                         <PerformanceLineChart 
                            data={data}
                            colorScheme={color}
                            unit='$'
                            style={{ height: '54px', width: '100px' }}
                            notMerge={true}
                            lazyUpdate={true}
                            theme={"theme_name"}
                        />
                        <span style={{ color: color }}>{value}% {arrow}</span>
                    </div>
                );
            }
        }
        
        
        
        
        

    ];

    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadExcelFunction();
        }
    };
    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of data) {
            // get monthly array
           
            let downloadMonthly = list.monthlyBreakdown.slice(1, 4)
         
            let monthlyUsageData = downloadMonthly.map(month => `${Formatter.numberWithCommas(month.TotalServiceMinutes)} (${month.Month}/${month.Year})`).join(', ');
            let monthlyRevenueData = downloadMonthly.map(month => `$${Formatter.numberWithCommas(month.Revenue)} (${month.Month}/${month.Year})`).join(', ');
            let newList = {
                "Company Name": list?.CompanyName,
                "Usage Minutes": Formatter.numberWithCommas(list?.TotalServiceMinutes),
                "Revenue":  Formatter.numberWithCommas(list?.Revenue),
                "Total Minutes %": Formatter.numberWithCommas(list?.ServiceMinutesPercentage),
                "Total Revenue %": Formatter.numberWithCommas(list?.RevenuePercentage),
                // add three month here
                "Recent 3 Months Usage": monthlyUsageData,
                "Recent 3 Months Revenue": monthlyRevenueData
                
            };
            downloadArray.push(newList);
        }
      
        const headerList = [
            "Company Name",
            "Usage Minutes",
            "Revenue",
            "Total Minutes %",
            "Total Revenue %",
            "Recent 3 Months Usage",
            "Recent 3 Months Revenue"
        ];
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Top_Customer_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        doc.text("Top Customer Report", 30, 20);
        doc.setFontSize(12);
        doc.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
    };
    const downloadCSVFunction = () => {
        let downloadArray = [];
        
        for (let list of data) {
            let downloadMonthly = list.monthlyBreakdown.slice(1, 4)

            let monthlyUsageData = downloadMonthly.map(month => `${Formatter.numberWithCommas(month.TotalServiceMinutes)} (${month.Month}/${month.Year})`).join(', ');
            let monthlyRevenueData = downloadMonthly.map(month => `$${Formatter.numberWithCommas(month.Revenue)} (${month.Month}/${month.Year})`).join(', ');
            let newList = {
                "Company Name": list?.CompanyName,
                "Usage Minutes": Formatter.numberWithCommas(list?.TotalServiceMinutes),
                "Revenue":  Formatter.numberWithCommas(list?.Revenue),
                "Total Minutes %": Formatter.numberWithCommas(list?.ServiceMinutesPercentage),
                "Total Revenue %": Formatter.numberWithCommas(list?.RevenuePercentage),
                "Recent 3 Months Usage": monthlyUsageData,
                "Recent 3 Months Revenue": monthlyRevenueData
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Top_Customer_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            //new Date().toISOString().split("T")[0],
            [
                "Company Name",
                "Usage Minutes",
                "Revenue",
                "Total Minutes %",
                "Total Revenue %",
                "Recent 3 Months Usage",
                "Recent 3 Months Revenue"
            ],
            filename
        );
    };

    const downloadExcelFunction = () => {
        let downloadArray = [];
        
        for (let list of data) {
            let downloadMonthly = list.monthlyBreakdown.slice(1, 4)

            let monthlyUsageData = downloadMonthly.map(month => `${Formatter.numberWithCommas(month.TotalServiceMinutes)} (${month.Month}/${month.Year})`).join(', ');
            let monthlyRevenueData = downloadMonthly.map(month => `$${Formatter.numberWithCommas(month.Revenue)} (${month.Month}/${month.Year})`).join(', ');
            let newList = {
                "Company Name": list?.CompanyName,
                "Usage Minutes": Formatter.numberWithCommas(list?.TotalServiceMinutes),
                "Revenue":  Formatter.numberWithCommas(list?.Revenue),
                "Total Minutes %": Formatter.numberWithCommas(list?.ServiceMinutesPercentage),
                "Total Revenue %": Formatter.numberWithCommas(list?.RevenuePercentage),
                "Recent 3 Months Usage": monthlyUsageData,
                "Recent 3 Months Revenue": monthlyRevenueData
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Top_Customer_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
        const sheetData = [downloadArray];
        const sheetNames = ["Top Customer Report"];

        exportToExcel(sheetData, sheetNames, filename);
    };

    return (
        <div style={{ padding: '20px'}}>
            <Row gutter={16} style={{ marginBottom: 30 }}>
               {disableTreeMap ? 
                <Col span={24}> 
                    <MemoizedTopCustomerExpandedRowRender CompanyId={id} /> 
                </Col>
                :
               (
                <Col span={24}>
                    <div style={{ marginBottom: 20 }}>
                        <Text strong style={{ marginRight: 8 }}>Sort by:</Text>
                        <Select defaultValue="TotalServiceMinutes" style={{ width: 200 }} onChange={handleSortChange}>
                            <Option value="TotalServiceMinutes">Service Minutes</Option>
                            <Option value="Revenue">Revenue</Option>
                        </Select>
                    </div>
                    <MemoizedCustomerTreeMap data={data} sortKey={sortKey}/>
                </Col>
                )}
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    {loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                            <span className="loading-text">Cooking up your data...</span>
                        </div>
                    ) : (
                        <Table
                            title={() => (
                                        <Row
                                            gutter={[8, 8]}
                                            justify="end"
                                            align="middle"
                                        >
                                            <Col>
                                                <DropDown
                                                    defaultValue={downloadType}
                                                    dropdownOptions={
                                                        downloadDropdownType
                                                    }
                                                    handleTypeSelect={(
                                                        downloadType
                                                    ) =>
                                                        setDownloadType(
                                                            downloadType
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    onClick={downloadFile}
                                                >
                                                    Download
                                                </Button>
                                            </Col>
                                        </Row>
                            )}
                            rowKey={(record) => record?.CompanyName}
                            dataSource={processedData}
                            columns={columns}
                            pagination={{ 
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '25', '50', '100']
                            }}
                            expandable={{
                                expandedRowRender: (record) => <MemoizedTopCustomerExpandedRowRender CompanyId={record?.CompanyId}/>,
                                defaultExpandAllRows: false,
                            }}
                            sticky
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
    
};

export default TopCustomer;
