import { Axios } from "axios";
import constants from "../constants";

class AVRService {
    /**
     * Company Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async getFailedTransactions(filter, offset, limit, sorter, signal) {

        try {
            let response = await this.request.post(
                `/detail-transactions/audio-video-request?offset=${offset}&limit=${limit}`,
                {
                    ...filter,
                    sorter
                },
                { filter: true, signal }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getFailedTransactionsTotal(filter) {

        try {
            let response = await this.request.post(
                `/detail-transactions/audio-video-request/total`,
                {
                    ...filter
                },
                { filter: true }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async reportDownloadCSV(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/audio-video-request/download/CSV`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async reportDownloadCSVUrl(filter, sorter, header, email) {
        try {
            let response = await this.request.post(
                `/detail-transactions/audio-video-request/download/CSV`,
                {
                    ...filter,
                    sorter,
                    header,
                    email
                },
                { filter: true}
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

}

export default AVRService;
