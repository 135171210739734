const ROLE = [
    { value: "Super Admin", label: "Super Admin" },
    { value: "Admin", label: "Admin" },
    { value: "Qa Specialist", label: "QA Specialist"},
    { value: "Monitor", label: "Monitor"},
    { value: "User", label: "User" },
    { value: "Interpreter", label: "Interpreter"}
];
// ROLE.sort((a, b) => a.label.localeCompare(b.label));

const USER = "User";
const SUPER_ADMIN = "Super Admin";
const ADMIN = "Admin";
const MONITOR = "Monitor";
const QA_SPECIALIST = "Qa Specialist";
const INTERPRETER = "Interpreter"

const PROHIBITED_ACCESS = {
    SuperAdmin: [],
    Admin: [],
    User: ["/pages/role-hierarchy"],
    Monitor: ["/pages/invoices", "/pages/invoices/"],
};

export default ROLE;
export { USER, SUPER_ADMIN, ADMIN, MONITOR, PROHIBITED_ACCESS, QA_SPECIALIST, INTERPRETER };
