import { useEffect, useState } from "react";
import { Table, Space, theme, Row, Col, Spin, Button, message } from "antd";
import "./deviceUsage.report.scss";
import { useSelector, useDispatch } from "react-redux";
import { getTotalActiveDevices } from "../../../store/actions/analytics.action";
import moment from "moment";
import Service from "../../../services";
import GetColumnSearchProps from "../../../components/table/search";
import Formatter from "../../../utils/Formatter";
import DropDown from "../../../components/dropdown";
import exportAsCSV from "../../../services/exportToCVS.service";
import jsPDF from "jspdf";
import "jspdf-autotable";
import userEvent from "@testing-library/user-event";
import { useNavigate , Link} from "react-router-dom";
import LocalCustomFilter from '../../transactions/localCustomFilter'
import { exportToExcel } from "../../../services/exportToExcel";
const DeviceUsage = () => {
  const { token: { colorBgLayout } } = theme.useToken();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [kandjiDeviceInfo, setKandiDeviceInfo] = useState(null);
  const userCompany = useSelector((state) => state?.profile?.user?.permissions?.company);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [downloadType, setDownloadType] = useState("EXCEL");

  // Function to fetch Kandji device information
  const fetchDeviceInfo = async (data) => {
    try {
      setLoading(true);
      const response = await Service.Device().getDeviceInfo(data);
      setKandiDeviceInfo(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    const data = {};
    fetchDeviceInfo(data);
    dispatch(getTotalActiveDevices({ RequestCompanyId: userCompany }));
  }, [state.filter?.languageType, state.filter?.date, state.company?.client, userCompany]);


  // Access the data from the Redux store using useSelector
  const deviceUsageData = useSelector((state) => state.analytics.totalActiveCarts);


  // Filter the data based on the CompanyId
  const filteredDeviceUsageData = deviceUsageData
    ?.map((item) => {
      // Find the corresponding device info from kandjiDeviceInfo based on serial number
      const deviceInfo = kandjiDeviceInfo?.find((device) => device.serialNumber === item.IOSSerialNumber);

      // If deviceInfo is found, update the item with device name and type
      if (deviceInfo) {
        return {
          ...item,
          deviceName: deviceInfo.deviceName,
          macAddress: deviceInfo.mac_address,
          deviceType: "Voyce Owned",
        };
      } else {
        // If deviceInfo is not found, set the device name as "Unknown" and device type as "External"
        return {
          ...item,
          deviceName: "Unknown",
          deviceType: "Customer Owned",
        };
      }
    });


  // Select Download Type
  const downloadFile = () => {
    if (downloadType === "PDF") {
      downloadPDF();
    } else if (downloadType === "EXCEL") {
      downloadExcelFunction();
    }
  };

  const downloadDropdownType = [
    {
      value: "PDF",
      label: "PDF",
    },
    {
      value: "EXCEL",
      label: "EXCEL",
    },
  ];

  // PDF download functionality
  const downloadPDF = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }

    const headerList = [
      "Device Name",
      "Serial Number",
      "Mac Address",
      "Minutes Used",
      "Device Owner",
      "Number of Transactions",
    ];
    const bodyList = downloadArray.map((item) => Object.values(item).map((value) => String(value)));
    const doc = new jsPDF("landscape", "px", "a4");
    doc.setFontSize(18);
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
    const filename = `Device_Usage_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
    doc.text("Device Usage Report", 30, 20);
    doc.setFontSize(12);
    doc?.autoTable({
      head: [headerList.map((header) => [header])],
      body: bodyList,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [33, 33, 33],
      },
      startY: 50,
    });
    doc.save(filename);
  };

  // CSV Download Functionality
  const downloadCSVFunction = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
    const filename = `Device_Usage_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
    exportAsCSV(
      downloadArray,
      filename,
      [ 
        "Device Name",
        "Serial Number",
        "Mac Address",
        "Minutes Used",
        "Device Owner",
        "Number of Transactions",
      ],
      filename
    );
  };

  const downloadExcelFunction = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
    const filename = `Device_Usage_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;

    const sheetData = [downloadArray];
    const sheetNames = ["Device Usage Report"];

    exportToExcel(sheetData, sheetNames, filename);
   

  };
  

  // Define the columns for the table
  const columns = [
    {
        title: "Device Name",
        dataIndex: "deviceName",
        key: "deviceName",
        sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
        ...LocalCustomFilter({
          dataIndex: 'deviceName',
          dataSource: filteredDeviceUsageData,
          // emptySearch: true
        }),
    },
    {
        title: "Serial Number",
        dataIndex: "IOSSerialNumber",
        key: "IOSSerialNumber",
        sorter: (a, b) => a.IOSSerialNumber.localeCompare(b.IOSSerialNumber),
        ...LocalCustomFilter({
          dataIndex: 'IOSSerialNumber',
          dataSource: filteredDeviceUsageData,
          // emptySearch: true
        }),
  
    },
    {
        title: "Mac Address",
        dataIndex: "macAddress",
        key: "macAddress",
        ...LocalCustomFilter({
          dataIndex: 'macAddress',
          dataSource: filteredDeviceUsageData,
          // emptySearch: true
        }),
        
      },
    {
        title: "Minutes Used",
        dataIndex: "ServiceMinutes",
        key: "ServiceMinutes",
        sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
        defaultSortOrder: 'descend'
    
    },
    {
        title: "Device Owner",
        dataIndex: "deviceType",
        key: "deviceType",
        sorter: (a, b) => a.deviceType.localeCompare(b.deviceType),
        ...LocalCustomFilter({
          dataIndex: 'deviceType',
          dataSource: filteredDeviceUsageData,
          // emptySearch: true
        }),
    },
    {
        title: "Number Of Transaction",
        dataIndex: "NumberOfServices",
        key: "NumberOfServices",
        sorter: (a, b) => a.NumberOfServices - b.NumberOfServices,
        render: (text, record) => (
          <Link
            to={`/pages/transactions?dataIdx0=IOSSerialNumber&status0=${record.IOSSerialNumber}`}
          >
            {text}
          </Link>
        ),
      },
      

  ];

  return (
    <Row>
        <Col className='language-col' flex={"auto"}>
        {loading ? ( // Check if loading state is true
            <div className="loading-container">
            <Spin size="large" />
            <span className="loading-text">Cooking up your data...</span>
            </div> // Show the loader while data is being fetched
        ) : (
          <Table
          title={() => (
            <Row
                gutter={[8, 8]}
                justify="end"
                align="middle"
            >
                <Col>
                    <DropDown
                        defaultValue={downloadType}
                        dropdownOptions={
                            downloadDropdownType
                        }
                        handleTypeSelect={(
                            downloadType
                        ) =>
                            setDownloadType(
                                downloadType
                            )
                        }
                    />
                </Col>
                <Col>
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={downloadFile}
                    >
                        Download
                    </Button>
                </Col>
            </Row>
)}
            dataSource={filteredDeviceUsageData}
            columns={columns}
            pagination={false}
          />
        )}             
        </Col>
    </Row>
)
};

export default DeviceUsage;