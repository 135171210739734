import { Result, Button, theme } from "antd"
import { useNavigate } from "react-router-dom";

const Page404 = () => {

    const navigate = useNavigate();
    const { token: { colorBgLayout } } = theme.useToken();

    return (
        <Result
            style={{backgroundColor: colorBgLayout, height: '100vh'}}
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button onClick={() => navigate({pathname: '/pages'})} type="primary">
                    Back Home
                </Button>
            }
        />
    )
}

export default Page404;