import { theme } from "antd";
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import { useSelector } from "react-redux";

const graphColors = [
    '#6c40a6',
    '#0f9191',
    '#226885',
    '#61636f'
]

const AVRequestChart = (props) => {
    
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const data = useSelector((state) => state.analytics?.avrBarChart);

    const options = {
        toolbox: {        
            right: 0,
            top: -5,   
            feature: {      
                saveAsImage: {
                    backgroundColor: colorBgLayout,
                    color: colorText
                } 
            }      
        },
        legend: {
            data: ['Total Video Call Requests', 'Converted to Audio Calls', 'Successful Video Calls'],
            align: 'left',
            top: 0,
            textStyle: { color: colorText },
        },
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            },
            axisPointer: {
              type: 'shadow',
            },
            // formatter: function ([params]) {
            //     return `<b><span style="color: ${params.data.itemStyle.color}">${params.seriesName}</span></b><br />
            //             ${params.name}: <b>${params.data.value}</b>`;
            // } 
        },
        grid: {
            top: '35%',
            left: 0,
            right: 40,
            bottom: 50,
            containLabel: true,
            background: 'black'
        },
        xAxis: [
            {
                type: "category",
                data: data?.legends,
                axisTick: {
                    alignWithLabel: true,
                    color: colorText,
                },
                axisLabel: {
                    textStyle: {
                        color: colorText,
                    },
                }
            },
        ],
        yAxis: [
            {
                name: "Video Call Requests",
                type: "log",
                axisLine: {
                    lineStyle: {
                        color: colorText,
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: colorText,
                    },
                    formatter: '{value} calls'
                },
            },
        ],
        series: [
            {
                name: "Total Video Call Requests",
                type: "bar",
                data: data?.total || [],
                itemStyle: {
                    color: '#6c40a6'
                }
            },
            {
                name: 'Successful Video Calls',
                type: 'bar',
                stack: 'Successful Video Calls',
                data: data?.success,
                itemStyle: {
                    color: '#226885'
                }
            },
            {
                name: 'Converted to Audio Calls',
                type: 'bar',
                stack: 'Converted to Audio Calls',
                data: data?.failed || [],
                itemStyle: {
                    color: '#0f9191'
                }
            },
        ],
        dataZoom: [
            {
                show: true,
                start: 0,
                left: 4,
                right: 0,
                end: 100,
            },
            {
                show: true,
                type: 'inside',
                start: 0,
                end: 100,
            },
        ],
    };

    return (
        <EChartsReact style={{ height: 320 }} option={options}/>
    )

}

export default AVRequestChart;