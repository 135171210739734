import { 
    Card, 
    Col, 
    Radio, 
    Row, 
    Spin, 
    Table, 
    theme,
    Input,
    Space,
    Button,
    Modal,
    Typography,
} from "antd"
import './avr.scss';
import AVRPieChart from "./percentage.pie";
import AVRequestChart from "./avrequest.bar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState, useCallback } from "react";
import { getAVRChartData } from "../../../store/actions/avr.action";
import AVRequestAreaChart from "./avrequest.area";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Service from "../../../services";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import DropDown from '../../../components/dropdown';
import LocalCustomFilter from '../../transactions/localCustomFilter'
import CustomFilter from "../../transactions/customFilter";

const GetColumnSearchProps = (dataIndex, updateFilters, isLive) => {
    const uniqueId = `audio-video-filter-${dataIndex.dataIndex}`;

    return ({
        filterDropdown: (props) => <CustomFilter 
            dataIndex={dataIndex.dataIndex} 
            showTimeRange={dataIndex.showTimeRange} 
            updateFilters={updateFilters}
            timeZone={dataIndex.timeZone}
            id={uniqueId}
            {...props} 
            />,
        filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined,
              }}
            />
          ),
    })
  };

const expandedRowRender = (record) => {
    let data = [
        {
            InterpreterFirstName: record?.InterpreterFirstName,
            InterpreterLastName: record?.InterpreterLastName,
            ServiceStartTime: record?.ServiceStartTime,
            Timezone: record?.Timezone,
        },
    ];
    const columns = [
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            render: (first, row) =>
                `${first || ""} ${row?.InterpreterLastName || ""}`,
            width: "30%",
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            render: (row) => (row ? row.split("T")[1] : "-"),
            width: "35%",
        },
    ];
    return (
        <Table
            key={record?.Id + "_sub"}
            title={() => <b>Call Accepted By</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            width="20px"
            pagination={false}
            rowClassName={(record, index) =>
                record?.AcceptTime ? "accept-row" : "declined-row"
            }
        />
    );
};

const AudioVideoReport = (props) => {

    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [expandAll, setExpandAll] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 100,
          total: 0
        },
    });
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filterSearch, setFilterSearch] = useState({});
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [downLoadLoading, setDownLoadLoading] = useState(false);
    const searchInput = useRef(null);
    const abortControllerRef = useRef(null);
    const [params, setParams] = useState();
    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);
    useEffect(() => {
        setLoading(true)
        dispatch(getAVRChartData())
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
        fetchData(params?.pagination, params?.filters, params?.sorter);
    }, [
        state.filter?.languageType, 
        state.filter?.date, 
        state.company?.selected, 
        state.company?.client,
        filterValues,
    ]);

    useEffect(() => {
        fetchData({
            current: 1,
            pageSize: 100,
            total: 0
        })
    }, 
    [ searchText ]
    );

    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData(params?.pagination, params?.filters, params?.sorter);
        }
    }, [params]);
    
    const fetchData = (pagination, filters, sorter) => {
        setTableLoading(true);
        if (abortControllerRef.current) {
            // Cancel the previous request
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();        
        let paramsx = pagination ? {
            pagination,
            filters,
            sorter,
        } : tableParams
        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch),
            },
        };
        getTotalRows(paramsx, filter);
        Service.AVR().getFailedTransactions(
            filter,
            ((paramsx.pagination.current - 1) * paramsx.pagination.pageSize),
            paramsx.pagination.pageSize,
            {
                [sorter?.field || "RequestTime"]: sorter?.order || "ascend",
            },
            abortControllerRef.current.signal
        )
        .then((data) => {
            setRows(data)
            setTableLoading(false);
        });
    };

    const getTotalRows = (params, filter) => {
        Service.AVR()
            .getFailedTransactionsTotal(filter)
            .then((data) => {
                if (data && data[0]) {
                    setTableParams({
                        ...params,
                        pagination: {
                            ...params.pagination,
                            total: data[0].total,
                        },
                    });
                }
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setParams({ pagination, filters, sorter });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        if(selectedKeys.length > 0 && typeof selectedKeys[0] == "string") {
            selectedKeys[0] = selectedKeys[0]?.trim();

        }

        confirm();
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys[0],
            };
            return updatedFilterSearch;
        });
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        setFilterSearch((prevState) => {
            const updatedFilterSearch = { ...prevState };
            delete updatedFilterSearch[dataIndex];
            return updatedFilterSearch;
        });
        setSearchText("");
        confirm();
    };


    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "ReferenceTransactionId",
            fixed: true,
            sorter: (a, b) => a.ReferenceTransactionId - b.ReferenceTransactionId,
            ...LocalCustomFilter({
                dataIndex: 'ReferenceTransactionId',
                dataSource: rows,
                // emptySearch: true
              }),
        },
        {
            title: "Request Time",
            dataIndex: "RequestTime",
            width: "200px",
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "Request Type",
            dataIndex: "RequestType",
            width: "180px",
            ...GetColumnSearchProps({ dataIndex: "RequestType" }, updateFilters),
            sorter: (a, b) => a.RequestType?.localeCompare(b.RequestType),
        },
        {
            title: "Call Type",
            dataIndex: "VideoOption",
        },
        {
            title: "Client Name",
            dataIndex: "ClientName",
            width: "280px",
            sorter: (a, b) => a.ClientName?.localeCompare(b.ClientName),
            ...GetColumnSearchProps({ dataIndex: "ClientName" }, updateFilters),
        },
        {
            title: "Target Language",
            dataIndex: "TargetLanguage",
            sorter: (a, b) => a.TargetLanguage?.localeCompare(b.TargetLanguage),
            ...GetColumnSearchProps({ dataIndex: "TargetLanguage" }, updateFilters),
        },
        {
            title: "Waiting Seconds",
            dataIndex: "WaitingSeconds",
            sorter: (a, b) => a.WaitingSeconds - b.WaitingSeconds,
        },
        {
            title: "Service Minutes",
            dataIndex: "ServiceMinutes",
            sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
            render: (text) => Math.ceil(text)
        },
        {
            title: "Caller Id",
            dataIndex: "CallerID",
            width: "180px",
            render: (text) => text || "-",
        },
    ];

    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of rows) {
            let newList = {};
            columns.forEach((column) => newList[column.dataIndex] = list[column.dataIndex])
            downloadArray.push(newList);
        }
      
        const headerList = columns.map((column) => column.dataIndex);;
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Audio_Video_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        doc.text("Audio Video Report", 30, 20);
        doc.setFontSize(12);
        doc.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
    };
      
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadCSVFunction(); 
        }
    };

    const downloadCSVFunction = (pagination, filters, sorter) => {
        let params = pagination
            ? {
                  pagination,
                  filters: filterSearch,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch),
            },
            // filters: filterSearch,
        };
        setDownLoadLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        if (!dataIndexValues.includes('ServiceStartTime')) {
            dataIndexValues.push('ServiceStartTime');
        }
        if (!dataIndexValues.includes('InterpreterFirstName')) {
            dataIndexValues.push('InterpreterFirstName');
        }
        if (state?.filter?.date?.dateRangeLabel === "Today") {
            Service.AVR()
            .reportDownloadCSV(filter, params.sort, dataIndexValues)
            .then((data) => {
                setDownLoadLoading(false);
                const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
                const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
                const filename = `Audio_Video_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        } else {
            Service.AVR()
            .reportDownloadCSVUrl(filter, params.sort, dataIndexValues, state?.profile?.user?.email)
            .then((data) => {
                setDownLoadLoading(false);
                const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
                const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
                const filename = `Audio_Video_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
                const config = {
                    title: filename,
                    content: (
                        <Typography.Text>
                            Your report is currently being generated. You will receive an email shortly with a download link. <br/> <strong>Please note that the link will only be valid for one hour after you receive it</strong>.
                        </Typography.Text>
                    ),
                };
                Modal.info(config);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        }
        
    };
    
    return (
        <Row className="avr-container" >
            <Col flex={"100%"}>
                <Row justify={"center"} align={"middle"} gutter={20} wrap={false}>
                    <Col flex={"auto"}>
                    <Spin spinning={loading}>
                        <Card style={{ backgroundColor: colorBgLayout, borderRadius: 4}} >
                        <AVRequestAreaChart/>
                        </Card>
                    </Spin>
                    </Col>
                </Row>
                <br/>
                <Row justify={"center"} align={"middle"} gutter={20} wrap={false} className="chart-div">
                    <Col flex={"auto"}>
                    <Spin spinning={loading}>
                        <Card style={{ backgroundColor: colorBgLayout, borderRadius: 4}} >
                        <AVRequestChart/>
                        </Card>
                    </Spin>
                    </Col>
                    <Col flex={"25%"}>
                        <Spin spinning={loading}>
                            <Card style={{ backgroundColor: colorBgLayout, borderRadius: 4}} className="pieChart-card">
                            <AVRPieChart/>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"100%"}>
                    <Table 
                        title={() => <>
                                <Row align={"middle"} gutter={10}>
                                    <Col>
                                        <div style={{color:'white', fontSize: 18}}>{`Video Call Request and Failure Analysis Report`}</div>
                                    </Col>
                                    <Col style={{marginLeft: 'auto'}}>
                                        <Radio.Group 
                                            buttonStyle="solid"
                                            options={[
                                                { label: 'Collapse All', value: false },
                                                { label: 'Expand All', value: true }
                                            ]}
                                            optionType="button"
                                            value={expandAll}
                                            onChange={(e) => setExpandAll(e.target.value)}
                                        />
                                    </Col>
                                    <Col > 
                                        <DropDown
                                            defaultValue={downloadType}
                                            dropdownOptions={
                                                downloadDropdownType
                                            }
                                            handleTypeSelect={(
                                                downloadType
                                            ) =>
                                                setDownloadType(
                                                    downloadType
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            loading={downLoadLoading}
                                            type="primary"
                                            onClick={downloadFile}
                                        >
                                            Download
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                            style={{width: '100%', backgroundColor: colorBgLayout}} 
                            columns={columns}
                            rowKey={(record) => record?.Id}
                            dataSource={rows}
                            pagination={tableParams.pagination}
                            loading={tableLoading}
                            onChange={handleTableChange}
                            size="middle"
                            className="voyce-custom-table"
                            expandable={{
                                expandedRowRender,
                                defaultExpandAllRows:false,
                                expandedRowKeys: expandAll ? rows?.map(o => o.Id) : undefined,
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AudioVideoReport;