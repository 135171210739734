import { Route, Routes, Navigate } from "react-router-dom";
import Page404 from "./pages/404";
import { Auth, Layout, ProtectedRoutes } from "./pages";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/auth/forgot.password";
import ResetPassword from "./pages/auth/reset.password";
import InterpreterRoutingHistory from "./pages/reports/interpreter.routing.history/interpreter.routing.history";
import Terp from "./pages/terp.hud/terp";
import RoleHierarchy from "./pages/role";
import Invoices from "./pages/invoices";
import Transactions from "./pages/transactions";
import ActivityMonitor from "./pages/transactions/activityMonitor"
import LanguageReport from "./pages/reports/language.report/language.report";
import AudioVideoReport from "./pages/reports/audio.video.report/audio.video";
import DeviceUsageReport from "./pages/reports/deviceUsage.report/deviceUsage.report";
import UserAudit from "./pages/reports/audit.report/audit.report";
import AdminAudit from "./pages/reports/audit.report/admin.audit.report";
import NonServicedReport from "./pages/cc.reports/non.serviced.report/non.serviced.report";
import TerpMonitor from "./pages/cc.reports/terp.avaialbility/terp.availability.report";
import QA from "./pages/qa/index"
import QAReport from './pages/qa-report/qa.report'
import TopCustomer from "./pages/reports/top.customer.report/top.customer";
import TerpHud from "./pages/terp.hud";
import QAHud from "./pages/qa-hud";
import HudProvider from "./pages/qa-hud/store/hud.provider";

const Router = () => {
    return (
        <>
          <Routes>
            <Route path="/auth" element={<Auth />}>
              <Route path={"signup"} element={<Signup />}></Route>
              <Route path={"login"} element={<Login />}></Route>
              <Route path={"forgot-password"} element={<ForgotPassword />} ></Route>
              <Route path={"reset-password/:token"} element={<ResetPassword />} ></Route>
              <Route index path="*" element={<Navigate to="/auth/login" />} ></Route>
            </Route>

            <Route path="/pages" element={<ProtectedRoutes />}>
              {/**
               * Add all protected routes here.
               * (Ones which need authentication.)
               */}
              <Route path="/pages" element={<Layout />}>
                <Route index element={<Dashboard />} />
                {/* <Route path={"terp"} element={<Terp />} /> */}
                <Route path={"activity-monitor"} element={<ActivityMonitor />} />
                <Route path={"transactions"} element={<Transactions />} />
                <Route path={"role-hierarchy"} element={<RoleHierarchy />} />
                {/* <Route path={"terp"} element={<Terp />} /> */}
                <Route path={"invoices"} element={<Invoices/>}/>
                <Route path="reports" >
                  {/* <Route index path={"interpreter-routing-history"} element={<InterpreterRoutingHistory/>} /> */}
                  <Route index path={"language-report"} element={<LanguageReport/>} />
                  <Route index path={"audio-video-report"} element={<AudioVideoReport/>} />
                  <Route index path={"device-usage"} element={<DeviceUsageReport/>} />
                  <Route index path={"user-audit"} element={<UserAudit/>} />
                  <Route index path={"admin-audit"} element={<AdminAudit/>} />
                  <Route index path={"top-customer"} element={<TopCustomer/>} />
                </Route>
                <Route path="cc-reports" >
                  <Route path={"non-service-calls"} element={<NonServicedReport/>} />
                  {/* <Route path={"terp-availability-report"} element={<TerpMonitor/>} /> */}
                </Route>
                <Route path="cc-reports" >
                  <Route path={"non-service-calls"} element={<NonServicedReport/>} />
                  <Route path={"terp-availability-report"} element={<TerpMonitor/>} />
                </Route>
                 
                <Route path="terp-hud" element={<TerpHud />}>
                  <Route path="dashboard" element={<Terp />} />
                  <Route path="availability-report" element={<TerpMonitor />} />
                  <Route path="routing-history" element={<InterpreterRoutingHistory />} />
                </Route>
                {/* 
                  Add Route here for diffrent pages that needs to be rendered
                  inside the dashboard layout.
                  eg. <Route path="/device-usage" element={<DeviceUsagePage/>} /> 
                */}
              </Route>

            </Route>  
            <Route path="/hud" element={<ProtectedRoutes />} >
              <Route path="/hud" element={<Layout />}>
                <Route path={"qa"} element={<QAHud />} />
                <Route path={"QA/Report"} element={<QAReport />} />
              </Route>
            </Route>

            {/* Wildcard path should always be the last route defined, to avoid redirections */}
            <Route index path="/" element={<Navigate to="/auth/login" />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </>
    );
};

export default Router;