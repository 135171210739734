import { store } from '.';
import { io } from 'socket.io-client'
import constants from './constants';

const getToken = () => {
    let state = store.getState();
    return state?.profile?.token
}

const getSession = () => {
  return sessionStorage.getItem('session')
}

export const socket = io(constants.SOCKET_BASE_URL, {
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket'],
  auth: (cb) => {
    cb({ 
      ['token']: getToken(),
      ['sessionId']: getSession()
    })
  }
})
