import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar'

const Spinner = () => {
    const ref = useRef(null)
    const isLoading = useSelector((state) => state.profile?.isLoading);

    let color = `linear-gradient(90deg, cyan 0%, #0a9191 25%, #6c40a6 100%)`

    useEffect(() => {
        if (isLoading) {
            ref.current?.continuousStart();
        } else {
            ref.current?.complete()
        }
    }, [isLoading])

    return (
        <LoadingBar height={2} color={color} ref={ref} />
    )
}

export default Spinner;