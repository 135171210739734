const events = {
  START_RTM_SESSION: 'START_RTM_SESSION',
  ERROR_IN_START_RTM_SESSION: 'ERROR_IN_START_RTM_SESSION',

  DUPLICATE_USER_SESSION_FOUND: 'DUPLICATE_USER_SESSION_FOUND',

  ADD_INTERPRETER_TO_WATCHLIST: 'ADD_INTERPRETER_TO_WATCHLIST',
  ADDED_INTERPRETER_TO_WATCHLIST: 'ADDED_INTERPRETER_TO_WATCHLIST',

  REMOVE_INTERPRETER_TO_WATCHLIST: 'REMOVE_INTERPRETER_TO_WATCHLIST',
  REMOVED_INTERPRETER_TO_WATCHLIST: 'REMOVED_INTERPRETER_TO_WATCHLIST',

  START_INTERPRETER_EVALUATION: 'START_INTERPRETER_EVALUATION',
  SUBMIT_INTERPRETER_EVALUATION: 'SUBMIT_INTERPRETER_EVALUATION',
  LEAVE_INTERPRETER_EVALUATION: 'LEAVE_INTERPRETER_EVALUATION',

  START_INTERPRETER_MONITORING: 'START_INTERPRETER_MONITORING',
  STOP_INTERPRETER_MONITORING: 'STOP_INTERPRETER_MONITORING',

  UPDATE_ONGOING_SESSIONS: 'UPDATE_ONGOING_SESSIONS',

  START_POLLING: 'START_POLLING',

  ASK_FOR_DATA: 'ASK_FOR_DATA',

  CHANGE_TIME_RANGE: 'CHANGE_TIME_RANGE',

  FORCE_LOGIN: 'FORCE_LOGIN',
  FORCE_LOGOUT: 'FORCE_LOGOUT',

  ERROR_MESSAGE: 'ERROR_MESSAGE',

  ASK_FOR_NEW_UPDATE: 'ASK_FOR_NEW_UPDATE',
  UPDATE_INTERPRETERS: 'UPDATE_INTERPRETERS',
  UPDATE_SCORES: 'UPDATE_SCORES',

  USER_CONNECTED: 'USER_CONNECTED',

  ASK_HISTORIC: 'ASK_FOR_HISTORIC',
  SEND_HISTORIC: 'SEND_HISTORIC',

  CHECK_MONITOR_AVAILABLE: 'CHECK_MONITOR_AVAILABLE',
  ADD_ONGOING_SESSIONS: 'ADD_ONGOING_SESSIONS',
  REMOVE_ONGOING_SESSIONS: 'REMOVE_ONGOING_SESSIONS'
}

export default events
