import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';

const graphColors = [
    '#6c40a6',
    '#0f9191',
    '#226885',
    '#61636f'
];

export function setAVRChart(res) {

    const avrBarChart = {
        legends: [],
        total: [],
        failed: [],
        success: [],
    };

    const avrPieChart = [
        { value: 0, name: `Video Calls Converted to Audio Calls`, itemStyle: {color: graphColors[1] || 'grey' }},
        { value: 0, name: `Successful Video Calls`, itemStyle: {color: graphColors[0] || 'grey' }}
    ]

    res.forEach((element) => {
        avrBarChart.legends.push(element.date?.split("T")[0]);
        avrBarChart.failed.push(element.totalAudioCalls);
        avrBarChart.total.push(element.totalVideoCalls);
        avrBarChart.success.push(element.totalVideoCalls - element.totalAudioCalls);

        avrPieChart[0].value += element.totalAudioCalls;
        avrPieChart[1].value += (element.totalVideoCalls - element.totalAudioCalls);
    });

    return {
        type: ActionType.SET_AVR_CHART,
        avrPieChart,
        avrBarChart
    }
}

export function getAVRChartData() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getAVRChartCount(filter);
            console.log(data)
            dispatch(setAVRChart(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}