import {
    Spin,
    Typography,
    Button,
    Select
} from "antd";
import { useEffect, useState, memo } from "react";
import Service from "../../../services";
import "jspdf-autotable";
import Formatter from "../../../utils/Formatter";
import ReactECharts from 'echarts-for-react'

const { Text } = Typography;
const { Option } = Select;
const metricToField = {
    'Usage': 'TotalServiceMinutes',
    'Revenue': 'Revenue'
};

const TopCustomerExpandedRowRender = ({ CompanyId }) => {
    const [monthlyData, setMonthlyData] = useState([]);
    const [extendedMonthlyData, setExtendedMonthlyData] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState('Usage');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMonthlyData();
    }, [CompanyId]);

    const fetchMonthlyData = async () => {
        setLoading(true);
        try {
            const filter = {
                id: CompanyId
            };
            const response = await Service.Analytics().getCustomerMonthly(filter);
            if (response && Array.isArray(response)) {
                const responseReversed = response.reverse();
                setMonthlyData(responseReversed.slice(Math.max(responseReversed.length - 25, 0), responseReversed.length - 1));
                // setExtendedMonthlyData(responseReversed.slice(Math.max(responseReversed.length - 37, 0), responseReversed.length - 1));
                setExtendedMonthlyData(responseReversed.slice(0, 36));
                
            } else {
                console.error("Unexpected response format:", response);
            }
        } catch (error) {
            console.error("Failed to fetch monthly data", error);
        }
        setLoading(false);
    };
    
    const getOption = () => {
        const dataField = metricToField[selectedMetric];
        const endDateIndex = extendedMonthlyData.length;
        // check valid slicing range
        const startDateIndex = Math.max(endDateIndex - 24, 0);
        
        const displayData = extendedMonthlyData.slice(startDateIndex, endDateIndex);
    
        const serviceData = displayData.map(item => ({
            name: `${item.Month}/${item.Year}`,
            value: item[dataField]
        }));
        
    
        const momData = displayData.map((item, index) => {
            if (index === 0) {
                // previous month's data in extendedMonthlyData
                if (startDateIndex > 0) {
                    const previousMonthData = extendedMonthlyData[startDateIndex - 1];
                    const momChange = ((item[dataField] - previousMonthData[dataField]) / previousMonthData[dataField] * 100).toFixed(2);
                    return {
                        name: `${item.Month}/${item.Year}`,
                        value: momChange
                    };
                }
                // If no previous month data available
                return { name: `${item.Month}/${item.Year}`, value: null };
            }
            const previous = displayData[index - 1];
            const momChange = ((item[dataField] - previous[dataField]) / previous[dataField] * 100).toFixed(2);
            return {
                name: `${item.Month}/${item.Year}`,
                value: momChange
            };
        });
    
        const yoyData = displayData.map(item => {
            const lastYearIndex = extendedMonthlyData.findIndex(prev => prev.Year === item.Year - 1 && prev.Month === item.Month);
            if (lastYearIndex === -1) return { name: `${item.Month}/${item.Year}`, value: null };
            const lastYearData = extendedMonthlyData[lastYearIndex];
            const yoyChange = ((item[dataField] - lastYearData[dataField]) / lastYearData[dataField] * 100).toFixed(2);
            return {
                name: `${item.Month}/${item.Year}`,
                value: yoyChange
            };
        });

        const qoqData = displayData.map((item, index) => {
            if (index < 3) {
                // check index of element is 3 months apart
                const extendedIndex = startDateIndex + index - 3;
                // if the extendedIndex is valid
                if (extendedIndex >= 0) {
                    const quarterPrevious = extendedMonthlyData[extendedIndex];
                    const qoqChange = ((item[dataField] - quarterPrevious[dataField]) / quarterPrevious[dataField] * 100).toFixed(2);
                    return {
                        name: `${item.Month}/${item.Year}`,
                        value: qoqChange
                    };
                }
                return { name: `${item.Month}/${item.Year}`, value: null };
            }
        
            const quarterPrevious = displayData[index - 3];
            const qoqChange = ((item[dataField] - quarterPrevious[dataField]) / quarterPrevious[dataField] * 100).toFixed(2);
            return {
                name: `${item.Month}/${item.Year}`,
                value: qoqChange
            };
        });

        return {
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params.map((param) => {
                        let color = param.data.value > 0 ? 'green' : (param.data.value < 0 ? 'red' : 'black');
                        if (param.seriesName === selectedMetric) {
                            return `${param.axisValueLabel}<br/>${param.marker}${Formatter.numberWithCommas(param.data.value)} ${selectedMetric === "Usage" ? 'minutes' : 'Dollars'}`;
                        } else if (param.data.value !== null) {
                            return `${param.marker}${param.seriesName}: <span style='color:${color};'>${Formatter.numberWithCommas(param.data.value)}%</span>`;
                        }
                        return '';
                    }).filter(text => text).join('<br>');
                }
            },
            legend: {
                data: [selectedMetric, 'MoM Change', 'QoQ Change', 'YoY Change'],
                top: 60,
                left: 10,
            orient: 'vertical', 
                textStyle: {
                    fontWeight: 'bold',
                    fontSize: 14,
                    fontColor: '#334455'
                }
            },
            xAxis: {
                type: 'category',
                data: serviceData.map(item => item.name),
                axisTick: { alignWithLabel: true },
                axisLabel: { interval: 0, rotate: 45, fontWeight: 'bold' },
                
            },
            yAxis: [
                {
                    type: 'value',
                    name: selectedMetric,
                    position: 'left',
                    axisLabel: {
                        fontWeight: 'bold'
                    }
                },
                {
                    type: 'value',
                    name: 'Change (%)',
                    position: 'right',
                    axisLabel: {
                        formatter: '{value} %',
                        fontWeight: 'bold'
                    }
                }
            ],
            series: [
                {
                    name: selectedMetric,
                    data: serviceData,
                    type: 'line',
                    smooth: true,
                    areaStyle: {},
                    
                },
                {
                    name: 'MoM Change',
                    data: momData,
                    type: 'line',
                    smooth: true,
                    yAxisIndex: 1,
                    lineStyle: {
                        color: '#FFD700'
                    },
                    itemStyle: {
                        color: '#FFD700'
                    },
                    showSymbol: false
                },
                {
                    name: 'QoQ Change',
                    data: qoqData,
                    type: 'line',
                    smooth: true,
                    yAxisIndex: 1,
                    lineStyle: { color: '#4682B4' },
                    itemStyle: { color: '#4682B4' },
                    showSymbol: false
                },
                {
                    name: 'YoY Change',
                    data: yoyData,
                    type: 'line',
                    smooth: true,
                    yAxisIndex: 1,
                    lineStyle: { color: '#FF6347' },
                    itemStyle: { color: '#FF6347' },
                    showSymbol: false
                }
            ],
            grid: {
                left: '10%',
                top: '10%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: { title: 'Download' }
                },
                // left: 10,
                // top: 140,
            },
        };
    };
    
    
    return (
        <div>
        
            {monthlyData?.length > 0 && !loading ? (
                <div>
                <Select defaultValue="Usage" style={{  position: 'absolute', left: 30, top: 20, zIndex: 10, width: 100  }} onChange={setSelectedMetric}>
                    <Option value="Usage">Usage</Option>
                    <Option value="Revenue">Revenue</Option>
                </Select>
                <ReactECharts option={getOption()} style={{ height: '400px', width: '100%' }} />
                </div>
            ) : !loading ? (
                <div>No data available</div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spin size="small" style={{ marginRight: 10 }} />
                    <Text strong>Loading...</Text>
                </div>
            )
            }
        </div>
    );
};

export default TopCustomerExpandedRowRender;
export const MemoizedTopCustomerExpandedRowRender = memo(TopCustomerExpandedRowRender)
