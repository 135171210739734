import { Radio } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setYear } from '../../store/actions/filter.action'
import moment from 'moment'

var previousYear = new Date()
previousYear.setFullYear(previousYear.getFullYear() - 1)
previousYear = previousYear.getFullYear().toString()

var currentYear = new Date()
currentYear = currentYear.getFullYear().toString()

//Option of years for which we want to fetch data
const YearOptions = [
    { label: previousYear, value: previousYear },
    { label: currentYear, value: currentYear }
]
const YearFilter = (props) => {
    const dispatch = useDispatch()
    const yearFilter = useSelector((state) => state?.filter?.year)

    let year = moment(yearFilter?.minDate).format('YYYY')

    return (
        <Radio.Group
            buttonStyle="solid"
            options={YearOptions}
            optionType="button"
            value={year}
            onChange={(e) => dispatch(setYear(e.target.value))}
        />
    )
}

export default YearFilter
