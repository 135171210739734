import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Steps,
  Radio,
  message,
  Typography,
  Checkbox,
  Select
} from 'antd'
import { useHud } from '../store/hud.context';
import { useState } from 'react';

const EvaluationForm = () => {
  const [formStep, setFormStep] = useState(0);
  const [formData, setValues] = useState({});
  const { handleEvaluationSubmit, monitoringRecord, loading }  = useHud()

  const handleForm1Submit = (values) => {
    console.log(values)
    setFormStep(formStep + 1)
  }

  const handleForm3Submit = (values) => {
    const scorableObject = {
      ...values,
      notes: 0
    }
    const score = Object.values(scorableObject).reduce(
      (partialSum, a) => partialSum + a,
      0
    )
    setValues({
      score: score,
      ...values
    })
    console.log('Score Saved - ', score)
    setFormStep(formStep + 1)
  }

  const handleForm4Submit = (values) => {
    setFormStep(formStep + 1)
    setValues((state) => ({ ...state, ...values }))
  }

  const handleForm5Submit = (values) => {
    handleEvaluationSubmit({
      score: formData?.score,
      notes: formData.notes,
      answers: { ...formData, ...values },
    })
  }

  const renderStep1 = () => {
    return (
      <Form
        key={'renderStep1'}
        layout="vertical"
        initialValues={{
          interpreterId: monitoringRecord?.InterpreterId,
          interpreterName:
          monitoringRecord?.FirstName + (monitoringRecord?.LastName ? monitoringRecord?.LastName : ''),
          interpreterLanguages: monitoringRecord?.Languages?.join(),
          interpreterCompanyId: monitoringRecord?.CompanyId,
          interpreterCompanyName: monitoringRecord?.CompanyCode
        }}
        onFinish={handleForm1Submit}
      >
        <Form.Item name={'interpreterId'} label="Interpreter Id">
          <Input disabled />
        </Form.Item>
        <Form.Item name={'interpreterName'} label="Interpreter Name">
          <Input disabled />
        </Form.Item>
        <Form.Item name={'interpreterLanguages'} label="Interpreter Languages">
          <Input disabled />
        </Form.Item>
        <Form.Item name={'interpreterCompanyId'} label="Interpreter Company Id">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={'interpreterCompanyName'}
          label="Interpreter Company Name"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item>
          <Row gutter={10}>
            <Col flex={'auto'}>
              <Button type={'primary'} block htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const renderStep2 = () => {
    return (
      <Form
        key={'renderStep2'}
        initialValues={{
          requestTime: new Date().toString(),
          requestId: monitoringRecord?.RequestId
        }}
        layout="vertical"
        onFinish={handleForm1Submit}
      >
        <Form.Item name={'requestTime'} label="Transaction Date">
          <Input disabled />
        </Form.Item>
        <Form.Item name={'requestId'} label="Transaction Id">
          <Input disabled />
        </Form.Item>
        <Form.Item>
          <Row gutter={10}>
            <Col flex={'auto'}>
              <Button
                type={'primary'}
                block
                danger
                onClick={() => setFormStep(formStep - 1)}
              >
                Back
              </Button>
            </Col>
            <Col flex={'auto'}>
              <Button type={'primary'} block htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const renderStep3 = () => {
    return (
      <Form
        key={'renderStep3'}
        labelAlign={'left'}
        labelCol={{ span: 13 }}
        onFinish={handleForm3Submit}
      >
        <Form.Item
          name={'Introduction/Scripting'}
          label="Introduction/Scripting"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={4}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'1st person Interpreting'}
          label="1st person Interpreting"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={4}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Omissions'}
          label="Omissions"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={6}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Additions/Substitutions'}
          label="Additions/Substitutions"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={6}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Terminology/Vocabulary'}
          label="Terminology/Vocabulary"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={6}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Protocol'}
          label="Protocol"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={8}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Professional Demeanor'}
          label="Professional Demeanor"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={10}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Remaining neutral'}
          label="Remaining neutral"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={6}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'OPI/VRI Compliance'}
          label="OPI/VRI Compliance"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={8}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'Proper closing'}
          label="Proper closing"
          rules={[{ required: true }]}
        >
          <Radio.Group size="medium" buttonStyle="solid">
            <Radio.Button value={4}>&nbsp;&nbsp;ME&nbsp;&nbsp;</Radio.Button>
            <Radio.Button value={0}>&nbsp;&nbsp;NI&nbsp;&nbsp;</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={'notes'}>
          <Input.TextArea rows={4} placeholder={'Notes'} />
        </Form.Item>
        <Form.Item>
          <Row gutter={10}>
            <Col flex={'auto'}>
              <Button
                type={'primary'}
                block
                danger
                onClick={() => setFormStep(formStep - 1)}
              >
                Back
              </Button>
            </Col>
            <Col flex={'auto'}>
              <Button type={'primary'} block htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const renderStep4 = () => {
    return (
      <Form
        key={'renderStep4'}
        // labelAlign={'left'}
        // labelCol={{ span: 13 }}
        layout={'vertical'}
        onFinish={handleForm4Submit}
      >
        <Form.Item
          name={'strengths'}
          label={'Strengths'}
          rules={[{ required: true }]}
        >
          <Checkbox.Group style={{ display: 'block', marginRight: 0 }}>
            {[
              'Command of Languages',
              'Customer Service',
              'Follows protocols',
              'Accuracy',
              'Terminology',
              'Knowledge',
              'Professionalism',
              'Resourceful',
              'Attire requirements met',
              'Solid background'
            ].map((e) => (
              <Row key={e} span={24} style={{ marginBottom: 8 }}>
                <Checkbox key={e} value={e}>
                  <Typography.Text style={{ fontSize: 16 }}>
                    {e}
                  </Typography.Text>
                </Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item>
          <Row gutter={10}>
            <Col flex={'auto'}>
              <Button
                type={'primary'}
                block
                danger
                onClick={() => setFormStep(formStep - 1)}
              >
                Back
              </Button>
            </Col>
            <Col flex={'auto'}>
              <Button type={'primary'} block htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const renderStep5 = () => {
    return (
      <Form
        key={'renderStep5'}
        // labelAlign={'left'}
        // labelCol={{ span: 13 }}
        layout={'vertical'}
        onFinish={handleForm5Submit}
      >
        <Form.Item name={'Opening/Closing'} label={'Areas of Opportunity'}>
          <Select mode="multiple" placeholder={'Opening/Closing'}>
            {[
              'Intro to Client',
              'Intro to LEP',
              'Confidentiality Disclaimer',
              'Proper Closing Not Stated',
              'Closing was Unprofessional/Casual'
            ].map((e) => (
              <Select.Option key={e} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'Accuracy'}>
          <Select mode="multiple" placeholder={'Accuracy'}>
            {[
              'Omissions',
              'Additions + Substitutions',
              'Medical/General Terminology'
            ].map((e) => (
              <Select.Option value={e}>{e}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'ProtocolOpt'}>
          <Select mode="multiple" placeholder={'Protocol'}>
            {[
              '3rd person Interpretation',
              'Asking for Repetitions/Clarification',
              'Asking for shorter segments',
              'Informing clarification',
              'Informing/Following Hold Time Policy',
              'Call Control/Managing Call Flow',
              'Expressing Personal Opinions/Suggestions/Biased',
              'Requesting Unauthorized Information from Callers',
              'Providing Unauthorized Instructions',
              'Call Control/Managing Call Flow'
            ].map((e) => (
              <Select.Option value={e}>{e}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'Professionalism'}>
          <Select mode="multiple" placeholder={'Professionalism'}>
            {[
              'Poor Customer Service',
              'Rudeness',
              'Overstaying on the Call',
              'Proper Attire',
              'Background Noise',
              'Distracted',
              'Eating/Drinking',
              'Cluttered/Busy background',
              'Body Language',
              'Poor Framing'
            ].map((e) => (
              <Select.Option value={e}>{e}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'Technical Issues'}>
          <Select mode="multiple" placeholder={'Technical Issues'}>
            {['Disconnected', 'Audio Issues', 'Lagging'].map((e) => (
              <Select.Option value={e}>{e}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'Feedback'} label={'Feedback'}>
          <Select placeholder={'Feedback'}>
            {['Email', 'Coaching'].map((e) => (
              <Select.Option value={e}>{e}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row gutter={10}>
            <Col flex={'auto'}>
              <Button
                type={'primary'}
                block
                danger
                onClick={() => setFormStep(formStep - 1)}
              >
                Back
              </Button>
            </Col>
            <Col flex={'auto'}>
              <Button loading={loading} disabled={loading} type={'primary'} block htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  return (
    <div style={{ padding: 24 }}>
      <Steps
        size="small"
        labelPlacement=""
        current={formStep}
        items={[
          {
            // title: 'Interpreter Info'
          },
          {
            // title: 'Transaction Info'
          },
          {
            // title: 'Call Quality'
          },
          {
            // title: 'Strength'
          },
          {
            // title: 'Strength'
          }
        ]}
      />
      <br />
      {formStep == 0
        ? renderStep1()
        : formStep == 1
        ? renderStep2()
        : formStep == 2
        ? renderStep3()
        : formStep == 3
        ? renderStep4()
        : renderStep5()}
    </div>
  )
}

export default EvaluationForm
