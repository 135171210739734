import { Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd'

const Square = ({ size, loading, id, children, onDrop, edit, title, disabled }) => {

    const [{ isOver }, drop] = useDrop(() => ({
        accept: "widgets",
        drop: () => onDrop(id),
        collect: monitor => ({
          isOver: !!monitor.isOver(),
        }),
    }), [id])

    return (
        <Card
            ref={ edit ? drop : undefined}
            className={ 
                (size == "large" ? "active-devices-card" :
                size == "medium" ? "widget-cards" : 
                size == "small" ? "calls-card" : "stars-card")
                + " widget-cards-no-hover"
                + (isOver ? " widget-cards-highlight" : "")
            }
            bordered={false}
            loading={loading}
            title={title}
            bodyStyle={{padding: 0}}
            style={!edit ? {background: "transparent"} : {}}
        >
            {isOver ? null : children}
        </Card>
    )
    
}

Square.propTypes = {
    size: PropTypes.oneOf(['large', 'small', 'medium']),
    loading: PropTypes.bool.isRequired
}

export default Square;