import ActionType from '../action.types';
import Service from '../../services';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { socket } from '../../socket';

/*
Actions to be dispatched for signin
- login
    async thunk action which checks for valid login
- loginError
    action to dispatch incase of login error
- operationInProgress
    action to dispatch incase of login operation in progress
- loginSuccess
    action to dispatch incase of sucessfull login
- logout
    action to log out of the system
*/
export function loginError(bool, message) {
    return {
        type: ActionType.LOGIN_ERROR,
        hasError: bool,
        message,
        isLoading: false
    };
}

export function operationInProgress(bool) {
    return {
        type: ActionType.OPERATION_INPROGRESS,
        isLoading: bool
    };
}

export function loginSuccess({token, sessionId}, user) {
    return {
        type: ActionType.LOGIN_SUCCESS,
        isLoading: false,
        token,
        sessionId,
        user
    };
}

export function _logout() {
    return {
        type: ActionType.LOGOUT,
        hasError: false,
        isLoading: false,
        token: null,
        sessionId: null,
        user: {}
    };
}

// Action creator for setting the theme
export function setTheme(theme) {
    return {
        type: ActionType.THEME,
        theme
    }
}

/**
 * Signin action for authenticating the user.
 * @param {*} username
 * @param {*} password 
 * @returns 
 */
export function signin({email, password}) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().login(email.toLowerCase(), password);
            let user = jwtDecode(data.token);
            console.log("user login: ", user);
            sessionStorage.setItem('token', data?.token);
            sessionStorage.setItem('session', data?.sessionId);
            dispatch(loginSuccess(data, user))
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
            dispatch(loginError(true, 'error communicating with the server'));
        }
    };
}

/**
/**
 * Signup action for creating the user.
 * @param {*} userObj
 * @returns 
 */
export function signup(userObj) {
    return async (dispatch) => {
        try {
            userObj.designation = "portal-signin";
            userObj.name = `${userObj.firstname} ${userObj.lastname}`;
            userObj.comment = "Create using VIP Admin portal";
            delete userObj.firstname;
            delete userObj.lastname;
            delete userObj.confirm;
            let data = await Service.Auth().signup(userObj);
            return true;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
            dispatch(loginError(true, 'error communicating with the server'));
        }
    };
}

/**
 * Forgot Password action for creating reset password link.
 * @param {*} userObj
 * @returns
 */
export function forgotPassword({ email }) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().forgotPassword(email);
            if (data) {
                message.success("Email has been successfully sent to " + email);
            } else {
                message.error("Email is not available on the server");
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, "error communicating with the server"));
        }
    };
}

export function resetPassword(values) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().resetPassword(values);
            if (data) {
                message.success("Password has been successfully updated");
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, "error communicating with the server"));
        }
    };
}

export function logout() {
    return async (dispatch) => {
        try {
            let sessionId = sessionStorage.getItem("session");
            await Service.Auth().logout(sessionId);
            sessionStorage.clear();
            await socket?.disconnect()
            dispatch(_logout());
        } catch(err) {
            console.log(err);
        }
    };
}

