import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageType } from "../../store/actions/filter.action";
import { useLocation } from "react-router-dom";

const disabled_pages = [
    '/pages/reports/language-report',
    '/pages/reports/language-report/',
    '/pages/reports/device-usage',
    '/pages/reports/device-usage/',
    '/pages/reports/user-audit',
    '/pages/reports/user-audit/',
    '/pages/reports/admin-audit',
    '/pages/reports/admin-audit/',
    '/pages/cc-reports/non-service-calls/',
    '/pages/cc-reports/non-service-calls',
    '/pages/cc-reports/terp-availability-report',
    '/pages/cc-reports/terp-availability-report/',
    '/pages/reports/top-customer',
    '/pages/reports/top-customer/',
    '/hud/qa',
    '/hud/QA/report'
]

const disabled_asl = [
    '/pages/reports/audio-video-report',
    '/pages/reports/audio-video-report/',
    
]

const LanguageFilter = (props) => {

    const location = useLocation();

    const dispatch = useDispatch();
    const type = useSelector((state) => state?.filter?.languageType)

    const languageOptions = [
        { label: 'All', value: '-1' },
        { label: 'Spanish', value: 'sp' },
        { label: 'LOTS', value: 'LOTS' },
        { label: 'ASL', value: 'ASL', disabled: disabled_asl.includes(location.pathname)},
    ];

    return (
        <Radio.Group 
            buttonStyle="solid"
            options={languageOptions}
            optionType="button"
            value={type}
            onChange={(e) => dispatch(setLanguageType(e.target.value))}
            disabled={disabled_pages.includes(location.pathname)}
        />
    )
}

export default LanguageFilter