import { Card, Col, Row, Spin, Table, Tag, theme } from "antd";
import './invoices.scss';
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts/core';
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices, getAnalyticsTotal, setInvoices, setMonthInvoices } from "../../store/actions/invoice.action";
import Formatter from "../../utils/Formatter";
import { LinkOutlined } from "@ant-design/icons";

const Invoices = () => {
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [graphLoading, setGraphLoading] = useState(false);
    const [month, setMonth] = useState('January');

    const filter = useSelector((state) => state.filter);
    const analytics = useSelector((state) => state.invoice?.analytics);
    const invoice = useSelector((state) => state?.invoice);
    const state = useSelector(state => state);

    useEffect(() => {
        setMonth('January')
        setLoading(true);
        dispatch(getAnalyticsTotal())
        .then(() => setLoading(false))
        .catch(() => setLoading(false))

        setGraphLoading(true);
        dispatch(fetchInvoices())
        .then(() => setGraphLoading(false))
        .catch(() => setGraphLoading(false))
    }, [filter.year, state.company?.selected, state.company?.client])

    useEffect(() => {
        dispatch(setMonthInvoices(month))
    }, [filter.invoiceType])

    let columns = [
        {
            title: 'Invoice Id',
            dataIndex: 'InvoiceMasterId',
            sorter: (a, b) => a.InvoiceMasterId - b.InvoiceMasterId
        },
        {
            title: 'Invoice Date',
            dataIndex: 'TxnDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.TxnDate.localeCompare(b.TxnDate)
        },
        {
            title: 'Company Name',
            dataIndex: 'CustomerName',
        },
        {
            title: 'Amount',
            dataIndex: 'TotalAmt',
            sorter: (a, b) => a.TotalAmt - b.TotalAmt,
            render: (e) => Formatter.numberWithCommas((e || 0)?.toFixed(2))
        },
        {
            title: 'Currency',
            dataIndex: 'Currency',
        },
        {
            title: 'Due Date',
            dataIndex: 'DueDate'
        },
        {
            title: 'Payment Date',
            dataIndex: 'PaymentDate',
        },
        {
            title: 'Payment Status',
            dataIndex: 'invoiceStatus',
            render: (status) => <Tag color={ 
                status == "Paid" ? "#6c40a6" : 
                status == "Pending" ? "#226885" : "tomato"
            }
            >{status}</Tag>
        }
    ]

    const getOption = () => {
        return {
            tooltip: {
                position: 'top',
                trigger: 'item',
                axisPointer: {
                type: 'cross'
                }
            },
            xAxis: {
                name: 'Invoice Date',
                nameLocation: 'bottom',
                nameGap: 0,
                nameTextStyle: {
                    verticalAlign: 'top',
                    padding: [25, 0, 0, 0],
                },
                type: 'time',
                axisLine: {
                    show: true,
                },
                axisTick: {
                    show: true,
                },
                axisLabel: {
                    interval: 0,
                    formatter: (axisValue, index) => {
                      // Add logic to display alternate labels
                      if (index % 2 !== 0) {
                        return moment(axisValue).format("YYYY-MM-DD");
                      } else {
                        return ''; // Empty string for alternate labels
                      }
                    }
                },
            },
            yAxis: {
                name: 'Payment Date',
                type: 'time',
                axisLine: {
                    show: true,
                },
                axisTick: {
                    show: true,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: colorText,
                    },
                },
                axisLabel: {
                    interval: 0,
                    formatter: (axisValue, index) => {
                      // Add logic to display alternate labels
                      if (index % 2 == 0) {
                        return moment(axisValue).format("YYYY-MM-DD");
                      } else {
                        return ''; // Empty string for alternate labels
                      }
                    }
                },
            },
            grid: {
                top: 30,
                left: 10,
                right: 40,
                bottom: 20,
                containLabel: true,
            },
            series: [{
                data: invoice.scatter,
                type: 'scatter'
            }]
        }
    };
    
    const xAxisData = invoice?.bar?.map(({ month }) => month);

     // Modify xAxis to have December as the first element
     const reorderedXAxisData = ["December"].concat(xAxisData?.slice(0, -1));

     // Modify invoice data to have December as the first element
     const reorderedBar = [...(invoice?.bar || [])];
     if (reorderedBar.length > 0) {
         const decemberItem = reorderedBar.pop();
         reorderedBar.unshift(decemberItem);
     }

    // Define the data for each series in the chart
    const seriesData = [
        // Line chart for the number of invoices per month
        {
            name: 'Invoices',
            type: 'line',
            data: reorderedBar?.map(({ invoices }) => invoices),
            itemStyle: {
                color: 'green'
            },
        },
        // Stacked bar chart for the number of paid invoices per month
        {
            name: 'Paid',
            type: 'bar',
            stack: 'status',
            data: reorderedBar?.map(({ paid }) => paid),
            itemStyle: {
                color: '#6c40a6'
            }
        },
        // Stacked bar chart for the number of pending invoices per month
        {
            name: 'Pending',
            type: 'bar',
            stack: 'status',
            data: reorderedBar?.map(({ pending }) => pending),
            itemStyle: {
                color: '#226885'
            }
        },
        // Stacked bar chart for the number of overdue invoices per month
        {
            name: 'Overdue',
            type: 'bar',
            stack: 'status',
            data: reorderedBar?.map(({ overdue }) => overdue),
            itemStyle: {
                color: '#61636f'
            }
        },
        // Stacked bar chart for the total amount of invoices per month
        {
            name: 'Total',
            type: 'bar',
            yAxisIndex: 1,
            stack: 'amount',
            data: reorderedBar?.map(({ total }) => total),
            itemStyle: {
                color: '#ff9933b4'
            }
        }
    ];

  // Define the options for the chart
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {
            data: ['Invoices', 'Paid', 'Pending', 'Overdue', 'Total'],
            align: 'right',
            top: 0,
            textStyle: {
                color: colorText
            },
        },
        grid: {
            left: '3%',
            right: '30',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: reorderedXAxisData
        },
        yAxis: [
            {
                type: 'value',
                name: 'Invoices',
                axisLabel: {
                formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Amount ($)',
                axisLabel: {
                formatter: '${value}'
                }
            }
        ],
        series: seriesData
    };

    const handleChartClick = (params) => {
        setMonth(params.name);
        dispatch(setMonthInvoices(params.name));
    }

    const onEvents = {
        'click': handleChartClick,
    }

    return (
        <Row className="invoices-container" >
            <Col flex={"100%"}>
                <Row justify={"space-between"} wrap={false}>
                    <Col flex={"49.5%"}>
                        <Spin spinning={graphLoading}>
                            <Card style={{backgroundColor: colorBgLayout}}><ReactECharts style={{width: '100%'}} option={getOption()} /></Card>
                        </Spin>
                    </Col>
                    <Col flex={"49.5%"}>
                        <Spin spinning={graphLoading}>
                            <Card style={{backgroundColor: colorBgLayout}}><ReactECharts onEvents={onEvents} style={{width: '100%'}} option={option} /></Card>
                        </Spin>
                    </Col>
                </Row>
                <br/>
                <Row className="invoice-cards" wrap={false} gutter={20} >
                    <Col flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card" title={`Paid Invoices (${analytics?.paid?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.paid?.totalAmt || 0)?.toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                    <Col flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card"  title={`Pending Invoices (${analytics?.pending?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.pending?.totalAmt || 0)?.toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                    <Col  flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card" title={`Overdue Invoices (${analytics?.unpaid?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.unpaid?.totalAmt || 0).toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"auto"}>
                        <Table 
                            title={() => month}
                            size="middle"
                            className="voyce-custom-table" 
                            columns={columns}
                            dataSource={invoice.invoices}
                        ></Table>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Invoices;