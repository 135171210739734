import { Button, Modal, Table } from 'antd'
import { useHud } from '../store/hud.context'
import { useMemo } from 'react'
import GetColumnSearchProps from '../../../components/table/search'
import WatchlistButton from '../watchlist.btn'

const WidgetFiveExpanded = ({ scoreLabel, handleCancel }) => {
  const { interpreters, scores } = useHud()

  const dataSource = useMemo(() => {
    return interpreters
      .filter((e) => {
        const scoreCard = scores[e.InterpreterId]
        return scoreCard?.scoreLabel == scoreLabel
      })
      .map((interpreter) => {
        const scoreCard = scores[interpreter.InterpreterId]
        return {
          ...interpreter,
          qaSpecialistName: scoreCard?.details[0]?.qaSpecialistName,
          scoreCard: scoreCard
        }
      })
  }, [interpreters, scoreLabel])

  const modalTitle = `Interpreters Analysis - ${scoreLabel}`

  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false })
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Languages',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'QA Name',
      dataIndex: 'qaSpecialistName',
      key: 'qaSpecialistName',
      // sorter: (a, b) => a.qaSpecialistName - b.qaSpecialistName,
      render: (text, record) => <div id={`cell-name-${record.id}`}>{text}</div>,

      ...GetColumnSearchProps({ dataIndex: 'qaSpecialistName' })
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.Status.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      render: (text, record) => (
        <WatchlistButton
          key={`watchlist-button-${record.InterpreterId}`}
          interpreterId={record.InterpreterId}
        />
      )
    }
  ]

  return (
    <Modal
      title={modalTitle}
      visible={Boolean(scoreLabel)}
      onCancel={handleCancel}
      width={'80%'} // Set the width of the modal
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>
      ]}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        id={`${modalTitle?.replace(/\s/g, '')}-table`}
        rowKey="InterpreterId"
      />
    </Modal>
  )
}

export default WidgetFiveExpanded
