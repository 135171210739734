import { Button, Card, Col, Rate, Row, Spin, Table, theme } from 'antd';
import { LinkOutlined } from "@ant-design/icons";
import './language.report.scss';
import LanguageDistributionBar from './distribution.chart';
import CallDurationBarChart from './avg.duration.chart';
import InterpretationType from './terp.type.graph';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getLanguagesDetails } from '../../../store/actions/language.action';
import Formatter from '../../../utils/Formatter';
import { Link, useNavigate } from 'react-router-dom';
import DropDown from '../../../components/dropdown';
import exportAsCSV from "../../../services/exportToCVS.service"
import jsPDF from "jspdf";
import GetColumnSearchProps from '../../../components/table/search';
import { setLanguageType } from '../../../store/actions/filter.action';
import moment from 'moment';
import LocalCustomFilter from '../../transactions/localCustomFilter'
import { exportToExcel } from '../../../services/exportToExcel';
const LanguageReport = () => {
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();
    const [loading, setLoading] = useState(false);

    const state = useSelector((state) => state);
    const filter = useSelector((state) => state.filter);
    const company = useSelector((state) => state.company);
    const language = useSelector((state) => state.language);
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [pageSize, setPageSize] = useState(100); // Default page size
    
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        dispatch(getLanguagesDetails())
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }, [filter?.date, company?.selected, company?.client]);

    let dataSource = language.rawData?.map((list) => {
        return {
            ...list,
            "TargetLanguage": list?.TargetLanguage,
            "totalCalls": Formatter.numberWithCommas(list?.TotalCalls),
            "serviceMins":  Formatter.numberWithCommas(list?.ServiceMinutes),
            "CountSuccessAduioCalls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
            "CountSuccessVideoCalls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
            "avgWaitingSeconds": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
            "avgLength": `${Formatter.numberWithCommas(Math.round(list?.ServiceMinutes/list?.TotalCalls))} minutes`,
            "avgRating": list?.AvgRating
        }
    })

    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of language.rawData) {
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
                "Avg. Rating": list?.AvgRating
            };
            downloadArray.push(newList);
        }
      
        const headerList = [
            "Language Name",
            "Total Calls",
            "Total Minutes",
            "Serviced Audio Calls",
            "Serviced Video Calls",
            "Avg. Wait Time",
        ];
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Language_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        doc.text("Language Report", 30, 20);
        doc.setFontSize(12);
        doc.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
    };
      
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadExcelFunction();
        }
    };
    
    const downloadCSVFunction = () => {
        let downloadArray = [];
        for (let list of language.rawData) {
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
                "Avg. Rating": list?.AvgRating
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Language_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            //new Date().toISOString().split("T")[0],
            [
                "Language Name",
                "Total Calls",
                "Total Minutes",
                "Serviced Audio Calls",
                "Serviced Video Calls",
                "Avg. Wait Time",
                "Avg. Rating",
            ],
            filename
        );
    };

    const downloadExcelFunction = () => {
        let downloadArray = [];
        for (let list of language.rawData) {
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes": Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds / list?.TotalCalls))} seconds`,
                "Avg. Rating": list?.AvgRating
            };
            downloadArray.push(newList);
        }
    
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const downloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Language_Report_${downloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
    
        const sheetData = [downloadArray];
        const sheetNames = ["Language Report"];
    
        exportToExcel(sheetData, sheetNames, filename);
    };
    
    const handleNavigationClick = (record) => {
        let targetLanguage = record.TargetLanguage;
        dispatch(setLanguageType(
            targetLanguage.match(/spanish/i) ? "sp" : 
            targetLanguage.match(/ASL/i) ? "ASL": "LOTS"
        ));
        navigate(`/pages/transactions?dataIdx0=languageType&status${0}=${targetLanguage}&dataIdx1=Status&status${1}=Serviced`);
    }

    const columns = [
        {
            title: 'Language Name',
            dataIndex: 'TargetLanguage',
            sorter: (a, b) => a.TargetLanguage.localeCompare(b.TargetLanguage),
            ...LocalCustomFilter({
                dataIndex: 'TargetLanguage',
                dataSource: dataSource,
                // emptySearch: true
              }),
        },
        {
            title: 'Total Calls',
            dataIndex: 'totalCalls',
            sorter: (a, b) => {
                const aValue = a.totalCalls ? parseFloat(a.totalCalls.replace(/,/g, '')) : 0;
                const bValue = b.totalCalls ? parseFloat(b.totalCalls.replace(/,/g, '')) : 0;
                return aValue - bValue;
              },
            defaultSortOrder: 'descend'
            // ...GetColumnSearchProps({dataIndex: 'totalCalls', isDate:false})
        },
        {
            title: 'Total Minutes',
            dataIndex: 'serviceMins',
            sorter: (a, b) => {
              const aValue = a.serviceMins ? parseFloat(String(a.serviceMins).replace(/,/g, '')) : 0;
              const bValue = b.serviceMins ? parseFloat(String(b.serviceMins).replace(/,/g, '')) : 0;
              return aValue - bValue;
            },
            // ...GetColumnSearchProps({dataIndex: 'serviceMins', isDate:false})
        },  
        {
            title: 'Serviced Audio Calls',
            dataIndex: 'CountSuccessAduioCalls',
            sorter: (a, b) => {
                const aValue = a.CountSuccessAduioCalls ? parseFloat(a.CountSuccessAduioCalls.replace(/,/g, '')) : 0;
                const bValue = b.CountSuccessAduioCalls ? parseFloat(b.CountSuccessAduioCalls.replace(/,/g, '')) : 0;
                return aValue - bValue;
              },
            // ...GetColumnSearchProps({dataIndex: 'CountSuccessAduioCalls', isDate:false})
        },
        {
            title: 'Serviced Video Calls',
            dataIndex: 'CountSuccessVideoCalls',
            sorter: (a, b) => {
                const aValue = a.CountSuccessVideoCalls ? parseFloat(a.CountSuccessVideoCalls.replace(/,/g, '')) : 0;
                const bValue = b.CountSuccessVideoCalls ? parseFloat(b.CountSuccessVideoCalls.replace(/,/g, '')) : 0;
                return aValue - bValue;
              },
            // ...GetColumnSearchProps({dataIndex: 'CountSuccessVideoCalls', isDate:false})
        },
        {
            title: 'Avg. Wait Time',
            dataIndex: 'avgWaitingSeconds',
            sorter: (a, b) => {
                const aValue = a.avgWaitingSeconds ? parseFloat(a.avgWaitingSeconds.replace(/,/g, '')) : 0;
                const bValue = b.avgWaitingSeconds ? parseFloat(b.avgWaitingSeconds.replace(/,/g, '')) : 0;
                return aValue - bValue;
              },
            // ...GetColumnSearchProps({dataIndex: 'avgWaitingSeconds', isDate:false})
        },
        {
            title: 'Avg. Call Length',
            dataIndex: 'avgLength',
            sorter: (a, b) => {
                const aValue = a.avgLength ? parseFloat(a.avgLength.replace(/,/g, '')) : 0;
                const bValue = b.avgLength ? parseFloat(b.avgLength.replace(/,/g, '')) : 0;
                return aValue - bValue;
              },
            // ...GetColumnSearchProps({dataIndex: 'avgLength', isDate:false})
        },
        {
            title: 'Avg. Rating',
            dataIndex: 'avgRating',
            sorter: (a, b) => a.avgRating - b.avgRating,
            // ...GetColumnSearchProps({dataIndex: 'avgRating', isDate:false}),
            render: (text, row) => <Rate style={{color: '#0f9191'}} allowHalf value={text} disabled/>
        },
        {
            title: 'Serviced Transactions',
            dataIndex: '_id',
            align: "center",
            render: (text, record) => {
                const formatValue = value => {
                    // Check if value is a string and contains a comma due to Formatter function 
                    if (typeof value === 'string' && value.includes(',')) {
                        // Remove commas and convert to number
                        return parseInt(value.replace(/,/g, ''), 10);
                    }
                    // If value is a number or a string without commas, try to convert directly
                    return Number(value) || 0;
                };
            
                return (
                    <a 
                        onClick={() => handleNavigationClick(record)}
                        style={{textDecoration: "underline"}}
                    >
                        { formatValue(record?.CountSuccessVideoCalls) + formatValue(record?.CountSuccessAudioCalls) } <LinkOutlined />
                    </a>
                );
            }
            
        },
    ];

    return (
        <Row>
            <Col className='language-col' flex={"auto"}>
                <Row wrap={false} gutter={20}>
                    <Col className='volume-card' flex={"100%"}>
                       <Spin spinning={loading}>
                        <Card style={{borderRadius: 4, backgroundColor: colorBgLayout}}>
                                <LanguageDistributionBar 
                                    data={language?.distribution || []} 
                                    interpretation={language?.interpretationType || {}}
                                    avgTime={language?.avgTime || {}}
                                />
                            </Card>
                       </Spin>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"auto"}>
                        <Table
                            title={() => (
                                        <Row
                                            gutter={[8, 8]}
                                            justify="end"
                                            align="middle"
                                        >
                                            <Col>
                                                <DropDown
                                                    defaultValue={downloadType}
                                                    dropdownOptions={
                                                        downloadDropdownType
                                                    }
                                                    handleTypeSelect={(
                                                        downloadType
                                                    ) =>
                                                        setDownloadType(
                                                            downloadType
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    onClick={downloadFile}
                                                >
                                                    Download
                                                </Button>
                                            </Col>
                                        </Row>
                            )}
                            className="voyce-custom-table"
                            style={{width: '100%', backgroundColor: colorBgLayout}}
                            dataSource={dataSource}
                            pagination={{
                                pageSize: pageSize,
                                onShowSizeChange: (current, size) => setPageSize(size),
                            }}
                            columns={columns}
                            size="small"
                            loading={loading}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default LanguageReport;