import { Row, Col, theme, Table, Typography, Card, Button } from 'antd'
import './terp.availability.scss'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { fetchInterpreters } from '../../../store/actions/terp.action'
import useIntervalAsync from '../../../components/hooks/useInterval'
import GetColumnSearchProps from '../../../components/table/search'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Formatter from '../../../utils/Formatter'
import DropDown from '../../../components/dropdown'
import exportAsCSV from '../../../services/exportToCVS.service'
import jsPDF from 'jspdf'
import HeatMap from './heat.map'
import ExpandedRowRender from './expanded.row'
import { exportToExcel } from '../../../services/exportToExcel'
import moment from 'moment'
import LocalCustomFilter from '../../transactions/localCustomFilter'

const TerpMonitor = () => {
    const {
        token: { colorText, colorBgLayout }
    } = theme.useToken()
    const [loading, setLoading] = useState(false)
    const [downloadType, setDownloadType] = useState('EXCEL')
    const state = useSelector((s) => s)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([])
    const [languages, setLanguages] = useState([])
    const [languageMap, setLangMap] = useState({})
    const [analytics, setAnalytics] = useState({
        online: 0,
        inConference: 0,
        offline: 0,
        operatorsOnline: 0
    })

    const fetchInterpretersCB = useCallback(async () => {
        dispatch(fetchInterpreters(true))
            .then((data) => {
                let source = [];
                const mapper = data.languageHoursMap;
                for (let i = 0; i < data.languages.length; i++) {
                    const language = data.languages[i];
                    if (!['operator', 'english'].includes(language.toLowerCase())) {
                        const langRow = {
                            language,
                            avgIdleMinutes: mapper[language].avgIdleMinutes,
                            online:
                                mapper[language].analysis.online +
                                mapper[language].analysis.inConference,
                            offline: mapper[language].analysis.offline,
                            operators: mapper[language].analysis.operatorsOnline,
                            available: mapper[language].analysis.online,
                            inConference: mapper[language].analysis.inConference,
                            interpreters: mapper[language].interpreters
                        };
                        source.push(langRow);
                    }
                }
                setDataSource(source);
                setLangMap(data.languageHoursMap);
                setLanguages(data.languages);
                setAnalytics(data.mainAnalysis);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    });

    const fetchTerps = useIntervalAsync(fetchInterpretersCB, 60 * 1000)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            fetchTerps()
        }

        fetchData()
    }, [state.filter?.date, state.company?.client])

    const downloadDropdownType = [
        {
            value: 'PDF',
            label: 'PDF'
        },
        {
            value: 'EXCEL',
            label: 'EXCEL'
        }
    ]

    const downloadPDF = () => {
        let downloadArray = []

        for (let list of dataSource) {
            let newList = {
                Language: list.language || '',
                'Avg. Idle Minutes': list.avgIdleMinutes || '',
                'Interpreters online': list.online || '',
                'Operators online': list.operators || '',
                'Interpreters in conference': list.inConference || '',
                'Interpreters available': list.available || '',
                'Interpreters offline': list.offline || ''
            }
            downloadArray.push(newList)
        }

        const headerList = [
            'Language',
            'Avg. Idle Minutes',
            'Interpreters online',
            'Operators online',
            'Interpreters in conference',
            'Interpreters available',
            'Interpreters offline'
        ]
        const bodyList = downloadArray.map((item) =>
            Object.values(item).map((value) => String(value))
        )
        const doc = new jsPDF('landscape', 'px', 'a4')
        doc.setFontSize(18)
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Interpreter_Availability_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        doc.text('Interpreter Availability Report', 30, 20)
        doc.setFontSize(12)
        doc.autoTable({
            head: [headerList.map((header) => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33]
            },
            startY: 50
        })
        doc.save(filename)
    }

    const downloadFile = () => {
        if (downloadType === 'PDF') {
            downloadPDF()
        } else if (downloadType === 'EXCEL') {
            downloadCSVFunction()
        }
    }

    const downloadCSVFunction = (pagination, filters, sorter) => {
        let langaugesArray = []
        let titles = ['main']
        let downloadArray = []
        for (let list of dataSource) {
            let newList = {
                Language: list.language || '',
                'Avg. Idle Minutes': list.avgIdleMinutes || '',
                'Interpreters online': list.online || '',
                'Operators online': list.operators || '',
                'Interpreters in conference': list.inConference || '',
                'Interpreters available': list.available || '',
                'Interpreters offline': list.offline || ''
            }
            downloadArray.push(newList)
            const invalidCharsRegex = /[/\\*'?[\]:]+/g;
            const maxLength = 31;
            let safeName = list.language.replaceAll(invalidCharsRegex, " ")
                                    .replaceAll("  ", " ")
                                    .trim();

            if (safeName.length > maxLength)
            {
                safeName = safeName.substring(0, maxLength);
            }

            console.log(safeName)

            titles.push(safeName)
            let sheet = [];
            for (let terp of list?.interpreters) {
                let row = {
                    'Id': terp.Id,
                    'Company': terp.CompanyCode,
                    'Name': `${terp.Name} ${terp.LastName || ''}`,
                    'Languages': _.uniq(terp.Language1_Array || [])
                        ?.filter((e) => e !== 'Operator')
                        ?.join(', '),
                    'Status': terp.Status,
                    'Avg. Idle Minutes': terp.avgIdleMinutes,
                }
                sheet.push(row);
            }
            langaugesArray.push(sheet);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Interpreter_Availability_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
        exportToExcel([
            downloadArray, ...langaugesArray
        ], titles, filename)
    }

    const columns = [
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            sorter: (a, b) => a.language.localeCompare(b.language),
            ...LocalCustomFilter({
                dataIndex: 'language',
                dataSource: dataSource,
                // emptySearch: true
              }),
            
        },
        {
            title: 'Avg. Idle Minutes',
            dataIndex: 'avgIdleMinutes',
            align: 'center',
            sorter: (a, b) => a.avgIdleMinutes - b.avgIdleMinutes,
            render: (text) => text || '-'
        },
        {
            title: 'Interpreters online',
            dataIndex: 'online',
            key: 'online',
            sorter: (a, b) => a.online - b.online,
            render: (text) => text || '-'
        },
        {
            title: 'Operators online',
            dataIndex: 'operators',
            sorter: (a, b) => a.operators - b.operators,
            render: (text) => text || '-'
        },
        {
            title: 'Interpreters in conference',
            dataIndex: 'inConference',
            sorter: (a, b) => a.inConference - b.inConference,
            render: (text) => text || '-'
        },
        {
            title: 'Interpreters available',
            dataIndex: 'available',
            sorter: (a, b) => a.available - b.available,
            render: (text) => text || '-'
        },
        {
            title: 'Interpreters offline',
            dataIndex: 'offline',
            sorter: (a, b) => a.offline - b.offline,
            render: (text) => text || '-'
        }
    ]

    return (
        <Row className="tar-container">
            <Col flex={'auto'}>
                <Card loading={loading} style={{ backgroundColor: colorBgLayout , borderColor: 'black'}}>
                    <Row>
                        <Col flex={'auto'}>
                            <Typography.Text>
                                Interpreters online
                            </Typography.Text>{' '}
                            <br />
                            <Typography.Text style={{ fontSize: 28 }}>
                                {Formatter.numberWithCommas(
                                    analytics.inConference + analytics.online,
                                    false
                                ) || 0}
                            </Typography.Text>
                        </Col>
                        <Col
                            style={{
                                backgroundColor: 'grey',
                                width: 1,
                                marginRight: 20
                            }}
                        ></Col>
                        <Col flex={'auto'}>
                            <Typography.Text >Operators online</Typography.Text>{' '}
                            <br />
                            <Typography.Text style={{ fontSize: 28 }}>
                                {Formatter.numberWithCommas(
                                    analytics.operatorsOnline,
                                    false
                                ) || 0}
                            </Typography.Text>
                        </Col>
                        <Col
                            style={{
                                backgroundColor: 'grey',
                                width: 1,
                                marginRight: 20
                            }}
                        ></Col>
                        <Col flex={'auto'}>
                            <Typography.Text>
                                Interpreters in conference
                            </Typography.Text>{' '}
                            <br />
                            <Typography.Text
                                style={{ fontSize: 28, color: '#00cc66ba' }}
                            >
                                {Formatter.numberWithCommas(
                                    analytics.inConference,
                                    false
                                ) || 0}
                            </Typography.Text>
                        </Col>
                        <Col
                            style={{
                                backgroundColor: 'grey',
                                width: 1,
                                marginRight: 20
                            }}
                        ></Col>
                        <Col flex={'auto'}>
                            <Typography.Text>
                                Interpreters available
                            </Typography.Text>{' '}
                            <br />
                            <Typography.Text
                                style={{ fontSize: 28, color: '#00b4e6be' }}
                            >
                                {Formatter.numberWithCommas(
                                    analytics.online,
                                    false
                                ) || 0}
                            </Typography.Text>
                        </Col>
                        <Col
                            style={{
                                backgroundColor: 'grey',
                                width: 1,
                                marginRight: 20
                            }}
                        ></Col>
                        <Col flex={''}>
                            <Typography.Text>
                                Interpreters offline
                            </Typography.Text>{' '}
                            <br />
                            <Typography.Text
                                style={{ fontSize: 28, color: '#FF6347' }}
                            >
                                {Formatter.numberWithCommas(
                                    analytics.offline,
                                    false
                                ) || 0}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Card>
                <br />
                <Row>
                    <Col flex={'auto'}>
                        <HeatMap dataSource={languageMap} loading={loading} />
                    </Col>
                </Row>
                <br />
                <Table
                    size="middle"
                    title={() => (
                        <>
                            <Row justify={'end'} align={'middle'} gutter={10}>
                                <Col>
                                    <DropDown
                                        defaultValue={downloadType}
                                        dropdownOptions={downloadDropdownType}
                                        handleTypeSelect={(downloadType) =>
                                            setDownloadType(downloadType)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={downloadFile}
                                    >
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                    pagination={{
                        defaultPageSize: 100,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    rowKey={(record) => record.language}
                    expandable={{
                        expandedRowRender: (record) => (
                            <ExpandedRowRender record={record} />
                        ),
                        defaultExpandAllRows: false
                    }}
                />
            </Col>
        </Row>
    )
}

export default TerpMonitor
