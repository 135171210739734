import { Button, Modal, Table } from 'antd'
import WatchlistButton from '../watchlist.btn'
import GetColumnSearchProps from '../../../components/table/search'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHud } from '../store/hud.context'

const WidgetFourExpanded = ({ expand, dataSource, handleCancel }) => {
  const { handleMonitorClick, loading } = useHud();

  const qaSpecialistId = useSelector((state) => state?.profile?.user?.id)
  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId' }),
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
        render: (text, row) =>
          row.Status === 'In conference' ? (
            <Button type="link" disabled={loading} onClick={() => handleMonitorClick(row)}>
              {text}
            </Button>
          ) : (
            <span>{text}</span>
          )
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Languages',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      sorter: (a, b) =>
        (a?.Languages || [])
          .join(',')
          .localeCompare((b?.Languages || []).join(',')),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'Add Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => {
        const aDate = a?.watchlist?.updatedAt
          ? moment(a.watchlist.updatedAt).format('YYYY-MM-DD')
          : null
        const bDate = b?.watchlist?.updatedAt
          ? moment(b.watchlist.updatedAt).format('YYYY-MM-DD')
          : null
        if (aDate && bDate) {
          return aDate?.localeCompare(bDate)
        }
        return 0
      },
      render: (text, record) => {
        return record?.watchlist?.updatedAt
          ? moment(record.watchlist.updatedAt).format('YYYY-MM-DD')
          : 'No Data'
      }
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => a?.Status?.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      align: 'center',
      render: (text, record) => (
        <WatchlistButton interpreterId={record.InterpreterId} />
      )
    }
  ]
  return (
    <Modal
      title="Watchlist"
      visible={expand}
      onCancel={handleCancel}
      width={'85%'}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>
      ]}
    >
      <Table
        size="small"
        columns={columns}
        id={`active-session-table-${'expanded'}`}
        dataSource={dataSource}
        rowClassName={(record, index) => {
          if (record?.watchlist?.addedByQaSpecialistId === qaSpecialistId) {
            return 'added-by-user' // Class name for rows added by user
          } else {
            return 'other-rows' // Class name for other rows
          }
        }}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
        scroll={{ x: 500 }}
      />
    </Modal>
  )
}

export default WidgetFourExpanded
