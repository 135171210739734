import ReactECharts from 'echarts-for-react'

const WidgetThreeGraph = ({ handleBarClick, dataSource }) => {
  let colors = ['#6c40a6', '#226885']

  const seriesDataWithColors = dataSource.map((value, index) => ({
    value,
    itemStyle: {
      color: colors[index % colors.length]
    }
  }))

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {top: 25, bottom: 35, right: 20},
    xAxis: {
      type: 'category',
      data: ['# Interpreters QAd', 'Total # QA Specialists'],
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        interval: 0,
        align: 'center'
      }
    },
    yAxis: {
      logBase: 10,
      axisLabel: {
        formatter: (value) => {
          return Number(value).toLocaleString()
        }
      }
    },
    series: [
      {
        type: 'bar',
        data: seriesDataWithColors,
        barWidth: '95%',
        itemStyle: {
          color: colors[0],
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5, 0 ,0 ]
          }
        }
      }
    ]
  }
  return (
    <ReactECharts
      option={options}
      style={{ height: 'calc(100% - 39px)' }}
      onEvents={{ click: handleBarClick }}
    />
  )
}

export default WidgetThreeGraph
