import ActionType from '../action.types'

const initialState = {
  loading: false,
  data: [],
  error: null,
  aggData: {},
  adminAuditData: { adminData: [], aggAdminData: {} }
}

export default function audit(state = initialState, action) {
  switch (action.type) {
    case ActionType.FETCH_AUDIT_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ActionType.FETCH_AUDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      }
    case ActionType.FETCH_AUDIT_AGG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        aggData: action.payload,
        error: null
      }
    case ActionType.FETCH_AUDIT_DATA_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload
      }
    case ActionType.FETCH_ADMIN_AUDIT_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ActionType.FETCH_ADMIN_AUDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        adminAuditData: {
          ...state.adminAuditData,
          adminData: action.payload
        },
        error: null
      }
    case ActionType.FETCH_ADMIN_AUDIT_AGG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        adminAuditData: {
          ...state.adminAuditData,
          aggAdminData: action.payload
        },
        error: null
      }
    case ActionType.FETCH_ADMIN_AUDIT_DATA_FAILURE:
      return {
        loading: false,
        adminAuditData: { adminData: [], aggAdminData: {} },
        error: action.payload
      }
    default:
      return state
  }
}
