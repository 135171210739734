import { Button, Modal, Table } from 'antd'
import { useHud } from '../store/hud.context'
import GetColumnSearchProps from '../../../components/table/search';

const WidgetTwoMonitor = ({ visible, handleClose, dataSource }) => {
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'interpreterId',
      key: 'interpreterId',
      ...GetColumnSearchProps({ dataIndex: 'interpreterId', isDate: false })
    },
    {
      title: 'Name',
      dataIndex: 'interpreterName',
      key: 'interpreterName',
      ...GetColumnSearchProps({ dataIndex: 'interpreterName', isDate: false }),
      sorter: (a, b) => {
        const aName = `${a?.interpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.interpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      }
    },
    {
      title: 'QA Specialist Name',
      dataIndex: 'qaSpecialistName',
      key: 'qaSpecialistName',
      sorter: (a, b) =>
        a?.qaSpecialistName.localeCompare(b?.CompaqaSpecialistNamenyCode),
      ...GetColumnSearchProps({ dataIndex: 'qaSpecialistName', isDate: false })
    }
  ]
  
  return (
    <Modal
      title={`Interpreter Details - Monitoring`}
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Close
        </Button>
      ]}
      width={'90%'}
    >
      <Table
        size="small"
        dataSource={dataSource} // Use monitorDataSource here
        columns={columns}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
        rowKey="InterpreterId"
      />
    </Modal>
  )
}

export default WidgetTwoMonitor
