import ActionType from "../action.types";
import { message } from "antd";
import Service from "../../services";

export const updateColumns = (columns, unselectedColumns, userId) => {
    return async (dispatch) => {
        const reqColumns = {
            id: userId,
            selectedColumns: columns.map((col) => col?.dataIndex),
            unSelectedColumns: unselectedColumns.map((col) => col?.dataIndex),
        };

        await Service.Auth().updateCustomColumns(reqColumns);

        dispatch({
            type: ActionType.CHANGE_COLUMNS,
            payload: {
                selectedColumns: columns,
                unselectedColumns: unselectedColumns,
            },
        });
    };
};
