import {
    Row,
    Col,
    theme,
    Table,
    Button,
    Input,
    Space,
    TimePicker,
    Select,
    Spin,
    message,
    Typography,
    Modal
} from "antd";
import { useEffect, useRef, useState, useCallback } from "react";
import { LinkOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateColumns } from "../../store/actions/selectedColumns";
import "./transaction.scss";
import Service from "../../services";
import moment from "moment";
import "moment-timezone";
import DropDown from "../../components/dropdown";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import Formatter from "../../utils/Formatter";
import DragnDrop from "./dragndrop";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useIntervalAsync from "../../components/hooks/useInterval";
import "jspdf-autotable";
import ExpandedRowRender from "./expanded.row";
import CustomFilter from "./customFilter";


dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Etc/UTC");
const { Option } = Select;

const GetColumnSearchProps = (dataIndex, updateFilters) => {
    const uniqueId = `transaction-filter-${dataIndex.dataIndex}`;
    return ({
        filterDropdown: (props) => <CustomFilter 
            dataIndex={dataIndex.dataIndex} 
            showTimeRange={dataIndex.showTimeRange}
            timeZone={dataIndex.timeZone}
            updateFilters={updateFilters}
            id={uniqueId}
            {...props} 
            />,
        filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined,
              }}
            />
          ),
    })
};

const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

const US_TIME_ZONES = [
    "Etc/UTC",
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
    "America/Anchorage",
    "Pacific/Honolulu",
];

const Transactions = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        let filters = {};

        for (let i = 0; ; i++) {
            const dataIdx = searchParams?.get(`dataIdx${i}`);
            const status = searchParams?.get(`status${i}`);

            if (dataIdx && status) {
                filters[dataIdx] = status;
            } else {
                break;
            }
        }

        if (filters?.ExtractedTime) {
            let hours = filters.ExtractedTime.split("-");
            filters.ExtractedTime = [
                dayjs().hour(hours[0]).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ss"),
                dayjs().hour(hours[1]).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ss"),
            ]
        }

        return filters;
    };
    const dashboardFilters = getDashboardFilters();

    const {
        token: { colorBgLayout , colorText, colorBgContainer},
    } = theme.useToken();

    const state = useSelector((state) => state);
    const columnState = useSelector((state) => state.selectedColumns);
    const userId = useSelector((state) => state?.profile?.user?.id);
    const { selectedColumns, unselectedColumns } = columnState;
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [tableLoading, setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filterSearch, setFilterSearch] = useState(dashboardFilters);
    const [params, setParams] = useState();
    const [currentSorter, setCurrentSorter] = useState({});

    const searchInput = useRef(null);
    const abortControllerRef = useRef(null);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const timeZoneRef = useRef("America/New_York");
    const createSorter = (dataIndex) => (a, b) => {};
    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);
    const themeColor = useSelector((state) => state?.profile?.theme);
    const getDefaultColumns = [
        {
            title: "Transaction ID",
            dataIndex: "ReferenceTransactionId",
            sorter: createSorter("ReferenceTransactionId"),
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "ReferenceTransactionId" }, updateFilters),
            width: "130px"
        },
        {
            title: "Request Date",
            dataIndex: "RequestTime",
            align: "center",
            // width: "200px",
            sorter: createSorter("RequestTime"),
            ...GetColumnSearchProps({ dataIndex: "RequestDate" }, updateFilters),
            render: (text, record) =>
                record.ConvertedDate
                    ? record.ConvertedDate
                    : moment(record.RequestTime)
                          .tz("America/New_York")
                          .format("YYYY-MM-DD"),
        },
        {
            title: "Request Time",
            dataIndex: "ExtractedTime",
            align: "center",
            // width: "200px",
            ...GetColumnSearchProps({
                dataIndex: "ExtractedTime",
                value: undefined,
                showTimeRange: true,
                timeZone: timeZoneRef.current
            }, updateFilters),
            render: (text, record) =>
                record.ConvertedTime
                    ? record.ConvertedTime
                    : moment(record.RequestTime)
                          .tz("America/New_York")
                          .format("HH:mm:ss")
        },
        {
            title: "Client Name",
            dataIndex: "ClientName",
            align: "center",
            // width: "280px",
            sorter: createSorter("ClientName"),
            ...GetColumnSearchProps({ dataIndex: "ClientName" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Time Zone",
            dataIndex: "Timezone",
            align: "center",
            // width: "280px",
            render: (text) => text || "-",
        },
        {
            title: "Target Language",
            dataIndex: "TargetLanguage",
            align: "center",
            sorter: createSorter("TargetLanguage"),
            ...GetColumnSearchProps({ dataIndex: "TargetLanguage" }, updateFilters),
        },
        {
            title: "Call Type",
            dataIndex: "VideoOption",
            align: "center",
            width: "150px",
            ...GetColumnSearchProps({ dataIndex: "VideoOption" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Status",
            dataIndex: "Status",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "Status" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Waiting Seconds",
            dataIndex: "WaitingSeconds",
            align: "center",
            sorter: createSorter("WaitingSeconds"),
            render: (text) => text || "-",
        },
        {
            title: "Service Minutes",
            dataIndex: "ServiceMinutes",
            align: "center",
            sorter: createSorter("ServiceMinutes"),
            render: (text) => text || "-",
        },
        {
            title: "Star Rating",
            dataIndex: "CallQualityRatingStar",
            align: "center",
            sorter: createSorter("CallQualityRatingStar"),
            ...GetColumnSearchProps({ dataIndex: "CallQualityRatingStar" }, updateFilters),
            render: (text) => 
                <div>
                    {text !== null ? text : '-'}
                </div>
        },
        {       
            title: "Routing Counts",
            dataIndex: "RoutingHistoryLength",
            align: "center",
            sorter: createSorter("RoutingHistoryLength"),
            ...GetColumnSearchProps({ dataIndex: "RoutingHistoryLength" }, updateFilters),
            render: (text, record) => {
                if (
                    record.RouteToBackup === "Yes" ||
                    record.RoutingHistoryLength === "undefined" ||
                    record.RoutingHistoryLength === 0 ||
                    record?.RoutingHistoryLength == null
                ) {
                    return "N/A";
                } else {
                    return (
                        <div>
                            <Link
                             key={colorText}
                             to={`/pages/terp-hud/routing-history?terp=${record.RoutingHistoryLength}&id=${record.ReferenceTransactionId}`}
                            >
                                {record.RoutingHistoryLength} <LinkOutlined />
                            </Link>
                        </div>
                    );
                }
            },
        },
        {
            title: "Caller Id",
            dataIndex: "CallerID",
            align: "center",
            // width: "180px",
            ...GetColumnSearchProps({ dataIndex: "CallerID" }, updateFilters),
            render: (text) => text || "-",
        },
    ];

    const getAdditionalColumns = [
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            align: "center",
            sorter: createSorter("ServiceStartTime"),
            render: (text, record) =>
                record.ConvertedServiceStartTime
                    ? record.ConvertedServiceStartTime
                    : record.ServiceStartTime
                    ? moment(text)
                          .tz("America/New_York")
                          .format("HH:mm:ss")
                    : "-",
                    },
        {
            title: "Cancel Time",
            dataIndex: "ServiceCancelTime",
            align: "center",
            sorter: createSorter("ServiceCancelTime"),
            render: (text, record) =>
                record.ConvertedCancelTime
                    ? record.ConvertedCancelTime
                    : record.ServiceCancelTime
                    ? moment(record.ServiceCancelTime)
                          .tz("America/New_York")
                          .format("YYYY-MM-DD HH:mm:ss")
                    : "-",
        },
        {
            title: "Client User Name",
            dataIndex: "UserName",
            align: "center",
            width: '150px !important',
            ...GetColumnSearchProps({ dataIndex: "UserName" }, updateFilters),
            },
        {
            title: "Route to Backup",
            dataIndex: "RouteToBackup",
            align: "center",
            sorter: createSorter("RouteToBackup"),
            ...GetColumnSearchProps({ dataIndex: "RouteToBackup" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "InterpreterId" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "InterpreterFirstName" }, updateFilters),
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Serial Number",
            dataIndex: "IOSSerialNumber",
            align: "center",
            width: "150px",
            // defaultSortOrder: "ascend",
            ...GetColumnSearchProps({ dataIndex: "IOSSerialNumber" }, updateFilters),
            render: (text) => text || "-",
        },
        {
            title: "Cost",
            dataIndex: "TotalPrice",
            align: "center",
            sorter: createSorter("TotalPrice"),
            render: (text) => 
            <div>
                {(text && `$${Number(text).toFixed(2)}`) || "-"}
            </div>
        },
    ];

    const getCustomColumns = async () => {
        const response = await Service.Auth().getCustomColumns(userId);
        const customColumns = response;
        let { preSelectedColumns, preUnSelectedColumns } = customColumns;
    
        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns;
        // create an copy of array
        let col = [...defaultColumns, ...additionalColumns];
        
        if (preSelectedColumns || preUnSelectedColumns) {
            const selectedColumns = [];
            const unselectedColumns = [];

            col.forEach((e) => {
                if (preSelectedColumns.includes(e.dataIndex)) {
                    selectedColumns.push(e);
                } else {
                    unselectedColumns.push(e);
                }
            })
            dispatch(updateColumns(selectedColumns, unselectedColumns, userId));
            return { selectedColumns, unselectedColumns };
        } else {
            let defaultCol = getDefaultColumns;
            let addCol = getAdditionalColumns;

            dispatch(updateColumns(defaultCol, addCol, userId));
            return { defaultCol, addCol };
        }
    };

    const fetchDataCB = useCallback(async () => {
        fetchDataPrivate(params?.pagination, params?.filters, params?.sorter);
    }, [params]);

    const fetchData = useIntervalAsync(
        fetchDataCB,
        state?.filter?.fetchInterval
    );

    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData();
        }
    }, [params]);

    useEffect(() => {
        setTableLoading(true);
        setParams({
            pagination: tableParams.pagination,
            filters: tableParams.filters,
            sorter: currentSorter,
        });
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.client,
        filterSearch,
        timeZone
    ]);

    const initialColumns = selectedColumns
        ? selectedColumns
        : getDefaultColumns;
    const initialUnselectedColumns = unselectedColumns
        ? unselectedColumns
        : getAdditionalColumns;
    const [columns, setColumns] = useState(initialColumns);
    const [columnsUnselected, setColumnsUnselected] = useState(
        initialUnselectedColumns
    );
   
    useEffect(() => {
        getCustomColumns().then(({ selectedColumns, unselectedColumns }) => {
            setColumns(selectedColumns);
            setColumnsUnselected(unselectedColumns);
        });
    }, []);

    useEffect(() => {
        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns;
        const newColumns =
        selectedColumns && selectedColumns.length > 0
            ? selectedColumns
            : defaultColumns;
        const newColumnsUnselected =
            unselectedColumns && unselectedColumns.length > 0
                ? unselectedColumns
                : additionalColumns;
        if (JSON.stringify(newColumns) !== JSON.stringify(columns)) {
            setColumns(newColumns);
        }
        if (
            JSON.stringify(newColumnsUnselected) !==
            JSON.stringify(columnsUnselected)
        ) {
            setColumnsUnselected(newColumnsUnselected);
        }
    }, [selectedColumns, unselectedColumns]);

    const getTotalRows = async (params, fltr) => {
        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: fltr,
            timeZone: timeZoneRef.current
        };

        await Service.Transaction()
            .getTransactionsTotal(filter, true)
            .then((data) => {
                setTableParams({
                    ...params,
                    pagination: {
                        ...params.pagination,
                        total: data.total,
                    },
                });
            });
    };

    const showModal = () => {
        setModalVisible(true);
        console.log("modalVisible: ", modalVisible);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDFFunction();
        } else if (downloadType === "EXCEL") {
            downloadCSVFunction();
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentSorter(sorter);
        setParams({ pagination, filters, sorter });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {

        if(selectedKeys.length > 0 && typeof selectedKeys[0] == "string") {
            selectedKeys[0] = selectedKeys[0]?.trim();

            if (selectedKeys[0] == '') {
                handleReset(clearFilters, dataIndex, confirm);
                return;
            }
        }

        if (selectedKeys.length == 0) {
            handleReset(clearFilters, dataIndex, confirm);
            return;
        }

        confirm();
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys,
            };
            setParams({
                pagination: tableParams.pagination,
                filters: { ...tableParams.filters, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            return updatedFilterSearch;
        });
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        confirm();
        setSearchText("");
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = JSON.parse(
                JSON.stringify(prevState || {})
            );
            const updatedTableParams = JSON.parse(
                JSON.stringify(tableParams.filters || {})
            );
            delete updatedTableParams[dataIndex];
            delete updatedFilterSearch[dataIndex];
            setParams({
                pagination: tableParams.pagination,
                filters: { ...updatedTableParams, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            setTableParams((pState) => {
                return { ...pState, filters: updatedTableParams };
            });
            return updatedFilterSearch;
        });
    };

    const fetchDataPrivate = async (pagination, filters, sorter = {}) => {
        let params = pagination
            ? {
                  pagination,
                  filters,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZoneRef.current
        };

        await getTotalRows(params, filter.filters);

        Service.Transaction()
            .getTransactions(
                filter,
                (params.pagination.current - 1) * params.pagination.pageSize,
                params.pagination.pageSize,
                params.sort,
                loading
            )
            .then( (data) => {
                // const promises = [];
                // for (let i = 0; i < data.length; i++) {
                //     promises.push((async () => {
                //         const details = await Service.Transaction().getAudioCallDetails(data[i].ReferenceTransactionId)
                //         data[i].details = details;
                //     })())
                // }
                // await Promise.all(promises)
                setData(data?.length > 0 ? formatData(data, timeZoneRef.current) : []);
                if (data !== undefined) {
                    setTableLoading(false);
                }
            });
    };

    const formatData = (rawData, timezoneStr) => {
        return rawData?.map((obj) => {
            let convertRequestTime = moment(obj.RequestTime).tz(timezoneStr);
            let convertedCancelTime, convertedServiceStartTime;
            let convertedDate = convertRequestTime.format("YYYY-MM-DD");
            let convertedTime = convertRequestTime.format("HH:mm:ss");
            if (obj.ServiceStartTime && obj.ServiceStartTime.length > 0) {
                convertedServiceStartTime = moment(obj.ServiceStartTime)
                    .tz(timezoneStr)
                    .format("HH:mm:ss");
            }
            if (obj.ServiceCancelTime && obj.ServiceCancelTime.length > 0) {
                convertedCancelTime = moment(obj.ServiceCancelTime)
                    .tz(timezoneStr)
                    .format("HH:mm:ss");
            }

            let newObj = {
                ...obj,
                ConvertedDate: convertedDate,
                ConvertedTime: convertedTime,
                ConvertedServiceStartTime: convertedServiceStartTime,
                ...(convertedCancelTime && {
                    ConvertedCancelTime: convertedCancelTime,
                }),
            };
            return newObj;
        });
    };


    const downloadCSVFunction = (pagination, filters, sorter) => {
        let params = pagination
            ? {
                  pagination,
                  filters: filterSearch,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZoneRef.current,
            // filters: filterSearch,
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        if (!dataIndexValues.includes('InterpreterId')) {
            dataIndexValues.push('InterpreterId');
        }
        if (!dataIndexValues.includes('InterpreterFirstName')) {
            dataIndexValues.push('InterpreterFirstName');
        }
        Service.Transaction()
        .downloadCSVUrl(
            filter, 
            params.sort, 
            dataIndexValues,
            state?.profile?.user?.email
        )
        .then((data) => {
            const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
            const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
            const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
            const filename = `Transaction_Records_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
            setLoading(false);
            const config = {
                title: filename,
                content: (
                    <Typography.Text>
                        Your report is currently being generated. You will receive an email shortly with a download link. <br/> <strong>Please note that the link will only be valid for one hour after you receive it</strong>.
                    </Typography.Text>
                ),
            };
            Modal.info(config);
        })
        .catch((err) => {
            console.log(err);
            throw err;
        });
    };

    const downloadPDFFunction = (pagination, filters, sorter) => {
        let params = pagination
            ? {
                  pagination,
                  filters: filterSearch,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZoneRef.current,
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        Service.Transaction()
            .downloadPDF(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
                const filename = `Transaction_Records_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    // Get dynamic table scroll width
    const totalMinWidth = columns?.reduce((acc, column) => {
        if (column?.width) {
          return acc + parseInt(column?.width, 10);
        }
        return acc + 120;
    }, 0);

    return (
        <div className="roles-container">
            <Row className="irh-container">
                <Col flex={"100%"}>
                    <Table
                        title={() => (
                            <Row className="select-col">
                                <Col flex={"auto"}>
                                    <Button
                                        onClick={showModal}
                                        type="primary"
                                        className="select-col-btn"
                                    >
                                        Select Columns
                                    </Button>
                                </Col>
                                <Col className="dropdown-col">
                                    <Row
                                        gutter={[8, 8]}
                                        justify="end"
                                        align="middle"
                                        className="dropdown-download-row"
                                    >
                                        <Col>
                                            <DropDown
                                                className="dropdown-element"
                                                defaultValue={downloadType}
                                                dropdownOptions={
                                                    downloadDropdownType
                                                }
                                                handleTypeSelect={(
                                                    downloadType
                                                ) =>
                                                    setDownloadType(
                                                        downloadType
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                onClick={downloadFile}
                                            >
                                                Download
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        scroll={{ x: totalMinWidth }}
                        columns={columns}
                        rowKey={(record) => record?.Id}
                        dataSource={data}
                        pagination={tableParams.pagination}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        size="middle"
                        className="voyce-custom-table responsive-table"
                        expandable={{
                            expandedRowRender: (record) => <ExpandedRowRender record={record}/>,
                            defaultExpandAllRows: false,
                        }}
                        style={{
                            backgroundColor: colorBgContainer,
                            color: colorText
                        }}
                        sticky
                        rowClassName={(record, index) =>
                            record?.Status == "New"
                                ? "new-row"
                                : record?.Status == "In Service"
                                ? "inservice-row"
                                : record?.Status == "Pending"
                                ? "pending-row"
                                : "other-row"
                        }
                    />
                </Col>
            </Row>
            <DragnDrop
                defaultColumns={columns}
                additionalColumns={columnsUnselected}
                modalVisible={modalVisible}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default Transactions;