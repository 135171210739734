import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different Company action types
*/

export default function language(state = {
        volume: [],
        avgTime: {legends: [], seriesData: []},
        distribution: {legends: [], seriesData: []},
        interpretationType: {
            audio: {
                legends: [],
                seriesData: []
            },
            video: {
                legends: [],
                seriesData: []
            }
        },
        rawData: []
    }, action) {
    switch (action.type) {
        case ActionType.SET_LANGUAGE_ANALYTICS:
            return {
                ...state, 
                volume: action.volume, 
                rawData: action.rawData, 
                distribution: action.distribution,
                avgTime: action.avgTime,
                interpretationType: action.interpretationType
            };
        case ActionType.LOGOUT: 
            return {
                volume: [],
                avgTime: {legends: [], seriesData: []},
                distribution: {legends: [], seriesData: []},
                interpretationType: {
                    audio: {
                        legends: [],
                        seriesData: []
                    },
                    video: {
                        legends: [],
                        seriesData: []
                    }
                },
                rawData: []
            }
        default:
            return state;
    }
}
