import axios from 'axios';
import { store } from '..';
import constants from '../constants';
import { logout, operationInProgress } from '../store/actions/profile.action';

const request = () => {

    // Setting default options for VDMS
    const defaultOptions = {
        baseURL: constants.API_BASE_URL,   
        headers: {
          'Content-Type': 'application/json',
        },
    };

    const createFilter = () => {
        let state = store.getState();
        let type = state?.company?.client && state?.company?.client?.ClientId != '-1' ? 'client' : 
                state?.company?.selected && state?.company?.selected?._id != '-1' ? 'company' : 
                'admin';
        return ({
            filterType: type,
            id: type == 'client' ? state.company.client.ClientId:
                type == 'company' ? state.company.selected._id :
                undefined,
            dateRangeLabel: state.filter?.date?.dateRangeLabel,
        }) || {}
    }

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for request
    instance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
        if (config.data && config.filter) {
            config.data = {
                ...config.data,
                ...createFilter()
            }
        }
        // Check abort signal
        if (config.signal) {
            config.cancelToken = new axios.CancelToken((cancel) => {
              config.signal.addEventListener("abort", cancel);
            });
          }

        if (!config || (config && !config.hideLoader)) {
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(true));
        }
        return config;
    }, function(error) {
        console.log('Error');
        return Promise.reject(error);
    });

    instance.interceptors.response.use(function(response) {
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(false));
        return response;
    }, function(error) {
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(false));
        if (error.response.status == 401) {
            store.dispatch(logout());
        }
        console.log('Error fetching the data');
        return Promise.reject(error);
    });

    return instance;
};

export default request();