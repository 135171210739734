import { Card, Spin, theme } from "antd";
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts/core';
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTop10Languages } from "../../store/actions/analytics.action";
import Formatter from "../../utils/Formatter";
import useIntervalAsync from "../../components/hooks/useInterval";

const TopLanguagesChart = () => {
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();
    const selected = useSelector(state => state?.company?.selected)
    const [loading, setLoading] = useState(false);

    const state = useSelector((s) => s);
    const dispatch = useDispatch();

    const fetchLanguagesCB = useCallback(async () => {
        dispatch(getTop10Languages(true))
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchLanguagesCB()
    }, [state.filter?.date, state.company?.client]);

    const data = useSelector((state) => state?.analytics.top10Languages);


    const getOption = () => {
        let graphics = echarts.graphic;
        let colors = [
            '#226885',
            '#0f9191',
            '#61636f',
            '#6c40a6'
        ]
        return {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0] - 120,'10%'];
                },
                axisPointer: {
                  type: 'shadow',
                },
                formatter: (params) => {
                    let tooltipContent = '';
                    
                    params.forEach((param) => {
                        const seriesName = param.seriesName;
                        const dataIndex = param.dataIndex;
                        const dataItem = data.data[dataIndex];
                        
                        if (seriesName === 'count') {
                            tooltipContent += `Language: <b>${dataItem.TargetLanguage}</b><br>`;
                            tooltipContent += `ISO: <b>${dataItem.ISOCode}</b><br>`;
                            tooltipContent += `Total Calls: ${Formatter.numberWithCommas(dataItem.TotalCalls)}<br>`;
                            tooltipContent += `Serviced Calls: ${Formatter.numberWithCommas(dataItem.CountSuccessAudioCalls + dataItem.CountSuccessVideoCalls)}<br>`;
                            tooltipContent += `Cancel Calls: ${Formatter.numberWithCommas(dataItem.CountFailedAudioCalls + dataItem.CountFailedVideoCalls)}<br>`;
                        }
                    });
                    
                    return tooltipContent;
                }
            },
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 2,
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: data?.legends.slice(0, 10),
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        interval: 0,
                        align: 'center',
                    }
                },
            ],
            yAxis: [
                {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                },
            ],
            series: [
                {
                    name: "count",
                    type: "bar",
                    barWidth: "70%",
                      data: data?.seriesData?.map((val, idx) => {
                        return {
                            value: val,
                            itemStyle: {
                                color: colors[idx % colors.length],
                            },
                        };
                    }),
                }
            ],
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
            ],
        };
    };


    return (
       <Spin spinning={loading} >
        <Card style={{height: 373, borderRadius: 4, backgroundColor: colorBgLayout}}>
            <ReactECharts option={getOption()} style={{height: 290}} />
            <p style={{textAlign: 'center'}} >Top 10 Languages</p>
        </Card>
       </Spin>
    )
}

export default TopLanguagesChart;
export const MemoizedTopLanguagesChart = memo(TopLanguagesChart);
