import moment from 'moment'
import { InvoiceEnum } from '../../invoice.enum'
import ActionType from '../action.types'

/*
    Reducer function to push data to the store in case of different Company action types
*/

export default function filter(
  state = {
    date: {
      maxDate: moment().endOf('day').toDate(),
      minDate: moment().startOf('day').toDate(),
      dateRangeLabel: 'Today'
    },
    fetchInterval: 60000,
    languageType: '-1',
    invoiceType: InvoiceEnum.ALL,
    year: {
      maxDate: moment()
        .endOf('year')
        .endOf('month')
        .endOf('days')
        .utc()
        .toDate(),
      minDate: moment()
        .startOf('year')
        .startOf('month')
        .add('days', 1)
        .utc()
        .toDate()
    },
    prevSelectedTimeRange: null, 
  },
  action
) {
  switch (action.type) {
    case ActionType.SET_DATE:
      return {
        ...state,
        date: {
          maxDate: action.date.maxDate,
          minDate: action.date.minDate,
          dateRangeLabel: action.date.dateRangeLabel
        },
        fetchInterval: action.date.fetchInterval
      }
    case ActionType.SET_DATE_YEAR:
      return { ...state, year: action.year }
    // Reducer switch cases
    case ActionType.SET_PREV_TIME_RANGE:
      return {
        ...state,
        prevSelectedTimeRange: action.timeRange
    };

    case ActionType.RESET_PREV_TIME_RANGE:
      return {
        ...state,
        prevSelectedTimeRange: null
    };
    case ActionType.SET_INVOICE_TYPE:
      return { ...state, invoiceType: action.invoiceType }
    case ActionType.SET_LANGUAGE_TYPE:
      return { ...state, languageType: action.languageType }
    case ActionType.LOGOUT:
      return {
        date: {
          maxDate: moment().endOf('day').toDate(),
          minDate: moment().startOf('day').toDate(),
          dateRangeLabel: 'Today'
        },
        languageType: '-1',
        invoiceType: InvoiceEnum.ALL,
        fetchInterval: 12000,
        year: {
          maxDate: moment('2023')
            .endOf('year')
            .endOf('month')
            .endOf('days')
            .utc()
            .toDate(),
          minDate: moment('2023')
            .startOf('year')
            .startOf('month')
            .add('days', 1)
            .utc()
            .toDate()
        }
      }
    default:
      return state
  }
}
