import { Modal, Row, Col, Button, Typography, Spin, message } from 'antd'
import { useState, useEffect } from 'react';
import Service from '../../services';

const Monitor = (props) => {
  const { open, handleClose, transactionId } = props
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (transactionId) {
      setLoading(true);
      setUrl()
      setError()
      Service.Transaction().getMonitorURL(transactionId)
      .then((result) => {
        if (!result || result.isError) {
          setError('The URL for the requested transaction could not be fetched. Please contact the administrator.')
        } else {
          setUrl(result.url);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false))
    } 
  }, [transactionId])

  return (
    <Modal
      centered
      title={
        <Row>
          <Col flex={'auto'}>
            <Typography.Text strong>
              &nbsp;&nbsp;Activity Monitor
            </Typography.Text>
          </Col>
        </Row>
      }
      closeIcon={false}
      footer={
        <Row>
          <Col flex={'auto'}>
            <Button type="primary" block onClick={handleClose} >
              Leave
            </Button>
          </Col>
        </Row>
      }
      open={open}
      onCancel={handleClose}
      destroyOnClose
      width={'95%'}
      className="monitor-modal"
      styles={{
        content: {
          padding: 5
        },
        footer: {
          // display: 'none'
        }
      }}
    >
      <Row justify={'center'} align={'middle'}>
        <Col flex={'auto'}>
          <Spin spinning={loading} tip="Loading Monitor..." size='large'>
          { error ?
            <Row justify={"center"} align={"middle"}>
              <Col style={{padding: 10}}>
              <Typography.Title level={2}>
                {error}
              </Typography.Title>
              </Col>
            </Row> :
              <iframe
              style={{
                width: '100%',
                borderRadius: 8,
                border: '0.5px solid rgba(71, 70, 70, 0.355)',
                height: 780
              }}
              allow="camera;microphone"
              src={url}
            >{
              
            }</iframe>

          }  
          </Spin>
        </Col>
      </Row>
    </Modal>
  )
}

export default Monitor
