import React, { useState, useEffect, useCallback } from 'react';
import { Table, Select, Row, Col, Button, Tag, Card, Spin } from 'antd';
import DropDown from "../../components/dropdown";
import EChartsReact from 'echarts-for-react';
import { fetchInterpretersEvaluated } from '../../store/actions/terp.action';
import { useDispatch, useSelector } from 'react-redux';
import Service from '../../services';
import moment from 'moment';
import _ from 'lodash';
import GetColumnSearchProps from '../../components/table/search';
import Papa from 'papaparse';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { exportToExcel } from '../../services/exportToExcel';
const ReportPage = () => {
  const [selectedSpecialists, setSelectedSpecialists] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState(dataSource);
  const [downloadType, setDownloadType] = useState("EXCEL");

  const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
  ];

  // Utility function to capitalize the first letter of each word in a string
  const capitalizeName = (name) => {
    return name.replace(/\b\w/g, char => char.toUpperCase());
  };

  // Function to get unique specialist IDs to be shown in dropdown of qaSpecialistId
  const qaSpecialistData = _.uniqBy(
    dataSource
      .filter(evaluation => evaluation.invalid === false)
      .map((e) => ({
        id: e?.qaSpecialistId,
        name: capitalizeName(e?.qaSpecialistName)
      })),
    'id'
  );

  const downloadFile = () => {
    if (downloadType === "PDF") {
        downloadPDF();
    } else if (downloadType === "EXCEL") {
        downloadExcel();
    }
  };

  // Function to fetch data from service to be displayed on frontend
  const fetchInterpretersCB = useCallback(() => {
    Service.Terp()
      .getEvaluatedInterpreters({
        month: 'April',
        start: state.filter?.date?.minDate,
        end: state.filter?.date?.maxDate,
      })
      .then((data) => {
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [state.filter?.date]);

  // To fetch the data and make sure we get updated data on date change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      fetchInterpretersCB();
    };

    fetchData();
  }, [state.filter?.date]);

  // Filter data when a QA specialist ID is changed and filter data to have no invalid entries
  useEffect(() => {
    const filtered = dataSource
      .filter(evaluation => evaluation.invalid === false)
      .filter(evaluation => evaluation.evaluationSubmitTime)
      .filter(evaluation => selectedSpecialists.length === 0 || selectedSpecialists.includes(evaluation.qaSpecialistId));  
    setFilteredData(transformDataForTable(filtered));
  }, [selectedSpecialists, dataSource]);

  // Modify data to be displayed in ME and NI columns
  const transformDataForTable = (data) => {
    return data.map((row) => {
      const evalData = {
        ME: [],
        NI: []
      };
  
      const evalFields = [
        'Introduction/Scripting',
        '1st person Interpreting',
        'Omissions',
        'Additions/Substitutions',
        'Terminology/Vocabulary',
        'Protocol',
        'Professional Demeanor',
        'Remaining neutral',
        'OPI/VRI Compliance',
        'Proper closing'
      ];
  
      evalFields.forEach(field => {
        const fieldValue = row.answers ? row.answers[field] : row[field];
        const bypassforMe = !row.answers && row.score === 62;

        if ((fieldValue !== undefined && typeof fieldValue === 'number' && fieldValue > 0) || bypassforMe) {
          evalData.ME.push(field.replace('/', ' '));
        } else if (typeof fieldValue !== 'string') {
          evalData.NI.push(field.replace('/', ' '));
        }
      });
  
      return {
        ...row,
        eval: evalData
      };
    });
  };
  const columns = [
    {
      title: 'Interpreter ID',
      dataIndex: 'interpreterId',
      key: 'interpreterId',
      align: 'center',
      width: 200,
      sorter: (a, b) => a?.interpreterId - b?.interpreterId,
      ...GetColumnSearchProps({ dataIndex: 'interpreterId', isDate: false })
    },
    {
      title: 'Interpreter Name',
      dataIndex: 'interpreterName',
      key: 'interpreterName',
      align: 'center',
      width: 200,
      sorter: (a, b) => {
        const aName = `${a?.interpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase();
        const bName = `${b?.interpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase();
        return aName.localeCompare(bName);
      },
      ...GetColumnSearchProps({ dataIndex: 'interpreterName', isDate: false })
    },
    {
      title: 'Total Score',
      dataIndex: 'score',
      key: 'totalScore',
      align: 'center',
      sorter: (a, b) => a?.score - b?.score,
      ...GetColumnSearchProps({ dataIndex: 'score', isDate: false })
    },
    {
      title: 'QA Specialist',
      dataIndex: 'qaSpecialistName',
      width: 200,
      align: 'center',
      key: 'qaSpecialistName'
    },
    {
      title: 'Evaluation',
      key: 'Eval',
      width: 400,
      children: [
        {
          title: 'ME',
          dataIndex: ['eval', 'ME'],
          key: 'evalME',
          align: 'center',
          render: (text) => text?.map((e) => <Tag>{e}</Tag>),
          width: 300
        },
        {
          title: 'NI',
          dataIndex: ['eval', 'NI'],
          align: 'center',
          key: 'eval',
          render: (text) => text?.map((e) => <Tag>{e}</Tag>),
          width: 300
        }
      ]
    },
    {
      title: 'Strengths',
      dataIndex: 'strengths',
      width: 500,
      align: 'center',
      key: 'Strengths',
      render: (text) => text?.map((e) => <Tag>{e}</Tag>)
    },
    {
      title: 'Opening/Closing',
      dataIndex: 'Opening/Closing',
      align: 'center',
      width: 180,
      key: 'Opening/Closing',
      render: (text) =>
        typeof text === 'string' ? (
          <Tag>{text}</Tag>
        ) : Array.isArray(text) ? (
          text.map((e) => <Tag>{e}</Tag>)
        ) : (
          '-'
        )
    },
    {
      title: 'Accuracy',
      dataIndex: 'Accuracy',
      align: 'center',
      width: 180,
      key: 'Accuracy',
      render: (text) =>
        typeof text === 'string' ? (
          <Tag>{text}</Tag>
        ) : Array.isArray(text) ? (
          text.map((e) => <Tag>{e}</Tag>)
        ) : (
          '-'
        )
    },
    {
      title: 'ProtocolOpt',
      dataIndex: 'ProtocolOpt',
      align: 'center',
      width: 180,
      key: 'ProtocolOpt',
      render: (text) =>
        typeof text === 'string' ? (
          <Tag>{text}</Tag>
        ) : Array.isArray(text) ? (
          text.map((e) => <Tag>{e}</Tag>)
        ) : (
          '-'
        )
    },
    {
      title: 'Professionalism',
      dataIndex: 'Professionalism',
      align: 'center',
      width: 180,
      key: 'Professionalism',
      render: (text) =>
        typeof text === 'string' ? (
          <Tag>{text}</Tag>
        ) : Array.isArray(text) ? (
          text.map((e) => <Tag>{e}</Tag>)
        ) : (
          '-'
        )
    },
    {
      title: 'Technical Issues',
      dataIndex: 'Technical Issues',
      align: 'center',
      width: 180,
      key: 'Technical Issues',
      render: (text) =>
        typeof text === 'string' ? (
          <Tag>{text}</Tag>
        ) : Array.isArray(text) ? (
          text.map((e) => <Tag>{e}</Tag>)
        ) : (
          '-'
        )
    },
    {
      title: 'Feedback',
      dataIndex: 'Feedback',
      align: 'center',
      width: 180,
      key: 'Feedback'
    },
    {
      title: 'Date',
      dataIndex: 'monitorStartTime',
      width: 160,
      align: 'center',
      key: 'dateOfEvaluation',
      render: (e) =>
        e
          ? moment.tz(e, 'America/New_York').format('MM-DD-YYYY')
          : '-'
    },
    {
      title: 'Request Id',
      dataIndex: 'requestId',
      width: 160,
      align: 'center',
      key: 'requestId'
    },
    {
      title: 'Time of Evaluation',
      dataIndex: 'evaluationSubmitTime',
      width: 200,
      align: 'center',
      key: 'dateOfEvaluation',
      render: (e) =>
        e
          ? moment.tz(e, 'America/New_York').format('HH:mm:ss')
          : '-'
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      align: 'center',
      key: 'notes',
      width: 180
    }
  ];

  // Option to modify chart details here
  const getOption = () => {
    return {
      grid: {
        top: 20,
        left: 20,
        right: 20,
        bottom: 20,
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const dataIndex = params[0].dataIndex;
          const interpreter = filteredData[dataIndex];
          return `Date: ${moment.utc(interpreter?.evaluationSubmitTime).format('MM/DD')}<br/>Score: ${interpreter.score}<br/>Interpreter ID: ${interpreter.interpreterId}`;
        }
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(data => moment.utc(data.evaluationSubmitTime).format('MM/DD'))
      },
      yAxis: {
        type: 'value',
      },
      series: [{
        data: filteredData.map(data => data.score),
        type: 'line',
        smooth: true
      }]
    };
  };

  // Download CSV function
  const downloadCSV = useCallback(() => {
    const csvData = filteredData.map(row => {
      const mergedRow = {...row, ...row.answers, "eval.ME": row?.eval?.ME?.join(), "eval.NI": row?.eval?.NI?.join()};
      
      const csvRow = columns.reduce((acc, col) => {
        if (col.children) {
          col.children.forEach(childCol => {
            acc[childCol.title] = mergedRow[childCol.dataIndex.join('.')];
          });
        } else {
          acc[col.title] = mergedRow[col.dataIndex];
        }
        return acc;
      }, {});
  
      return csvRow;
    });
  
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [filteredData, columns]);


  const downloadExcel = useCallback(() => {
    const excelData = filteredData.map(row => {
      const mergedRow = { 
        ...row, 
        ...row.answers, 
        "eval.ME": Array.isArray(row?.eval?.ME) ? row.eval.ME.join(' / ') : '', 
        "eval.NI": Array.isArray(row?.eval?.NI) ? row.eval.NI.join(' / ') : '',
        'strengths': Array.isArray(row?.answers?.strengths) ? row.answers.strengths.join(' / ') : '',
        'Opening/Closing': Array.isArray(row?.answers?.['Opening/Closing']) ? row.answers['Opening/Closing'].join(' / ') : '',
        'Accuracy': Array.isArray(row?.answers?.Accuracy) ? row.answers.Accuracy.join(' / ') : '',
        'ProtocolOpt': Array.isArray(row?.answers?.ProtocolOpt) ? row.answers.ProtocolOpt.join(' / ') : '',
        'Professionalism': Array.isArray(row?.answers?.Professionalism) ? row.answers.Professionalism.join(' / ') : '',
        'Technical Issues': Array.isArray(row?.answers?.['Technical Issues']) ? row.answers['Technical Issues'].join(' / ') : ''
      
      };
  
      const excelRow = columns.reduce((acc, col) => {
        if (col.children) {
          col.children.forEach(childCol => {
            acc[childCol.title] = mergedRow[childCol.dataIndex.join('.')];
          });
        } else {
          acc[col.title] = mergedRow[col.dataIndex];
        }
        return acc;
      }, {});
      return excelRow;
    });
  
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
    const filename = `QA_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
    const sheetData = [excelData];
    const sheetNames = ["QA Report"];
  
    exportToExcel(sheetData, sheetNames, filename);
  }, [filteredData, columns]);

  // Download PDF function
  const downloadPDF = useCallback(() => {
    const doc = new jsPDF('l', 'in', [10, 15]);
  
    const head = columns.map(col => col.children ? col.children.map(child => child.title) : col.title).flat();
  
    const body = filteredData.map(row => {
      const mergedRow = {...row, ...row.answers, "eval.ME": row?.eval?.ME?.join(`,\n`), "eval.NI": row?.eval?.NI?.join(`,\n`)};
  
      return columns.flatMap(col => {
        if (col.children) {
          return col.children.map(childCol => mergedRow[childCol.dataIndex.join('.')]);
        } else {
          return mergedRow[col.dataIndex];
        }
      });
    });
  
    doc.autoTable({
      styles: { fontSize: 6, cellWidth: 'wrap' },
      columnStyles: { 4: { cellWidth: '300px' }, 5: { cellWidth: '300px' } },
      head: [head],
      body: body,
    });
  
    doc.save('report.pdf');
  }, [filteredData, columns]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]} className="irh-container">
        <Col flex={'auto'} style={{ align: 'center' }}>
          <Select
            size='large'
            mode="multiple"
            style={{ width: '25%', fontSize: '1.2rem' }}
            placeholder="Select QA Specialists"
            showSearch
            filterOption={(input, option) => 
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={setSelectedSpecialists}
          >
            {qaSpecialistData.map((specialist) => (
              <Select.Option style={{ fontSize: '1.2rem' }} key={specialist.id} value={specialist.id}>
                {specialist.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={24}>
          <Card>
            <EChartsReact
              option={getOption()}
              style={{ height: '300px', width: '100%' }}
            />
            <p style={{ textAlign: 'center', color: 'white' }}>Interpreter Score</p>
          </Card>
        </Col>
        <Col span={24}>
          <Table
            title={() => (
              <Col className="dropdown-col" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Row
                  gutter={[8, 8]}
                  justify="end"
                  align="middle"
                  className="dropdown-download-row"
                >
                  <Col>
                    <DropDown
                      className="dropdown-element"
                      defaultValue={downloadType}
                      dropdownOptions={downloadDropdownType}
                      handleTypeSelect={setDownloadType}
                    />
                  </Col>
                  <Col>
                    <Button
                      loading={loading}
                      type="primary"
                      onClick={downloadFile}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
            bordered
            size='small'
            columns={columns}
            dataSource={filteredData?.map((e) => ({
              ...e,
              ...e?.answers
            }))}
            rowKey="id"
            className="voyce-custom-table responsive-table"
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            scroll={{ x: 2800 }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ReportPage;
