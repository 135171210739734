class Validator {
    static PasswordRules = [
        {name: 'At least 7 characters.', rule: /^.{7,}$/},
        {name: 'At least one number.', rule: /.*[0-9].*/},
        {name: 'At least one special character.', rule: /(?=.*\W)/},
        {name: 'Must contain one uppercase letter.', rule: /(?=.*?[A-Z])/},
        {name: 'Must contain one lowercase letter.', rule: /(?=.*?[a-z])/},
    ];

    static validatePassowrd = (value='') => {
        for (let i=0; i<this.PasswordRules.length; i++) {
            if (!value.match(this.PasswordRules[i].rule)) {
                return;
            }
        }
        return true;
    }
}

export default Validator;