import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Page403 from "./403";
import { USER, SUPER_ADMIN, ADMIN, PROHIBITED_ACCESS } from "../role";

const ProtectedRoutes = () => {
    const token = useSelector((state) => state.profile?.token);
    const user = useSelector((state) => state.profile?.user);
    const location = useLocation();
    const [pathname, setPathname] = useState("");
    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    const UnauthorizeUser = () => {
        const prohibitedPaths = PROHIBITED_ACCESS[user.permissions.role];
        return prohibitedPaths?.includes(pathname);
    };

    return token ? (
        UnauthorizeUser() ? (
            <Page403 />
        ) : (
            <Outlet />            
        )
    ) : (
        <Navigate to={"/auth/login"} />
    );
};

export default ProtectedRoutes;
