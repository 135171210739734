const ActionType = {
    // Login action types
    LOGIN_ERROR: "LOGIN_ERROR",
    OPERATION_INPROGRESS: "OPERATION_INPROGRESS",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT: "LOGOUT",
    THEME: "THEME", // Action type for changing theme

    // Company Action Types
    SET_COMPANIES: "SET_COMPANIES",
    SELECT_COMPANY: "SELECT_COMPANY",
    SELECT_CLIENT: "SELECT_CLIENT",

    // Filter Action Types
    SET_LANGUAGE_TYPE: "SET_LANGUAGE_TYPE",
    SET_DATE: "SET_DATE",
    SET_DATE_YEAR: "SET_DATE_YEAR",
    SET_INVOICE_TYPE: "SET_INVOICE_TYPE",
    SET_QA_TIME_RANGE: "SET_QA_TIME_RANGE",
    SET_PREV_TIME_RANGE: "SET_PREV_TIME_RANGE",
    RESET_PREV_TIME_RANGE: "RESET_PREV_TIME_RANGE",


    SET_WIDGETS: 'SET_WIDGETS',
    SET_TOP10: 'SET_TOP10',
    SET_PREFERRED_VIDEO: 'SET_PREFERRED_VIDEO',
    SET_AVR_CHART: 'SET_AVR_CHART',
    SET_CALLS_BY_HOURS: 'SET_CALLS_BY_HOURS',
    SET_ACTIVE_CARTS: 'SET_ACTIVE_CARTS',
    SET_TOTAL_ACTIVE_CARTS: 'SET_TOTAL_ACTIVE_CARTS',
    SET_TERP_POSITION_COUNT: 'SET_TERP_POSITION_COUNT',
    SET_STAR_RATING: 'SET_STAR_RATING', // Action type for star rating
    SET_TOP_CUSTOMER: 'SET_TOP_CUSTOMER',

    // User Management
    UPDATE_USER: "UPDATE_USER",
    GET_USER_LIST: "GET_USER_LIST",
    GET_TERP_LIST: "GET_TERP_LIST",
    USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
    USER_DELETE_ERROR: "USER_DELETE_ERROR",

    SET_INVOICE_ANALYTICS: "SET_INVOICE_ANALYTICS",
    SET_COOKED_INVOICES: "SET_COOKED_INVOICES",
    SET_INVOICES: "SET_INVOICES",

    //Terp
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',

    //Select Columns
    CHANGE_COLUMNS: 'CHANGE_COLUMNS',

    //Language Actions
    SET_LANGUAGE_ANALYTICS: 'SET_LANGUAGE_ANALYTICS',

    // User Audit
    FETCH_AUDIT_DATA_REQUEST: 'FETCH_AUDIT_DATA_REQUEST',
    FETCH_AUDIT_DATA_SUCCESS: 'FETCH_AUDIT_DATA_SUCCESS',
    FETCH_AUDIT_DATA_FAILURE: 'FETCH_AUDIT_DATA_FAILURE',
    FETCH_AUDIT_AGG_DATA_SUCCESS: 'FETCH_AUDIT_AGG_DATA_SUCCESS',

    // Admin Audit
    FETCH_ADMIN_AUDIT_DATA_REQUEST: 'FETCH_ADMIN_AUDIT_DATA_REQUEST',
    FETCH_ADMIN_AUDIT_DATA_SUCCESS: 'FETCH_ADMIN_AUDIT_DATA_SUCCESS',
    FETCH_ADMIN_AUDIT_DATA_FAILURE: 'FETCH_ADMIN_AUDIT_DATA_FAILURE',
    FETCH_ADMIN_AUDIT_AGG_DATA_SUCCESS: 'FETCH_ADMIN_AUDIT_AGG_DATA_SUCCESS',
};

export default ActionType;
