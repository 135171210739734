// InterpreterOnline.js

import React, { useCallback, useState } from 'react'
import { Card, Modal, Button, Table, theme, Switch, Tabs, Badge } from 'antd'
import ReactECharts from 'echarts-for-react'
import ScoreCard from '../score.card/score.card'
import InterpretersBarGraph from './interpreters.evaluated';
import GetColumnSearchProps from '../../../components/table/search';
import events from '../socket.events';

const LABELS = [
  'NI',
  'LME',
  'ME',
  'EE'
]

const { TabPane } = Tabs;

const InterpreterOnline = ({ interpreters, socket, ongoingSessions, ongoingSessionsDetails }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [tableModalVisible, setTableModalVisible] = useState(false)
  const [monitorModalVisible, setMonitorModalVisible] = useState(false)
  const [selectedInterpreter, setSelectedInterpreter] = useState(null)
  const [showScoreCard, setShowScoreCard] = useState(false)
  const [showInterpreterBar, setShowInterpreterBar] = useState(true)
  const scoreLabelCount = {
    NI: 0,
    LME: 0,
    ME: 0,
    EE: 0
  }
  const [filteredInterpreters, setFilteredInterpreters] = useState([]);


  const showModal = () => {
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
    setTableModalVisible(false)
    setMonitorModalVisible(false)
    setSelectedInterpreter(null)
    setShowScoreCard(false)
  }

  const handleBarClick = (params) => {
    // Handle bar click and show the table modal
    // setSelectedInterpreter(params.name)
    setTableModalVisible(LABELS[params.dataIndex])
  }

  const handleInterpreterClick = (record) => {
    // Handle interpreter click and show the monitor modal
    setSelectedInterpreter(record)
    setMonitorModalVisible(true)
  }

  const handleToggle = () => {
    setShowInterpreterBar((prevShowInterpreterBar) => !prevShowInterpreterBar)
  }

  const handleMonitorModalYes = useCallback(() => {
    if (ongoingSessions[selectedInterpreter?.RequestId]) {
      Modal.info({
        title: 'Interpreter Under Evaluation',
        content: 'Please Consider Trying Another Interpreter'
      })
      handleCancel()
    } else {
      setShowScoreCard(true);
      socket.emit(events.START_INTERPRETER_MONITORING, {
        interpreterId: selectedInterpreter?.InterpreterId,
        requestId: selectedInterpreter?.RequestId,
        interpreterName: selectedInterpreter?.FirstName+ " " + selectedInterpreter?.LastName,
      });
    }
  }, [selectedInterpreter, ongoingSessions])

  const handleMonitorModalNo = () => {
    setShowScoreCard(false)
    handleCancel()
  }

  const {
    token: { colorText }
  } = theme.useToken()

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      render: (text, row) => (
        <Button type="link" onClick={() => handleInterpreterClick(row)}>
          {text}
        </Button>
      )
    },
    {
        title: 'Name',
        dataIndex: 'InterpreterName',
        key: 'InterpreterName',
        sorter: (a, b) => {
          const aName = `${a.InterpreterName || ''} ${a.LastName || ''}`.trim().toLowerCase();
          const bName = `${b.InterpreterName || ''} ${b.LastName || ''}`.trim().toLowerCase();
          return aName.localeCompare(bName);
        },
        ...GetColumnSearchProps({dataIndex: 'InterpreterName', isDate:false}),
    },
    {
        title: 'Company ID',
        dataIndex: 'CompanyId',
        key: 'CompanyId',
        sorter: (a, b) => a.CompanyId - b.CompanyId,
        ...GetColumnSearchProps({dataIndex: 'CompanyId', isDate:false}),
      },
      {
        title: 'Language',
        dataIndex: 'Languages',
        key: 'Languages',
        render: (Languages) => (Languages ? Languages.join(', ') : ''),
        sorter: (a, b) => (a.Languages || []).join(',').localeCompare((b.Languages || []).join(',')),
        ...GetColumnSearchProps({dataIndex: 'Languages', isDate:false}),
      },
  ]

  const handleStatusFilterChange = (key) => {
    switch (key) {
      case 'inConference':
        setFilteredInterpreters(interpreters.filter(i => i.status === 'in conference'));
        break;
      case 'online':
        setFilteredInterpreters(interpreters.filter(i => i.status === 'online'));
        break;
      case 'offline':
        setFilteredInterpreters(interpreters.filter(i => i.status === 'offline'));
        break;
      default:
        setFilteredInterpreters(interpreters);
    }
  };
  return (
    <Card style={{ minHeight: '404px' }}>
      {showInterpreterBar ? (
        <InterpretersBarGraph
          interpreters={interpreters}
          socket={socket}
          ongoingSessions={ongoingSessions}
          ongoingSessionsDetails={ongoingSessionsDetails}
        />
      ) : (
        <>
          {/* Check interpreters without a scoreCard and with status "Online" or "In Conference" */}
          <Table
            columns={tableColumns}
            dataSource={interpreters?.filter(
              (interpreter) =>
                !interpreter?.scoreCard &&
                ['Online', 'In Conference'].includes(interpreter?.Status)
            )}
            size="small"
            scroll={{
              x: 800
            }}
            pagination={{ pageSize: 4 }}
          />
        </>
      )}
      <Modal
        title="Interpreters Details"
        visible={tableModalVisible}
        onCancel={handleCancel}
        width={'80%'} // Set the width of the modal
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>
        ]}
      >
        {/* Render the table format details here */}
        <Table
          columns={tableColumns}
          dataSource={interpreters?.filter(
            (e) => e?.scoreCard?.scoreLabel == tableModalVisible
          )}
          pagination={false}
          size="small"
        />
      </Modal>
      <Modal
        title={`Monitor Call for Interpreter ID: ${selectedInterpreter?.InterpreterId}`}
        visible={monitorModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="yes" type="primary" onClick={handleMonitorModalYes}>
            Yes
          </Button>,
          <Button key="no" onClick={handleMonitorModalNo}>
            No
          </Button>
        ]}
      >
        {/* Render monitor call details and options here */}
        <p>
          Do you want to monitor the call for Interpreter ID:{' '}
          {selectedInterpreter}?
        </p>
        {/* Add monitor call options */}
      </Modal>
      <ScoreCard
        open={showScoreCard} // Set open to true to show the modal
        transactionId={selectedInterpreter?.RequestId}
        handleClose={handleCancel}
        InterpreterId={selectedInterpreter?.InterpreterId}
        interpreter={selectedInterpreter}
        interpreterName={
          selectedInterpreter?.FirstName + ' ' + selectedInterpreter?.LastName
        }
        socket={socket}
      />
    </Card>
  )
}

export default InterpreterOnline
