import { Col, Row, theme, Typography } from 'antd'
import './widget.five.scss'
import WidgetFiveGraph from './widget.graph'
import WidgetFiveExpanded from './widget.expanded'
import { useState } from 'react'

const LABELS = [
  'NI',
  'LME',
  'ME',
  'EE'
]

const WidgetFive = () => {
  const [tableModalVisible, setTableModalVisible] = useState(false)

  const {
    token: { colorBgBase }
  } = theme.useToken()

  const handleBarClick = (params) => {
    setTableModalVisible(LABELS[params.dataIndex])
  }

  return (
    <div
      className="WidgetThreeContainer"
      style={{ backgroundColor: colorBgBase }}
    >
      <Row style={{ padding: 8, borderBottom: '1px solid #303030' }}>
        <Col>
          <Typography.Text strong>
            {`Interpreter Analysis By Category`}
          </Typography.Text>
        </Col>
      </Row>
      <WidgetFiveGraph handleBarClick={handleBarClick} />
      <WidgetFiveExpanded
        handleCancel={() => setTableModalVisible(false)}
        scoreLabel={tableModalVisible}
      />
    </div>
  )
}

export default WidgetFive
