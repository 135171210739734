import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import dots from '../../images/dlogo1.png';
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { forgotPassword } from "../../store/actions/profile.action";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFormSubmit = async (values) => {
        let email = values.email.split('@')[0]+'@voyceglobal.com'
        let flag = dispatch(forgotPassword({...values, email}));
        if (flag) {
            navigate("/auth/login");
        }
    }

    return (
        <div className="login-container">
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{width: 550, padding: 20}}>
                <Card bordered={false}>
                    <Row gutter={20} className="title-row" justify={"center"} align={"middle"} >
                        <img src={dots} height={24}/>
                        <Col>VIP Admin</Col>
                    </Row>
                    <br/>
                    <Form onFinish={handleFormSubmit} style={{width: '100%'}}>
                    <p onClick={() => navigate("/auth/login")} style={{position: 'absolute', zIndex: 1, top: 20, cursor: 'pointer'}}>
                        <ArrowLeftOutlined/> Back to Sign In
                    </p>
                    <Row align={"stretch"}>
                        <Col flex={"auto"} >
                            <Form.Item
                                name="email"
                                rules={[{ required: true}]}
                            >
                                <Input placeholder="E-mail" addonAfter="@voyceglobal.com"  />
                            </Form.Item>
                            <p style={{color: "grey", marginTop: 0}}>
                                Enter yout email and we'll send you a link to reset your password.
                            </p>
                            <Row justify={"center"}>
                                <Form.Item>
                                    <Button style={{width: 180}} shape="round" type="primary" htmlType="submit" >Submit</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    </Form>
                </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ForgotPassword;