import { Axios } from "axios";
import constants from "../constants";

class AuditService {
    /**
     * Company Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async auditUser(filter) {
        try {
            let response = await this.request.post(
                '/user-audit', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async aggByAuditUser(filter) {
        try {
            let response = await this.request.post(
                '/user-audit/total', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
}

export default AuditService;
