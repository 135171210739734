import ActionType from "../action.types";
import Service from "../../services";
import { message } from "antd";
import { store } from "../..";

export function auditData() {
    return async (dispatch) => {
        try {

            let state = store.getState();

            let type = state?.company?.client && state?.company?.client?.ClientId !== '-1' ? 'client' : 
                state?.company?.selected && state?.company?.selected?._id !== '-1' ? 'company' : 
                'admin';

            
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                id: state.filter?.company?.selected?._id,
                filterType: type
            }

            let data = await Service.Audit().auditUser(filter);
            dispatch(fetchAuditDataSuccess(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export function auditAggData() {
    return async (dispatch) => {
        try {

            let state = store.getState();

            let type = state?.company?.client && state?.company?.client?.ClientId !== '-1' ? 'client' : 
                state?.company?.selected && state?.company?.selected?._id !== '-1' ? 'company' : 
                'admin';

            
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                id: state.filter?.company?.selected?._id,
                filterType: type
            }

            let data = await Service.Audit().aggByAuditUser(filter);
            dispatch(fetchAuditAggDataSuccess(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}



export function auditAdminData() {
    return async (dispatch) => {
        try {

            let state = store.getState();

            let type = state?.company?.client && state?.company?.client?.ClientId !== '-1' ? 'client' : 
                state?.company?.selected && state?.company?.selected?._id !== '-1' ? 'company' : 
                'admin';

            
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                id: state.filter?.company?.selected?._id,
                filterType: type
            }

            let data = await Service.Auth().auditAdmin(filter);
            dispatch(fetchAuditAdminDataSuccess(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export function auditAggAdminData() {
    return async (dispatch) => {
        try {

            let state = store.getState();

            let type = state?.company?.client && state?.company?.client?.ClientId !== '-1' ? 'client' : 
                state?.company?.selected && state?.company?.selected?._id !== '-1' ? 'company' : 
                'admin';

            
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                id: state.filter?.company?.selected?._id,
                filterType: type
            }

            let data = await Service.Auth().aggByAuditAdmin(filter);
            dispatch(fetchAuditAggAdminDataSuccess(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export const fetchAuditDataRequest = () => {
    return {
        type: ActionType.FETCH_AUDIT_DATA_REQUEST,
    };
};

export const fetchAuditDataSuccess = (data) => {
    return {
        type: ActionType.FETCH_AUDIT_DATA_SUCCESS,
        payload: data,
    };
};

export const fetchAuditAggDataSuccess = (data) => {
    return {
        type: ActionType.FETCH_AUDIT_AGG_DATA_SUCCESS,
        payload: data,
    };
};

export const fetchAuditDataFailure = (error) => {
    return {
        type: ActionType.FETCH_AUDIT_DATA_FAILURE,
        payload: error,
    };
};


export const fetchAuditAdminDataSuccess = (data) => {
    return {
        type: ActionType.FETCH_ADMIN_AUDIT_DATA_SUCCESS,
        payload: data,
    };
};

export const fetchAuditAggAdminDataSuccess = (data) => {
    return {
        type: ActionType.FETCH_ADMIN_AUDIT_AGG_DATA_SUCCESS,
        payload: data,
    };
};