import { useState, useEffect } from 'react';

function usePolling(apiFunction, delay) {
  const [data, setData] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const [isPolling, setIsPolling] = useState(false);

  console.log(delay)

  // Function to start polling
  const startPolling = () => {
    setIsPolling(true);
  };

  // Function to stop polling
  const stopPolling = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setIsPolling(false);
    }
  };

  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      if (abortController) {
        abortController.abort();
      }

      const controller = new AbortController();
      setAbortController(controller);

      try {
        const response = await apiFunction({ signal: controller.signal });
        setData(response);

        timeoutId = setTimeout(fetchData, delay);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching data:', error);
        }
      }
    };

    if (isPolling) {
      fetchData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (abortController) {
        abortController.abort();
        setAbortController(null);
      }
    };
  }, [apiFunction, delay, isPolling]);

  return { data, startPolling, stopPolling };
}

export default usePolling;
