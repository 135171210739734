import { useState } from 'react'
import GetColumnSearchProps from '../../../components/table/search'
import WatchlistButton from '../watchlist.btn'
import { Button, Modal, Table, Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { useHud } from '../store/hud.context'

const statusCategories = ['All', 'In conference', 'Online', 'Offline']
const WidgetTwoExpanded = ({
  interpreters,
  online,
  inConference,
  offline,
  visible,
  handleClose,
  type
}) => {
  const { handleMonitorClick, loading } = useHud()
  const [activeTabKey, setActiveTabKey] = useState('All')

  const handleTabChange = (key) => {
    setActiveTabKey(key)
  }

  const handleModalClose = () => {
    setActiveTabKey('All')
    handleClose()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false }),
      render: (text, row) =>
        row.Status === 'In conference' ? (
          <Button
            type="link"
            disabled={loading}
            onClick={() =>
              handleMonitorClick(row)
            }
          >
            {text}
          </Button>
        ) : (
          <span>{text}</span>
        )
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false }),
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      }
    },
    {
      title: 'Previous Month Score',
      dataIndex: ['previousScore', 'score'],
      render: (text, row) =>
        row?.previousScore?.score
          ? Math.round(text / row?.previousScore?.totalQaSessions)
          : '-'
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Language',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'Dedicated',
      dataIndex: 'Dedicated',
      key: 'Dedicated',
      ...GetColumnSearchProps({ dataIndex: 'Dedicated', isDate: false })
    },
    {
      title: 'Monthly QA Status',
      key: 'monthlyQaStatus',
      sorter: (a, b) => {
        const statusA = a?.scoreCard?.monthlyQaStatus ?? ''
        const statusB = b?.scoreCard?.monthlyQaStatus ?? ''

        return statusA.localeCompare(statusB)
      },
      render: (text, record) => {
        let qaStatus = record?.scoreCard?.monthlyQaStatus || 'New'
        let backgroundColor
        if (qaStatus === 'Completed') {
          backgroundColor = 'lightgreen'
        } else if (qaStatus === 'In Progress') {
          backgroundColor = 'yellow'
        } else if (qaStatus === 'New') {
          backgroundColor = 'lightblue'
        }
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>
            <span
              style={{
                backgroundColor,
                padding: '2px 4px',
                borderRadius: '4px',
                color: '#000',
                fontWeight: 'bold'
              }}
            >
              {qaStatus}
            </span>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.Status.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      render: (text, record) => (
        <WatchlistButton interpreterId={record.InterpreterId} />
      )
    }
  ]

  return (
    <Modal
      title={`Interpreter Details - ${type}`}
      visible={visible}
      onCancel={handleModalClose}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Close
        </Button>
      ]}
      width={'90%'}
    >
      <Tabs
        defaultActiveKey="1"
        type="card"
        activeKey={activeTabKey}
        onChange={handleTabChange}
      >
        {statusCategories.map((status, index) => (
          <TabPane
            tab={
              <span>
                {status} (
                {status === 'In conference'
                  ? inConference?.length
                  : status === 'Online'
                  ? online.length
                  : status === 'Offline'
                  ? offline.length
                  : interpreters?.length}
                )
              </span>
            }
            key={status}
          >
            <Table
              size="small"
              dataSource={
                activeTabKey === 'In conference'
                  ? inConference
                  : activeTabKey === 'Online'
                  ? online
                  : activeTabKey === 'Offline'
                  ? offline
                  : interpreters
              }
              columns={columns}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100']
              }}
              rowKey="InterpreterId"
              id={`${activeTabKey.replace(/\s/g, '')}-terp-table`}
            />
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  )
}

export default WidgetTwoExpanded
