import {
  AreaChartOutlined,
  ArrowRightOutlined,
  ControlFilled,
  DollarCircleFilled,
  DotChartOutlined,
  FilterFilled,
  PieChartFilled,
  RadarChartOutlined,
  SettingFilled,
  UsergroupAddOutlined,
  WalletOutlined,
  DashboardOutlined,
  CustomerServiceFilled,
  TranslationOutlined,
  FundFilled,
  VideoCameraFilled,
  AuditOutlined,
  MobileFilled,
  ExclamationCircleFilled,
  DesktopOutlined,
  GoldOutlined
} from '@ant-design/icons'
import { Col, Divider, Input, Layout, Radio, Row, Select, theme } from 'antd'
import { Component, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  getActiveCompanies,
  selectClient,
  selectCompany
} from '../../store/actions/company.action'
import FilterDate from './filter.date'
import LanguageFilter from './language.filter'
import YearFilter from './filter.year'
import InvoiceFilter from './invoice.filter'
import './header.scss'
import ThemeFilter from './theme.button'

const { Header } = Layout

const routes = {
  '/pages': {
    name: 'Dashboard',
    icon: <PieChartFilled style={{ color: '#0a9191' }} />
  },
  '/pages/': {
    name: 'Dashboard',
    icon: <PieChartFilled style={{ color: '#0a9191' }} />
  },
  '/pages/activity-monitor': {
    name: 'Activity Monitor',
    icon: <DashboardOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/activity-monitor/': {
    name: 'Activity Monitor',
    icon: <DashboardOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/transactions': {
    name: 'Transactions',
    icon: <WalletOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/transactions/': {
    name: 'Transactions',
    icon: <WalletOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/invoices': {
    name: 'Invoices',
    icon: <DollarCircleFilled style={{ color: '#0a9191' }} />
  },
  '/pages/invoices/': {
    name: 'Invoices',
    icon: <DollarCircleFilled style={{ color: '#0a9191' }} />
  },
  '/pages/terp': {
    name: 'Interpreter Dashboard',
    icon: <CustomerServiceFilled style={{ color: '#0a9191' }} />
  },
  '/pages/terp/': {
    name: 'Interpreter Dashboard',
    icon: <CustomerServiceFilled style={{ color: '#0a9191' }} />
  },
  '/pages/role-hierarchy': {
    name: 'User Management',
    icon: <UsergroupAddOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/interpreter-routing-history': {
    name: 'Interpreter Routing History',
    icon: <RadarChartOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/interpreter-routing-history/': {
    name: 'Interpreter Routing History',
    icon: <RadarChartOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/language-report': {
    name: 'Language Report',
    icon: <TranslationOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/language-report/': {
    name: 'Language Report',
    icon: <TranslationOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/device-usage': {
    name: 'Device Usage',
    icon: <MobileFilled style={{ color: '#0a9191' }} />
  },
  '/pages/reports/device-usage/': {
    name: 'Device Usage',
    icon: <MobileFilled style={{ color: '#0a9191' }} />
  },
  '/pages/reports/audio-video-report/': {
    name: 'Video Call Analysis Report',
    icon: <VideoCameraFilled style={{ color: '#0a9191' }} />
  },
  '/pages/reports/audio-video-report': {
    name: 'Video Call Analysis Report',
    icon: <VideoCameraFilled style={{ color: '#0a9191' }} />
  },
  '/pages/reports/top-customer': {
    name: 'Top Customer Report',
    icon: <GoldOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/top-customer/': {
    name: 'Top Customer Report',
    icon: <GoldOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/admin-audit/': {
    name: 'Admin Portal Audit Report',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/admin-audit': {
    name: 'Admin Portal Audit Report',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/user-audit/': {
    name: 'Customer Portal Audit Report',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/reports/user-audit': {
    name: 'Customer Portal Audit Report',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/cc-reports/non-service-calls': {
    name: 'Non Serviced Calls Report',
    icon: <ExclamationCircleFilled style={{ color: '#0a9191' }} />
  },
  '/pages/cc-reports/non-service-calls/': {
    name: 'Non Serviced Calls Report',
    icon: <ExclamationCircleFilled style={{ color: '#0a9191' }} />
  },
  '/pages/cc-reports/terp-availability-report': {
    name: 'Interpreter Availability Report',
    icon: <RadarChartOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/cc-reports/terp-availability-report/': {
    name: 'Interpreter Availability Report',
    icon: <RadarChartOutlined style={{ color: '#0a9191' }} />
  },
  '/hud/QA': {
    name: 'Quality Assurance HUD',
    icon: <DesktopOutlined style={{ color: '#0a9191' }} />
  },
  '/hud/QA/': {
    name: 'Quality Assurance HUD',
    icon: <DesktopOutlined style={{ color: '#0a9191' }} />
  },
  '/hud/QA/report': {
    name: 'QA Report',
    icon: <DesktopOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/terp-hud/': {
    name: 'Interpreter HUD',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/terp-hud': {
    name: 'Interpreter HUD',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/terp-hud/dashboard': {
    name: 'Interpreter HUD',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/terp-hud/availability-report': {
    name: 'Interpreter HUD',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
  '/pages/terp-hud/routing-history': {
    name: 'Interpreter HUD',
    icon: <AuditOutlined style={{ color: '#0a9191' }} />
  },
}

const disabled_pages = [
  '/pages/cc-reports/terp-availability-report',
  '/pages/cc-reports/terp-availability-report/',
]

const hidden_pages = [
  '/pages/reports/admin-audit',
  '/pages/reports/admin-audit/',
  '/hud/QA',
  '/hud/QA/',
  '/hud/qa',
  '/hud/qa/',
  '/hud/QA/report',
  '/hud/QA/report/'
]

const hidden_client_pages = [
  '/pages/reports/top-customer',
  '/pages/reports/top-customer/',
  '/pages/hud/QA/report',
  '/pages/hud/QA/report/',
]
const client_hide_pages = [
  ...hidden_pages,
  ...hidden_client_pages
]
const hide_date_filter = [
  // '/hud/QA',
  // '/hud/QA/',
]

const CUSTOM_HEADER_NAME = {
  "1899": 'Yale New Haven Healthcare'
}

const VIPHeader = ({ collapsed, setCollapsed }) => {
  const {
    token: { colorBgLayout, colorPrimary }
  } = theme.useToken()
  const [pathname, setPath] = useState('/pages')
  const activeCompanies = useSelector((state) => state.company?.active || [])
  const isLoading = useSelector((state) => state.profile?.isLoading)
  const company = useSelector((state) => state.company?.selected)
  const client = useSelector((state) => state.company?.client)
  const selectedCompanies = useSelector(
    (state) => state?.profile?.user.permissions.selectedCompanies
  )
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setPath(location.pathname)
  }, [location.pathname])
  useEffect(() => {
    dispatch(getActiveCompanies())
  }, [])

  const handleCompanyChange = (value) => {
    if (value == -1) {
      dispatch(selectCompany({ _id: '-1' }))
      return
    }
    dispatch(
      selectCompany(
        activeCompanies?.find((e) => e._id.toString() === value),
        { ClientId: '-1' }
      )
    )
  }

  const handleClientChange = (value) => {
    if (value == -1) {
      dispatch(selectClient({ ClientId: '-1' }))
      return
    }
    dispatch(
      selectClient(company.clients.find((e) => e.ClientId.toString() === value))
    )
  }

  const renderCompanies = () => {
    let companies = JSON.parse(JSON.stringify(activeCompanies))
    return companies
      ?.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
      ?.map((company) => ({
        value: company?._id?.toString(),
        label: CUSTOM_HEADER_NAME[company?._id?.toString()] || company.CompanyName
      }))
  }

  const renderClients = () => {
    return (
      company?.clients
        ?.filter((client) => client?.ClientName !== company?.CompanyName)
        .map((client) => {
          return {
            value: client?.ClientId?.toString(),
            label: client?.ClientName
          }
        }) || []
    )
  }

  return (
    <Header
      className="header-container"
      id="header-container-id"
      style={{ background: colorBgLayout }}
    >
      <Row
        style={{ height: '100%' }}
        align={'middle'}
        justify={'end'}
        gutter={10}
      >
        <Col className="title-row">
          <div className="header-title">
            <div className="icon-col" style={{ fontSize: 22 }}>
              {routes[pathname]?.icon}
            </div>
            <div className="name-col" style={{ fontSize: 22, marginLeft: 10 }}>
              {routes[pathname]?.name}
            </div>
          </div>
        </Col>

        {/* <Col className="title-row" style={{marginTop: 10}}>
                    <Row className="header-title-row" align={"middle"} gutter={10} justify="start">
                        <Col className="icon-col" xs={4} style={{fontSize: 22}} >{routes[pathname]?.icon}</Col>
                        <Col className="name-col" xs={20} style={{fontSize: 22}} >{routes[pathname]?.name}</Col>
                    </Row>
                </Col> */}
        <Col style={{ marginLeft: 'auto' }} className="title-row">
          {pathname.includes('invoice') ? (
            <InvoiceFilter />
          ) : (pathname !== '/hud/QA' && pathname !== '/hud/QA/' && pathname !== '/hud/qa/' && pathname !== '/hud/qa' && pathname !== '/hud/QA/report') ? (
            <LanguageFilter />
          ) : null}
        </Col>
        <Col hidden={hide_date_filter.includes(location.pathname)} className="title-row">
          {pathname.includes('invoice') ? <YearFilter /> : <FilterDate />}
        </Col>
        <Col hidden={hidden_pages.includes(location.pathname)} className="title-row">
          <Select
            className="select-row"
            disabled={disabled_pages.includes(location.pathname)}
            showSearch
            placeholder="Select Company"
            optionFilterProp="children"
            style={{ minWidth: 200 }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB) =>
            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            // }
            defaultValue={'-1'}
            options={[
              { value: '-1', label: 'All Companies' },
              ...renderCompanies()
            ]}
            onChange={handleCompanyChange}
            value={company?._id?.toString()}
          />
        </Col>
        <Col hidden={client_hide_pages.includes(location.pathname)}  className="title-row">
          <ArrowRightOutlined />
        </Col>
        <Col hidden={client_hide_pages.includes(location.pathname)} className="title-row">
          <Select
            className="select-row"
            disabled={disabled_pages.includes(location.pathname)}
            showSearch
            placeholder="Select Client"
            optionFilterProp="children"
            style={{ minWidth: 200 }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            defaultValue={'-1'}
            options={[
              { value: '-1', label: 'All Clients' },
              ...renderClients()
            ]}
            value={client?.ClientId?.toString()}
            onChange={handleClientChange}
          />
        </Col>
        {/* <Col><Divider type="vertical" /></Col> */}
        {/* <Col>
                    <SettingFilled style={{fontSize: 24}} />    
                </Col> */}
        <Col>
          <ThemeFilter />
        </Col>
      </Row>
    </Header>
  )
}

export default VIPHeader
