import { useMemo } from "react";
import GetColumnSearchProps from "../../../components/table/search"
import { useHud } from "../store/hud.context"
import WatchlistButton from "../watchlist.btn"
import { Button, Modal, Table } from "antd";

const WidgetFourScore = ({ visible, handleClose }) => {
  const { scores, watchList, interpreters } = useHud();

  const dataSource = useMemo(() => {
    if (visible === 'scored') {
      return interpreters?.filter(
        (e) => {
          const score = scores[e.InterpreterId]
          const watch = watchList[e.InterpreterId]
          return score && watch
        }
      )
    } else if (visible === 'not scored') {
      return interpreters?.filter(
        (e) => {
          const score = scores[e.InterpreterId]
          const watch = watchList[e.InterpreterId]
          return !score && watch
        }
      )
    }
    return []
  }, [scores, watchList, interpreters, visible])


  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId' }),
      // render: (text, row) =>
      //   row.Status === 'In conference' ? (
      //     <Button type="link" onClick={() => handleInterpreterClick(row)}>
      //       {text}
      //     </Button>
      //   ) : (
      //     <span>{text}</span>
      //   )
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Languages',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      sorter: (a, b) =>
        (a?.Languages || [])
          .join(',')
          .localeCompare((b?.Languages || []).join(',')),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => a?.Status?.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      align: 'center',
      render: (text, record) => (
        <WatchlistButton
          interpreterId={record.InterpreterId}
        />
      )
    }
  ]

  return (
    <Modal
      title={`Interpreter Sessions - ${visible}`}
      visible={visible}
      onCancel={handleClose}
      width={'auto'}
      footer={[
        <Button key="back" onClick={handleClose}>
          Close
        </Button>
      ]}
    >
      <Table
        columns={columns}
        dataSource={dataSource.sort((a, b) =>
          a.Status.localeCompare(b.Status)
        )}
        size="small"
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
      />
    </Modal>
  )
}

export default WidgetFourScore;