import { Table, Col, Row } from 'antd'
import Formatter from '../../../utils/Formatter'
import GetColumnSearchProps from '../../../components/table/search'
import { useEffect, useState } from 'react'
import _ from 'lodash'

const ExpandedRowRender = ({ record }) => {
    if (record?.interpreters) {
        record.interpreters = record?.interpreters?.map((e) => ({
            ...e,
            order:
                e.Status == 'In conference' ? 0 : e.Status == 'Online' ? 1 : 2,
            Language1_Array: e.Language1_Array.concat(e.Language2_Array)
        }))
        record.interpreters?.sort((a, b) => a.order - b.order)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'Id',
            key: 'Id',
            sorter: (a, b) => a.Id - b.Id,
            ...GetColumnSearchProps({ dataIndex: 'Id', isDate: false })
        },
        {
            title: 'Company',
            dataIndex: 'CompanyCode',
            key: 'CompanyCode',
            sorter: (a, b) => a.CompanyCode.localeCompare(b.CompanyCode),
            ...GetColumnSearchProps({
                dataIndex: 'CompanyCode',
                isDate: false
            }),
            render: (text, row) => `${text || '-'}`
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            sorter: (a, b) => {
                const ltrim = (str) => str.replace(/^\s+/, '');
                const aValue = ltrim(a?.Name || '');
                const bValue = ltrim(b?.Name || '');
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                } else {
                    return 0;
                }
            },
            ...GetColumnSearchProps({ dataIndex: 'Name', isDate: false }),
            render: (text, row) => `${text} ${row.LastName || ''}`
        },
        {
            title: 'Languages',
            dataIndex: 'Language1_Array',
            ...GetColumnSearchProps({
                dataIndex: 'Language1_Array',
                isDate: false
            }),
            render: (text) =>
                _.uniq(text || [])
                    ?.filter((e) => e !== 'Operator')
                    ?.join(', ')
        },
        // Keeping this commented code for future reference.
        // {
        //     title: 'Second Language',
        //     dataIndex: 'Language2_Array',
        //     ...GetColumnSearchProps({
        //         dataIndex: 'Language2_Array',
        //         isDate: false
        //     }),
        //     render: (text) =>
        //         _.uniq(text || [])
        //             ?.filter((e) => e !== 'Operator')
        //             ?.join(', ')
        // },
        {
            title: 'Status',
            dataIndex: 'Status',
            width: 180,
            sorter: (a, b) => a.Status.localeCompare(b.Status),
            ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
        },
        {
            title: 'Avg. Idle Minutes',
            dataIndex: 'avgIdleMinutes',
            align: 'center',
            render: (text) => text || '-'
        }
        // Keeping this for future Monitoring capabilities
        // {
        //     title: '',
        //     dataIndex: 'RequestId',
        //     align: 'center',
        //     render: (text) => {
        //         if (text) {
        //             return (
        //                 <Button
        //                     type={'default'}
        //                     onClick={() => {
        //                         navigate(
        //                             `/pages/activity-monitor?dataIdx${0}=${'ReferenceTransactionId'}&status${0}=${text}`
        //                         )
        //                     }}
        //                 >
        //                     Monitor
        //                 </Button>
        //             )
        //         }
        //     }
        // }
    ]

    record?.hours?.sort((a, b) => a?.hour - b?.hour)

    return (
        <Row>
            <Col flex={'auto'}>
                <Table
                    key={record?.language + '_sub'}
                    size="small"
                    rowKey={(record) => record.Id}
                    className="expanded-table"
                    columns={columns}
                    dataSource={record?.interpreters || []}
                    width="20px"
                    rowClassName={(record, index) =>
                        record?.Status == 'Online'
                            ? 'online-row'
                            : record?.Status == 'In conference'
                            ? 'inconference-row'
                            : 'other-row'
                    }
                />
            </Col>
        </Row>
    )
}

export default ExpandedRowRender
