import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    BarChartOutlined,
    DollarCircleFilled,
    FileFilled,
    LinkOutlined,
    LogoutOutlined,
    PieChartFilled,
    DashboardOutlined,
    UserOutlined,
    UsergroupAddOutlined,
    CustomerServiceFilled,
    WalletOutlined,
    PhoneFilled,
    TranslationOutlined,
    DesktopOutlined,
    // CustomerServiceOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Divider, Dropdown, Layout, Menu, Row } from "antd";
import SmallLogo from '../../images/dlogo1.png';
import LogoWhite from '../../images/VOY-Logo-WHITE.png';
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/profile.action";
import { useSelector } from "react-redux";
import * as ROLE from "../../role";
import Service from "../../services";
import constants from "../../constants";
const { Sider } = Layout;

const SideMenu = ({ collapsed, setCollapsed }) => {

    const userRole = useSelector((state) => state.profile?.user?.permissions?.role);
    const isQaSpecialist = useSelector((state) => state.profile?.user?.permissions?.isQaSpecialist);
    const isInterpreter = useSelector((state) => state.profile?.user?.permissions?.role) === ROLE.INTERPRETER;
    const reportsSubItems = [
        getSubItem(
            <Link to="/pages/reports/device-usage">
              Device Usage Report
            </Link>,
            "/pages/reports/device-usage",
            null,
            null,
            "Device Usage Report"
        ),
        getSubItem(
        <Link to="/pages/reports/language-report">Language Report</Link>,
        "/pages/reports/language-report",
        null,
        null,
        "Language Report"
        ),
        getSubItem(
            <Link to="/pages/cc-reports/non-service-calls">
              Non Serviced Calls
            </Link>,
            "/pages/cc-reports/non-service-calls",
            null,
            null,
            "Non Serviced Calls"
        ),
        ...((userRole === ROLE.SUPER_ADMIN || userRole === ROLE.ADMIN || userRole === ROLE.MONITOR) ? [
            getSubItem(
                <Link to="/pages/reports/admin-audit">
                  Admin Portal Audit Report
                </Link>,
                "/pages/reports/admin-audit",
                null,
                null,
                "Admin Audit Report"
            ),
            getSubItem(
                <Link to="/pages/reports/user-audit">
                  Customer Portal Audit Report
                </Link>,
                "/pages/reports/user-audit",
                null,
                null,
                "Audit Report"
            ),
            getSubItem(
                <Link to="/pages/reports/top-customer">
                  Top Customer Report
                </Link>,
                "/pages/reports/top-customer",
                null,
                null,
                "Top Customer Report"
            ),
        ] : []),
        getSubItem(
        <Link to="/pages/reports/audio-video-report">
            Video Call Analysis Report
        </Link>,
        "/pages/reports/audio-video-report",
        null,
        null,
        "Video Call Analysis Report"
        ),
    ];
    reportsSubItems.sort((a, b) => {
        return a.sortLabel.localeCompare(b.sortLabel);
    });

    //Sub items for QA HUD
    const QASubItems = [
        getSubItem(
            <Link to="/hud/QA/">
              Quality Assurance HUD
            </Link>,
            "/hud/QA",
            null,
            null,
            "Quality Assurance HUD"
        ),
        getSubItem(
            <Link to="/hud/QA/report">Quality Assurance Report</Link>,
            "/QA/report",
            null,
            null,
            "Quality Assurance Report"
        )
    ];
    const terpHudPaths = [
        "/pages/terp-hud/dashboard",
        "/pages/terp-hud/availability-report",
        "/pages/terp-hud/routing-history"
    ];
      
    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    const [accessToken, setToken] = useState();
    const dispatch = useDispatch();
    const [items, setItems] = useState([
        getItem(<Link to="/pages">Dashboard</Link>, "/pages", <PieChartFilled />),
        getItem(<Link to="/pages/activity-monitor">Activity Monitor</Link>, "/pages/activity-monitor", <DashboardOutlined />),
        getItem(<Link to="/pages/transactions">Transactions</Link>, "/pages/transactions", <WalletOutlined />),
        getItem("Reports", "/pages/reports", <BarChartOutlined />, reportsSubItems),
        ...(userRole !== ROLE.MONITOR ? [getItem(<Link to="/pages/invoices">Invoices</Link>, "/pages/invoices", <DollarCircleFilled />)] : []),
        getItem(<Link to="/pages/terp-hud">Interpreters HUD</Link>, "/pages/terp-hud", <CustomerServiceFilled />),
    ])

    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

    const dropItems = [
        {
        label: 'Logout',
        key: 'logout',
        icon: <LogoutOutlined />,
        danger: true,
        },
    ];

    const qaMenuItems = [
        getItem("QA HUD", "/hud/QA/", <DesktopOutlined />, QASubItems),
    ];
    const terpMenuItems = [
        getItem("Interpreters", "/pages/terp", <CustomerServiceFilled />)
    ];
    const handleMenuClick = ({ key }) => {
        switch (key) {
            case "logout":
                dispatch(logout());
                break;
            default:
                return;
        }
    };

    const menuProps = {
        items: dropItems,
        onClick: handleMenuClick,
    };

    function getSubItem(label, key, icon, children, sortLabel = "") {
        if (!sortLabel) {
            throw new Error("sortLabel must be provided");
        }
        return {
            key,
            icon,
            children,
            label,
            sortLabel
        };
    }
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }



    useEffect(() => {
        if (
            (userRole === ROLE.SUPER_ADMIN ||
            userRole === ROLE.ADMIN ||
            userRole === ROLE.MONITOR) &&
            (userRole !== ROLE.QA_SPECIALIST || userRole !== ROLE.INTERPRETER)
        ) {
            setItems((state) => {
                return [
                    ...state,
                    getItem(
                        <Link to="/pages/role-hierarchy">Users</Link>,
                        '/pages/role-hierarchy',
                        <UsergroupAddOutlined />
                    ),
                    ...(isQaSpecialist ? qaMenuItems : [])
                ];
            });
        }

        if (userRole === ROLE.QA_SPECIALIST) {
            setItems((state) => qaMenuItems);
        }
        if (isInterpreter) {
            setItems((state) => terpMenuItems);
        }
    }, []);
       
    const selectedKeys = terpHudPaths.includes(path) ? ["/pages/terp-hud"] : [path];

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="sider-container"
            theme={"light"}
        >
            {
                !collapsed ?
                <div style={{ margin: 16, height: 26 }}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{ marginTop: 7 }} height={18} src={LogoWhite} />
                    </Row>
                </div> :
                <div style={{ margin: 16, height: 26 }}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{ filter: 'drop-shadow(0 0 0.65rem purple)' }} height={26} src={SmallLogo} />
                    </Row>
                </div>
            }
            <Menu
                // selectedKeys={[path]}
                selectedKeys={selectedKeys}
                defaultSelectedKeys={[path]}
                mode="inline"
                items={items}
            />
            <Row style={{ position: "fixed", bottom: 20, left: 20, zIndex: "100" }} justify={"center"} align={"bottom"} >
                <Col>
                    <Dropdown
                        menu={menuProps}
                        trigger={["click"]}
                        placement="topRight"
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                    <div onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                        <Avatar size="large" icon={<UserOutlined />} />
                    </div>
                    </Dropdown>
                </Col>
            </Row>

        </Sider>
    )
}

export default SideMenu;