import { Col, Row, Spin, Typography } from 'antd'
import { useHud } from './store/hud.context'
import WidgetOne from './widget.one/widget.one'
import WidgetThree from './widget.three/widget.three'
import WidgetFive from './widget.five/widget.five'
import WidgetSix from './widget.six/widget.six'
import WidgetTwo from './widget.two/widget.two'
import WidgetFour from './widget.four/widget.four'
import Monitor from './monitor/monitor'

const QAHud = (props) => {
  const { connected, loading } = useHud()

  return (
    <Spin spinning={!connected || loading} tip="Connecting hud...">
      <div style={{ padding: 24 }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetOne />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetTwo />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetThree />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetFour />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetFive />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8}>
            <WidgetSix />
          </Col>
        </Row>
      </div>
      <Monitor/>
    </Spin>
  )
}

export default QAHud
