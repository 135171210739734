import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { updateColumns } from "../../store/actions/selectedColumns";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Service from "../../services";
import "./transaction.scss";

const DragnDrop = (props) => {
    const columnState = useSelector((state) => state.selectedColumns);
    const userId = useSelector((state) => state?.profile?.user?.id);
    const userProfile = useSelector((state) => state?.profile);
    const dispatch = useDispatch();

    const [items, setItems] = useState({
        unselected: [],
        selected: [],
    });

    useEffect(() => {
        const newItems = {
            unselected:
                // columnState.unselectedColumns?.length > 0
                //     ? columnState.unselectedColumns
                //     : props.additionalColumns,
                columnState.unselectedColumns !== undefined
                    ? columnState.unselectedColumns.length > 0
                        ? columnState.unselectedColumns
                        : []
                    : [],
            selected:
                columnState.selectedColumns !== undefined
                    ? columnState.selectedColumns?.length > 0
                        ? columnState.selectedColumns
                        : []
                    : [],
        };
        setItems(newItems);
    }, [columnState, props.additionalColumns, props.defaultColumns]);

    const unselectedItems = items.unselected;
    const selectedItems = items.selected;

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;

        if (source.droppableId === destination.droppableId) {
            const newItems = Array.from(items[source.droppableId]);
            const [removedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, removedItem);

            setItems({ ...items, [source.droppableId]: newItems });
        } else {
            const newSourceItems = Array.from(items[source.droppableId]);
            const newDestinationItems = Array.from(
                items[destination.droppableId]
            );
            const [removedItem] = newSourceItems.splice(source.index, 1);
            newDestinationItems.splice(destination.index, 0, removedItem);

            setItems({
                ...items,
                [source.droppableId]: newSourceItems,
                [destination.droppableId]: newDestinationItems,
            });
        }
    };
    const handleColumnsUpdate = () => {
        const newSelectedItems =
            selectedItems.length > 0 ? selectedItems : [];
        const newUnselectedItems =
            unselectedItems.length > 0 ? unselectedItems : [];
        dispatch(updateColumns(newSelectedItems, newUnselectedItems, userId));
        props.handleCancel();
    };

    return (
        <Modal
            className="modal-div"
            open={props.modalVisible}
            width={600}
            title="Select Columns"
            onCancel={props.handleCancel}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleColumnsUpdate}
                >
                    OK
                </Button>,
            ]}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                    <div>
                        <h3>Unselected Items</h3>
                        <Droppable droppableId="unselected">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`droppable${
                                        snapshot.isDraggingOver
                                            ? " dragging-over"
                                            : ""
                                    } unselected-column`}
                                >
                                    {unselectedItems.map((item, index) => (
                                        <Draggable
                                            key={item?.title}
                                            draggableId={item?.title}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`draggable${
                                                        snapshot.isDragging
                                                            ? " dragging"
                                                            : ""
                                                    } card-item`}
                                                >
                                                    {item?.title}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="double-arrow-container">
                        <ArrowLeftOutlined />
                        <br />
                        <ArrowRightOutlined />
                    </div>
                    <div>
                        <h3>Selected Items</h3>
                        <Droppable droppableId="selected">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`droppable${
                                        snapshot.isDraggingOver
                                            ? " dragging-over"
                                            : ""
                                    } selected-column`}
                                >
                                    {selectedItems.map((item, index) => (
                                        <Draggable
                                            key={item?.title}
                                            draggableId={item?.title}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`droppable${
                                                        snapshot.isDraggingOver
                                                            ? " dragging-over"
                                                            : ""
                                                    } card-item`}
                                                >
                                                    {item?.title}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>
        </Modal>
    );
};

export default DragnDrop;
