import { Col, Row, theme, Typography } from 'antd'
import { useHud } from '../store/hud.context'
import './widget.two.scss'
import { useEffect, useState } from 'react'
import WidgetTwoProgress from './widget.progress'
import WidgetTwoExpanded from './widget.expanded'
import WidgetTwoMonitor from './widget.monitor'
import _ from 'lodash';

const WidgetTwo = () => {
  const { interpreters, scores, ongoingSessions, previousScores } = useHud()
  const [modalVisible, setModalVisisble] = useState({
    interpreters: [],
    evaluated: [],
    inConference: [],
    online: [],
    offline: [],
    visible: false
  })
  const [liveSessions, setLiveSessions] = useState([])
  const [monitorModal, setMonitorModal] = useState(false)
  const {
    token: { colorBgBase }
  } = theme.useToken()

  useEffect(() => {
    if ((interpreters, ongoingSessions)) {
      let sessions = _.uniqBy(ongoingSessions, 'requestId')
      sessions = _.unionBy(sessions, 'qaSpecialistId')
      sessions = sessions.filter((e) => {
        const interpreter = interpreters.find(
          (terp) => terp.InterpreterId == e.interpreterId && terp.Status === 'In conference'
        )
        return Boolean(interpreter)
      }) 
      setLiveSessions(sessions)
    }
  }, [interpreters, ongoingSessions])

  const [dataSource, setDataSource] = useState({
    interpreters: [],
    evaluated: [],
    evaluatedSpanish: [],
    evaluatedLOTS: [],
    evaluatedASL: [],
    online: [],
    inConference: [],
    offline: [],
    spanish: [],
    spanishOnline: [],
    spanishInConference: [],
    spanishOffline: [],
    LOTS: [],
    LOTSOnline: [],
    LOTSInConferernce: [],
    LOTSOffline: [],
    ASL: [],
    ASLOnline: [],
    ASLInConference: [],
    ASLOffline: [],
    offline: []
  })

  useEffect(() => {
    if (interpreters && interpreters.length > 0) {
      const analytics = {
        interpreters: [],
        evaluated: [],
        evaluatedSpanish: [],
        evaluatedLOTS: [],
        evaluatedASL: [],
        online: [],
        inConference: [],
        offline: [],
        spanish: [],
        spanishOnline: [],
        spanishInConference: [],
        spanishOffline: [],
        LOTS: [],
        LOTSOnline: [],
        LOTSInConferernce: [],
        LOTSOffline: [],
        ASL: [],
        ASLOnline: [],
        ASLInConference: [],
        ASLOffline: [],
        offline: []
      }
      interpreters.forEach((interpreter) => {
        const score = scores[interpreter.InterpreterId]
        const previousScore = previousScores[interpreter.InterpreterId]
        interpreter.scoreCard = score;
        interpreter.previousScore = previousScore;
        analytics.interpreters.push(interpreter)
        if (score?.monthlyQaStatus == 'Completed') {
          analytics.evaluated.push(interpreter)

          if (interpreter.Languages.includes('Spanish')) {
            analytics.evaluatedSpanish.push(interpreter)
          }
          if (
            !interpreter.Languages.includes('Spanish') &&
            !interpreter?.Languages.includes('ASL')
          ) {
            analytics.evaluatedLOTS.push(interpreter)
          }
          if (interpreter.Languages.includes('American Sign Language (ASL)')) {
            analytics.evaluatedASL.push(interpreter)
          }
        }

        if (interpreter.Status == 'Online') {
          analytics.online.push(interpreter)

          if (interpreter.Languages.includes('Spanish')) {
            analytics.spanishOnline.push(interpreter)
          }
          if (
            !interpreter.Languages.includes('Spanish') &&
            !interpreter?.Languages.includes('ASL')
          ) {
            analytics.LOTSOnline.push(interpreter)
          }
          if (interpreter.Languages.includes('American Sign Language (ASL)')) {
            analytics.ASLOnline.push(interpreter)
          }
        }

        if (interpreter.Status == 'In conference') {
          analytics.inConference.push(interpreter)

          if (interpreter.Languages.includes('Spanish')) {
            analytics.spanishInConference.push(interpreter)
          }
          if (
            !interpreter.Languages.includes('Spanish') &&
            !interpreter?.Languages.includes('ASL')
          ) {
            analytics.LOTSInConferernce.push(interpreter)
          }
          if (interpreter.Languages.includes('American Sign Language (ASL)')) {
            analytics.ASLInConference.push(interpreter)
          }
        }

        if (interpreter.Status == 'Offline') {
          analytics.offline.push(interpreter)

          if (interpreter.Languages.includes('Spanish')) {
            analytics.spanishOffline.push(interpreter)
          }
          if (
            !interpreter.Languages.includes('Spanish') &&
            !interpreter?.Languages.includes('ASL')
          ) {
            analytics.LOTSOffline.push(interpreter)
          }
          if (interpreter.Languages.includes('American Sign Language (ASL)')) {
            analytics.ASLOffline.push(interpreter)
          }
        }
      })
      analytics.spanish = analytics.spanishOffline.concat(
        analytics.spanishOnline.concat(analytics.spanishInConference)
      )
      analytics.LOTS = analytics.LOTSOffline.concat(
        analytics.LOTSOnline.concat(analytics.LOTSInConferernce)
      )
      analytics.ASL = analytics.ASLOffline.concat(
        analytics.ASLOnline.concat(analytics.ASLInConference)
      )
      setDataSource(analytics)
    }
  }, [interpreters, scores])

  const handleClick = (type) => {
    if (type === 'Total Evaluated') {
      setModalVisisble({
        interpreters: dataSource.interpreters,
        evaluated: dataSource.evaluated,
        inConference: dataSource.inConference,
        online: dataSource.online,
        offline: dataSource.offline,
        visible: true,
        type: type
      })
    } else if (type === 'ASL') {
      setModalVisisble({
        interpreters: dataSource.ASL,
        evaluated: dataSource.evaluatedASL,
        inConference: dataSource.ASLInConference,
        online: dataSource.ASLOnline,
        offline: dataSource.ASLOffline,
        visible: true,
        type: type
      })
    } else if (type === 'Spanish') {
      setModalVisisble({
        interpreters: dataSource.spanish,
        evaluated: dataSource.evaluatedSpanish,
        inConference: dataSource.spanishInConference,
        online: dataSource.spanishOnline,
        offline: dataSource.spanishOffline,
        visible: true,
        type: type
      })
    } else if (type === 'LOTS') {
      setModalVisisble({
        interpreters: dataSource.LOTS,
        evaluated: dataSource.evaluatedLOTS,
        inConference: dataSource.LOTSInConferernce,
        online: dataSource.LOTSOnline,
        offline: dataSource.LOTSOffline,
        visible: true,
        type: type
      })
    } else if (type === 'Monitoring') {
      setMonitorModal(true)
    }
  }

  return (
    <div
      className={'WidgetTwoContainer'}
      style={{ backgroundColor: colorBgBase }}
    >
      <Row>
        <Col flex={'auto'}>
          <WidgetTwoProgress
            current={liveSessions.length}
            total={interpreters.length}
            type={'Monitoring'}
            style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            handleClick={handleClick}
          />
        </Col>
      </Row>
      <Row justify={'center'} align={'middle'}>
        <Col flex={'50%'}>
          <WidgetTwoProgress
            current={dataSource.evaluated.length}
            total={interpreters.length}
            type={'Total Evaluated'}
            handleClick={handleClick}
          />
        </Col>
        <Col flex={'50%'}>
          <WidgetTwoProgress
            current={dataSource.evaluatedASL.length}
            total={dataSource.ASL.length}
            type={'ASL'}
            handleClick={handleClick}
          />
        </Col>
      </Row>
      <Row justify={'center'} align={'middle'}>
        <Col flex={'50%'}>
          <WidgetTwoProgress
            current={dataSource.evaluatedSpanish.length}
            total={dataSource.spanish.length}
            type={'Spanish'}
            style={{ borderBottomLeftRadius: 8 }}
            handleClick={handleClick}
          />
        </Col>
        <Col flex={'50%'}>
          <WidgetTwoProgress
            current={dataSource.evaluatedLOTS.length}
            total={dataSource.LOTS.length}
            type={'LOTS'}
            style={{ borderBottomRightRadius: 8 }}
            handleClick={handleClick}
          />
        </Col>
      </Row>
      <WidgetTwoExpanded
        {...(modalVisible || {})}
        handleClose={() =>
          setModalVisisble({
            interpreters: [],
            evaluated: [],
            inConference: [],
            online: [],
            offline: [],
            visible: false
          })
        }
      />
      <WidgetTwoMonitor
        visible={monitorModal}
        handleClose={() => setMonitorModal(false)}
        dataSource={liveSessions}
      />
    </div>
  )
}

export default WidgetTwo
