import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different profile action types
*/

export default function avr(state = {}, action) {
    switch (action.type) {
        case ActionType.LOGIN_ERROR:
            return {...state, error: action.hasError, message: action.message, isLoading: action.isLoading };
        default:
            return state;
    }
}
