import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceType } from "../../store/actions/filter.action";
import { InvoiceOptions } from "../../invoice.enum";

const InvoiceFilter = (props) => {

    const dispatch = useDispatch();
    const type = useSelector((state) => state?.filter?.invoiceType)

    return (
        <Radio.Group 
            buttonStyle="solid"
            options={InvoiceOptions}
            optionType="button"
            value={type}
            onChange={(e) => dispatch(setInvoiceType(e.target.value))}
        />
    )
}

export default InvoiceFilter