import React, { useState, useMemo } from 'react'
import { Table, Button, Card, Modal, Typography, Row, Col } from 'antd'
import moment from 'moment'
import GetColumnSearchProps from '../../../components/table/search'
import { useHud } from '../store/hud.context'
import WatchlistButton from '../watchlist.btn'

const WidgetOne = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const { interpreters } = useHud()

  const basicColumns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      width: '80px',
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false })
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      width: '50%',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      align: 'center',
      width: '20%',
      render: (text, record) => (
        <WatchlistButton interpreterId={record.InterpreterId} />
      )
    }
  ]
  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false })
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Languages',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      sorter: (a, b) =>
        (a?.Languages || [])
          .join(',')
          .localeCompare((b?.Languages || []).join(',')),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'Date Added',
      dataIndex: 'InterpreterJoinDate',
      key: 'InterpreterJoinDate',
      sorter: (a, b) =>
        moment(a.InterpreterJoinDate)?.unix() -
        moment(b.InterpreterJoinDate)?.unix(),
      render: (text) => (text ? text.split('T')[0] : '-')
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      render: (text, record) => (
        <WatchlistButton interpreterId={record.InterpreterId} />
      )
    }
  ]

  const filterNewTerpOnTime = useMemo(() => {
    return interpreters.filter((terp) =>
      moment
        .utc(terp.InterpreterJoinDate)
        .isAfter(moment.utc().subtract(45, 'days'))
    )
  }, [interpreters])

  return (
    <>
      <Table
        title={() => (
          <Row>
            <Col>
              <Typography.Text strong>
                {`New Interpreter Added (${filterNewTerpOnTime.length})`}
              </Typography.Text>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => setModalVisible(true)}
              >
                Expand
              </Button>
            </Col>
          </Row>
        )}
        size="small"
        columns={basicColumns}
        dataSource={filterNewTerpOnTime}
        scroll={{ y: 250 }}
        pagination={false}
        rowKey="InterpreterId"
        id="new-terp-unexpanded"
        bordered
        footer={() => (
          <Typography.Text italic>
            The interpreters listed above were added in
            the last 30 days.
          </Typography.Text>
        )}
      />
      <Modal
        title={`New Interpreter Added (${filterNewTerpOnTime.length})`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={'80%'}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Close
          </Button>
        ]}
      >
        {/* Render expanded details here */}
        <Table
          size="small"
          columns={columns}
          dataSource={filterNewTerpOnTime}
          rowKey="InterpreterId"
          id="new-terp-expanded"
          scroll={{
            x: 1000
          }}
          pagination={{
            defaultPageSize: 100,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100']
          }}
        />
      </Modal>
    </>
  )
}

export default WidgetOne
