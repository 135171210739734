import { Spin, Typography, Button, Select } from 'antd'
import { useEffect, useState, memo } from 'react'
import Service from '../../../services'
import 'jspdf-autotable'
import Formatter from '../../../utils/Formatter'
import ReactECharts from 'echarts-for-react'

const PerformanceLineChart = ({ data, colorScheme, unit }) => {
  const options = {
    xAxis: {
      type: 'category',
      data: data.map((item) => item.name),
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      {
        data: data.map((item) => item.value),
        type: 'line',
        smooth: true,
        lineStyle: {
          color: colorScheme
        },
        itemStyle: {
          color: colorScheme
        },
        showSymbol: true
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      },
      formatter: (params) => {
        return params
          .map((series) => {
            return `${series.marker}${
              series.name
            } <br/> ${unit} ${Formatter.numberWithCommas(series.value, false)}`
          })
          .join('<br>')
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '50%',
      bottom: '20%'
    }
  }

  return (
    <ReactECharts
      option={options}
      color={colorScheme}
      style={{ height: '54px', width: '100px' }}
      notMerge={true}
      lazyUpdate={true}
      theme={'theme_name'}
    />
  )
}

export default PerformanceLineChart
