import { Button, Col, Row, Table, Typography } from 'antd'
import { useHud } from '../store/hud.context'
import { useEffect, useMemo, useState } from 'react'
import GetColumnSearchProps from '../../../components/table/search'
import WidgetSixExpanded from './widget.expanded'

const WidgetSix = () => {
  const { scores } = useHud()
  const [modalVisible, setModalVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (scores && Object.keys(scores).length > 0) {
      const QaSpecialists = {}

      Object.values(scores).forEach((scoreCard) => {
        if (scoreCard && scoreCard?.details) {
          let totalScore = 0
          scoreCard?.details?.forEach((detail) => {
            if (!QaSpecialists[detail.qaSpecialistId]) {
              QaSpecialists[detail.qaSpecialistId] = {
                monitorHistory: [JSON.parse(JSON.stringify(detail))],
                key: detail.qaSpecialistId,
                qaSpecialistName: detail?.qaSpecialistName,
                id: detail.qaSpecialistId,
                sessionsMonitored: 1,
                totalMonitorMinutes: detail.totalMonitorSeconds
                  ? Math.ceil(detail.totalMonitorSeconds / 60)
                  : 0,
                totalEvalMinutes: detail.totalEvaluationSeconds
                  ? Math.ceil(detail.totalEvaluationSeconds / 60)
                  : 0,
                numInterpretersMonitored: 1,
                monitoredInterpreterSet: new Set([detail.interpreterId])
              }
            } else {
              QaSpecialists[detail.qaSpecialistId].monitorHistory.push(
                JSON.parse(JSON.stringify(detail))
              )
              QaSpecialists[detail.qaSpecialistId].sessionsMonitored++
              QaSpecialists[detail.qaSpecialistId].totalMonitorMinutes +=
                detail.totalMonitorSeconds
                  ? Math.ceil(detail.totalMonitorSeconds / 60)
                  : 0
              QaSpecialists[detail.qaSpecialistId].monitoredInterpreterSet.add(
                detail.interpreterId
              )
              QaSpecialists[detail.qaSpecialistId].numInterpretersMonitored =
                QaSpecialists[
                  detail.qaSpecialistId
                ].monitoredInterpreterSet.size
              QaSpecialists[detail.qaSpecialistId].totalEvalMinutes +=
                detail.totalEvaluationSeconds
                  ? Math.ceil(detail.totalEvaluationSeconds / 60)
                  : 0
            }
          })
        }
      })

      setDataSource(Object.keys(QaSpecialists).map((e) => QaSpecialists[e]))
    }
  }, [scores])

  const columns = [
    {
      title: 'QA Name',
      dataIndex: 'qaSpecialistName',
      key: 'qaSpecialistName',
      width: '170px',
      render: (text, record) => <div id={`cell-name-${record.id}`}>{text}</div>,
      ...GetColumnSearchProps({ dataIndex: 'qaSpecialistName' })
    },
    {
      title: 'Sessions',
      dataIndex: 'sessionsMonitored',
      key: 'sessionsMonitored',
      sorter: (a, b) => {
        const aValue = a.sessionsMonitored
          ? parseFloat(String(a.sessionsMonitored).replace(/,/g, ''))
          : 0
        const bValue = b.sessionsMonitored
          ? parseFloat(String(b.sessionsMonitored).replace(/,/g, ''))
          : 0
        return aValue - bValue
      }
    },
    {
      title: 'E Mins',
      dataIndex: 'totalEvalMinutes',
      key: 'totalEvalMinutes',
      sorter: (a, b) => {
        const aValue = a.totalEvalMinutes
          ? parseFloat(String(a.totalEvalMinutes).replace(/,/g, ''))
          : 0
        const bValue = b.totalEvalMinutes
          ? parseFloat(String(b.totalEvalMinutes).replace(/,/g, ''))
          : 0
        return aValue - bValue
      },
      render: (text) => (text ? Math.ceil(text) : 0)
    },
    {
      title: 'M Mins',
      dataIndex: 'totalMonitorMinutes',
      key: 'totalMonitorMinutes',
      sorter: (a, b) => {
        const aValue = a.totalMonitorMinutes
          ? parseFloat(String(a.totalMonitorMinutes).replace(/,/g, ''))
          : 0
        const bValue = b.totalMonitorMinutes
          ? parseFloat(String(b.totalMonitorMinutes).replace(/,/g, ''))
          : 0
        return aValue - bValue
      },
      render: (text) => (text ? Math.ceil(text) : 0)
    }
  ]
  return (
    <>
      <Table
        title={() => (
          <Row>
            <Col>
              <Typography.Text strong>{`QA Analysis`}</Typography.Text>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => setModalVisible(true)}
              >
                Expand
              </Button>
            </Col>
          </Row>
        )}
        footer={() => (
          <Typography.Text italic>
          </Typography.Text>
        )}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: 250 }}
        size="small"
        id="qa-analysis-table-unexpanded"
        bordered
      />
      <WidgetSixExpanded
        dataSource={dataSource}
        baseColumns={columns}
        handleCancel={() => setModalVisible(false)}
        modalVisible={modalVisible}
      />
    </>
  )
}

export default WidgetSix
