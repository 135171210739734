import {
    Table,
    Spin,
    Row,
    Col,
    Card,
    Typography,
    theme,
    Button,
    Space,
    Select,
    Input,
    Form,
    Tabs
} from 'antd'
import DropDown from '../../../components/dropdown'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import jsPDF from 'jspdf'
import Service from '../../../services'
import 'jspdf-autotable'
import { useSelector } from 'react-redux'
import Formatter from '../../../utils/Formatter'
import './terp.scss'
import { saveAs } from 'file-saver'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import exportAsCSV from '../../../services/exportToCVS.service'
import { Link } from 'react-router-dom'
import { memo } from 'react'
import { exportToExcel } from '../../../services/exportToExcel'

const { Option } = Select

const languageSorter = (a, b) => {
    const aLanguages = a.languages.join()
    const bLanguages = b.languages.join()
    return aLanguages.localeCompare(bLanguages)
}

const US_BASED_FLAG = {
    TOTAL: 'TOTAL',
    US: 'US',
    NON_US: 'NotUS'
}

const TerpTable = () => {
    const location = useLocation()
    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search)
        let filters = {}

        for (let i = 0; ; i++) {
            const dataIdx = searchParams.get(`dataIdx${i}`)
            const status = searchParams.get(`status${i}`)

            if (dataIdx && status) {
                filters[dataIdx] = status
            } else {
                break
            }
        }

        return filters
    }
    const dashboardFilters = getDashboardFilters()
    const {
        token: { colorBgLayout }
    } = theme.useToken()
    const [mongoDBData, setMongoDBData] = useState([])
    const [allTerpData, setAllTerpData] = useState([])
    const state = useSelector((state) => state)
    const [searchedData, setSearchedData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const [downloadType, setDownloadType] = useState('EXCEL')
    const [searchColumn, setSearchColumn] = useState('')
    const [searchText, setSearchText] = useState('')
    const [callType, setCallType] = useState('')
    const [filterSearch, setFilterSearch] = useState(dashboardFilters)
    const [loading, setLoading] = useState(false)
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100
        }
    })

    const createSorter = (baseDataIndex, USBasedFlag, dataType) => {
        return (a, b) => {
            let dataIndex
            if (USBasedFlag == US_BASED_FLAG.US) {
                dataIndex = `${baseDataIndex}US`
            } else if (USBasedFlag == US_BASED_FLAG.NON_US) {
                dataIndex = `${baseDataIndex}NotUS`
            } else {
                dataIndex = baseDataIndex
            }

            let aValue, bValue
            if (dataType === 'percentage') {
                aValue = isNaN(a[dataIndex]) ? -Infinity : a[dataIndex]
                bValue = isNaN(b[dataIndex]) ? -Infinity : b[dataIndex]
            } else if (dataType === 'string') {
                aValue = parseFloat(a[dataIndex]?.replace(/,/g, '')) || 0
                bValue = parseFloat(b[dataIndex]?.replace(/,/g, '')) || 0
            } else {
                aValue = a[dataIndex]
                bValue = b[dataIndex]
            }
            return aValue - bValue
        }
    }

    const originalColumn = (USBasedFlag) => [
        {
            title: 'Interpreter ID',
            dataIndex: 'InterpreterId',
            sorter: (a, b) => a.InterpreterId - b.InterpreterId,
            className: 'header-table'
        },
        {
            title: 'Interpreter Name',
            dataIndex: 'InterpreterName',
            sorter: (a, b) => {
                const ltrim = (str) => str.replace(/^\s+/, '');
                const aValue = ltrim(a?.InterpreterName || '');
                const bValue = ltrim(b?.InterpreterName || '');
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                } else {
                    return 0;
                }
            },
            className: 'header-table',
        },
        {
            title: 'Language',
            dataIndex: 'TargetLanguages',
            key: 'TargetLanguages',
            render: (TargetLanguages) =>
                TargetLanguages ? TargetLanguages.join(', ') : '',
            sorter: (a, b) =>
                (a.TargetLanguages || [])
                    .join(',')
                    .localeCompare((b.TargetLanguages || []).join(','))
        },
        {
            title: '# Calls',
            dataIndex: 'TotalCalls',
            sorter: createSorter('TotalCalls', USBasedFlag),
            render: (text) => Formatter.numberWithCommas(text)
        },
        {
            title: 'Answered',
            dataIndex: 'TotalCallsAnswered',
            sorter: createSorter('TotalCallsAnswered', USBasedFlag)
        },
        {
            title: 'Missed %',
            dataIndex: 'TotalCallsMissedPercentage',
            sorter: createSorter(
                'TotalCallsMissedPercentage',
                USBasedFlag,
                'percentage'
            ),
            render: (text, row) => (
                <Link
                    to={`/pages/terp-hud/routing-history?InterpreterId=${
                        row.InterpreterId
                    }&Status=${'missed'}`}
                >
                    {text}
                </Link>
            )
        },
        {
            title: 'Avg WT',
            dataIndex: 'TotalWaitTime',
            sorter: (a, b) => a.TotalWaitTime - b.TotalWaitTime
        },
        {
            title: 'Serviced Mins',
            dataIndex: 'TotalServiceMinutes',
            sorter: createSorter('TotalServiceMinutes', USBasedFlag, 'string'),
            render: (text, record) => {
                let linkPath = `/pages/transactions?dataIdx0=InterpreterId&status0=${record.InterpreterId}`;
                // Append additional parameters based on USBasedFlag
                if (USBasedFlag === US_BASED_FLAG.US) {
                    linkPath += "&dataIdx1=USBased&status1=Yes";
                } else if (USBasedFlag === US_BASED_FLAG.NON_US) {
                    linkPath += "&dataIdx1=USBased&status1=No";
                }
                return (
                    <div>
                        <Link to={linkPath}>
                            {text}
                        </Link>
                    </div>
                );
            }
        }
    ]

    const [columns, setColumns] = useState(originalColumn())
    const [visibleColumns, setVisibleColumns] = useState([
        'InterpreterID',
        'InterpreterFirstName',
        'InterpreterLastName',
        'TotalCalls',
        'TotalCallsAnsweered, TotalCallsMissedPercentage, TotalServiceMinutes, TotalWaitTIme'
    ])

    // Select type of download
    const downloadDropdownType = [
        {
            value: 'PDF',
            label: 'PDF'
        },
        {
            value: 'EXCEL',
            label: 'EXCEL'
        }
    ]

    //Get per terp aggregated data from backend
    const getDataSource = () => {
        let filteredData = searchedData
            ?.sort((a, b) => b.TotalCalls - a.TotalCalls)
            ?.map((item, index) => {
                return {
                    Rank: index + 1,
                    InterpreterId: item.InterpreterId,
                    InterpreterName: item.InterpreterName,
                    TotalCalls: item.TotalCalls,
                    TotalCallsUS: item.TotalCallsUS,
                    TotalCallsNotUS: item.TotalCalls - item.TotalCallsUS,
                    TotalCallsAnswered: item.TotalCallsAnswered,
                    TotalCallsAnsweredUS: item.TotalCallsAcceptedUS,
                    TotalCallsAnsweredNotUS:
                        item.TotalCallsAnswered - item.TotalCallsAcceptedUS,
                    TotalCallsMissed: item.TotalCallsMissed,
                    TotalCallsMissedUS: item.TotalCallsMissedUS,
                    TotalCallsMissedNotUS:
                        item.TotalCallsMissed - item.TotalCallsMissedUS,
                    TotalWaitTime: Formatter.numberWithCommas(
                        Math.round(item?.TotalWaitTime / item?.TotalCalls)
                    ),
                    TotalWaitTimeUS: Formatter.numberWithCommas(
                        Math.round(item?.TotalWaitTimeUS / item?.TotalCallsUS)
                    ),
                    TotalWaitTimeNotUS: Formatter.numberWithCommas(
                        Math.round(item?.TotalWaitTimeNotUS / (item?.TotalCalls - item?.TotalCallsUS))
                    ),
                    TargetLanguages: item.UniqueTargetLanguages,
                    TotalServiceMinutes: Formatter.numberWithCommas(
                        item?.TotalServiceMinutes
                    ),
                    TotalServiceMinutesUS: Formatter.numberWithCommas(
                        item?.TotalServiceMinutesUS
                    ),
                    TotalServiceMinutesNotUS: Formatter.numberWithCommas(
                        item?.TotalServiceMinutes - item?.TotalServiceMinutesUS
                    ),
                    TotalCallsMissedPercentage: Math.round(
                        (item?.TotalCallsMissed / item?.TotalCalls) * 100
                    ),
                    TotalCallsMissedPercentageUS: Math.round(
                        (item?.TotalCallsMissedUS / item?.TotalCallsUS) * 100
                    ),
                    TotalCallsMissedPercentageNotUS: Math.round(
                        ((item?.TotalCallsMissed - item.TotalCallsMissedUS) /
                            (item?.TotalCalls - item?.TotalCallsUS)) *
                            100
                    )
                }
            })

        if (searchColumn && searchColumn !== '') {
            filteredData = filteredData.filter((item) => {
                const columnValue = item[searchColumn]?.toString().toLowerCase()
                const searchTextValue = searchText.toLowerCase()
                return (
                    columnValue &&
                    (columnValue.includes(searchTextValue) ||
                        columnValue.indexOf(searchTextValue) !== -1)
                )
            })
        }

        // Filter data based on callType
        if (callType === 'US') {
            filteredData = filteredData.filter(item => item.TotalServiceMinutesUS > 0);
        } else if (callType === 'NotUS') {
            filteredData = filteredData.filter(item => item.TotalServiceMinutesNotUS > 0);
        }

        return filteredData
    }

    const downloadFile = () => {
        if (downloadType === 'PDF') {
            console.log('download Type: PDF')
            //PDF converter
            downloadPDF()
            //   props?.downloadPDF(range);
        } else if (downloadType === 'EXCEL') {
            // downloadCSVFunction(tableParams.pagination, filterSearch, tableParams.sorter);
            downloadExcelFunction();
            //CSV converter
            //   props?.downloadCSV(range);
        }
    }

    const handleSearch = (values) => {
        setSearchColumn(values?.column ? values?.column : '')
        setSearchText(values?.text ? values?.text?.trim() : '')
    }

    const handleReset = () => {
        setSearchColumn('')
        setSearchText('')
        setVisibleColumns([
            'InterpreterID',
            'InterpreterFirstName',
            'InterpreterLastName',
            'TotalCalls',
            'TotalCallsAnsweered, TotalCallsMissedPercentage, TotalServiceMinutes, TotalWaitTIme'
        ])
    }
    const fetchData = (filters) => {
        setTableLoading(true)
        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters
        }

        Service.Terp()
            .getTerpDetails(filter)
            .then((response) => {
                const Searchdata = response
                setSearchedData(Searchdata)
                setTableLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setTableLoading(false)
            })

        Service.Terp()
            .getAllTerpDetails(filter)
            .then((response) => {
                const AllTerpData = response
                setAllTerpData(AllTerpData)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        fetchData()
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.selected,
        state.company?.client
    ])

    const allData = allTerpData

    const totalCalls = allData.reduce(
        (acc, curr) => acc + (curr.TotalCalls || 0),
        0
    )
    const totalWaitTime = allData.reduce(
        (acc, curr) => acc + (curr.TotalWaitTime || 0),
        0
    )
    const totalCallsAnswered = allData.reduce(
        (acc, curr) => acc + (curr.TotalCallsAnswered || 0),
        0
    )
    const totalServiceMinutesUS = allData.reduce(
        (acc, curr) => acc + (curr.TotalServiceMinutesUS || 0),
        0
    )
    const totalServiceMinutes = allData.reduce(
        (acc, curr) => acc + (curr.TotalServiceMinutes || 0),
        0
    )
    const totalMissedCalls = allData.reduce(
        (acc, curr) => acc + (curr.TotalCallsMissed || 0),
        0
    )

    let PercentageMissedCalls = null

    if (allData && allData.length > 0 && allData[0].TotalCalls) {
        PercentageMissedCalls = Math.round(
            (totalMissedCalls / totalCalls) * 100 || 0
        )
    }

    const downloadPDF = () => {
        let downloadArray = []

        if (
            !searchedData ||
            getDataSource()?.InterpreterFirstName?.length == 0
        ) {
            return alert('no data', getDataSource())
        }

        const commonProperties = {
            'Interpreter ID': 'InterpreterId',
            Name: 'InterpreterName',
            Language: 'TargetLanguages'
        }

        for (let list of getDataSource().sort(
            (a, b) => b.TotalCalls - a.TotalCalls
        )) {
            let newList = {}

            if (callType === 'US') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsUS',
                    Answered: 'TotalCallsAnsweredUS',
                    'Missed %': 'TotalCallsMissedPercentageUS',
                    'Serviced Mins': 'TotalServiceMinutesUS',
                    'Avg WT': 'TotalWaitTimeUS'
                }
            } else if (callType === 'NotUS') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsNotUS',
                    Answered: 'TotalCallsAnsweredNotUS',
                    'Missed %': 'TotalCallsMissedPercentageNotUS',
                    'Serviced Mins': 'TotalServiceMinutesNotUS',
                    'Avg WT': 'TotalWaitTimeNotUS'
                }
            } else {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCalls',
                    Answered: 'TotalCallsAnswered',
                    'Missed %': 'TotalCallsMissedPercentage',
                    'Serviced Mins': 'TotalServiceMinutes',
                    'Avg WT': 'TotalWaitTime'
                }
            }

            for (let [key, value] of Object.entries(newList)) {
                if (key !== 'Interpreter ID') {
                    newList[key] = list[value]
                        ? Formatter.numberWithCommas(list[value])
                        : list[value]
                } else {
                    newList[key] = list[value]
                }
            }

            downloadArray.push(newList)
        }

        const headerList = [
            'Interpreter ID',
            'Name',
            'Language',
            '# Calls',
            'Answered',
            'Missed %',
            'Serviced Mins',
            'Avg WT'
        ]
        const bodyList = downloadArray.map((item) =>
            Object.values(item).map((value) => String(value))
        )
        const doc = new jsPDF('landscape', 'px', 'a4')
        doc.setFontSize(18)
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Interpreter_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        doc.text('Interpreter Report', 30, 20)
        doc.setFontSize(12)
        doc.autoTable({
            head: [headerList.map((header) => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33]
            },
            startY: 50
        })
        doc.save(filename)
    }

    const downloadCSVFunction = () => {
        let downloadArray = []

        if (
            !searchedData ||
            getDataSource()?.InterpreterFirstName?.length == 0
        ) {
            return alert('no data', getDataSource())
        }

        const commonProperties = {
            'Interpreter ID': 'InterpreterId',
            Name: 'InterpreterName',
            Language: 'TargetLanguages'
        }

        for (let list of getDataSource().sort(
            (a, b) => b.TotalCalls - a.TotalCalls
        )) {
            let newList = {}

            if (callType === 'US') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsUS',
                    Answered: 'TotalCallsAnsweredUS',
                    'Missed %': 'TotalCallsMissedPercentageUS',
                    'Serviced Mins': 'TotalServiceMinutesUS',
                    'Avg WT': 'TotalWaitTimeUS',
                }
            } else if (callType === 'NotUS') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsNotUS',
                    Answered: 'TotalCallsAnsweredNotUS',
                    'Missed %': 'TotalCallsMissedPercentageNotUS',
                    'Serviced Mins': 'TotalServiceMinutesNotUS',
                    'Avg WT': 'TotalWaitTimeNotUS',
                }
            } else {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCalls',
                    Answered: 'TotalCallsAnswered',
                    'Missed %': 'TotalCallsMissedPercentage',
                    'Serviced Mins': 'TotalServiceMinutes',
                    'Avg WT': 'TotalWaitTime'
                }
            }

            for (let [key, value] of Object.entries(newList)) {
                if (key !== 'Interpreter ID') {
                    newList[key] = list[value]
                        ? Formatter.numberWithCommas(list[value])
                        : list[value]
                } else {
                    newList[key] = list[value]
                }
            }

            downloadArray.push(newList)
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Interpreter_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            [
                'Interpreter ID',
                'Name',
                'Language',
                '# Calls',
                'Answered',
                'Missed %',
                'Serviced Mins',
                'Avg WT'
            ],
            filename
        )
    }

    const downloadExcelFunction = () => {
        let downloadArray = []

        if (
            !searchedData ||
            getDataSource()?.InterpreterFirstName?.length == 0
        ) {
            return alert('no data', getDataSource())
        }

        const commonProperties = {
            'Interpreter ID': 'InterpreterId',
            Name: 'InterpreterName',
            Language: 'TargetLanguages'
        }

        for (let list of getDataSource().sort(
            (a, b) => b.TotalCalls - a.TotalCalls
        )) {
            let newList = {}

            if (callType === 'US') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsUS',
                    Answered: 'TotalCallsAnsweredUS',
                    'Missed %': 'TotalCallsMissedPercentageUS',
                    'Serviced Mins': 'TotalServiceMinutesUS',
                    'Avg WT': 'TotalWaitTimeUS'
                }
            } else if (callType === 'NotUS') {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCallsNotUS',
                    Answered: 'TotalCallsAnsweredNotUS',
                    'Missed %': 'TotalCallsMissedPercentageNotUS',
                    'Serviced Mins': 'TotalServiceMinutesNotUS',
                    'Avg WT': 'TotalWaitTimeNotUS'
                }
            } else {
                newList = {
                    ...commonProperties,
                    '# Calls': 'TotalCalls',
                    Answered: 'TotalCallsAnswered',
                    'Missed %': 'TotalCallsMissedPercentage',
                    'Serviced Mins': 'TotalServiceMinutes',
                    'Avg WT': 'TotalWaitTime'
                }
            }

            for (let [key, value] of Object.entries(newList)) {
                if (key !== 'Interpreter ID') {
                    newList[key] = list[value]
                        ? Formatter.numberWithCommas(list[value])
                        : list[value]
                } else {
                    newList[key] = list[value]
                }
            }

            downloadArray.push(newList)
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Interpreter_Report_${donwloadDateRange}_${downloadRangeStart}_to_${downloadRangeEnd}.xlsx`;
        const sheetData = [downloadArray];
        const sheetNames = ["Interpreter Dashboard"];

        exportToExcel(sheetData, sheetNames, filename);
    }
    const handleCardClick = (columnsToModify, type) => {
        if (!Array.isArray(columnsToModify)) {
            console.error('columnsToModify should be an array.')
            return
        }

        // If the same card is clicked again, reset the columns and callType
        if (type === callType) {
            setColumns(originalColumn())
            setCallType('')
            return
        }
        setColumns(originalColumn(type))
        setColumns((columns) => {
            const modifiedColumns = [...columns]

            columnsToModify.forEach(({ dataIndex, newDataIndex }) => {
                if (!dataIndex || !newDataIndex) {
                    console.error(
                        'dataIndex and newDataIndex should be provided for each item in columnsToModify.'
                    )
                    return
                }

                const columnToModify = modifiedColumns.find((column) =>
                    dataIndex.includes(column.dataIndex)
                )
                if (columnToModify) {
                    columnToModify.dataIndex = newDataIndex
                } else {
                    console.error(
                        `Column with dataIndex ${dataIndex.join(
                            ', '
                        )} not found.`
                    )
                }
            })

            return modifiedColumns
        })

        // Set the callType
        setCallType(type)
    }

    const filteredColumns = columns.filter((col) =>
        visibleColumns.includes(col.dataIndex)
    )

    return (
        <Spin spinning={tableLoading} tip="Cooking up your data...">
            <div style={{ padding: 24 }}>
                <Row
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Form
                        onFinish={handleSearch}
                        onReset={handleReset}
                        className="search-form"
                    >
                        <Space>
                            <div className="search-form">
                                <Form.Item name="column">
                                    <Select
                                        placeholder="Select a column"
                                        style={{ width: 200 }}
                                    >
                                        {columns.map((column) => (
                                            <Option
                                                value={column.dataIndex}
                                                key={column.key}
                                            >
                                                {column.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="text">
                                    <Input
                                        placeholder="Enter text to search"
                                        style={{ width: 200 }}
                                    />
                                </Form.Item>
                                <Form.Item className="form-button">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        icon={<SearchOutlined />}
                                    >
                                        Search
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="reset"
                                        className="form-button"
                                    >
                                        Reset
                                    </Button>
                                </Form.Item>
                            </div>
                        </Space>
                    </Form>
                    <Col style={{ marginLeft: 'auto', marginRight: 10 }}>
                        <DropDown
                            defaultValue={downloadType}
                            dropdownOptions={downloadDropdownType}
                            handleTypeSelect={(downloadType) =>
                                setDownloadType(downloadType)
                            }
                            style={{ marginRight: '10px' }}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            className="download-button"
                            onClick={downloadFile}
                        >
                            <DownloadOutlined />
                            Download
                        </Button>
                    </Col>
                </Row>
                <div
                    className="site-card-wrapper"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px 0'
                    }}
                >
                    <Row
                        gutter={40}
                        bordered={true}
                        style={{ height: 150 }}
                        className="card-container responsive-row"
                    >
                        <Col xs={24} sm={12} md={6}>
                            <Card
                                className="TerpCard"
                                title="AAT Seconds"
                                bordered={false}
                            >
                                {totalCallsAnswered > 0
                                    ? (
                                          totalWaitTime / totalCallsAnswered
                                      ).toFixed(0)
                                    : '-'}
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6} className="card-col">
                            <Card
                                className="TerpCard"
                                title="Abandonment"
                                bordered={false}
                            >
                                {PercentageMissedCalls}%
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6} className="card-col">
                            <Card
                                className={
                                    callType == 'US'
                                        ? 'TerpCard TerpCardSelected'
                                        : 'TerpCard'
                                }
                                title="Domestic mins"
                                bordered={false}
                                onClick={() =>
                                    handleCardClick(
                                        [
                                            {
                                                dataIndex: [
                                                    'TotalCalls',
                                                    'TotalCallsNotUS',
                                                    'TotalCallsUS'
                                                ],
                                                newDataIndex: 'TotalCallsUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalCallsAnswered',
                                                    'TotalCallsAnsweredUS',
                                                    'TotalCallsAnsweredNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalCallsAnsweredUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalServiceMinutes',
                                                    'TotalServiceMinutesUS',
                                                    'TotalServiceMinutesNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalServiceMinutesUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalWaitTime',
                                                    'TotalWaitTimeUS',
                                                    'TotalWaitTimeNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalWaitTimeUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalCallsMissedPercentage',
                                                    'TotalCallsMissedPercentageUS',
                                                    'TotalCallsMissedPercentageNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalCallsMissedPercentageUS'
                                            }
                                        ],
                                        'US'
                                    )
                                }
                            >
                                {Formatter.numberWithCommas(
                                    totalServiceMinutesUS
                                )}
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6} className="card-col">
                            <Card
                                className={
                                    callType == 'NotUS'
                                        ? 'TerpCard TerpCardSelected'
                                        : 'TerpCard'
                                }
                                title="International mins"
                                bordered={false}
                                onClick={() =>
                                    handleCardClick(
                                        [
                                            {
                                                dataIndex: [
                                                    'TotalCalls',
                                                    'TotalCallsNotUS',
                                                    'TotalCallsUS'
                                                ],
                                                newDataIndex: 'TotalCallsNotUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalCallsAnswered',
                                                    'TotalCallsAnsweredUS',
                                                    'TotalCallsAnsweredNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalCallsAnsweredNotUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalServiceMinutes',
                                                    'TotalServiceMinutesUS',
                                                    'TotalServiceMinutesNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalServiceMinutesNotUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalWaitTime',
                                                    'TotalWaitTimeUS',
                                                    'TotalWaitTimeNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalWaitTimeNotUS'
                                            },
                                            {
                                                dataIndex: [
                                                    'TotalCallsMissedPercentage',
                                                    'TotalCallsMissedPercentageUS',
                                                    'TotalCallsMissedPercentageNotUS'
                                                ],
                                                newDataIndex:
                                                    'TotalCallsMissedPercentageNotUS'
                                            }
                                        ],
                                        'NotUS'
                                    )
                                }
                            >
                                {Formatter.numberWithCommas(
                                    totalServiceMinutes - totalServiceMinutesUS
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Table
                    columns={columns}
                    dataSource={getDataSource()}
                    size="middle"
                    className="terp-table"
                    rowKey={(record) => record.Rank + callType}
                    style={{
                        width: '100%',
                        backgroundColor: colorBgLayout,
                        overflowX: 'auto'
                    }}
                    pagination={{ 
                        defaultPageSize: 100,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                />
            </div>
        </Spin>
    )
}
export default TerpTable
