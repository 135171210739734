import ReactECharts from 'echarts-for-react'
import chroma from 'chroma-js'
import PropTypes from 'prop-types'
import Formatter from '../../../utils/Formatter'
import { memo } from 'react'

const CustomerTreeMap = ({ data, sortKey }) => {
  // Generate a color scale based on the length of data

  const top25Data = data?.slice(0, 25)

  const colors = chroma
    .scale(['#6c40a6', '#226885', '#0f9191', '#61636f'])
    .colors(top25Data?.length || 25)

  const unit = sortKey === 'TotalServiceMinutes' ? 'Minutes' : 'Dollars'

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: ({ data }) => {
        const name = data.name
        const value = Formatter.numberWithCommas(data.value)
        const percentage = Formatter.numberWithCommas(data.percentage)
        const percentageUnit =
          sortKey === 'TotalServiceMinutes'
            ? '% of Service Minutes'
            : '% of Revenue'
        return `${name}<br/>${value} ${unit} (${percentage} ${percentageUnit})`
      }
    },
    series: [
      {
        type: 'treemap',
        data: top25Data?.map((item, idx) => ({
          name: item.CompanyName,
          value:
            sortKey === 'TotalServiceMinutes'
              ? item.TotalServiceMinutes
              : item.Revenue,
          percentage:
            sortKey === 'TotalServiceMinutes'
              ? item.ServiceMinutesPercentage
              : item.RevenuePercentage,

          itemStyle: { color: colors[idx % colors.length] }
        })),
        leafDepth: 1,
        label: {
          show: true,
          formatter: ({ name, value }) => {
            const formattedValue = Formatter.shortFormatNumber(value);
            const unitSuffix = sortKey === 'TotalServiceMinutes' ? ' Min' : '';
            const unitPrefix = sortKey === 'TotalServiceMinutes' ? '' : '$';
            return value ? `{b|${name}}\n{c|${unitPrefix}${formattedValue}${unitSuffix}}` : '';
          },
          position: 'inside',
          rich: {
            b: {
              align: 'left',
              verticalAlign: 'top',
              padding: [0, 0, 10, 0]
            },
            c: {
              verticalAlign: 'middle',
              fontSize: 14,
              fontWeight: 'bold'
            }
          },
          fontSize: 14,
          padding: 10
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'black',
          gapWidth: 1
        },
        emphasis: {
          itemStyle: {
            borderWidth: 0,
            gapWidth: 0
          }
        },
        nodeClick: false,
        roam: false,
        zoomToNodeRatio: 0,
        breadcrumb: {
          show: false
        },
        width: '100%',
        height: '200px',
        left: 0,
        top: 10,
        bottom: 0,
        right: 0
      }
    ]
  }

  return <ReactECharts option={option} style={{ height: 220, padding: 0 }} />
}

CustomerTreeMap.propTypes = {
  data: PropTypes.array.isRequired
}

export default CustomerTreeMap
export const MemoizedCustomerTreeMap = memo(CustomerTreeMap)
