import {
    Card,
    Col,
    Input,
    Radio,
    Row,
    Spin,
    Switch,
    Table,
    theme,
    Space,
    Button,
    Typography
} from 'antd'
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts/core'
import './irh.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef, useCallback } from 'react'
import {
    getTerpPositionAnalytics,
    getWidgetsData
} from '../../../store/actions/analytics.action'
import Service from '../../../services'
import moment from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import Search from 'antd/es/input/Search'
import { SearchOutlined } from '@ant-design/icons'
import Formatter from '../../../utils/Formatter'
import CustomFilter from '../../transactions/customFilter'

const graphColors = ['#6c40a6', '#0f9191', '#226885', '#61636f']


const GetColumnSearchProps = (dataIndex, updateFilters) => {
    const uniqueId = `transaction-filter-${dataIndex.dataIndex}`;
    return ({
        filterDropdown: (props) => <CustomFilter 
            dataIndex={dataIndex.dataIndex} 
            showTimeRange={dataIndex.showTimeRange}
            timeZone={dataIndex.timeZone}
            updateFilters={updateFilters}
            allStatus={true}
            id={uniqueId}
            {...props} 
            />,
        filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined,
              }}
            />
          ),
    })
};

const expandedRowRender = (record) => {
    let data
    if (record.RoutingHistory) {
        data = JSON.parse(JSON.stringify(record?.RoutingHistory))
        data?.sort(
            (a, b) =>
                moment.utc(a.SentTime || 1990).valueOf() -
                moment.utc(b.SentTime || 1990).valueOf()
        )
    }
    const columns = [
        {
            title: 'Interpreter Name',
            dataIndex: 'InterpreterFirstName',
            render: (first, row) =>
                `${first || '-'} ${row?.InterpreterLastName || '-'}`,
            width: '30%'
        },
        {
            title: 'Request Sent Time',
            dataIndex: 'SentTime',
            render: (text, row) =>
                text ? moment(row.SentTime).format('YYYY-MM-DD HH:mm:ss') : '-',
            width: '35%'
        },
        {
            title: 'Request Accept Time',
            dataIndex: 'AcceptTime',
            render: (text, row) =>
                text
                    ? moment(row.AcceptTime).format('YYYY-MM-DD HH:mm:ss')
                    : '-',
            width: '35%'
        }
    ]
    return (
        <Table
            key={record?.Id + '_sub'}
            title={() => <b>Routing History</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            pagination={false}
            rowClassName={(record, index) =>
                record?.AcceptTime &&
                !record?.AcceptTime?.includes('1970-01-01')
                    ? 'accept-row'
                    : 'declined-row'
            }
        />
    )
}

const InterpreterRoutingHistory = (props) => {
    const {
        token: { colorText, colorBgLayout }
    } = theme.useToken()

    const dispatch = useDispatch()
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const state = useSelector((state) => state)
    const terpPositionCount =
        JSON.parse(JSON.stringify(state.analytics?.terpPositionCount)) || []
    const terpPositionCountRef = useRef(terpPositionCount)
    terpPositionCountRef.current = terpPositionCount
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [terp, setTerp] = useState(query.get('terp') || 1)
    const [rows, setRows] = useState([])
    const [expandAll, setExpandAll] = useState(true)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            total: 0
        }
    })

    const searchInput = useRef(null)
    const [searchedColumn, setSearchedColumn] = useState('')
    const [filterSearch, setFilterSearch] = useState()
    const [params, setParams] = useState()
    const [currentSorter, setCurrentSorter] = useState({})

    const createSorter = (dataIndex) => (a, b) => {}
    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);

    const getTransactionFilters = () => {
        const searchParams = new URLSearchParams(search)
        let filters = {}
        const transactionId = searchParams?.get(`id`)
        const InterpreterId = searchParams?.get(`InterpreterId`)
        const status = searchParams?.get(`Status`)

        if (transactionId) {
            filters['ReferenceTransactionId'] = transactionId
        }

        if (InterpreterId) {
            filters['UniversalInterpreterId'] = InterpreterId
        }

        if (status) {
            filters['UniversalStatus'] = status
        }

        return filters
    }

    const transactionFilter = getTransactionFilters()
    const [searchText, setSearchText] = useState()
    const [transactionId, setTransactionId] = useState()

    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData(params?.pagination, params?.filters, params?.sorter)
        }
    }, [params, filterSearch])

    useEffect(() => {
        setLoading(true)
        dispatch(getTerpPositionAnalytics())
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
        setParams({
            pagination: tableParams.pagination,
            filters: tableParams.filters,
            sorter: currentSorter
        })
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.selected,
        state.company?.client
    ])

    useEffect(() => {
        fetchData({
            current: 1,
            pageSize: 50,
            total: 0
        })
        setParams({
            pagination: tableParams.pagination,
            filters: tableParams.filters,
            sorter: currentSorter
        })
    }, [terp])

    const getTotalRows = async (params, fltr) => {
        await Service.TerpPosition()
            .getTerpPositionCallsTotal(
                {
                    start: state.filter?.date?.minDate,
                    end: state.filter?.date?.maxDate,
                    languageType: state.filter?.languageType,
                    filters: {
                        ...fltr,
                        ...Object.assign({}, filterSearch, transactionFilter)
                    }
                },
                terp,
                loading
            )
            .then((data) => {
                setTableParams({
                    ...params,
                    pagination: {
                        ...params.pagination,
                        total: data.total
                    }
                })
            })
    }

    const fetchData = async (pagination, filters, sorter) => {
        setTableLoading(true)
        let params = pagination
            ? {
                  pagination,
                  filters,
                  sort: {
                      [sorter?.field || 'defaultField']: sorter?.order || 'asc'
                  }
              }
            : tableParams
        await getTotalRows(params, params.filters)
        Service.TerpPosition()
            .getTerpPositionCalls(
                {
                    start: state.filter?.date?.minDate,
                    end: state.filter?.date?.maxDate,
                    languageType: state.filter?.languageType,
                    filters: {
                        ...filters,
                        ...Object.assign({}, filterSearch, transactionFilter)
                    }
                },
                terp,
                params?.sort,
                (params.pagination.current - 1) * params.pagination.pageSize,
                params.pagination.pageSize
            )
            .then((data) => {
                setRows(data.calls)
                if (data !== undefined) {
                    setTableLoading(false)
                }
            })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentSorter(sorter)
        setParams({ pagination, filters, sorter })
    }

    const handleTransactionsSearch = (value) => {
        setParams((state) => ({
            ...state,
            filters: {
                ...state.filters,
                TransactionId: value
            }
        }))
    }

    const getColumnSearchProps = ({ dataIndex, value, showTimeRange }) => {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close
            }) => (
                <div
                    style={{
                        padding: 8
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        // value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(
                                selectedKeys,
                                confirm,
                                dataIndex,
                                clearFilters
                            )
                        }
                        style={{
                            marginBottom: 8,
                            display: 'block'
                        }}
                    />

                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    dataIndex,
                                    clearFilters
                                )
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() =>
                                clearFilters &&
                                handleReset(clearFilters, dataIndex, confirm)
                            }
                            size="small"
                            style={{
                                width: 90
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({
                                    closeDropdown: false
                                })
                                setSearchText(selectedKeys[0])
                                setSearchedColumn(dataIndex)
                            }}
                        >
                            Filter
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close()
                            }}
                        >
                            close
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#1890ff' : undefined
                    }}
                />
            ),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            },
            render: (text, record) =>
                record?.[dataIndex] && searchedColumn === dataIndex
                    ? text?.toString()
                    : text?.toString() ?? ''
        }
    }

    
    const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
        if (selectedKeys.length > 0 && typeof selectedKeys[0] == 'string') {
            selectedKeys[0] = selectedKeys[0]?.trim()

            if (selectedKeys[0] == '') {
                handleReset(clearFilters, dataIndex, confirm)
                return
            }
        }

        if (selectedKeys.length == 0) {
            handleReset(clearFilters, dataIndex, confirm)
            return
        }

        confirm()
        setSearchText(selectedKeys)
        setSearchedColumn(dataIndex)

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys
            }
            setParams({
                pagination: tableParams.pagination,
                filters: { ...tableParams.filters, ...updatedFilterSearch },
                sorter: tableParams.sorter
            })
            return updatedFilterSearch
        })
    }

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters()
        confirm()
        setSearchText('')
        setSearchedColumn(dataIndex)

        setFilterSearch((prevState) => {
            const updatedFilterSearch = JSON.parse(
                JSON.stringify(prevState || {})
            )
            const updatedTableParams = JSON.parse(
                JSON.stringify(tableParams.filters || {})
            )
            delete updatedTableParams[dataIndex]
            delete updatedFilterSearch[dataIndex]
            setParams({
                pagination: tableParams.pagination,
                filters: { ...updatedTableParams, ...updatedFilterSearch },
                sorter: tableParams.sorter
            })
            setTableParams((pState) => {
                return { ...pState, filters: updatedTableParams }
            })
            return updatedFilterSearch
        })
    }
    const getColumns = () => [
        {
            title: 'Transaction ID',
            dataIndex: 'ReferenceTransactionId',
            sorter: createSorter('ReferenceTransactionId'),
            // ...getColumnSearchProps({ dataIndex: 'ReferenceTransactionId' })
            ...GetColumnSearchProps({ dataIndex: "ReferenceTransactionId" }, updateFilters),

        },
        {
            title: 'Language Name',
            dataIndex: 'TargetLanguage',
            sorter: createSorter('TargetLanguage'),
            ...GetColumnSearchProps({ dataIndex: "TargetLanguage" }, updateFilters),
        },
        {
            title: 'Interpreter Name',
            dataIndex: 'InterpreterFirstName',
            sorter: createSorter('InterpreterFirstName'),
            ...GetColumnSearchProps({ dataIndex: "InterpreterFirstName" }, updateFilters),
            render: (first, row) => {
                const firstName = first || ''
                const lastName = row?.InterpreterLastName || ''
                return `${firstName} ${lastName}`.trim() || '-'
            }
        },
        {
            title: 'Company ID',
            dataIndex: 'RequestCompanyId',
            ...GetColumnSearchProps({ dataIndex: "RequestCompanyId" }, updateFilters),
        },
        {
            title: 'Client ID',
            dataIndex: 'ClientId',
            ...GetColumnSearchProps({ dataIndex: "ClientId" }, updateFilters),
        },
        {
            title: 'Request Time',
            dataIndex: 'RequestTime',
            sorter: createSorter('RequestTime'),
            render: (date) =>
                date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-'
        },
        {
            title: 'Accept Time',
            dataIndex: 'RoutingHistory',
            render: (history) => {
                const acceptTime = history?.find(
                    (e) => e.AcceptTime
                )?.AcceptTime
                return acceptTime
                    ? moment(acceptTime).format('YYYY-MM-DD HH:mm:ss')
                    : '-'
            }
        },
        {
            title: 'Request Status',
            dataIndex: 'Status',
            sorter: createSorter('Status'),
            ...GetColumnSearchProps({ dataIndex: "Status" }, updateFilters),
        },
        {
            title: 'Total minutes',
            dataIndex: 'ServiceMinutes',
            sorter: createSorter('ServiceMinutes'),
            render: (text) => <div>{text || '-'}</div>
        },
        {
            title: 'Total Wait Time',
            dataIndex: 'WaitingSeconds',
            render: (secs) => `${secs} seconds`,
            sorter: createSorter('WaitingSeconds')
        }
    ]

    const getOption = () => {
        let graphics = echarts.graphic
        let data = {
            legends:
                terpPositionCount?.rawData
                    ?.sort((a, b) => b.TotalCalls - a.TotalCalls)
                    ?.map((e, i) => `#${e._id}`) || [],
            seriesData:
                terpPositionCount?.rawData
                    ?.sort((a, b) => b.TotalCalls - a.TotalCalls)
                    ?.map((e, i) => ({
                        value: e.TotalCalls,
                        name: `#${e._id}`,
                        itemStyle: {
                            color: graphColors[i % graphColors.length]
                        }
                    })) || []
        }
        return {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%']
                },
                axisPointer: {
                    type: 'shadow'
                },
                formatter: ([{ data, axisValueLabel }]) => {
                    return `${axisValueLabel}</br>${Number(
                        data.value
                    ).toLocaleString()} Calls`
                }
            },
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 0,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: data.legends,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        onZero: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'log',
                    axisLine: {
                        lineStyle: {
                            color: colorText
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'count',
                    type: 'bar',
                    barWidth: '60%',
                    data: data.seriesData
                }
            ]
        }
    }

    const getOptionPie = () => {
        return {
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    return `${params.seriesName}<br />
                            ${params.name}: ${params.data.value} (${params.percent}%)`
                }
            },
            series: [
                {
                    name: 'Calls Percentage',
                    type: 'pie',
                    radius: '90%',
                    data: terpPositionCount?.rawData
                        ?.sort((a, b) => b.TotalCalls - a.TotalCalls)
                        ?.map((e, i) => ({
                            value: e.TotalCalls,
                            name: `#${e._id}`,
                            itemStyle: { color: graphColors[i] || 'grey' }
                        })),
                    label: {
                        color: colorText
                    },
                    labelLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                    },
                    itemStyle: {
                        shadowBlur: 200,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200
                    }
                }
            ]
        }
    }

    const handleChartClick = (params) => {
        setTerp(Number(params.name?.toString().replace('#', '')))
    }

    const onEvents = {
        click: handleChartClick
    }

    const handleRowExpandChanges = (currentExpandedKeys) => {
        setExpandedKeys(currentExpandedKeys)
    }

    return (
        <Row className="irh-container">
            <Col flex={'100%'}>
                <Row wrap={false} gutter={20} className="charts-row">
                    <Col flex={'75%'}>
                        <Spin spinning={loading}>
                            <Card
                                style={{
                                    width: '100%',
                                    backgroundColor: colorBgLayout,
                                    borderRadius: 4
                                }}
                            >
                                <EChartsReact
                                    onEvents={onEvents}
                                    style={{ height: 220 }}
                                    option={getOption()}
                                />
                            </Card>
                        </Spin>
                    </Col>
                    <Col flex={'25%'}>
                        <Spin spinning={loading}>
                            <Card
                                className="chart-card"
                                style={{
                                    width: '100%',
                                    backgroundColor: colorBgLayout,
                                    borderRadius: 4
                                }}
                            >
                                <EChartsReact
                                    onEvents={onEvents}
                                    style={{ height: 220 }}
                                    option={getOptionPie()}
                                />
                            </Card>
                        </Spin>
                    </Col>
                </Row>
                <br />
                <Row style={{ width: '100%' }}>
                    <Table
                        title={() => (
                            <>
                                <Row align={'middle'} gutter={10}>
                                    <Col>
                                        <Typography.Text
                                            style={{ fontSize: 18 }}
                                        >{`Interpreter #${terp}`}</Typography.Text>
                                    </Col>
                                    <Col
                                        style={{ marginLeft: 'auto' }}
                                        className="search-col"
                                    >
                                        <Search
                                            placeholder="Search Transaction"
                                            style={{ width: 420 }}
                                            allowClear
                                            enterButton
                                            onSearch={handleTransactionsSearch}
                                        />
                                    </Col>
                                    <Col>
                                        {/* <Switch 
                                        checkedChildren="Expand All" 
                                        unCheckedChildren="Collapse All" 
                                        checked={expandAll} 
                                        onChange={(bool) => setExpandAll(bool)}
                                    ></Switch> */}
                                        <Radio.Group
                                            buttonStyle="solid"
                                            options={[
                                                {
                                                    label: 'Collapse All',
                                                    value: false
                                                },
                                                {
                                                    label: 'Expand All',
                                                    value: true
                                                }
                                            ]}
                                            optionType="button"
                                            value={expandAll}
                                            onChange={() => {
                                                setExpandAll(
                                                    (prevState) => !prevState
                                                )
                                                if (!expandAll) {
                                                    // If not expanded --> set all rows as expanded.
                                                    setExpandedKeys(
                                                        rows?.map((o) => o.Id)
                                                    )
                                                } else {
                                                    // If expanded --> clear expanded keys.
                                                    setExpandedKeys([])
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        style={{
                            width: '100%',
                            backgroundColor: colorBgLayout
                        }}
                        columns={getColumns()}
                        rowKey={(record) => record.Id}
                        dataSource={rows}
                        pagination={tableParams.pagination}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        size="middle"
                        className="voyce-custom-table"
                        expandable={{
                            expandedRowRender,
                            expandedRowKeys: expandAll
                                ? rows?.map((o) => o.Id)
                                : expandedKeys,
                            onExpandedRowsChange: handleRowExpandChanges
                        }}
                    />
                </Row>
            </Col>
        </Row>
    )
}

export default InterpreterRoutingHistory
