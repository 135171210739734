import { Button, Col, Row, Switch, Table, theme, Typography } from 'antd'
import './widget.four.scss'
import { useEffect, useMemo, useState } from 'react'
import WidgetFourGraph from './widget.graph'
import WidgetFourScore from './widget.score'
import GetColumnSearchProps from '../../../components/table/search'
import { useHud } from '../store/hud.context'
import { useSelector } from 'react-redux'
import WidgetFourExpanded from './widget.expanded'

const WidgetFour = () => {
  const { interpreters, watchList, handleMonitorClick, loading } = useHud()
  const {
    token: { colorBgBase }
  } = theme.useToken()
  const qaSpecialistId = useSelector((state) => state?.profile?.user?.id)

  const [scoreModal, setScoreModal] = useState(false)
  const [showGraph, setShowGraph] = useState(false)
  const [expandTable, setExpandTable] = useState(false)
  const [dataSource, setDataSource] = useState([])

  const handleBarClick = (params) => {
    const clickedBar = params.name.toLowerCase()
    setScoreModal(clickedBar)
  }

  useEffect(() => {
    const interpretersAddedByUser = []
    const remainingInterpreters = []
    interpreters
      .filter((interpreter) => watchList[interpreter.InterpreterId])
      .forEach((interpreter) => {
        const watch = watchList[interpreter.InterpreterId]
        if (watch?.addedByQaSpecialistId === qaSpecialistId) {
          interpretersAddedByUser.push({ ...interpreter, watchlist: watch })
        } else {
          remainingInterpreters.push({ ...interpreter, watchlist: watch })
        }
      })
    // Sort each array in ascending order based on the Status field
    interpretersAddedByUser.sort((a, b) => a.Status.localeCompare(b.Status))
    remainingInterpreters.sort((a, b) => a.Status.localeCompare(b.Status))

    // Concatenate the two arrays, putting added-by-user interpreters first
    setDataSource([...interpretersAddedByUser, ...remainingInterpreters])
  }, [interpreters, watchList])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      width: '80px',
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false }),
      render: (text, row) =>
        row.Status === 'In conference' ? (
          <Button
            type="link"
            disabled={loading}
            onClick={() => handleMonitorClick(row)}
          >
            {text}
          </Button>
        ) : (
          <span>{text}</span>
        )
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      width: '50%',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false })
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: '20%',
      sorter: (a, b) => a?.Status?.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    }
  ]

  return (
    <>
      {showGraph && (
        <div
          className="WidgetFourContainer"
          style={{ backgroundColor: colorBgBase }}
        >
          <Row
            style={{ padding: 8, borderBottom: '1px solid #303030' }}
            align={'middle'}
          >
            <Col>
              <Typography.Text strong>{`Watchlist`}</Typography.Text>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <Switch
                size="small"
                onChange={setShowGraph}
                checked={showGraph}
              />
            </Col>
          </Row>
          <WidgetFourGraph handleBarClick={handleBarClick} />
          <WidgetFourScore
            visible={scoreModal}
            handleClose={() => setScoreModal(false)}
          />
        </div>
      )}
      {!showGraph && (
        <Table
          size="small"
          title={() => (
            <Row align={'middle'}>
              <Col>
                <Typography.Text strong>{`Watchlist`}</Typography.Text>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                <Switch
                  size="small"
                  onChange={setShowGraph}
                  checked={showGraph}
                />
              </Col>
              <Col style={{ marginLeft: 'auto' }}>
                <Button
                  type={'primary'}
                  size="small"
                  onClick={() => setExpandTable(true)}
                >
                  Expand
                </Button>
              </Col>
            </Row>
          )}
          columns={columns}
          dataSource={dataSource}
          rowClassName={(record, index) => {
            if (record?.watchlist?.addedByQaSpecialistId === qaSpecialistId) {
              return 'added-by-user' // Class name for rows added by user
            } else {
              return 'other-rows' // Class name for other rows
            }
          }}
          pagination={false}
          bordered
          scroll={{ y: 250 }}
          footer={() => <></>}
        />
      )}
      <WidgetFourExpanded
        expand={expandTable}
        handleCancel={() => setExpandTable(false)}
        dataSource={dataSource}
      />
    </>
  )
}
export default WidgetFour
