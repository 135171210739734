import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different Company action types
*/

export default function analytics(state = {
        widgets: {},
        top10Languages: {legends: [], seriesData: [], data: [], legendsIdle: []},
        callsByHours: {legends: [], seriesData: []},
        terpPositionCount: {
            position1: 0,
            position2: 0,
            position3: 0,
            position4plus: 0,
            rawData: []
        },
        totalActiveCarts: [],
        activeCarts: 0,
        avrBarChart: {
            legends: [],
            total: [],
            failed: [],
            success: []
        },
        avrPieChart: [],
        starRating: [],
        topCustomer: [],
    }, action) {
    switch (action.type) {
        case ActionType.SET_WIDGETS:
            return {...state, widgets: action.widgets};
        case ActionType.SET_TOP10:
            return {...state, top10Languages: action.top10Languages};
        case ActionType.SET_PREFERRED_VIDEO:
            return {...state, preferredVideo: action.preferredVideo};
        case ActionType.SET_CALLS_BY_HOURS:
            return {...state, callsByHours: action.callsByHours};
        case ActionType.SET_ACTIVE_CARTS:
            return {...state, activeCarts: action.activeCarts};
        case ActionType.SET_TOTAL_ACTIVE_CARTS:
            return {...state, totalActiveCarts: action.totalActiveCarts};
        case ActionType.SET_TERP_POSITION_COUNT:
            return {...state, terpPositionCount: action.terpPositionCount};
            // Update star rating data in the state
        case ActionType.SET_STAR_RATING: 
        return {
            ...state,
            starRating: action.starRating
        }
        case ActionType.SET_TOP_CUSTOMER:
            return {
                ...state,
                topCustomer: action.topCustomer
            }
    
        case ActionType.SET_AVR_CHART:
            return {...state, avrBarChart: action.avrBarChart, avrPieChart: action.avrPieChart};
        case ActionType.LOGOUT: 
            return {
                widgets: {},
                top10Languages: {legends: [], seriesData: []},
                callsByHours: {legends: [], seriesData: []},
                terpPositionCount: {
                    position1: 0,
                    position2: 0,
                    position3: 0,
                    position4plus: 0,
                    rawData: []
                },
                avrBarChart: {
                    legends: [],
                    total: [],
                    failed: [],
                    success: []
                },
                avrPieChart: []
            }
        default:
            return state;
    }
}
