import { Button } from 'antd'
import { useHud } from './store/hud.context'

const WatchlistButton = ({ interpreterId }) => {
  const { watchList, removeFromWatchList, addToWatchList } = useHud();

  const inWatchList = watchList[interpreterId]

  const handleClick = () => {
    if (inWatchList) {
      removeFromWatchList(interpreterId)
    }
    if (!inWatchList) {
      addToWatchList(interpreterId)
    }
  }

  return (
    <Button
      size={'small'}
      type={'primary'}
      onClick={() => handleClick()}
      danger={Boolean(inWatchList)}
      style={{ width: '100%' }}
    >
      <span>{inWatchList ? 'Remove' : 'Add'}</span>
    </Button>
  )
}

export default WatchlistButton
