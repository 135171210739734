import { Card, Spin, theme } from "antd";
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';

const TotalBarChart = ({dataSource, loading}) => {

    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const getOption = () => {
        let graphics = echarts.graphic;
        let data = {
            legends: dataSource
                ?.map((e, i) => `${e.TargetLanguage}`) || [],
            seriesDataFailed: dataSource
                ?.map((e, i) => (
                { value: e.FailedCalls, name: `${e.TargetLanguage}`})) || [],
            seriesDataSuccess: dataSource
                ?.map((e, i) => (
                { value: e.SuccessCalls, name: `${e.TargetLanguage}` }
            )) || []
        }
        return {
            toolbox: {        
                right: 0,
                top: -5  
            },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0], '10%'];
                },
                axisPointer: {
                  type: 'shadow',
                },
            },
            legend: {
                data: ['Successful Calls', 'Canceled Calls'],
                align: 'left',
                top: -5,
                textStyle: {color: colorText},
            },
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: data.legends,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLine: {
                        onZero: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    }
                },
            ],
            yAxis: [
                {
                    type: "log",
                    min: 1,
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    }
                },
            ],
            series: [
                {
                    name: 'Successful Calls',
                    type: 'bar',
                    stack: 'Calls',
                    data: data?.seriesDataSuccess,
                    itemStyle: {
                        color: '#54c9e8'
                    }
                },
                {
                    name: 'Canceled Calls',
                    type: 'bar',
                    stack: 'Calls',
                    data: data?.seriesDataFailed || [],
                    itemStyle: {
                        color: '#FF6347'
                    }
                },
            ],
        };
    };

    return (
        <Spin spinning={loading}>
            <Card style={{width: '100%', borderRadius: 4}} >
                <EChartsReact style={{ height: 232 }} option={getOption()}/>
            </Card>
        </Spin>
    )
}

export default TotalBarChart;