import ActionType from "../action.types";

/*
    Reducer function to push data to the store in case of different profile action types
*/

const initialState = {
    selectedColumns: [],
    unselectedColumns: [],
};

export default function selectedColumns(state = initialState, action) {
    switch (action.type) {
        case ActionType.CHANGE_COLUMNS:
            return {
                ...state,
                selectedColumns: action.payload.selectedColumns,
                unselectedColumns: action.payload.unselectedColumns,
            };

        default:
            return state;
    }
}
