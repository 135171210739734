import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export default function initStore(initialState) {

    const enhancer = applyMiddleware(thunk)

    return configureStore(
        {   reducer: rootReducer,
            preloadedState: initialState
        },
        initialState,
        enhancer
    );
};