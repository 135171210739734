import { Button, DatePicker, Select } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setFilterDate, setPrevTimeRange, resetPrevTimeRange } from "../../store/actions/filter.action";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux'
import "./filter.date.scss";
import { useLocation } from 'react-router-dom'

const { RangePicker } = DatePicker;

const DropDown = (props) => {
    return (
        <Select
            style={props.style}
            value={props.selectedValue}
            defaultValue={props.defaultValue}
            className="select-div"
            onSelect={props.handleTypeSelect}
            options={props.dropdownOptions}
        />
    );
};

const FilterDate = (props) => {

    const dispatch = useDispatch();
    const [customDateDialog, setCustomDateDialog] = useState(false);
    //fetch timerange state from sessionstorage if not available then default to today
    const [selectedTimeRange, setSelectedTimeRange] = useState(sessionStorage.getItem('selectedTimeRange') || 'Today');
    const location = useLocation()
    const prevRange = useSelector(state => state.filter.prevSelectedTimeRange);

    const ONLY_SHOW_LIVE_DATA = [
        'activity-monitor',
    ]
    const SHOW_MONTH_AND_QUARTER = [
      '/hud/qa',
      '/hud/QA',
    ]
    const isLiveDataOnly = useCallback(() => {
        return ONLY_SHOW_LIVE_DATA.some(path => location.pathname.includes(path));
    }, [location.pathname]);

    const showMonthAndQuarter = useCallback(() => {
        return SHOW_MONTH_AND_QUARTER.some(path => location.pathname.includes(path));
    }, [location.pathname]);

    useEffect(() => {
        // This effect sets the initial date range based on the current location
        if (showMonthAndQuarter()) {
            setSelectedTimeRange('This Month');
            dispatch(setFilterDate({
                maxDate: moment().endOf("month").toDate(),
                minDate: moment().startOf("month").toDate(),
                fetchInterval: 60000,
                dateRangeLabel: 'This Month'
            }));
        } else if (isLiveDataOnly()) {
            setSelectedTimeRange('Today');
            dispatch(setFilterDate({
                maxDate: moment().endOf('day').toDate(),
                minDate: moment().startOf('day').toDate(),
                fetchInterval: 60000,
                currentDateRangeLabel: 'Today',
            }));
            dispatch(setPrevTimeRange(selectedTimeRange));
        } else {
            if (prevRange) {
                handleTimeRange(prevRange);
                dispatch(resetPrevTimeRange());
            } else {
                //get selectedTimeRange from session storage
                const savedTimeRange = sessionStorage.getItem('selectedTimeRange');
                if (savedTimeRange) {
                    handleTimeRange(savedTimeRange);
                }
            }
        }
    }, [location.pathname]); // Simplify the dependency to just the path

    let timeRangeDropdownType = [
        { value: 'Today', label: 'Today' }
    ];

    if (!isLiveDataOnly()) {
        timeRangeDropdownType = timeRangeDropdownType.concat([
            { value: 'Yesterday', label: 'Yesterday' },
            { value: 'This Week', label: 'This Week' },
            { value: 'Last Week', label: 'Last Week' },
            { value: 'This Month', label: 'This Month' },
            { value: 'Last 30 Days', label: 'Last 30 Days' },
            { value: 'Last Month', label: 'Last Month' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Last Year', label: 'Last Year' },
            { value: 'Custom Date', label: 'Custom Date' }
        ]);
    }

    const monthlyRangeOptions = [
        { value: 'Last Month', label: 'Last Month' },
        { value: 'This Month', label: 'This Month' },
    ];

    const quarterlyRangeOptions = [
        { value: 'Q1', label: 'Q1 - Jan to Mar' },
        { value: 'Q2', label: 'Q2 - Apr to Jun' },
        { value: 'Q3', label: 'Q3 - Jul to Sep' },
        { value: 'Q4', label: 'Q4 - Oct to Dec' }
    ];

    if (showMonthAndQuarter()) {
        timeRangeDropdownType = [...monthlyRangeOptions, ...quarterlyRangeOptions];
    }

    const handleTimeRange = (value) => {
        setSelectedTimeRange(value);
        //store date range in session storage
        sessionStorage.setItem('selectedTimeRange', value);
        if (value === "Custom Date") {
            setCustomDateDialog(true);
        } else if (value === "Today") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("day").toDate(),
                minDate: moment().startOf("day").toDate(),
                fetchInterval: 60000,
                dateRangeLabel: value
            }));
        } else if (value === "Yesterday") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "day").endOf("day").toDate(),
                minDate: moment().subtract(1, "day").startOf("day").toDate(),
                fetchInterval: 60000, // seconds
                dateRangeLabel: value
            }));
        } else if (value === "This Week") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("isoWeek").toDate(),
                minDate: moment().startOf("isoWeek").toDate(),
                fetchInterval: 60000,
                dateRangeLabel: value
            }));
        } else if (value === "Last Week") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
                minDate: moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
                fetchInterval: 1 * 60 * 1000,
                dateRangeLabel: value
            }));
        } else if (value === "This Month") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("month").toDate(),
                minDate: moment().startOf("month").toDate(),
                fetchInterval: 60000,
                dateRangeLabel: value
            }));
        } else if (value === "Last 30 Days") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("day").toDate(),
                minDate: moment().subtract(30, "days").startOf("day").toDate(),
                fetchInterval: 60000,
                dateRangeLabel: value
            }));
        } else if (value === "Last Month") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "months").endOf("month").toDate(),
                minDate: moment().subtract(1, "months").startOf("month").toDate(),
                fetchInterval: 2 * 60 * 1000,
                dateRangeLabel: value
            }));
        } else if (value === "This Year") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("year").toDate(),
                minDate: moment().startOf("year").toDate(),
                fetchInterval: 30 * 60 * 1000,
                dateRangeLabel: value
            }));
        } else if (value === "Last Year") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "years").endOf("year").toDate(),
                minDate: moment().subtract(1, "years").startOf("year").toDate(),
                fetchInterval: 60 * 60 * 1000,
                dateRangeLabel: value
            }));
        } else if (value === 'Q1') {
            dispatch(setFilterDate({
                maxDate: moment().startOf('year').add(2, 'months').endOf('month').toDate(),
                minDate: moment().startOf('year').toDate(),
                fetchInterval: 60 * 60 * 1000,
                dateRangeLabel: 'Q1'
            }));
        } else if (value === 'Q2') {
            dispatch(setFilterDate({
                maxDate: moment().startOf('year').add(5, 'months').endOf('month').toDate(),
                minDate: moment().startOf('year').add(3, 'months').toDate(),
                fetchInterval: 60 * 60 * 1000,
                dateRangeLabel: 'Q2'
            }));
        } else if (value === 'Q3') {
            dispatch(setFilterDate({
                maxDate: moment().startOf('year').add(8, 'months').endOf('month').toDate(),
                minDate: moment().startOf('year').add(6, 'months').toDate(),
                fetchInterval: 60 * 60 * 1000,
                dateRangeLabel: 'Q3'
            }));
        } else if (value === 'Q4') {
            dispatch(setFilterDate({
                maxDate: moment().endOf('year').toDate(),
                minDate: moment().startOf('year').add(9, 'months').toDate(),
                fetchInterval: 60 * 60 * 1000,
                dateRangeLabel: 'Q4'
            }));
        }
    };

    const handleReset = () => {
        setCustomDateDialog(false);
        setSelectedTimeRange('Today');
        //remove selectedTime range from session storage when user resets custom date
        sessionStorage.removeItem('selectedTimeRange');
        dispatch(setFilterDate({
            maxDate: moment().endOf("day").toDate(),
            minDate: moment().startOf("day").toDate(),
            fetchInterval: 60000,
            dateRangeLabel: 'Today'
        }));
    };

    return !customDateDialog ? (
        <DropDown
            defaultValue={showMonthAndQuarter() ? 'This Month' : 'Today'}
            selectedValue={selectedTimeRange}
            dropdownOptions={timeRangeDropdownType}
            handleTypeSelect={(timeRange) => handleTimeRange(timeRange)}
            style={{ width: showMonthAndQuarter() ? 160 : 120 }}
        />
    ) : (
        <div className="range-picker-div">
            <RangePicker
                onChange={(x) => {
                    dispatch(setFilterDate({
                        maxDate: x[1].add(1, "days").$d,
                        minDate: x[0].$d,
                        fetchInterval: 60000,
                        dateRangeLabel: 'Custom Date'
                    }));
                }}
            />
            &nbsp;
            <Button
                className="done-btn-div"
                type="primary"
                onClick={handleReset}
            >
                Reset
            </Button>
        </div>
    );
}

export default FilterDate;
