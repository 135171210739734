import { useCallback, useEffect, useRef, useState } from 'react';

const useIntervalAsync = (fn, ms) => {
  const running = useRef(false);
  const timeoutId = useRef();
  const mountedRef = useRef(false);

  const run = useCallback(async () => {
    if (mountedRef.current && !running.current) {
      running.current = true;
      await fn();
      running.current = false;
      scheduleNext();
    }
  }, [fn]);

  const scheduleNext = useCallback(() => {
    if (mountedRef.current) {
      timeoutId.current = setTimeout(run, ms);
    }
  }, [run, ms]);

  useEffect(() => {
    mountedRef.current = true;
    scheduleNext();

    return () => {
      mountedRef.current = false;
      clearTimeout(timeoutId.current);
    };
  }, [scheduleNext]);

  const flush = useCallback(() => {
 
    clearTimeout(timeoutId.current);
    run();
  }, [run]);

  return flush;
};

export default useIntervalAsync;
