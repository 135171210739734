import { Table } from 'antd'
import _ from 'lodash'

const IntakeQuestions = ({ record }) => {
  const dataSource =
    record?.CompanyId == 1506
      ? (record?.intakeQuestionsNyu && record?.intakeQuestionsNyu[0])
      : record?.intakeQuestions?.reduce((acc, obj) => {
          acc[obj.Name] = obj.Value
          return acc
        }, {})

  const columns = Object.keys(dataSource || {}).map((e) => ({
    title: _.startCase(_.toLower(e)),
    dataIndex: e
  }))

  console.log(columns)

  return (
    (columns.length > 0) && (
      <Table
        title={() => <b>Intake Questions</b>}
        dataSource={[dataSource]}
        columns={columns}
        size="small"
        pagination={false}
      ></Table>
    )
  )
}

export default IntakeQuestions
