import { useMemo } from 'react'
import { useHud } from '../store/hud.context'
import { theme } from 'antd'
import ReactECharts from 'echarts-for-react'

const WidgetFiveGraph = ({ handleBarClick }) => {
  const { interpreters, scores } = useHud()
  const {
    token: { colorText }
  } = theme.useToken()

  const LABELS = ['NI', 'LME', 'ME', 'EE']

  const scoreCounts = useMemo(() => {
    const scoreLabelCount = {
      NI: 0,
      LME: 0,
      ME: 0,
      EE: 0
    }
    interpreters.forEach((terp) => {
      const score = scores[terp.InterpreterId]
      if (score) {
        scoreLabelCount[score?.scoreLabel]++
      }
    })
    return scoreLabelCount
  }, [interpreters, scores])

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {top: 25, bottom: 35, right: 20},
    xAxis: {
      type: 'category',
      data: LABELS,
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14
        }
      }
    },

    series: [
      {
        name: 'Number of Interpreters',
        type: 'bar',
        data: [
          { value: scoreCounts.NI, itemStyle: { color: '#dc4446' } },
          { value: scoreCounts.LME, itemStyle: { color: '#6c40a6' } },
          { value: scoreCounts.ME, itemStyle: { color: '#6c40a6' } },
          { value: scoreCounts.EE, itemStyle: { color: '#6c40a6' } }
        ],
        barWidth: '95%',
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5, 0, 0]
          }
        }
      }
    ]
  }

  return (
    <ReactECharts
      option={options}
      style={{ height: 'calc(100% - 39px)' }}
      onEvents={{
        click: handleBarClick
      }}
    />
  )
}

export default WidgetFiveGraph
