import { Button, Card, Col, Form, Input, Row, message } from "antd";
import { useRef, useState } from "react";
import dots from '../../images/dlogo1.png';
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleFilled, CloseCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { resetPassword } from "../../store/actions/profile.action";
import { useDispatch } from "react-redux";
import Validator from "../../utils/validator";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = useParams();

    const token = params.token;

    const renderCriterias = () => {
        return Validator.PasswordRules.map((criteria) => {
            return (
                <Row style={{paddingLeft: 11}} gutter={10}>
                    <Col>
                        {
                            password.trim() == '' ? <MinusCircleFilled /> : 
                            password.match(criteria.rule) ? 
                            <CheckCircleFilled style={{color: '#49aa19'}}/> : 
                            <CloseCircleFilled style={{color: '#dc4446'}}/>
                        }
                    </Col>
                    <Col>{criteria.name}</Col>
                </Row>
            )
        });
    }

    const handleFormSubmit = async (values) => {
        values.token = token;
        let flag = await dispatch(resetPassword(values));
        if (flag) {
            navigate("/auth/login");
        }
    }

    return (
        <div className="login-container">
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{width: 500, padding: 20}}>
                <Card bordered={false}>
                    <Row gutter={20} className="title-row" justify={"center"} align={"middle"} >
                        <img src={dots} height={24}/>
                        <Col>VIP Admin</Col>
                    </Row>
                    <br/>
                    <Form onFinish={handleFormSubmit} style={{width: '100%'}}>
                    <Row align={"stretch"}>
                        <Col flex={"auto"} >
                            <Form.Item
                                name="password"
                                rules={[{ 
                                        required: true, 
                                        message: 'Please input your password!' 
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || Validator.validatePassowrd(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Password entry does not meet criteria'));
                                        },
                                    })
                                ]}
                            >
                                <Input.Password onChange={((e) => setPassword(e.target.value))} placeholder="Password" />
                            </Form.Item>
                            
                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                            <Col>{renderCriterias()}</Col>
                            <br/>
                            <Row justify={"center"}>
                                <Form.Item>
                                    <Button style={{width: 180}} shape="round" type="primary" htmlType="submit" >Reset Password</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    </Form>
                </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ResetPassword;