import React, { useState } from 'react'
import { Modal, Table, Tabs } from 'antd'
import GetColumnSearchProps from '../../../components/table/search'
import moment from 'moment';
const { TabPane } = Tabs;

const RatingMap = {
  "NI": { color: '#d2facb' },
  "LME": { color: '#203870' },
  "ME": {color: '#54c9e8' },
  "EE": { color: '#ff876f' },
}

const StatusTag = ({ qaStatus }) => {
  let backgroundColor
  if (qaStatus === 'Completed') {
    backgroundColor = 'lightgreen'
  } else if (qaStatus === 'In Progress') {
    backgroundColor = 'yellow'
  } else if (qaStatus === 'New') {
    backgroundColor = 'lightblue'
  }
  return (
    <div style={{ textAlign: 'center', width: '100px' }}>
      <span
        style={{
          backgroundColor,
          padding: '2px 4px',
          borderRadius: '4px',
          color: '#000',
          fontWeight: 'bold'
        }}
      >
        {qaStatus}
      </span>
    </div>
  )
}

const InnerExpandedRowRender = ({ record }) => {
  const innerData = [record]

  const innerColumns = [
    {
      title: 'Monitoring Start Time',
      dataIndex: 'monitorStartTime',
      key: 'monitorStartTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Monitoring End Time',
      dataIndex: 'monitorEndTime',
      key: 'monitorEndTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation Submit Time',
      dataIndex: 'evaluationSubmitTime',
      key: 'evaluationSubmitTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  ]

  return (
    <Table
      key={record?.requestId + '_sub'}
      dataSource={innerData}
      columns={innerColumns}
      pagination={false}
    />
  )
}

const ExpandedRowRender = ({ record }) => {
  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'requestId',
      ...GetColumnSearchProps({ dataIndex: 'requestId' }),
      sorter: (a, b) => a?.requestId - b?.requestId
    },
    {
      title: 'QA Name',
      dataIndex: 'qaSpecialistName',
      ...GetColumnSearchProps({ dataIndex: 'qaSpecialistName' }),
      sorter: (a, b) => a?.qaSpecialistName.localeCompare(b?.qaSpecialistName)
    },
    {
      title: 'Score',
      dataIndex: 'score',
      sorter: (a, b) => a?.score - b?.score
    },
    {
      title: 'Eval Minutes',
      dataIndex: 'totalEvaluationSeconds',
      render: (text) => (text ? Math.ceil(text / 60) : 0),
      sorter: (a, b) => a?.totalEvaluationSeconds - b?.totalEvaluationSeconds
    },
    {
      title: 'Monitor Minutes',
      dataIndex: 'totalMonitorSeconds',
      render: (text) => (text ? Math.ceil(text / 60) : 0),
      sorter: (a, b) => a?.totalMonitorSeconds - b?.totalMonitorSeconds
    }
  ]
  return (
    <Table
      key={record?.requestId + '_sub'}
      rowKey={(record) => `${record?.requestId}-${record?.monitorStartTime}`}
      title={() => <b>Monitor History</b>}
      size="small"
      expandable={{
        expandedRowRender: (innerRecord) => (
          <InnerExpandedRowRender record={innerRecord} />
        )
      }}
      className="expanded-table"
      columns={columns}
      dataSource={record}
      pagination={false}
    />
  )
}

const WidgetThreeExpanded = ({ expand, dataSource, close }) => {
  const [activeTabKey, setActiveTabKey] = useState('All');
  const countByStatus = (status) => {
    if (status === 'All') return dataSource.length
    return dataSource.filter((item) => item.qaStatus === status).length
  }

  const filterDataByStatus = (status) => {
    if (status === 'All') return dataSource
    return dataSource.filter((item) => item.qaStatus === status)
  }

  const tabs = [
    { key: 'All', label: 'All', count: countByStatus('All') },
    { key: 'Completed', label: 'Completed', count: countByStatus('Completed') },
    {
      key: 'In Progress',
      label: 'In Progress',
      count: countByStatus('In Progress')
    }
  ]

  const columns = [
    {
      title: 'Interpreter Name',
      dataIndex: 'interpreterName',
      key: 'interpreterName',
      ...GetColumnSearchProps({ dataIndex: 'interpreterName' })
    },
    {
      title: 'Interpreter ID',
      dataIndex: 'interpreterId',
      key: 'interpreterId',
      sorter: (a, b) => a?.interpreterId - b?.interpreterId,
      ...GetColumnSearchProps({ dataIndex: 'interpreterId' })
    },
    {
      title: 'Score',
      dataIndex: 'scoreLabel',
      key: 'scoreLabel',
      sorter: (a, b) => a?.score - b?.score,
      filters: [
        { text: 'NI', value: 'NI' },
        { text: 'LME', value: 'LME' },
        { text: 'ME', value: 'ME' },
        { text: 'EE', value: 'EE' }
      ],
      onFilter: (value, record) => record.scoreLabel === value,
      render: (text, record) => {
        const { color } = RatingMap[record.scoreLabel] || { color: '#74a8cf' }
        return (
          <span
            style={{
              backgroundColor: color,
              padding: '2px 4px',
              borderRadius: '4px',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            {record.scoreLabel} - {Math.round(record.score)}
          </span>
        )
      }
    },
    {
      title: 'Sessions Monitored',
      dataIndex: 'sessionsMonitored',
      key: 'sessionsMonitored',
      sorter: (a, b) => {
        const aValue = a?.sessionsMonitored
          ? parseFloat(String(a?.sessionsMonitored).replace(/,/g, ''))
          : 0
        const bValue = b?.sessionsMonitored
          ? parseFloat(String(b?.sessionsMonitored).replace(/,/g, ''))
          : 0
        return aValue - bValue
      }
    },
    {
      title: 'Minutes Monitored',
      dataIndex: 'totalMinutes',
      key: 'totalMinutes',
      sorter: (a, b) => {
        const aValue = a?.totalMinutes
          ? parseFloat(String(a?.totalMinutes).replace(/,/g, ''))
          : 0
        const bValue = b?.totalMinutes
          ? parseFloat(String(b?.totalMinutes).replace(/,/g, ''))
          : 0
        return aValue - bValue
      },
      render: (text) => (text ? Math.ceil(text) : 0)
    },
    {
      title: 'QA Status',
      dataIndex: 'qaStatus',
      key: 'qaStatus',
      sorter: (a, b) => a?.qaStatus.localeCompare(b?.qaStatus),
      render: (qaStatus) => <StatusTag qaStatus={qaStatus} />
    }
  ]

  return (
    <Modal
      title="QA'd Interpreter Details"
      visible={expand}
      onOk={close}
      onCancel={close}
      width="80%"
    >
      <Tabs defaultActiveKey="1" type="card" onChang={(key) => setActiveTabKey(key)}>
        {tabs.map((tab) => (
          <TabPane tab={`${tab.label} (${tab.count})`} key={tab.key}>
            <Table
              dataSource={filterDataByStatus(tab.key)}
              columns={columns}
              rowKey={(record) => record?.interpreterId}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandedRowRender record={record.monitorHistory} />
                ),
                defaultExpandAllRows: false
              }}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100']
              }}
              id="qad-table"
            />
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  )
}

export default WidgetThreeExpanded
