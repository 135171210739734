import { Outlet } from "react-router-dom";
import { Col, Layout, Row, Space } from "antd";
import { SideMenu, Header } from "../../components/layout";
import "./layout.scss";
import dots from "../../images/dlogo1.png";
import { useEffect, useState } from "react";
import Spinner from "../../components/spinner";

const { Content, Footer } = Layout;

const Main = () => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Layout
            hasSider
            style={{
                minHeight: "100vh",
            }}
        >
            <Spinner />
            <SideMenu {...{ collapsed, setCollapsed }} />
            <Layout className="site-layout">
                <Header {...{ collapsed, setCollapsed }} />
                <Content className="content-container">
                    <Outlet />
                </Content>
                <Footer
                    className="footer-container"
                    style={{ textAlign: "center" }}
                >
                    <Row justify={"center"} align={"middle"} gutter={5}>
                        <Col>
                            <img
                                style={{ marginTop: 5 }}
                                alt="logo"
                                src={dots}
                                height={14}
                            />
                        </Col>
                        <Col>Voyce.</Col>
                        <Col>
                            <span>© {new Date().getFullYear()} </span>
                        </Col>
                        <Col>All rights reserved.</Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    );
};
export default Main;
