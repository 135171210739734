import ActionType from "../action.types";
import Service from "../../services";
import jwtDecode from "jwt-decode";
import { message } from "antd";
import { store } from "../..";

export function setUserList(userList) {
    return {
        type: ActionType.GET_USER_LIST,
        userList: userList,
    };
}

export function setTerpList(terpList) {
    return {
        type: ActionType.GET_TERP_LIST,
        terpList: terpList,
    };
}

export function deleteSuccess(bool, message) {
    return {
        type: ActionType.USER_DELETE_SUCCESS,
        hasError: bool,
        message,
        isLoading: false,
    };
}

export function deleteError(bool, message) {
    return {
        type: ActionType.USER_DELETE_ERROR,
        hasError: bool,
        message,
        isLoading: false,
    };
}

export function updateUser(userList) {
    return {
        type: ActionType.UPDATE_USER,
        userList,
    };
}

/**
/**
 * Signup action for creating the user.
 * @param {*} userObj
 * @returns 
 */
export function addUser(userObj) {
    return async (dispatch) => {
        try {
            userObj.designation = "portal-signin";
            userObj.name = `${userObj.firstname} ${userObj.lastname}`;
            userObj.comment = "Create using VIP Admin portal";
            delete userObj.firstname;
            delete userObj.lastname;
            delete userObj.confirm;
            const newUserObj = {
                ...userObj,
                selectedCompanies: userObj.selectedCompanyObjects,
            };
            delete newUserObj.selectedCompanyObjects;
            let data = await Service.Auth().signup(newUserObj);
            if (data.statusCode === 400) {
                message.error(data.message);
                return;
            }
            let state = store.getState();
            let users = JSON.parse(
                JSON.stringify(state.userManagement.userList)
            );
            dispatch(setUserList([...users, newUserObj]));

            return message.success(data.message);

            // return true;
        } catch (err) {
            // let msg = err?.response?.data?.error?.message;
            let msg =
                err?.response?.data?.message ||
                err?.response?.data?.error?.message;

            msg && message.error(msg);
        }
    };
}

export function getUserList(role) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().getUsers(role);
            dispatch(setUserList(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export function getTerpList(role) {
    return async (dispatch) => {
        try {
            let data = await Service.Terp().getAllTerpUserManagement(role);
            dispatch(setTerpList(data));
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export function updateUserInfo(values) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().updateUserInfo(values);
            if (data) {
                let state = store.getState();
                let users = JSON.parse(
                    JSON.stringify(state.userManagement.userList)
                );
                // let updatedList = users.map((user) =>
                //     user.email === values.email ? {...user, ...values} : user
                // );
                let updatedList = users.map((user) => {
                    if (user.email === values.email) {
                        if (values.firstname && values.lastname) {
                            values.name =
                                values.firstname + " " + values.lastname;
                        }
                        const updatedUser = { ...user, ...values, selectedCompanies: values.selectedCompanyObjects };
                        return updatedUser;
                    }
                    return user;
                });
                dispatch(updateUser(updatedList));
                // message.success("Information has been successfully updated");
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

export function deleteUser(email) {
    return async (dispatch) => {
        try {
            let message = await Service.Auth().deleteUser(email);
            let state = store.getState();
            // copy userlist
            let users = JSON.parse(
                JSON.stringify(state.userManagement.userList)
            );
            // new user list filtered
            dispatch(setUserList(users.filter((user) => user.email !== email)));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(deleteError(true, msg));
        }
    };
}
