import { THEME_TYPES } from '../../components/layout/theme.button';
import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different profile action types
*/

export default function profile(state = {isLoading: false, token: null, sessionId: null, user: {}, theme: THEME_TYPES.DARK}, action) {
    switch (action.type) {
        case ActionType.LOGIN_ERROR:
            return {...state, error: action.hasError, message: action.message, isLoading: action.isLoading };
        case ActionType.OPERATION_INPROGRESS:
            return { ...state, isLoading: action.isLoading };
        case ActionType.LOGIN_SUCCESS:
            return {...state, token: action.token, isLoading: action.isLoading, user: action.user};
        case ActionType.CHANGE_THEME:
            return {...state, theme: action.theme};
        case ActionType.LOGOUT:
            return {...state, token: action.token, isLoading: action.isLoading, user: action.user, theme: THEME_TYPES.DARK };
        // Handles theme change action
        case ActionType.THEME:
            return {...state, theme: action.theme}
        default:

            return state;
    }
}
