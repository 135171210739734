import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Typography,
    Spin,
    Radio,
    TimePicker,
} from "antd";
import { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Service from "../../services";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
const PAGE_SIZE = 200;

const CustomFilter = ({
    dataIndex,
    confirm,
    clearFilters,
    setSelectedKeys,
    close,
    showTimeRange,
    timeZone,
    updateFilters,
    isLive,
    allStatus,
    id
}) => {
    const state = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [debounceLoader, setDebounceLoder] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filterOptions, setFilterOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        setFilterOptions([]);
        setPage(0);
        fetchFilterOptions(searchText);
    }, [state.filter.date?.minDate, state.filter.date?.maxDate]);

    const fetchFilterOptions = useCallback(
        debounce(async (search, loadMore = false) => {
            if (showTimeRange) return;
            setLoading(true);
            if (!loadMore) {
                setDebounceLoder(true);
            }
            const filter = {
                start: state.filter.date?.minDate,
                end: state.filter.date?.maxDate,
                languageType: state.filter?.languageType,
                filters: { [dataIndex]: search },
                page: loadMore ? page * PAGE_SIZE : 0,
                pageSize: PAGE_SIZE,
                isLive: isLive,
                allStatus: allStatus
            };

            const newData = await Service.Transaction().getFilterItems(
                filter,
                true
            );

            if (newData && newData.length > 0) {
                setFilterOptions((prev) =>
                    loadMore ? [...prev, ...newData] : newData
                );
                setPage((prev) => prev + 1);
                setHasMore(newData.length === filter.pageSize);
            } else {
                if (!loadMore) {
                    setFilterOptions([]);
                }
                setHasMore(false);
            }
            setDebounceLoder(false);
            setLoading(false);
        }, 2000),
        [dataIndex, page, state.filter, filterOptions]
    );

    const handleSearch = (value) => {
        setSearchText(value);
        setPage(0);
        fetchFilterOptions(value);
        // confirm();
        if (showTimeRange) {
            setSelectedKeys(selectedOption);
            updateFilters(dataIndex, selectedOption);
            confirm();
        }
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        setSelectedKeys([value]);
        updateFilters(dataIndex, value);
        confirm();
    };

    const handleReset = () => {
        setSelectedOption(undefined);
        updateFilters(dataIndex, undefined);
        setSearchText("");
        setFilterOptions([]);
        setPage(0);
        setHasMore(true);
        fetchFilterOptions("");
        clearFilters();
        confirm();
        close();
    };
    return (
        <Card id={id}>
            <Row>
                <Col flex="auto">
                    {showTimeRange ? (
                        <TimePicker.RangePicker
                            value={
                                selectedOption?.length
                                ? [moment(selectedOption[0]), moment(selectedOption[1])]
                                : undefined
                            }
                            placeholder={['Start Time', 'End Time']}
                            onChange={(timeRange) => {
                            let period = [];
                            if (timeRange && timeRange[0] && timeRange[1]) {
                                let currentZone = timeZone;
                                let start = moment.tz(timeRange[0].format("YYYY-MM-DDTHH:mm:ss"),currentZone);
                                let end = moment.tz(timeRange[1].format("YYYY-MM-DDTHH:mm:ss"), currentZone);

                                period[0] = start.utc().format();
                                period[1] = end.utc().format();
                            
                            }
                            setSelectedOption(timeRange ? period : []);
                            }}
                           
                            style={{
                            marginBottom: 8,
                            display: 'flex',
                            }}
                        />
                    ) : (
                        <>
                            <Input
                                style={{ marginBottom: 10 }}
                                placeholder={`Search ${dataIndex}`}
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {debounceLoader ? (
                                <h4
                                    style={{
                                        textAlign: "center",
                                        opacity: 0.5,
                                        height: "163px",
                                    }}
                                >
                                    <Spin />
                                    Loading...
                                </h4>
                            ) : (
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        overflow: "auto",
                                        height: "200px",
                                    }}
                                >
                                    <InfiniteScroll
                                        scrollableTarget="scrollableDiv"
                                        dataLength={filterOptions.length}
                                        next={() =>
                                            fetchFilterOptions(searchText, true)
                                        }
                                        hasMore={hasMore}
                                        loader={
                                            <h4
                                                style={{
                                                    textAlign: "center",
                                                    opacity: 0.5,
                                                }}
                                            >
                                                <Spin />
                                                Loading...
                                            </h4>
                                        }
                                        height={200}
                                        endMessage={
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    opacity: 0.5,
                                                }}
                                            >
                                                <b>-- END --</b>
                                            </p>
                                        }
                                    >
                                        <Radio.Group
                                            onChange={(e) =>
                                                handleOptionClick(
                                                    e.target.value
                                                )
                                            }
                                            value={selectedOption}
                                            className="radio-filter"
                                        >
                                            {filterOptions
                                                .filter(item => {
                                                    const value = item[dataIndex];
                                                    return value != null && (typeof value === 'string' ? value.length > 0 : true);
                                                })

                                                .map((item, index) => (
                                                    <Radio
                                                        value={item[dataIndex]}
                                                        key={index}
                                                    >
                                                        <Typography.Text strong>
                                                            {item[dataIndex]}
                                                        </Typography.Text>
                                                    </Radio>
                                                ))}
                                        </Radio.Group>
                                    </InfiniteScroll>
                                </div>
                            )}
                        </>
                    )}
                    <Row style={{ paddingTop: 10 }} gutter={10}>
                        <Col flex="auto">
                            <Button
                                icon={<SearchOutlined />}
                                block
                                type="primary"
                                onClick={() => handleSearch(searchText)}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col flex="auto">
                            <Button block onClick={() => handleReset()}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default CustomFilter;
