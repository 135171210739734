import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';

export function setCompanies(companies) {
    return {
        type: ActionType.SET_COMPANIES,
        companies
    };
}

export function selectCompany(selected, defaultClient) {
    return {
        type: ActionType.SELECT_COMPANY,
        selected,
        client: defaultClient
    };
}

export function selectClient(client) {
    return {
        type: ActionType.SELECT_CLIENT,
        client
    };
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getActiveCompanies() {
    return async (dispatch) => {
        try {
            let data = await Service.Company().allActive();
            dispatch(setCompanies(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
