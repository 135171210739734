import { Progress, Tooltip, Typography } from 'antd'
import { getColorFromPercentage } from '../../../utils/Colours'
import './widget.two.scss';

const progressCircleStyle = {
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',
  // textAlign: 'center',
  // lineHeight: '1.2', // Adjust line height to control spacing between lines
  // fontSize: '14px', // Adjust font size as needed
  // backgroundColor: '#dc4446'
  padding: 5,
  paddingLeft: 5,
  border: '1px solid white',
  cursor: 'pointer'
}

const WidgetTwoProgress = ({ current, total, type, handleClick, style }) => {
  const percentage = type === 'Monitoring' ? 100 : (current / total) * 100
  // return (
  //   <Tooltip
  //     title={type === 'Monitoring' ? '' : `${Math.round(percentage)}%`}
  //     placement="top"
  //   >
  //     <Progress
  //       type="circle"
  //       percent={percentage}
  //       width={type === 'Monitoring' ? 125 : 115}
  //       format={() => (
  // <div
  //   style={progressCircleStyle}
  //   onClick={() => handleClick(type)}
  // >
  //   <h3 style={{ textAlign: 'center', fontSize: '0.9rem' }}>
  //     <b>
  //       {type}
  //     </b>
  //   </h3>
  //   <h2
  //     style={{ textAlign: 'center', margin: 0, fontSize: '1rem' }}
  //   >{`${current}`}</h2>
  //   {type !== 'Monitoring' && (
  //     <h2
  //       style={{
  //         textAlign: 'center',
  //         margin: 0,
  //         textDecoration: 'overline',
  //         fontSize: '1rem'
  //       }}
  //     >{`${total}`}</h2>
  //   )}
  // </div>
  //       )}
  //     />
  //   </Tooltip>
  // )

  // return (
  //   <div style={{...progressCircleStyle, backgroundColor: getColorFromPercentage(percentage)}} onClick={() => handleClick(type)}>
  //     <Typography.Text strong style={{ textAlign: 'center', fontSize: '0.9rem' }}>
  //     {type}
  //     </Typography.Text>
  //     <Typography.Text style={{ textAlign: 'center', margin: 0, fontSize: '1.7rem' }}>
  //     {`${current}`}
  //     </Typography.Text>
  //     {type !== 'Monitoring' && (
  //       <Typography.Text  style={{
  //         textAlign: 'center',
  //         margin: 0,
  //         textDecoration: 'overline',
  //         fontSize: '1.7rem'
  //       }}>{`${total}`}
  //       </Typography.Text>
  //     )}
  //   </div>
  // )

  return (
    <div
      className="WdigetTwoProgress"
      style={{
        ...progressCircleStyle,
        ...(style || {}),
        backgroundColor: getColorFromPercentage(percentage)
      }}
      onClick={() => handleClick(type)}
    >
      <Typography.Text strong>{type}</Typography.Text>
      <br />
      <Typography.Text
        style={{
          textAlign: 'center',
          margin: 0,
          paddingLeft: 70,
          fontSize: '2.9rem'
        }}
      >
        {type == 'Monitoring' ? current : `${Math.round(percentage || 0)}%`}
      </Typography.Text>
      <br />
      <Typography.Text
        style={{ textAlign: 'center', margin: 0, fontSize: '1rem' }}
      >
        {type !== 'Monitoring' && `${current}/${total}`}
      </Typography.Text>
    </div>
  )
}

export default WidgetTwoProgress
