import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different Company action types
*/

export default function invoice(state = {
        analytics: {
            paid: {
                count: 0,
                totalAmt: 0
            },
            pending: {
                count: 0,
                totalAmt: 0
            },
            unpaid: {
                count: 0,
                totalAmt: 0
            }
        },
        scatter: [],
        bar: [],
        invoices: [],
        rawData: []
    }, action) {
    switch (action.type) {
        case ActionType.SET_INVOICE_ANALYTICS:
            return {...state, analytics: action.analytics};
        case ActionType.SET_COOKED_INVOICES:
            return {...state, scatter: action.scatter, bar: action.bar, invoices: action.invoices, rawData: action.rawData};
        case ActionType.SET_INVOICES:
            return {...state, invoices: action.invoices};
        default:
            return state;
    }
}
