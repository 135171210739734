import { Card, Col, Divider, Row, Spin, Typography, theme } from 'antd'
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getNSCByHoursMinutes } from '../../../store/actions/analytics.action'
import { useSelector } from 'react-redux'

const HeatMap = () => {
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const state = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            let data = await dispatch(getNSCByHoursMinutes())
            setDataSource(data || [])
            setLoading(false)
        }

        fetchData()
    }, [state.filter?.date, state.company?.selected, state.company?.client])

    const {
        token: { colorText, colorBgLayout }
    } = theme.useToken()

    const colors = ['#74a8cf', '#d2facb', '#54c9e8', '#203870', '#ff876f']

    const hours = [
        '12a',
        '1a',
        '2a',
        '3a',
        '4a',
        '5a',
        '6a',
        '7a',
        '8a',
        '9a',
        '10a',
        '11a',
        '12p',
        '1p',
        '2p',
        '3p',
        '4p',
        '5p',
        '6p',
        '7p',
        '8p',
        '9p',
        '10p',
        '11p'
    ]

    const languages = dataSource.map((e) =>
        e?.languageName?.includes('ASL') ? 'ASL' : e?.languageName
    )
    const data = []

    for (let i = 0; i < 10; i++) {
        let lang_index = i
        for (let j = 0; j < 24; j++) {
            let hour_index = j
            let hour = dataSource[i]?.hours?.find((e) => e?.hour == hour_index)
            data.push([hour_index, lang_index, hour ? hour.totalCanceled : 0])
        }
    }

    const options = {
        tooltip: {
            position: 'top'
        },
        grid: {
            height: '90%',
            top: '20',
            right: '1%',
            left: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: hours,
            splitArea: {
                show: true
            },
            position: 'top',
            axisLabel: {
                textStyle: {
                    color: colorText,
                    fontSize: 14
                }
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'category',
            data: languages,
            splitArea: {
                show: true
            },
            position: 'right',
            axisLabel: {
                textStyle: {
                    color: colorText,
                    fontSize: 14
                },
                interval: 0
            },
            axisTick: {
                show: false
            }
        },
        visualMap: {
            min: 0,
            max: 10,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            show: false,
            inRange: {
                color: colors
            }
        },
        series: [
            {
                name: 'Non serviced calls',
                type: 'heatmap',
                data: data,
                label: {
                    normal: {
                        show: false,
                        formatter: (param) => {
                            if (param.data[2] === 0) {
                                return ''
                            }
                        }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    normal: {
                        borderColor: '#000',
                        borderWidth: 4,
                        borderCap: 'round',
                        borderRadius: 6
                    }
                },
                progressive: 1000
            }
        ]
    }

    return (
        <Spin spinning={loading}>
            <Card
                style={{
                    width: '100%',
                    borderRadius: 4,
                    backgroundColor: colorBgLayout
                }}
                bodyStyle={{ padding: '5' }}
            >
                <EChartsReact style={{ height: 200 }} option={options} />
                <Row
                    style={{ marginTop: 10, marginLeft: 8 }}
                    align={'middle'}
                    justify={'start'}
                    gutter={10}
                >
                    <Col>
                        <Typography.Text strong>Less</Typography.Text>
                    </Col>
                    <Col
                        style={{
                            backgroundColor: colors[0],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[1],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[2],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[3],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[4],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col>
                        <Typography.Text strong>More</Typography.Text>
                    </Col>
                    <Divider type={'vertical'} />
                    <Col>
                        <Typography.Text strong>
                            Canceled calls by hour (EST)
                        </Typography.Text>
                    </Col>
                    {/* <Divider type={"vertical"} />
                    <Col flex={"auto"}><Typography.Text strong italic> ⚠️ click to show minutes</Typography.Text></Col> */}
                </Row>
            </Card>
        </Spin>
    )
}

export default HeatMap
