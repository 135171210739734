import { Axios } from "axios";
import { filter } from "lodash";
import constants from "../constants";

class AnalyticsService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async getTotalAggData(filter, hideLoader, signal) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/total', filter,
                {filter: true, hideLoader, signal}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getTopLanguages(filter) {
        try {
            let response = await this.request.post(
                '/agg-by-client/hour/language', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getLanguagesTotal(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/detail/total', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getCallsByHours(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/hour/hourly/language/', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getNSCByHourMinutes(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/detail-transactions/non-serviced-calls', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getNSCTotal(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/non-serviced-calls', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getActiveDevices(filter) {
        try {
            let response = await this.request.post(
                '/active-devices', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getTerpPositionCount(filter, signal) {
        try {
            let response = await this.request.post(
                '/terp-position/language/by-date/total', filter,
                {filter: true, signal}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getPreferredVideoChart(filter) {
        try {
            let response = await this.request.post(
                '/detail-transactions/preferred-video/count', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getAVRChartCount(filter) {
        try {
            let response = await this.request.post(
                '/detail-transactions/audio-video-request/by-date', filter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    //API call to get device usage data
    async getTotalActiveDevices(filter, body = {}) {
        try {
          const response = await this.request.post('/get-all-devices', body, {
            filter: true,
            params: {
              start: filter?.start?.toISOString(),
              end: filter?.end?.toISOString(),
            },
          });
          return response.data;
        } catch (err) {
          console.log(err);
          throw err;
        }
      }

       //function to call api for call by hour data for heatmap chart
    async getCallsByHoursLang(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/hour/hourly/language', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
    
    // function to get star rating
    async getStarRatingList(filter, hideLoader, signal) {
        console.log("star rating ", filter);
        try {
            const response = await this.request.post('/star-rating/by-date', filter, {
                filter: true,
                hideLoader,
                signal
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }  
    async getTopCustomer(filter, hideLoader, signal) {
        try {
            const response = await this.request.post('/detail-transactions/top-customer', filter, {
                filter: true,
                hideLoader,
                signal
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    async getCustomerMonthly(filter, hideLoader, signal) {
        console.log("getCustomerMonthly filter ", filter);
        try {
            const response = await this.request.post('/detail-transactions/top-customer/company/monthly', filter, {
                ...filter,
                // filter: true,
                hideLoader,
                signal
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}




export default AnalyticsService;

