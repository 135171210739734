import _ from 'lodash'
import { SearchOutlined } from "@ant-design/icons";

const LocalCustomFilter = ({
  dataIndex,
  dataSource,
  emptySearch,
  emptySearchName
}) => {
  const NO_DATA_SPECIAL_VALUE = 'NO_DATA'
  const EMPTY_FIELDS = []
  if (emptySearch) {
    EMPTY_FIELDS.push({
      text: emptySearchName ? emptySearchName : 'No Data',
      value: NO_DATA_SPECIAL_VALUE // Special value to indicate no data
    })
  }

  let filters = [
    ..._.uniqBy(
      dataSource?.map((e) => ({
        text: e[dataIndex],
        value: e[dataIndex]
      })) || [],
      'text'
    ),
    ...EMPTY_FIELDS
  ]

  filters = _.orderBy(filters, "text")


  return {
    filters: filters,
    filterMultiple: false,
    filterSearch: true,
    onFilter: (value, record) => {
      if (value === NO_DATA_SPECIAL_VALUE) {
        return (
          !record[dataIndex] ||
          (record[dataIndex] && record[dataIndex]?.toString()?.trim() == '')
        )
      } else {
        return record[dataIndex]?.toString()?.indexOf(value) === 0
      }
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  }
}

export default LocalCustomFilter