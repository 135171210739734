import { Col, Row, theme, Typography } from 'antd'
import { useHud } from '../store/hud.context'
import WidgetThreeGraph from './widget.graph'
import './widget.three.scss'
import WidgetThreeExpanded from './widget.expanded'
import { useEffect, useState } from 'react'

const WidgetThree = () => {
  const { scores, interpreters } = useHud()
  const [expand, setExpand] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [analytics, setAnalytics] = useState({
    qaSpecialistsSize: 0,
    interpreterSize: 0
  })
  const {
    token: { colorBgBase }
  } = theme.useToken()

  useEffect(() => {
    let totalMinutesQAed = 0
    let qaSpecialistsSet = new Set()
    let interpreterSet = new Set()

    let qaDetails = []

    interpreters?.forEach((interpreter) => {
      const scoreCard = scores[interpreter.InterpreterId]
      let evaluatedSessions = 0

      if (scoreCard && scoreCard.details) {
        let totalMonitorSeconds = 0
        scoreCard?.details?.forEach((detail) => {
          if (detail.evaluationSubmitTime) {
            evaluatedSessions += 1
          }
          if (evaluatedSessions >= 2) {
            interpreterSet.add(scoreCard._id.interpreter)
          }
          totalMinutesQAed += detail.totalMonitorSeconds / 60
          totalMonitorSeconds += Math.ceil(
            (detail.totalMonitorSeconds || 0) / 60
          )
          qaSpecialistsSet.add(detail.qaSpecialistId)
        })
        qaDetails.push({
          interpreterName: interpreter.InterpreterName,
          interpreterId: scoreCard._id.interpreter,
          sessionsMonitored: scoreCard.totalQaSessions,
          totalMinutes: totalMonitorSeconds,
          score: scoreCard?.score,
          scoreLabel: scoreCard?.scoreLabel,
          qaStatus: scoreCard?.monthlyQaStatus || 'New',
          monitorHistory: scoreCard?.details
        })
      }
    })
    setDataSource(qaDetails);
    setAnalytics({
      qaSpecialistsSize: qaSpecialistsSet.size,
      interpreterSize: interpreterSet.size
    })
  }, [scores, interpreters])

  return (
    <div
      className="WidgetThreeContainer"
      style={{ backgroundColor: colorBgBase }}
    >
      <Row style={{ padding: 8, borderBottom: '1px solid #303030' }}>
        <Col>
          <Typography.Text strong>{`QA'd Interpreters`}</Typography.Text>
        </Col>
      </Row>
      <WidgetThreeGraph
        handleBarClick={() => setExpand(true)}
        dataSource={[analytics.interpreterSize, analytics.qaSpecialistsSize]}
      />
      <WidgetThreeExpanded
        expand={expand}
        dataSource={dataSource}
        close={() => setExpand(false)}
      />
    </div>
  )
}

export default WidgetThree
