import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';
import moment from 'moment';

const DATE_RANGE_LABEL_WITHOUT_LIVE_DATA = [
    'Yesterday',
    'Last Week',
    'Last Month',
    'Last Year'
]
export function setWidgets(res, liveDataFlag) {
    const aggregated = {
        avgWaitTime: 0,
        successVideoCalls: 0,
        successAudioCalls: 0,
        totalMins: 0,
        totalCalls: 0,
        totalCallServiced: 0,
        totalCallCancelled: 0,
        avgWaitTime: 0,
        avgCallLength: 0,
        toalPrice: 0,
        avgRating: 0,
        totalBackupCals: 0,
        avgBackupWaitTime: 0,
        avgWaitTimeVideoSp: 0,
        avgWaitTimeVideoLots: 0
    };

    if (!res || (res && res.length == 0)) {
        return {
            type: ActionType.SET_WIDGETS,
            widgets: aggregated
        }
    }

    const totalAmountRated = res[0].oneStar + res[0].twoStar + res[0].threeStar + res[0].fourStar + res[0].fiveStar;

    aggregated.avgWaitTime = (res[0].WaitingSeconds && res[0].TotalCalls) ? res[0].WaitingSeconds / res[0].TotalCalls : 0;
    aggregated.successVideoCalls = res[0].CountSuccessVideoCalls ? res[0].CountSuccessVideoCalls : 0;
    aggregated.successAudioCalls = res[0].CountSuccessAudioCalls ? res[0].CountSuccessAudioCalls : 0;
    aggregated.successAudioCalls = res[0].CountSuccessAudioCalls ? res[0].CountSuccessAudioCalls : 0;
    aggregated.totalMins = res[0].ServiceMinutes ? res[0].ServiceMinutes : 0;
    aggregated.totalCalls = res[0].TotalCalls ? res[0].TotalCalls : 0;
    aggregated.totalCallServiced = (res[0].CountSuccessVideoCalls || res[0].CountSuccessAudioCalls) ? res[0].CountSuccessVideoCalls + res[0].CountSuccessAudioCalls : 0;
    aggregated.totalCallCancelled = (res[0].CountFailedAudioCalls + res[0].CountFailedVideoCalls);
    aggregated.avgWaitTime = (res[0].WaitingSeconds && res[0].TotalCalls) ? res[0].WaitingSeconds / res[0].TotalCalls : 0;
    aggregated.avgCallLength = (res[0].ServiceMinutes && res[0].TotalCalls) ? res[0].ServiceMinutes / res[0].TotalCalls : 0;
    aggregated.toalPrice = res[0].TotalPrice ? res[0].TotalPrice : 0;
    aggregated.avgRating = res[0].CallQualityRatingStar / res[0].CountRatingStarCalls;
    aggregated.totalBackupCals = res[0].CountBackupCalls || 0;
    aggregated.avgBackupWaitTime = (res[0].BackupWaitingSeconds || 0) /  aggregated.totalBackupCals;
    aggregated.avgWaitTimeVideoSp = res[0]?.AvgSpanishWaitingSeconds || 0;
    aggregated.avgWaitTimeVideoLots = res[0]?.AvgLotsWaitingSeconds || 0;
    aggregated.totalInServiceCalls = liveDataFlag ? (res[0]?.CountInServiceAudioCalls || 0) + (res[0]?.CountInServiceVideoCalls || 0) : 0;
    aggregated.totalNewCalls = liveDataFlag ? (res[0]?.CountNewAudioCalls || 0) + (res[0]?.CountNewVideoCalls || 0) : 0;
    console.log("======================Total Agg Data======================\n", aggregated,
        "\n======================Raw Total Agg Data======================\n" , res[0]);

    for (let key in aggregated) {
        if (isNaN(aggregated[key])) {
            aggregated[key] = 0
        }
    }

    return {
        type: ActionType.SET_WIDGETS,
        widgets: aggregated
    };
}

export function setTop10Languages(res) {

    const legends = [];
    const legendsIdle = [];
    const seriesData = [];
    const data = [];

    res.forEach((element) => {
        legends.push(element.ISOCode)
        legendsIdle.push(element.TargetLanguage)
        seriesData.push(element.TotalCalls)
    });

    return {
        type: ActionType.SET_TOP10,
        top10Languages: { 
            legends: legends,
            legendsIdle: legendsIdle,
            seriesData: seriesData,
            data: res
        }
    }
}

export function setPreferredVideo(res) {

    const legends = [];
    const seriesData = [];
    res.sort((a,b) => b.count - a.count);
    res.forEach((element) => {
        legends.push(element._id)
        seriesData.push(element.count)
    });

    return {
        type: ActionType.SET_PREFERRED_VIDEO,
        preferredVideo: { 
            legends: legends,
            seriesData: seriesData
        }
    }
}

export function setActiveDevices(result) {
    let activeCarts = result && result.data && result.data[0] && result.data[0].myCount ? result.data[0].myCount : 0
    return {
        type: ActionType.SET_ACTIVE_CARTS,
        activeCarts
    }
}

export function setTotalActiveDevices(result) {
    return {
        type: ActionType.SET_TOTAL_ACTIVE_CARTS,
        totalActiveCarts: result // Assuming the API response is an array
    };
}

export function setTerpPositionCount(result) {
    let object = {
        position1: 0,
        position2: 0,
        position3: 0,
        position4plus: 0,
        rawData: JSON.parse(JSON.stringify(result))
    };

    for (let i = 0; i < result.length; i++ ) {
        if (result[i]._id == 1) {
            object.position1 = result[i].TotalCalls;
        } else if (result[i]._id == 2) {
            object.position2 = result[i].TotalCalls;
        } else if (result[i]._id == 3) {
            object.position3 = result[i].TotalCalls;
        } else if (result[i]._id >= 4) {
            object.position4plus = object.position4plus + result[i].TotalCalls
        }
    }

    return {
        type: ActionType.SET_TERP_POSITION_COUNT,
        terpPositionCount: object
    }
}

export function setCallsByHours(data) {
    const legends = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
    const mainSeriesData = [];

    if(data && data.length > 0){
        const barChartData_CallsByHour = {
            hoursCallsVideo :  new Array(24).fill(0),
            hoursCallsAudio :  new Array(24).fill(0),
            hoursCallsAsl   :  new Array(24).fill(0),
            hoursCallsRTB   :  new Array(24).fill(0),
        }
        
        for (let i = 0; i < data.length; i++) {      
            barChartData_CallsByHour.hoursCallsVideo[Number(data[i].Hour)] = data[i].TotalVideoCalls;
             barChartData_CallsByHour.hoursCallsAudio[Number(data[i].Hour)] = data[i].TotalAudioCalls;
             barChartData_CallsByHour.hoursCallsAsl[Number(data[i].Hour)] = data[i].TotalAslCalls;
             barChartData_CallsByHour.hoursCallsRTB[Number(data[i].Hour)] = data[i].totalRTBCall;
        }
        
        mainSeriesData.push({data: barChartData_CallsByHour.hoursCallsVideo});
        mainSeriesData.push({data: barChartData_CallsByHour.hoursCallsAudio});
        mainSeriesData.push({data: barChartData_CallsByHour.hoursCallsAsl});
        mainSeriesData.push({data: barChartData_CallsByHour.hoursCallsRTB});
    }

    return {
        type: ActionType.SET_CALLS_BY_HOURS,
        callsByHours: { 
            legends,
            seriesData: mainSeriesData
        }
    }
}

export function setStarRating(res) {
    return {
        type: ActionType.SET_STAR_RATING,
        starRating: res
    }
}

export function setTopCustomer(data) {
    return {
        type: ActionType.SET_TOP_CUSTOMER,
        topCustomer: data
    }
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getWidgetsData(hideLoader, signal) {
    return async (dispatch) => {
        try {
            console.log("called getWidgetsData")
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let noLiveDataFlag = DATE_RANGE_LABEL_WITHOUT_LIVE_DATA.includes(state?.filter?.date?.dateRangeLabel);
            let data = await Service.Analytics().getTotalAggData(filter, hideLoader, signal);
            dispatch(setWidgets(data, !noLiveDataFlag));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getTerpPositionAnalytics(signal) {
    return async (dispatch) => {
        try {
            console.log("called getTerpPositionAnalytics")
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let terpPositionCount = await Service.Analytics().getTerpPositionCount(filter, signal);
            dispatch(setTerpPositionCount(terpPositionCount));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getTop10Languages(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getTopLanguages(filter, hideLoader);
            dispatch(setTop10Languages(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getCallsByHours(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getCallsByHours(filter, hideLoader);
            dispatch(setCallsByHours(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

export function getNSCByHoursMinutes(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getNSCByHourMinutes(filter, hideLoader);
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

export function getNSCByTotal(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getNSCTotal(filter, hideLoader);
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getActiveDevices() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate
            }
            let data = await Service.Analytics().getActiveDevices(filter);
            dispatch(setActiveDevices(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

//Action to get all active devices i.e devices whose minutes can be tracked (v2 devices)
export function getTotalActiveDevices(body = {}) {
    return async (dispatch, getState) => {
      try {
        const { date } = getState().filter;
        if (!date || !date.minDate || !date.maxDate) {
          throw new Error("Both start and end parameters are required.");
        }
  
        const state = store.getState();
        const userCompany = state?.profile?.user?.permissions?.company;
        const filter = {
          start: state.filter?.date?.minDate,
          end: state.filter?.date?.maxDate,
        };
  
        if (!filter.start || !filter.end) {
          throw new Error("Invalid start or end date.");
        }
  
        // Pass the RequestCompanyId as part of the body
        const requestData = { ...body, RequestCompanyId: userCompany };
        const result = await Service.Analytics().getTotalActiveDevices(filter, requestData);
        dispatch(setTotalActiveDevices(result));
      } catch (err) {
        console.log(err);
        let msg = err?.response?.data?.error?.message || err.message;
        msg && message.error(msg);
      }
    };
  }

//Code to display preferred-video-chart
export function getPreferredVideo() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getPreferredVideoChart(filter);
            dispatch(setPreferredVideo(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

export function getCallsByHoursLanguage() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
            }
            let data = await Service.Analytics().getCallsByHoursLang(filter);
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

export function getStarRating(hideLoader, signal) {
    return async (dispatch) => {
        try {console.log("called getStarRating")

            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                
            }
            let data = await Service.Analytics().getStarRatingList(filter, hideLoader, signal);
            dispatch(setStarRating(data));
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    }
}

export function getTopCustomer(sorter, hideLoader, signal) {
    return async (dispatch) => {
        try {
            console.log("called getTopCustomer")

            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                sorter
            }
            let data = await Service.Analytics().getTopCustomer(filter, hideLoader, signal);
            dispatch(setTopCustomer(data));
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    }
}

export function getCustomerMonthly(selectedTableId, hideLoader, signal) {
    return async (dispatch) => {
        try {
            console.log("called getTopCustomer")

            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                id: state.filter?.id || selectedTableId
            }
            let data = await Service.Analytics().getCustomerMonthly(filter, hideLoader, signal);
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    }
}