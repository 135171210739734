import { theme } from 'antd';
import { useHud } from '../store/hud.context'
import ReactECharts from 'echarts-for-react'

const WidgetFourGraph = ({ handleBarClick }) => {
  const { interpreters, scores, watchList } = useHud()
  const {
    token: { colorText }
  } = theme.useToken();

  const options = {
    grid: {top: 25, bottom: 35, right: 20},
    xAxis: {
      type: 'category',
      data: ['Not Scored', 'Scored'],
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14
        }
      }
    },
    series: [
      {
        data: [
          {value: interpreters?.filter((e) => {
            const score = scores[e.InterpreterId]
            const watch = watchList[e.InterpreterId]
            return !score && watch
          })?.length, itemStyle: { color: '#226885' }},
          {value: interpreters?.filter((e) => {
            const score = scores[e.InterpreterId]
            const watch = watchList[e.InterpreterId]
            return score && watch
          })?.length, itemStyle: { color: '#6c40a6' }}
        ],
        type: 'bar',
        barWidth: '95%',
        itemStyle: {
            color: '#6c40a6',
            emphasis: {
              barBorderRadius: [5, 5]
            },
            normal: {
              barBorderRadius: [5, 5, 0 ,0 ]
            }
          }
      }
    ]
  }
  return (
    <ReactECharts
      option={options}
      style={{ height: '300px' }}
      onEvents={{
        click: handleBarClick
      }}
    />
  )
}

export default WidgetFourGraph;