import { Button, Modal, Table } from 'antd'
import moment from 'moment'
import GetColumnSearchProps from '../../../components/table/search'

const InnerExpandedRowRender = ({ record }) => {
  const innerData = [record]

  const innerColumns = [
    {
      title: 'Monitoring Start Time',
      dataIndex: 'monitorStartTime',
      key: 'monitorStartTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Monitoring End Time',
      dataIndex: 'monitorEndTime',
      key: 'monitorEndTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation Submit Time',
      dataIndex: 'evaluationSubmitTime',
      key: 'evaluationSubmitTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    {
      title: 'Evaluation End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  ]

  return (
    <Table
      key={record?.requestId + '_sub'}
      dataSource={innerData}
      columns={innerColumns}
      pagination={false}
    />
  )
}

const ExpandedRowRender = ({ record }) => {
  const data = record?.monitorHistory

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'requestId',
      ...GetColumnSearchProps({ dataIndex: 'requestId' }),
      sorter: (a, b) => {
        if (a?.requestId === undefined) return -1
        if (b?.requestId === undefined) return 1
        return a?.requestId - b?.requestId
      }
    },
    {
      title: 'Interpreter ID',
      dataIndex: 'interpreterId',
      ...GetColumnSearchProps({ dataIndex: 'interpreterId' }),
      sorter: (a, b) => a?.interpreterId - b?.interpreterId
    },
    {
      title: 'Interpreter Name',
      dataIndex: 'interpreterName',
      ...GetColumnSearchProps({ dataIndex: 'interpreterName' }),
      sorter: (a, b) => a?.interpreterName?.localeCompare(b?.interpreterName)
    },
    {
      title: 'Score',
      dataIndex: 'score',
      sorter: (a, b) => a?.score - b?.score
    },
    {
      title: 'Eval Minutes',
      dataIndex: 'totalEvaluationSeconds',
      render: (text) => (text ? Math.ceil(text / 60) : 0),
      sorter: (a, b) => {
        if (a?.totalEvaluationSeconds === undefined) return -1
        if (b?.totalEvaluationSeconds === undefined) return 1
        return (
          Number(a?.totalEvaluationSeconds) - Number(b?.totalEvaluationSeconds)
        )
      }
    },
    {
      title: 'Monitor Minutes',
      dataIndex: 'totalMonitorSeconds',
      render: (text) => (text ? Math.ceil(text / 60) : 0),
      sorter: (a, b) => {
        if (a?.totalMonitorSeconds === undefined) return -1
        if (b?.totalMonitorSeconds === undefined) return 1
        return Number(a?.totalMonitorSeconds) - Number(b?.totalMonitorSeconds)
      }
    }
  ]
  return (
    <Table
      key={record?.requestId + '_sub'}
      rowKey={(record) =>
        `${record?.requestId} - ${
          record?.monitorStartTime
            ? record?.monitorStartTime
            : record?.startTime
        }`
      }
      title={() => <b>Monitor History</b>}
      size="small"
      className="expanded-table"
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (innerRecord) => (
          <InnerExpandedRowRender record={innerRecord} />
        )
      }}
      pagination={{
        defaultPageSize: 100,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
    />
  )
}

const WidgetSixExpanded = ({ dataSource, modalVisible, handleCancel, baseColumns }) => {
  const columns = [
    {
      title: 'Interpreters Monitored',
      dataIndex: 'numInterpretersMonitored',
      key: 'numInterpretersMonitored',
      sorter: (a, b) => {
        const aValue = a.numInterpretersMonitored
          ? parseFloat(String(a.numInterpretersMonitored).replace(/,/g, ''))
          : 0
        const bValue = b.numInterpretersMonitored
          ? parseFloat(String(b.numInterpretersMonitored).replace(/,/g, ''))
          : 0
        return aValue - bValue
      },
      render: (text) => (text ? Math.ceil(text) : 0)
    }
  ]
  return (
    <Modal
      title="QA Specialist Details"
      visible={modalVisible}
      onCancel={handleCancel}
      width={'80%'} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>
      ]}
    >
      {/* Render expanded details here */}
      <Table
        dataSource={dataSource}
        columns={[...baseColumns, ...columns]}
        rowKey={(record) => record?.id}
        expandable={{
          expandedRowRender: (record) => <ExpandedRowRender record={record} />,
          defaultExpandAllRows: false
        }}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
        scroll={{
          x: 800
        }}
        size="small"
        id="qa-analysis-table-expanded"
      />
    </Modal>
  )
}

export default WidgetSixExpanded
