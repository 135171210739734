import { Card, Col, FloatButton, Row, theme, Progress } from "antd";
import './dashboard.scss';
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveDevices, getTerpPositionAnalytics, getWidgetsData, getStarRating } from "../../store/actions/analytics.action";
import TopLanguagesChart, { MemoizedTopLanguagesChart } from "./top.10.chart";
import CallsHourChart, { MemoizedCallsHourChart } from "./calls.hour.chart";
import Formatter from "../../utils/Formatter";
import { useNavigate } from "react-router-dom";
import Service from "../../services";
import constants from "../../constants";
import { setLanguageType } from "../../store/actions/filter.action";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Square from "./draggable/box.droppable";
import SquareDrag from "./draggable/box.draggable";
import { CloseCircleFilled, CloseOutlined, EditFilled, SaveFilled } from "@ant-design/icons";
import useIntervalAsync from "../../components/hooks/useInterval";
import { getTotalActiveDevices } from "../../store/actions/analytics.action"; // Import the action that fetches data from the API
import moment from "moment";
import blueprintMap from "../../blueprint_map.json"
import { MemoizedHeatMap } from "./heatmap.chart";
import StarRatingBarGroup from "./rating/starRatingBarGroup";
import usePolling from "../../components/hooks/usePolling";

const STATIC_WIDGETS_ID = {
    LARGE           : 12,
    LEFT_SMALL      : 18,
    RIGHT_SMALL     : 17
}

const Dashboard = () => {

    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const state = useSelector(state => state);
    const widget = state.analytics?.widgets;
    const terpPositionCount = state.analytics?.terpPositionCount
    const devices = state.analytics?.activeCarts;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [terpLoading, setTerpLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [drop, setDrop] = useState();
    const [currentDrag, setDrag] = useState();
    const [kandjiDeviceInfo, setKandiDeviceInfo] = useState(null);
    const userCompany = useSelector((state) => state?.profile?.user?.permissions?.company)
    const activeCompanies = useSelector((state) => state.company?.active || []);
    const currentCompany = useSelector((state) => state?.company?.selected)
    const starRating = state.analytics?.starRating;

    const fetchWidgetsCB = useCallback(async ({signal}) => {
        setLoading(true);
        setTerpLoading(true);
        dispatch(getStarRating(true, signal))
        dispatch(getWidgetsData(true, signal))
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
        dispatch(getTerpPositionAnalytics(signal))
            .then(() => setTerpLoading(false))
            .catch(() => setTerpLoading(false))
    }, [
        state.filter?.languageType, 
          state.filter?.date,
          state.company?.client, 
          userCompany,
          activeCompanies
    ]);  
    let customerOwnedCount = 0;
    let voyceOwnedCount = 0;
    // const fetchWidgets = useIntervalAsync(fetchWidgetsCB, state?.filter?.fetchInterval);
    const {startPolling: fetchWidgets, stopPolling} = usePolling(fetchWidgetsCB, state?.filter?.fetchInterval)

    const deviceUsageData = useSelector((state) => state.analytics?.totalActiveCarts);

    // Filter the data based on the CompanyId
    const filteredDeviceUsageData = deviceUsageData
    ?.map((item) => {
        // Find the corresponding device info from kandjiDeviceInfo based on serial number
        const deviceInfo = kandjiDeviceInfo && kandjiDeviceInfo?.find(
          (device) => device?.serialNumber === item?.IOSSerialNumber
        );
        // return deviceInfo
        if (deviceInfo) {
            // Increment the voyceOwnedCount for each "Voyce Owned" device
            voyceOwnedCount++;
            return {
              ...item,
              deviceName: deviceInfo?.deviceName,
              macAddress: deviceInfo?.mac_address,
              deviceType: "Voyce Owned",
            };
          } else {
            // Increment the customerOwnedCount for each "Customer Owned" device
            customerOwnedCount++;
            // If deviceInfo is not found, set the device name as "Unknown" and device type as "External"
            return {
              ...item,
              deviceName: "Unknown",
              deviceType: "Customer Owned",
            };
          }
        })|| []; // Initialize with an empty array if it is null

          // Function to fetch Kandji device information
    const fetchDeviceInfo = async () => {
        try {
            let code = activeCompanies.find((companyFullObject) => companyFullObject?._id?.toString() == currentCompany?._id?.toString())?.CompanyCode;
    
            if (currentCompany?._id !== -1) {
    
                // Check if the companyCode is in the blueprint map
                const matchingBlueprint = blueprintMap?.find((blueprint) => blueprint.companyCode === code);
    
                if (matchingBlueprint) {
                    setKandiDeviceInfo([]); // Set to [] if a match is found in the blueprint map
                } else {
                    // Fetch device information if no match is found
                    const response = await Service.Device().getDeviceInfo({
                        blueprintName: activeCompanies?.find((companyFullObject) => companyFullObject?._id?.toString() == currentCompany?._id.toString())?.CompanyCode
                    });
                    setKandiDeviceInfo(response); // Update the state with the fetched device information
                }
            } else {
                const response = await Service.Device().getDeviceInfo({
                });
                setKandiDeviceInfo(response);
            }
        } catch (err) {
            console.log(err);
        }
    };


  
  
      useEffect(() => {
        //   setLoading(true);
        //   setTerpLoading(true);
          stopPolling();
          fetchWidgets();
            fetchDeviceInfo();
            dispatch(getTotalActiveDevices({
                RequestCompanyId: state.filter.id
            }));
            
      }, [
          state.filter?.languageType, 
          state.filter?.date,
          state.company?.client, 
          userCompany,
          activeCompanies
      ]);

    const handleDeviceClick = () => {
        navigate("/pages/reports/device-usage");
    }

    const [widgets, setWidgets] = useState([
        {
            squareId: 1,
            widget: {
                title: 'Minutes Used', value: (wd) => Formatter.numberWithCommas(wd?.totalMins)
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 2,
            widget:  {
                title: 'Avg. Wait Secs Video', 
                value:(wd) =>  Math.round(parseFloat(wd?.avgWaitTime)), 
                hidden: (s) => s?.filter?.languageType === '-1',
                queryParams: [
                    {dataIdx: 'VideoOption', status: 'Video'}
                ]
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 3,
            widget: {
                title: 'Avg Wait Secs Video Spanish', 
                value:(wd) =>  Math.round(parseFloat(wd?.avgWaitTimeVideoSp)),
                hidden: (s) => s?.filter?.languageType !== '-1',
                queryParams: [
                    {dataIdx: 'VideoOption', status: 'Video'}
                ],
                languageType: 'sp',
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 4,
            widget: {
                title: 'Avg, Wait Secs Video LOTS', 
                value:(wd) =>  Math.round(parseFloat(wd?.avgWaitTimeVideoLots)),
                hidden: (s) => s?.filter?.languageType !== '-1',
                queryParams: [
                    {dataIdx: 'VideoOption', status: 'Video'}
                ],
                languageType: 'LOTS',
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 5,
            widget: {
                title: 'Avg. Call Length Mins', value:(wd) =>  Math.round(parseFloat(wd?.avgCallLength)),
                queryParams: [
                    {dataIdx: 'Status', status: 'Serviced'}
                ]
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 6,
            widget: {
                title: 'Total Calls Serviced', value:(wd) =>  Formatter.numberWithCommas(wd?.totalCallServiced),
                queryParams: [{dataIdx: 'Status', status: 'Serviced'}]
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 7,
            widget: {
                title: 'Completed Video Calls', value:(wd) =>  Formatter.numberWithCommas(wd?.successVideoCalls),
                queryParams: [
                    {dataIdx: 'Status', status: 'Serviced' }, 
                    {dataIdx: 'VideoOption', status: 'Video'}
                ]
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 8,
            widget: {
                title: 'Completed Audio Calls', value:(wd) =>  Formatter.numberWithCommas(wd?.successAudioCalls),
                queryParams: [
                    {dataIdx: 'Status', status: 'Serviced' }, 
                    {dataIdx: 'VideoOption', status: 'Audio'}
                ]
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 9,
            widget: {
                title: 'Total Requests', 
                value:(wd) =>  Formatter.numberWithCommas(wd?.totalCalls)
            },
            size: "medium",
            className: "medium-card",
        },
        {
            squareId: 14,
            widget: {
                title: 'Total New Requests',
                customClassName: "new-card",
                queryParams: [{dataIdx: 'Status', status: 'new'}],
                value:(wd) =>  Formatter.numberWithCommas(wd?.totalNewCalls)
            },
            className: "medium-card",
            size: "medium",
        },
        {
            squareId: 15,
            widget: {
                title: 'Total In-Service Requests',
                customClassName: "inservice-card",
                queryParams: [{dataIdx: 'Status', status: 'inservice'}],
                value:(wd) =>  Formatter.numberWithCommas(wd?.totalInServiceCalls)
            },
            className: "medium-card",
            size: "medium"
        },
        {
            squareId: 13,
            widget: {
                title: 'Requests Cancelled', value:(wd) =>  Formatter.numberWithCommas(wd?.totalCallCancelled),
                queryParams: [
                    {dataIdx: 'Status', status: 'Canceled'}
                ]
            },
            size: "medium"
        },
        {
            squareId: 12,
            widget: {
              title: 'Active Carts',
              value: (activeCartCount, kandjiCount) => (
                <div className="devices-count">
                  {activeCartCount} / {kandjiCount}
                </div>
              ),
              onClick: handleDeviceClick,
            },
            size: 'large',
            className: "active-carts"
          },
          {
            squareId: 17,
            widget: {
              title: "COD",
              value: (customerOwnedCount) => (
                <div className="devices-count owned-count">{customerOwnedCount}</div>
              ),
              queryParams: [{ dataIdx: "deviceType", status: "Customer Owned" }],
              path: "/pages/reports/device-usage",
            },
            className: "customer-device-card medium-card",
            size: "small",
          },
          {
            squareId: 18,
            widget: {
              title: "VOD",
              value: (voyceOwnedCount) => (
                <div className="devices-count owned-count">{voyceOwnedCount}</div>
              ),
              queryParams: [{ dataIdx: "deviceType", status: "Voyce Owned" }],
              path: "/pages/reports/device-usage",
            },
            className: "voyce-device-card medium-card",
            size: "small", 
          },

    ])

    useEffect(() => {
        if (drop) { swapWidgets(currentDrag, drop); }
    }, [drop]);

    function swapWidgets(sourceSquareId, targetSquareId) {
        setWidgets(prevWidgets => {
          const updatedWidgets = prevWidgets.map(widget => {
            if (widget.squareId === sourceSquareId) {
              return { ...widget, widget: prevWidgets.find(w => w.squareId === targetSquareId)?.widget };
            } else if (widget.squareId === targetSquareId) {
              return { ...widget, widget: prevWidgets.find(w => w.squareId === sourceSquareId)?.widget };
            }
            return widget;
          });
      
          return updatedWidgets;
        });
    }
      
    /**
     * Create widget handler.
     * @param {widgets} wd 
     */
    const createHandleClick = (wd) => {
        const { queryParams, languageType, onClick } = wd;
      
        if (onClick) {
          return onClick();
        }
      
        const queryString = (queryParams || [])
          .map(({ dataIdx, status }, index) => `dataIdx${index}=${dataIdx}&status${index}=${status}`)
          .join('&');
      
        // Check the widget's title to determine the redirect
        if (wd.title === 'Total New Requests' || wd.title === 'Total Pending Requests' || wd.title === 'Total In-Service Requests') {
          // Redirect to pages/activity-monitor for 'Total New Requests' widget
          navigate(`/pages/activity-monitor?${queryString}`);
        } else if (wd?.title === 'VOD' || wd.title === 'COD') {
          navigate(`/pages/reports/device-usage?${queryString}`);
        } else {
          // Redirect to pages/transactions for all other widgets
          navigate(`/pages/transactions?${queryString}`);
        }
      
        // Dispatch the languageType if needed
        if (languageType) {
          dispatch(setLanguageType(languageType));
        }
    };
    
      
      
      
      

    const activeCartCount = filteredDeviceUsageData.length;
    const kandjiCount = kandjiDeviceInfo?.length || 0;

    const renderWidgets = () => {
        return widgets
          .filter((square) => square.size === 'medium')
          .sort((a, b) => a.squareId - b.squareId)
          .map((square) => {
            const wd = square.widget;
      
            return (
              <Col hidden={wd?.hidden ? wd?.hidden(state) : false} style={{ marginBottom: 20 }} flex="19%" key={square.squareId} className="medium-col">
                <Square 
                    onDrop={setDrop} 
                    id={square.squareId} 
                    edit={edit} 
                    size="medium" 
                    loading={loading} 
                    title={wd.title}>
                  <SquareDrag
                     id={square.squareId}
                     edit={edit}
                     size="medium"
                     loading={loading}
                     title={wd.title}
                     hover={!edit}
                     onClick={() => (!edit ? createHandleClick(wd) : {})}
                     onDrag={setDrag}
                     className={(wd.customClassName ? wd.customClassName : "") + " " + square.className }            
                    >
                    {/* Keep the original content for the "Active Carts" widget */}
                    {wd.title === 'Active Carts' ? (
                        <div className="devices-count">
                        {activeCartCount} / {kandjiCount}
                        </div>
                    ) : (
                        wd.value(widget) // For other widgets, render their specific content
                    )}
                </SquareDrag>
                </Square>
              </Col>
            );
          });
      };
      

    const largeWidgetSquare = widgets.find((square) => square.squareId === STATIC_WIDGETS_ID.LARGE);
    const lSmallWidgetSquare = widgets.find((square) => square.squareId === STATIC_WIDGETS_ID.LEFT_SMALL);
    const rSmallWidgetSquare = widgets.find((square) => square.squareId === STATIC_WIDGETS_ID.RIGHT_SMALL);
    const avgRating = useMemo(() => {
        const data = starRating;
        const totalStars = data?.filter((e) => e.ans != 0)?.reduce((sum, item) => sum + (item.ans * item.totalCount), 0);
        const totalCounts = data?.filter((e) => e.ans != 0)?.reduce((sum, item) => sum + (item.totalCount), 0);
        return (totalStars/totalCounts)
    }, [starRating])


    return (
        <DndProvider backend={HTML5Backend}>
        <Col className="dashboard-container">
        <Row className="charts-row" wrap={false} gutter={20}>
            <Col flex={"auto"}  className="call-by-hour-chart">
                <MemoizedHeatMap/>
            </Col>
            <Col flex={"37.5%"} >
                <MemoizedTopLanguagesChart/>
            </Col>
        </Row>
        <br/>

        <Row className={ edit ? "widget-row glow" : "widget-row"}  wrap={false} gutter={20} >
            <Col flex={"30%"}>
                <Row gutter={20}>
                    <Col flex={"50%"} xs={24} md={12}>
                {/* Display the "Customer Owned Device" widget here */}
                <Square
                  onDrop={setDrop}
                  id={lSmallWidgetSquare?.squareId}
                  size={rSmallWidgetSquare?.widget?.size}
                  loading={loading}
                  title={lSmallWidgetSquare?.widget?.title}
                >
                  <SquareDrag
                    id={lSmallWidgetSquare?.squareId}

                    size={rSmallWidgetSquare?.widget?.size}
                    loading={loading}
                    title={lSmallWidgetSquare?.widget?.title}
                    onClick={() =>
                      !edit ? createHandleClick(lSmallWidgetSquare?.widget) : {}
                    }
                    onDrag={setDrag}
                    style={{fontWeight: 'bold'}}
                  >
                    {lSmallWidgetSquare?.widget?.value(voyceOwnedCount)}
                  </SquareDrag>
                </Square>
              </Col>

              <Col flex={"50%"} xs={24} md={12}>
                {/* Display the "Voyce Owned Device" widget here */}
                <Square
                  onDrop={setDrop}
                  id={rSmallWidgetSquare?.squareId}
                  size={rSmallWidgetSquare?.widget?.size}
                  loading={loading}
                  title={rSmallWidgetSquare?.widget?.title}
                >
                  <SquareDrag
                    id={rSmallWidgetSquare?.squareId}
                    size={rSmallWidgetSquare?.widget?.size}
                    loading={loading}
                    title={rSmallWidgetSquare?.widget?.title}
                    onClick={() =>
                      !edit ? createHandleClick(rSmallWidgetSquare?.widget) : {}
                    }
                    onDrag={setDrag}
                    style={{fontWeight: 'bold'}}
                  >
                    {rSmallWidgetSquare?.widget?.value(customerOwnedCount)}
                  </SquareDrag>
                </Square>
              </Col>
                </Row>
                <br/>
                <Row gutter={20}>
                    <Col style={{ marginBottom: 20 }} flex={"100%"} className="star-rating-col">
                        <Card
                            className="widget-cards terp-card"
                            bordered={false}
                            style={{ fontSize: 20, cursor: "default", height: 185 }}
                            loading={loading}
                            title="Star Rating"
                        >
                            <Row gutter={20} className="rating-row">
                                <Col flex={"25%"} className="average-rating-col" style={{marginRight: -10}}>
                                    <p>{(Math.round((avgRating + Number.EPSILON) * 100) / 100) + " / 5"}</p>
                                </Col>
                                <Col flex={"70%"} className="star-rating-graph-col">
                                    <StarRatingBarGroup data={starRating}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col flex={"70%"} >
                <Row gutter={20}>
                    {renderWidgets(widget)}
                    
                    <Col
                                style={{ marginBottom: 20 }}
                                flex={
                                    state.filter?.languageType == "-1"
                                        ? "50%"
                                        : "75%"
                                }
                            >
                                <Card
                                    className="widget-cards terp-card"
                                    bordered={false}
                                    style={{
                                        fontSize: 20,
                                        cursor: "default",
                                        maxHeight: 90,
                                    }}
                                    loading={terpLoading}
                                >
                                    <div className="terp-container">
                                        <div className="terp-header">
                                            <div><span style={{color: colorText}}>R1</span></div>
                                            <div><span style={{color: colorText}}>R2</span></div>
                                            <div><span style={{color: colorText}}>R3</span></div>
                                            <div><span style={{color: colorText}}>R4+</span></div>
                                        </div>
                                        <div className="terp-data">
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/pages/terp-hud/routing-history?terp=1"
                                                    )
                                                }
                                            >
                                                {Formatter.numberWithCommas(
                                                    terpPositionCount?.position1
                                                )}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/pages/terp-hud/routing-history?terp=2"
                                                    )
                                                }
                                            >
                                                {Formatter.numberWithCommas(
                                                    terpPositionCount?.position2
                                                )}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/pages/terp-hud/routing-history?terp=3"
                                                    )
                                                }
                                            >
                                                {Formatter.numberWithCommas(
                                                    terpPositionCount?.position3
                                                )}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/pages/terp-hud/routing-history?terp=4"
                                                    )
                                                }
                                            >
                                                {Formatter.numberWithCommas(
                                                    terpPositionCount?.position4plus
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                </Row>
            </Col>
        </Row>



             <i><p style={{color: "white", marginBottom: 0}} >
                <span style={{color: colorText, fontSize: 12}}>* Spanish Languages, LOTS - Languages other than Spanish, Sign Languages. </span>
            </p></i>
            <FloatButton 
                type="primary" 
                onClick={() => setEdit(!edit)} 
                icon={edit ? <CloseOutlined /> : <EditFilled/>} 
                style={{marginBottom: 15}}
            />
        </Col>
        </DndProvider>
    )
}

export default Dashboard;