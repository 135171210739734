const development = {
    API_BASE_URL: 'https://staging.admin.vipapi.voyceglobal.com',
    AUTH_BASE_URL: 'https://staging.admin.vipauth.voyceglobal.com',
    BASE_URL: 'https://staging.admin.vip.voyceglobal.com',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/',
    SOCKET_BASE_URL: 'https://staging.socket.vip.voyceglobal.com'
};

const staging = {
    API_BASE_URL: 'https://staging.admin.vipapi.voyceglobal.com',
    AUTH_BASE_URL: 'https://staging.admin.vipauth.voyceglobal.com',
    BASE_URL: 'https://staging.admin.vip.voyceglobal.com',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/',
    SOCKET_BASE_URL: 'https://staging.socket.vip.voyceglobal.com'
}

const production = {
    API_BASE_URL: 'https://api.admin.vip.voyceglobal.com',
    AUTH_BASE_URL: 'https://auth.admin.vip.voyceglobal.com',
    BASE_URL: 'https://admin.vip.voyceglobal.com',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/',
    SOCKET_BASE_URL: 'https://socket.vip.voyceglobal.com'
}

export default 
    process.env.REACT_APP_ENVIRONMENT == 'development' ? development : 
    process.env.REACT_APP_ENVIRONMENT == 'staging' ? staging : production;
