import { useEffect, useState } from 'react'
import useSocket from '../../../components/hooks/useSocket'
import HudContext from './hud.context'
import events from '../../qa/socket.events'
import { message, Typography } from 'antd'
import { useSelector } from 'react-redux'
import Service from '../../../services'

function HudProvider(props) {
  const { children } = props
  const [connected, setConnected] = useState(false)
  const [scores, setScores] = useState({})
  const [previousScores, setPreviousScores] = useState({})
  const [historicScores, setHistoricScores] = useState({})
  const [previousHistoricScores, setPreviousHistoricScores] = useState({})
  const [watchList, setWatchList] = useState({})
  const [interpreters, setInterpreters] = useState([])
  const [ongoingSessions, setOngoingSessions] = useState([])
  const timeRange = useSelector((state) => state?.filter?.date)
  const [loading, setLoading] = useState(false)
  const dateRangeLabel = useSelector(
    (state) => state?.filter?.date?.dateRangeLabel
  )
  const [monitorUrl, setMonitorUrl] = useState()
  const [monitoringRecord, setMonitorRecord] = useState()
  const [evaluating, setEvaluating] = useState(false)

  const { socket } = useSocket()

  useEffect(() => {
    socket.on(events.USER_CONNECTED, (data) => {
      console.log('USER_CONNECTED', data)
      setConnected(true)
      initiateData(data)
    })

    socket.on(events.ADDED_INTERPRETER_TO_WATCHLIST, (data) => {
      console.log('Added interpreter to watchlist', data)
      setWatchList((state) => ({ ...state, [data.interpreterId]: data }))
    })

    socket.on(events.REMOVED_INTERPRETER_TO_WATCHLIST, (data) => {
      console.log('removed interpreter from watchlist', data)
      setWatchList((state) => {
        delete state[data.interpreterId]
        return { ...state }
      })
    })

    socket.on(events.ADD_ONGOING_SESSIONS, (data) => {
      console.log('Added new session to ongoingSessions', data)
      setOngoingSessions((state) => [...state, data]);
    })

    socket.on(events.REMOVE_ONGOING_SESSIONS, (data) => {
      console.log('removed session from ongoingSessions', data)
      setOngoingSessions((state) => {
        return  [...state.filter((e) => e._id != data._id) ]
      })
    })

    socket.on(events.UPDATE_INTERPRETERS, (data) => {
      console.log('New interpreters have arrived', data)
      setInterpreters(data.map((e) => JSON.parse(e)))
    })

    socket.on(events.UPDATE_SCORES, (data) => {
      console.log('New Scores have arrived', data);
      updateNewScores(data);
    })

    socket.on(events.SEND_HISTORIC, (data) => {
      console.log('Received historic data')
      setLoading(false)
      setHistoricData(data)
    })

    return () => {
      socket.off(events.USER_CONNECTED)
      socket.off(events.ADDED_INTERPRETER_TO_WATCHLIST)
      socket.off(events.REMOVED_INTERPRETER_TO_WATCHLIST)
      socket.off(events.UPDATE_INTERPRETERS)
      socket.off(events.UPDATE_SCORES)
      socket.off(events.SEND_HISTORIC)
      socket.off(events.ADD_ONGOING_SESSIONS)
      socket.off(events.REMOVE_ONGOING_SESSIONS)
    }
  }, [])

  useEffect(() => {
    if (timeRange) {
      if (dateRangeLabel === 'This Month') {
        setLoading(false)
      } else if (['Q1', 'Q2', 'Q3', 'Q4', 'Last Month'].includes(dateRangeLabel)) {
        setLoading(true)
        socket?.emit(events.ASK_HISTORIC, timeRange)
      }
    }
  }, [timeRange])

  const updateNewScores = ({ scores, previousScores }) => {
    const previousScoresObject = {}
    const scoreObject = {}

    scores.forEach((e) => {
      scoreObject[e._id.interpreter] = e
    })
    previousScores.forEach((e) => {
      previousScoresObject[e._id.interpreter] = e
    })

    setScores(scoreObject);
    setPreviousScores(previousScoresObject);
  }

  const setHistoricData = (data) => {
    const { scores, previousScores } = data
    const previousScoresObject = {}
    const scoreObject = {}

    scores.forEach((e) => {
      scoreObject[e._id.interpreter] = e
    })
    previousScores.forEach((e) => {
      previousScoresObject[e._id.interpreter] = e
    })
    setHistoricScores(scoreObject)
    setPreviousHistoricScores(previousScoresObject)
  }

  const initiateData = (data) => {
    const { interpreters, scores, previousScores, ongoingSessions, watchList } =
      data
    const watchListObject = {}
    const previousScoresObject = {}
    const scoreObject = {}

    setInterpreters(interpreters.map((e) => JSON.parse(e)))
    scores.forEach((e) => {
      scoreObject[e._id.interpreter] = e
    })
    previousScores.forEach((e) => {
      previousScoresObject[e._id.interpreter] = e
    })
    watchList.forEach((e) => {
      watchListObject[e.interpreterId] = e
    })
    setScores(scoreObject)
    setPreviousScores(previousScoresObject)
    setWatchList(watchListObject)
    setOngoingSessions(ongoingSessions)
  }

  const addToWatchList = (interpreterId) => {
    socket.emit(events.ADD_INTERPRETER_TO_WATCHLIST, {
      interpreterId: interpreterId
    })
    message.success(`Added interpreter ${interpreterId} to the watchlist`)
  }

  const removeFromWatchList = (interpreterId) => {
    socket.emit(events.REMOVE_INTERPRETER_TO_WATCHLIST, {
      interpreterId: interpreterId
    })
    message.success(`Removed interpreter ${interpreterId} from the watchlist`)
  }

  const checkMonitorAvailability = async (requestId) => {
    const response = await socket
      .timeout(15000)
      .emitWithAck(events.CHECK_MONITOR_AVAILABLE, requestId)
    return !response.error
  }

  const handleMonitorClick = async (record) => {
    try {
      setLoading(true)
      const available = await checkMonitorAvailability(record?.RequestId)
      if (!available) {
        setLoading(false)
        message.error(
          <>
            <Typography.Title>Interpreter Under Evaluation</Typography.Title>
            <Typography.Text>
              Please Consider Trying Another Interpreter
            </Typography.Text>
          </>
        )
        return
      }
      const response = await Service.Transaction().getMonitorURL(
        record?.RequestId
      )
      setLoading(false)
      if (!response || response.isError) {
        message.error(
          'The URL for the requested transaction could not be fetched. Please contact the administrator.'
        )
        return
      }
      setMonitorUrl(response.url)
      setMonitorRecord(record)
      socket.emit(events.START_INTERPRETER_MONITORING, {
        interpreterId: record.InterpreterId,
        requestId: record?.RequestId,
        interpreterName: record?.FirstName + ' ' + record?.LastName
      })
    } catch (err) {
      console.log(err)
      message.error(
        'The URL for the requested transaction could not be fetched. Please contact the administrator.'
      )
    }
  }

  const handleMonitorClose = () => {
    try {
      socket.emit(events.STOP_INTERPRETER_MONITORING, {
        interpreterId: monitoringRecord.InterpreterId,
        requestId: monitoringRecord?.RequestId,
        interpreterName:
          monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName
      })
      setMonitorRecord()
      setMonitorUrl()
    } catch (err) {
      console.log(err)
    }
  }

  const handleEvaluationSart = () => {
    socket.emit(events.START_INTERPRETER_EVALUATION, {
      interpreterId: monitoringRecord.InterpreterId,
      requestId: monitoringRecord?.RequestId,
      interpreterName:
        monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName
    })
    setEvaluating(true)
  }

  const handleLeaveEvaluation = () => {
    socket.emit(events.LEAVE_INTERPRETER_EVALUATION, {
      interpreterId: monitoringRecord.InterpreterId,
      requestId: monitoringRecord?.RequestId,
      interpreterName:
        monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName
    })
    setEvaluating(false)
    setMonitorRecord()
    setMonitorUrl()
  }

  const handleEvaluationSubmit = async (values) => {
    try {
      setLoading(true)
      const result = await socket
        .timeout(15000)
        .emitWithAck(events.SUBMIT_INTERPRETER_EVALUATION, {
          interpreterId: monitoringRecord.InterpreterId,
          requestId: monitoringRecord?.RequestId,
          interpreterName:
            monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName,
          ...values
        })
      if (!result) {
        message.error(`Error while submitting the evaluation for interpreter ${
          monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName
          }. Please contact the administrator immediately.`)
        return;
      }
      message.success(
        `Evaluation submitted successfully for interpreter ${
          monitoringRecord?.FirstName + ' ' + monitoringRecord?.LastName
        }.`
      )
      setLoading(false)
      setEvaluating(false)
      setMonitorRecord()
      setMonitorUrl()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <HudContext.Provider
      value={{
        socket: socket,
        connected: connected,
        loading: loading,
        evaluating: evaluating,
        monitorUrl: monitorUrl,
        monitorVisible: Boolean(monitorUrl),
        monitoringRecord: monitoringRecord,
        interpreters: interpreters,
        scores: dateRangeLabel === 'This Month' ? scores : historicScores,
        watchList: watchList,
        previousScores:
          dateRangeLabel === 'This Month'
            ? previousScores
            : previousHistoricScores,
        ongoingSessions: ongoingSessions,
        addToWatchList: addToWatchList,
        removeFromWatchList: removeFromWatchList,
        handleMonitorClick: handleMonitorClick,
        handleMonitorClose: handleMonitorClose,
        handleEvaluationSart: handleEvaluationSart,
        handleLeaveEvaluation: handleLeaveEvaluation,
        handleEvaluationSubmit: handleEvaluationSubmit
      }}
    >
      {children}
    </HudContext.Provider>
  )
}

export default HudProvider
