import Routes from './route'
import ErrorBoundary from './boundary'
import { ConfigProvider } from 'antd'
import { DarkTheme, LightTheme } from './theme'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from './store/actions/profile.action'
import { THEME_TYPES } from './components/layout/theme.button'
import useSocket from './components/hooks/useSocket'
import HudProvider from './pages/qa-hud/store/hud.provider'

const titles = {
  '/auth/login': 'Login',
  '/auth/signup': 'Sign Up',
  '/auth/forgot-password': 'Forget Password',
  '/auth/reset-password': 'Reset Password'
}

const activityEvents = ['mousemove', 'mousedown', 'keypress', 'touchmove']

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const locationRef = useRef(location.pathname)
  const currentTheme = useSelector((state) => state.profile?.theme)

  // 240 mins auto logout
  const inactivityTimeLimit = 4 * 60 * 60 * 1000
  const inactivityTimer = useRef(null)
  const lastActivityTime = useRef(new Date())

  // reset the timer
  const resetTimer = () => {
    if (titles[locationRef.current]) {
      return
    }
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current)
    }
    inactivityTimer.current = setTimeout(() => {
      dispatch(logout())
    }, inactivityTimeLimit)
  }

  // check when browser is idle / sleep
  const checkForInactivity = () => {
    const now = new Date()
    const timeDifference = now.getTime() - lastActivityTime.current.getTime()

    if (timeDifference > inactivityTimeLimit) {
      dispatch(logout())
    }
  }

  useEffect(() => {
    const updateLastActivityTime = () => {
      lastActivityTime.current = new Date()
      resetTimer()
    }

    // set up event listeners for user activity
    activityEvents.forEach((event) =>
      window.addEventListener(event, updateLastActivityTime)
    )

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        resetTimer()
      } else {
        checkForInactivity()
      }
    })

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, updateLastActivityTime)
      )
      document.removeEventListener('visibilitychange', checkForInactivity)

      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current)
      }
    }
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      dispatch(logout())
    }

    window.addEventListener('onunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('onunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    document.title = titles[location.pathname] ?? 'VIP'
  }, [location])

  return (
    <ConfigProvider
      theme={currentTheme === THEME_TYPES.DARK ? DarkTheme : LightTheme}
    >
      <ErrorBoundary>
        <HudProvider>
        <Routes />
        </HudProvider>
      </ErrorBoundary>
    </ConfigProvider>
  )
}

export default App
