import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  Card,
  Row,
  Col,
  Modal,
  Table,
  Button,
  Progress,
  Tabs,
  Tooltip
} from 'antd'
import useSocket from '../../../components/hooks/useSocket'
import events from '../socket.events'
import ScoreCard from '../score.card/score.card'
import { toUpper } from 'lodash'
import GetColumnSearchProps from '../../../components/table/search'

const { TabPane } = Tabs
const { Meta } = Card

const InterpretersBarGraph = ({
  interpreters,
  socket,
  ongoingSessions,
  ongoingSessionsDetails
}) => {
  const [showScoreCard, setShowScoreCard] = useState(false)
  const [monitorModalVisible, setMonitorModalVisible] = useState(false)
  const [totalInterpretersCount, setTotalInterpretersCount] = useState(0)
  const [totalEvaluatedInterpreters, setTotalEvaluatedInterpreters] =
    useState(0)
  const [
    totalSpanishEvaluatedInterpreters,
    setTotalSpanishEvaluatedInterpreters
  ] = useState(0)
  const [totalLOTSEvaluatedInterpreters, setTotalLOTSEvaluatedInterpreters] =
    useState(0)
  const [totalASLEvaluatedInterpreters, setTotalASLEvaluatedInterpreters] =
    useState(0)
  const [totalOnlineInterpreters, setTotalOnlineInterpreters] = useState(0)
  const [totalInConferenceInterpreters, setTotalInConferenceInterpreters] =
    useState(0)
  const [totalOfflineInterpreters, setTotalOfflineInterpreters] = useState(0)
  const [totalSpanishInterpreters, setTotalSpanishInterpreters] = useState(0)
  const [totalLOTSInterpreters, setTotalLOTSInterpreters] = useState(0)
  const [totalASLInterpreters, setTotalASLInterpreters] = useState(0)
  const [totalSpanishOnlineTerps, setTotalSpanishOnlineTerps] = useState(0)
  const [totalLOTShOnlineTerps, setTotalLOTSOnlineTerps] = useState(0)
  const [selectedInterpreter, setSelectedInterpreter] = useState(null)
  const [selectedInterpreterData, setSelectedInterpreterData] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [evaluatedInterpreters, setEvaluatedInterpreters] = useState(0)
  const [onlineInterpreters, setOnlineInterpreters] = useState(0)
  const [inConferenceInterpreters, setInConferenceInterpreters] = useState(0)
  const [offlineInterpreters, setOffInterpreters] = useState(0)
  const [totalInterpreters, setTotalInterpreters] = useState(0)
  const [spanishInterpreters, setSpanishInterpreters] = useState(0)
  const [LOTSInterpreters, setLOTSInterpreters] = useState(0)
  const [ASLInterpreters, setASLInterpreters] = useState(0)
  const [activeTabKey, setActiveTabKey] = useState('All')
  const [filteredConferenceInterpreters, setFilteredConferenceInterpreters] =
    useState([])
  const [filteredOnlineInterpreters, setFilteredOnlineInterpreters] = useState(
    []
  )
  const [filteredOfflineInterpreters, setFilteredOfflineInterpreters] =
    useState([])
  const [conferenceCount, setConferenceCount] = useState(0)
  const [onlineCount, setOnlineCount] = useState(0)
  const [offlineCount, setOfflineCount] = useState(0)
  const [clickedProgressBarType, setClickedProgressBarType] = useState(null)
  const [currentTableColumns, setCurrentTableColumns] = useState(null)
  const [monitorDataSource, setMonitorDataSource] = useState(null)

  const statusCategories = ['All', 'In conference', 'Online', 'Offline']

  useEffect(() => {
    if (interpreters) {
      const interpretersArray = Object.values(interpreters)
      const activeEvaluations = []
      interpretersArray.forEach((interpreter) => {
        const interpreterScore = interpreter
        // Check if the interpreter's requestId is a key in the ongoingSessions object

        if (
          interpreterScore.scoreCard &&
          ongoingSessions.hasOwnProperty(
            interpreterScore.scoreCard.details.requestId
          )
        ) {
          console.log(' rewached here')
          if (
            interpreterScore.scoreCard.details.startTime &&
            !interpreterScore.scoreCard.details.endTime
          )
            console.log(interpreterScore.InterpreterId)
          activeEvaluations.push({
            QASpecialistName: interpreter.scoreCard.details.qaSpecialistName,
            InterpreterId: interpreter.InterpreterId,
            InterpreterName: `${interpreter.FirstName} ${interpreter.LastName}`,
            InterpreterCompanyName: interpreter.CompanyCode
          })
        }
      })

      const totalInterpretersCount = interpretersArray.length
      const evaluatedInterpretersCount = interpretersArray.filter(
        (interpreter) => interpreter?.scoreCard?.monthlyQaStatus == 'Completed'
      ).length
      const evaluatedInterpreters = interpretersArray.filter(
        (interpreter) => interpreter?.scoreCard?.monthlyQaStatus == 'Completed'
      )
      const evaluatedSpanishInterpreters = interpretersArray.filter(
        (interpreter) =>
          interpreter?.scoreCard?.monthlyQaStatus == 'Completed' &&
          interpreter.Languages.includes('Spanish')
      )
      const evaluatedSpanishInterpretersCount = interpretersArray.filter(
        (interpreter) =>
          interpreter?.scoreCard?.monthlyQaStatus == 'Completed' &&
          interpreter.Languages.includes('Spanish')
      ).length
      const evaluatedLOTSInterpreters = interpretersArray.filter(
        (interpreter) =>
          interpreter?.scoreCard && !interpreter.Languages.includes('Spanish')
      )
      const evaluatedLOTSInterpretersCount = interpretersArray.filter(
        (interpreter) =>
          interpreter?.scoreCard?.monthlyQaStatus == 'Completed' &&
          !interpreter.Languages.includes('Spanish') &&
          !interpreter?.Languages.includes('ASL')
      ).length
      const evaluatedASLInterpreters = interpretersArray?.filter(
        (interpreter) =>
          interpreter?.scoreCard &&
          interpreter.Languages.includes('American Sign Language (ASL)')
      )
      const evaluatedASLInterpretersCount = interpretersArray?.filter(
        (interpreter) =>
          interpreter?.scoreCard?.monthlyQaStatus == 'Completed' &&
          interpreter.Languages.includes('American Sign Language (ASL)')
      ).length
      const onlineInterpretersCount = interpretersArray.filter(
        (e) => e.Status === 'Online'
      ).length
      const onlineInterpreters = interpretersArray.filter(
        (e) => e.Status === 'Online'
      )
      const inConferenceInterpretersCount = interpretersArray.filter(
        (e) => e.Status === 'In conference'
      ).length
      const inConferenceInterpreters = interpretersArray.filter(
        (e) => e.Status === 'In conference'
      )
      const offlineInterpretersCount = interpretersArray.filter(
        (e) => e.Status === 'Offline'
      ).length
      const offlineInterpreters = interpretersArray.filter(
        (e) => e.Status === 'Offline'
      )
      const spanishInterpretersCount = interpretersArray.filter((e) =>
        e.Languages.includes('Spanish')
      ).length
      const spanishInterpreters = interpretersArray.filter((e) =>
        e.Languages.includes('Spanish')
      )
      const spanishOnlineInterpreters = interpretersArray.filter(
        (e) =>
          e.Languages.includes('Spanish') &&
          (e.Status === 'Online' || e.Status === 'In conference')
      ).length
      const LOTSInterpretersCount = interpretersArray.filter(
        (e) => !e.Languages.includes('Spanish')
      ).length
      const LOTSInterpreters = interpretersArray.filter(
        (e) => !e.Languages.includes('Spanish')
      )
      const LOTSOnlineInterpreters = interpretersArray.filter(
        (e) =>
          !e.Languages.includes('Spanish') &&
          (e.Status === 'Online' || e.Status === 'In conference')
      ).length
      const ASLInterpretersCount = interpretersArray?.filter((e) =>
        e.Languages.includes('American Sign Language (ASL)')
      ).length
      const ASLInterpreters = interpretersArray.filter((e) =>
        e.Languages.includes('American Sign Language (ASL)')
      )

      setTotalInterpreters(interpretersArray)
      setTotalInterpretersCount(totalInterpretersCount)
      setTotalEvaluatedInterpreters(evaluatedInterpretersCount)
      setTotalSpanishEvaluatedInterpreters(evaluatedSpanishInterpretersCount)
      setTotalLOTSEvaluatedInterpreters(evaluatedLOTSInterpretersCount)
      setTotalASLEvaluatedInterpreters(evaluatedASLInterpretersCount)
      setTotalOnlineInterpreters(onlineInterpretersCount)
      setTotalInConferenceInterpreters(inConferenceInterpretersCount)
      setTotalOfflineInterpreters(offlineInterpretersCount)
      setTotalSpanishInterpreters(spanishInterpretersCount)
      setTotalSpanishOnlineTerps(spanishOnlineInterpreters)
      setTotalLOTSInterpreters(LOTSInterpretersCount)
      setTotalLOTSOnlineTerps(LOTSOnlineInterpreters)
      setTotalASLInterpreters(ASLInterpretersCount)

      setEvaluatedInterpreters(evaluatedInterpreters)
      setOnlineInterpreters(onlineInterpreters)
      setInConferenceInterpreters(inConferenceInterpreters)
      setOffInterpreters(offlineInterpreters)
      setSpanishInterpreters(spanishInterpreters)
      setLOTSInterpreters(LOTSInterpreters)
      setASLInterpreters(ASLInterpreters)

      setMonitorDataSource(activeEvaluations)

      if (clickedProgressBarType && isModalVisible) {
        handleProgressBarClick(clickedProgressBarType)
      }
    }
  }, [interpreters, ongoingSessions])

  const handleInterpreterClick = (record) => {
    // Handle interpreter click and show the monitor modal
    setSelectedInterpreter(record)
    setMonitorModalVisible(true)
  }

  const handleCancel = () => {
    setMonitorModalVisible(false)
    setShowScoreCard(false)
    setSelectedInterpreter(null)
  }

  const handleMonitorModalYes = useCallback(() => {
    if (ongoingSessions[selectedInterpreter?.RequestId]) {
      Modal.info({
        title: 'Interpreter Under Evaluation',
        content: 'Please Consider Trying Another Interpreter'
      })
      handleCancel()
    } else {
      setShowScoreCard(true)
      socket.emit(events.START_INTERPRETER_MONITORING, {
        interpreterId: selectedInterpreter?.InterpreterId,
        requestId: selectedInterpreter?.RequestId,
        interpreterName:
          selectedInterpreter?.FirstName + ' ' + selectedInterpreter?.LastName
      })
    }
  }, [selectedInterpreter, ongoingSessions])

  const handleMonitorModalNo = () => {
    setShowScoreCard(false)
    setMonitorModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    setActiveTabKey('All')
  }

  const WatchlistButton = (props) => {
    const { watchlist, InterpreterId } = props.record
    const socket = props.socket
    const [loading, setLoading] = useState(false) // Add loading state
    const [buttonText, setButtonText] = useState(watchlist ? 'Remove' : 'Add') // Set initial button text

    useEffect(() => {
      if (watchlist) {
        setLoading(false)
        setButtonText('Remove')
      } else {
        setLoading(false)
        setButtonText('Add')
      }
    }, [watchlist])

    useEffect(() => {
      // Listen for the confirmation events and update loading state and button text accordingly
      const handleAddedToWatchlist = (data) => {
        if (data.interpreterId === InterpreterId) {
          setLoading(false)
          setButtonText((prevText) =>
            prevText === 'Add' ? 'Remove' : prevText
          )
        }
      }

      const handleRemovedFromWatchlist = (data) => {
        if (data.interpreterId === InterpreterId) {
          setLoading(false)
          setButtonText((prevText) =>
            prevText === 'Remove' ? 'Add' : prevText
          )
        }
      }

      socket.on(events.ADDED_INTERPRETER_TO_WATCHLIST, handleAddedToWatchlist)
      socket.on(
        events.REMOVED_INTERPRETER_TO_WATCHLIST,
        handleRemovedFromWatchlist
      )

      return () => {
        // Remove event listeners when component unmounts
        socket.off(
          events.ADDED_INTERPRETER_TO_WATCHLIST,
          handleAddedToWatchlist
        )
        socket.off(
          events.REMOVED_INTERPRETER_TO_WATCHLIST,
          handleRemovedFromWatchlist
        )
      }
    }, [socket, InterpreterId])

    const handleAddToWatchlist = () => {
      setLoading(true)
      console.log('Adding to watchlist:', InterpreterId)
      socket.emit(events.ADD_INTERPRETER_TO_WATCHLIST, {
        interpreterId: InterpreterId
      })
    }

    const handleRemoveFromWatchlist = () => {
      setLoading(true)
      console.log('Removing from watchlist:', InterpreterId)
      socket.emit(events.REMOVE_INTERPRETER_TO_WATCHLIST, {
        interpreterId: InterpreterId
      })
    }

    return (
      <Button
        size={props?.expanded ? 'middle' : 'small'}
        type={watchlist ? 'primary' : 'primary'}
        onClick={watchlist ? handleRemoveFromWatchlist : handleAddToWatchlist}
        loading={loading}
        danger={watchlist && buttonText === 'Remove'}
        style={{ width: '100%' }}
      >
        <span>{buttonText}</span>
      </Button>
    )
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId', isDate: false }),
      render: (text, row) =>
        row.Status === 'In conference' ? (
          <Button type="link" onClick={() => handleInterpreterClick(row)}>
            {text}
          </Button>
        ) : (
          <span>{text}</span>
        )
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false }),
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      }
    },
    {
      title: 'Previous Month Score',
      dataIndex: ['previousScore', 'score'],
      render: (text, row) =>
        row?.previousScore?.score
          ? Math.round(text / row?.previousScore?.totalQaSessions)
          : '-'
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({ dataIndex: 'CompanyCode', isDate: false })
    },
    {
      title: 'Language',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      ...GetColumnSearchProps({ dataIndex: 'Languages', isDate: false })
    },
    {
      title: 'Dedicated',
      dataIndex: 'Dedicated',
      key: 'Dedicated',
      ...GetColumnSearchProps({ dataIndex: 'Dedicated', isDate: false }),
    },
    {
      title: 'Monthly QA Status',
      key: 'monthlyQaStatus',
      sorter: (a, b) => {
        const statusA = a?.scoreCard?.monthlyQaStatus ?? ''
        const statusB = b?.scoreCard?.monthlyQaStatus ?? ''

        return statusA.localeCompare(statusB)
      },
      render: (text, record) => {
        let qaStatus = record?.scoreCard?.monthlyQaStatus || 'New'
        let backgroundColor
        if (qaStatus === 'Completed') {
          backgroundColor = 'lightgreen'
        } else if (qaStatus === 'In Progress') {
          backgroundColor = 'yellow'
        } else if (qaStatus === 'New') {
          backgroundColor = 'lightblue'
        }
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>
            <span
              style={{
                backgroundColor,
                padding: '2px 4px',
                borderRadius: '4px',
                color: '#000',
                fontWeight: 'bold'
              }}
            >
              {qaStatus}
            </span>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.Status.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false })
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      render: (text, record) => (
        <WatchlistButton
          key={`watchlist-button-${record.InterpreterId}`}
          record={record}
          socket={socket}
        />
      )
    }
  ]

  const monitorTableColumns = [
    {
      title: 'ID',
      dataIndex: 'interpreterId',
      key: 'interpreterId',
      ...GetColumnSearchProps({ dataIndex: 'interpreterId', isDate: false })
    },
    {
      title: 'Name',
      dataIndex: 'interpreterName',
      key: 'interpreterName',
      ...GetColumnSearchProps({ dataIndex: 'interpreterName', isDate: false }),
      sorter: (a, b) => {
        const aName = `${a?.interpreterName || ''} ${a?.LastName || ''}`
          .trim()
          .toLowerCase()
        const bName = `${b?.interpreterName || ''} ${b?.LastName || ''}`
          .trim()
          .toLowerCase()
        return aName.localeCompare(bName)
      }
    },
    // {
    //   title: 'Company Name',
    //   dataIndex: 'CompanyCode',
    //   key: 'CompanyCode',
    //   sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
    //   ...GetColumnSearchProps({dataIndex: 'CompanyCode', isDate:false}),
    // },
    {
      title: 'QA Specialist Name',
      dataIndex: 'qaSpecialistName',
      key: 'qaSpecialistName',
      sorter: (a, b) =>
        a?.qaSpecialistName.localeCompare(b?.CompaqaSpecialistNamenyCode),
      ...GetColumnSearchProps({ dataIndex: 'qaSpecialistName', isDate: false })
    }
  ]

  const countByStatus = (subGroupInterpreters, status) => {
    return subGroupInterpreters.filter(
      (interpreter) => interpreter.Status === status
    ).length
  }

  const progressCircleStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '1.2', // Adjust line height to control spacing between lines
    fontSize: '14px' // Adjust font size as needed
  }

  const handleTabChange = (key) => {
    setActiveTabKey(key)

    switch (key) {
      case 'In conference':
        setFilteredConferenceInterpreters(
          selectedInterpreterData.filter(
            (interpreter) => interpreter.Status === key
          )
        )
        break
      case 'Online':
        setFilteredOnlineInterpreters(
          selectedInterpreterData.filter(
            (interpreter) => interpreter.Status === key
          )
        )
        break
      case 'Offline':
        setFilteredOfflineInterpreters(
          selectedInterpreterData.filter(
            (interpreter) => interpreter.Status === key
          )
        )
        break
      default:
        break
    }

    // Set the selected tab name in the state
  }

  const handleProgressBarClick = (type) => {
    let interpreterData
    setClickedProgressBarType(type) // Keep track of which progress bar was clicked
    setIsModalVisible(true) // Ensure modal visibility is controlled

    if (type === 'Monitoring') {
      setCurrentTableColumns(monitorTableColumns)
      setSelectedInterpreterData(ongoingSessionsDetails) // For 'Monitoring', use the specific data source and columns
    } else {
      // For other types, calculate and set the data specific to each category
      switch (type) {
        case 'total':
          interpreterData = evaluatedInterpreters
          break
        case 'Spanish':
          interpreterData = spanishInterpreters
          break
        case 'LOTS':
          interpreterData = LOTSInterpreters
          break
        case 'Evaluated':
          interpreterData = totalInterpreters
          break
        case 'ASL':
          interpreterData = ASLInterpreters
          break
        default:
          interpreterData = null
      }

      setCurrentTableColumns(tableColumns) // Use the general table columns
      setSelectedInterpreterData(interpreterData)

      // Update counts for each tab
      setConferenceCount(countByStatus(interpreterData, 'In conference'))
      setOnlineCount(countByStatus(interpreterData, 'Online'))
      setOfflineCount(countByStatus(interpreterData, 'Offline'))
    }
  }

  const formatProgress = (current, total, type) => {
    const percentage = type === 'Monitoring' ? 100 : (current / total) * 100

    return (
      <Tooltip
        title={type === 'Monitoring' ? '' : `${Math.round(percentage)}%`}
        placement="top"
      >
        <Progress
          type="circle"
          percent={percentage}
          width={type === 'Monitoring' ? 125 : 115}
          format={() => (
            <div
              style={progressCircleStyle}
              onClick={() => handleProgressBarClick(type)}
            >
              <h3 style={{ textAlign: 'center', fontSize: '0.9rem' }}>
                <b>
                  {type === 'Evaluated'
                    ? 'Evaluated'
                    : type === 'Spanish'
                    ? 'Spanish'
                    : type === 'ASL'
                    ? 'ASL'
                    : type === 'Monitoring'
                    ? 'Monitoring'
                    : 'LOTS'}
                </b>
              </h3>
              <h2
                style={{ textAlign: 'center', margin: 0, fontSize: '1rem' }}
              >{`${current}`}</h2>
              {type !== 'Monitoring' && (
                <h2
                  style={{
                    textAlign: 'center',
                    margin: 0,
                    textDecoration: 'overline',
                    fontSize: '1rem'
                  }}
                >{`${total}`}</h2>
              )}
            </div>
          )}
        />
      </Tooltip>
    )
  }

  return (
    <Row>
      <Row style={{ justifyContent: 'space-between', width: '100%' }}>
        <Col span={8}>
          {formatProgress(
            totalEvaluatedInterpreters,
            totalInterpretersCount,
            'Evaluated'
          )}
        </Col>
        <Col span={8}>
          {formatProgress(
            totalASLEvaluatedInterpreters,
            totalASLInterpreters,
            'ASL'
          )}
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Col span={8}>
          {formatProgress(
            ongoingSessionsDetails?.length,
            totalASLInterpreters,
            'Monitoring'
          )}
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Col span={8}>
          {formatProgress(
            totalSpanishEvaluatedInterpreters,
            totalSpanishInterpreters,
            'Spanish'
          )}
        </Col>
        <Col span={8}>
          {formatProgress(
            totalLOTSEvaluatedInterpreters,
            totalLOTSInterpreters,
            'LOTS'
          )}
        </Col>
      </Row>

      <Modal
        title={`Interpreter Details - ${clickedProgressBarType}`}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Close
          </Button>
        ]}
        width={'90%'}
      >
        {selectedInterpreterData &&
          (clickedProgressBarType == 'Monitoring' ? (
            // Display only the table for monitoring without tabs
            <Table
              size="small"
              dataSource={ongoingSessionsDetails} // Use monitorDataSource here
              columns={monitorTableColumns}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100']
              }}
              rowKey="InterpreterId"
            />
          ) : (
            // Render tabs with different tables for other data
            <Tabs
              defaultActiveKey="1"
              type="card"
              activeKey={activeTabKey}
              onChange={handleTabChange}
            >
              {statusCategories.map((status, index) => (
                <TabPane
                  tab={
                    <span>
                      {status} (
                      {status === 'In conference'
                        ? conferenceCount
                        : status === 'Online'
                        ? onlineCount
                        : status === 'Offline'
                        ? offlineCount
                        : selectedInterpreterData?.length}
                      )
                    </span>
                  }
                  key={status}
                >
                  <Table
                    size="small"
                    dataSource={
                      activeTabKey === 'In conference'
                        ? filteredConferenceInterpreters
                        : activeTabKey === 'Online'
                        ? filteredOnlineInterpreters
                        : activeTabKey === 'Offline'
                        ? filteredOfflineInterpreters
                        : selectedInterpreterData
                    }
                    columns={tableColumns}
                    pagination={{
                      defaultPageSize: 100,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    rowKey="InterpreterId"
                    id={`${activeTabKey.replace(/\s/g, '')}-terp-table`}
                  />
                  <Modal
                    title={`Monitor Call for Interpreter ID: ${selectedInterpreter?.InterpreterId}`}
                    visible={monitorModalVisible}
                    onCancel={handleMonitorModalNo}
                    footer={[
                      <Button
                        key="yes"
                        type="primary"
                        onClick={handleMonitorModalYes}
                      >
                        Yes
                      </Button>,
                      <Button key="no" onClick={handleMonitorModalNo}>
                        No
                      </Button>
                    ]}
                  >
                    {/* Render monitor call details and options here */}
                    <p>
                      Do you want to monitor the call for Interpreter ID:{' '}
                      {selectedInterpreter?.InterpreterId}?
                    </p>
                    {/* Add monitor call options */}
                  </Modal>
                  <ScoreCard
                    open={showScoreCard} // Set open to true to show the modal
                    transactionId={selectedInterpreter?.RequestId}
                    handleClose={handleCancel}
                    InterpreterId={selectedInterpreter?.InterpreterId}
                    interpreter={selectedInterpreter}
                    interpreterName={
                      selectedInterpreter?.FirstName +
                      ' ' +
                      selectedInterpreter?.LastName
                    }
                    socket={socket}
                  />
                </TabPane>
              ))}
            </Tabs>
          ))}
      </Modal>
    </Row>
  )
}

export default InterpretersBarGraph
