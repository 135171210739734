import EChartsReact from "echarts-for-react";
import { useSelector } from "react-redux";
import { theme } from 'antd';

const graphColors = [
    '#6c40a6',
    '#0f9191',
    '#226885',
    '#61636f'
]

const AVRPieChart = (props) => {
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const data = useSelector((state) => state.analytics?.avrPieChart);

    const options = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
  
                return `<b><span style="color: ${params?.data?.itemStyle?.color}">${params?.seriesName}</span></b><br />
                        ${params?.name}: <b>${params?.data?.value} (${params?.percent}%)</b>`;
            }   
        },
        series: [
          {
            name: 'Video Call Request and Failure Analysis',
            type: 'pie',
            radius: '85%',
            data: data,
            label: {
              color: colorText
            },
            labelLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 0.3)'
                },
                smooth: 0.2,
                length: 10,
                length2: 20
            },
            itemStyle: {
                shadowBlur: 200,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
    };

    return (
        <EChartsReact style={{ height: 320 }} option={options}/>
    )

}

export default AVRPieChart;