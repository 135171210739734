import { Axios } from "axios";
import constants from "../constants";

class CompanyService {
    /**
     * Company Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async allActive() {
        try {
            // const companyList = { selectedCompanies: data };

            let response = await this.request.post("/company/active");
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default CompanyService;
