import {
    Row,
    Col,
    Table,
    Button,
    Modal,
    Form,
    Input,
    Select,
    Avatar,
    Tooltip,
    message,
    Tabs,
    Typography
} from 'antd'
import { useEffect, useState } from 'react'
import {
    EditOutlined,
    DeleteOutlined,
    UserOutlined,
    UserAddOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    MinusCircleFilled
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import ROLE, { USER, SUPER_ADMIN, ADMIN, MONITOR, QA_SPECIALIST, INTERPRETER } from '../../role'
import './role.scss'
import {
    getUserList,
    deleteUser,
    addUser,
    updateUserInfo,
    getTerpList
} from '../../store/actions/userManagement.action'
import Validator from '../../utils/validator'
import GetColumnSearchProps from '../../components/table/search'
import LocalCustomFilter from '../transactions/localCustomFilter'

const { TabPane } = Tabs
const { Option } = Select

const RoleHierarchy = () => {
    const [password, setPassword] = useState('')
    const [selectedCompanies, setSelectedCompanies] = useState([])

    const activeCompanies = useSelector((state) => state.company?.active || [])

    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state?.signin?.isLoading)
    const userRole = useSelector(
        (state) => state?.profile?.user.permissions.role
    )
    const userName = useSelector((state) => state?.profile?.user.name)

    const userInfo = useSelector((state) => state.userManagement?.userList)
    const terpInfo = useSelector((state) => state.userManagement?.terpList)
    const [visible, setVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [form] = Form.useForm()
    const [addUserAccessibilitySelect, setAddUserAccessibilitySelect] =
        useState('')
    const [selectedEditUser, setSelectedEditUser] = useState({
        key: '',
        firstname: '',
        lastname: '',
        email: '',
        role: '',
        profileImage: '',
        selectedCompanies: [],
        monitorAccess: false,
    })
    const [selectedDeleteUser, setSelectedDeleteUser] = useState('')
    const [currentTab, setCurrentTab] = useState('accountInfo')
    const [assignAll, setAssignAll] = useState(false)
    const [interpreterDropdownVisible, setInterpreterDropdownVisible] = useState(false);
    const [isLoadingTerps, setIsLoadingTerps] = useState(false);

    useEffect(() => {
        dispatch(getUserList(userRole))
        dispatch(getTerpList());
    }, [])

    const handleEdit = (record) => {
        const [firstname, lastname] = record.name.split(' ')
        const email = record.email.split('@')[0]
        const editUser = {
            ...record,
            firstname,
            lastname,
            email
        }
        setAssignAll(record?.assignAll)
        setInterpreterDropdownVisible(record.role === INTERPRETER);
        setEditMode(true)
        setSelectedEditUser(editUser)
        setSelectedCompanies(
            editUser?.selectedCompanies?.map((e) => ({
                value: e?.value,
                label: e?.label
            })) || []
        )
        form.setFieldsValue(editUser)
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
        setDeleteModalVisible(false)
        setCurrentTab('accountInfo')
        setSelectedCompanies([])
    }

    const renderCompanies = () => {
        let companies = JSON.parse(JSON.stringify(activeCompanies))
        return (
            companies.map((company) => {
                return {
                    value: company?._id?.toString(),
                    label: company.CompanyName
                }
            }) || []
        )
    }

    const handleSelectAll = () => {
        setAssignAll(!assignAll)
    }

    const handleClearAll = () => {
        setSelectedCompanies([])
    }

    const handleOk = (event) => {
        //handle edit/add request
        form.validateFields().then((values) => {
            let allCompanies = renderCompanies()
            const selectAll = (addUserAccessibilitySelect === MONITOR || 
                addUserAccessibilitySelect === QA_SPECIALIST || 
                addUserAccessibilitySelect === INTERPRETER
                ) ? true : assignAll;
            let selectedCompanyObjects = allCompanies.filter((company) =>
                selectedCompanies.includes(company.value.toString())
            )

            let email =
                values.email.split('@')[0].toLowerCase() + '@voyceglobal.com'
            let monitorAccess = (addUserAccessibilitySelect === MONITOR || addUserAccessibilitySelect === QA_SPECIALIST) ? true : false;
            let requestbBody = {
                ...values,
                email,
                selectedCompanyObjects,
                assignAll: selectAll,
                monitorAccess: monitorAccess
            }
            if ((requestbBody.role !== SUPER_ADMIN && !selectAll) && requestbBody.selectedCompanyObjects.length === 0) {
                message.error('Please Assign Companies', 3);
                return;
            }
            if (!editMode) {
                dispatch(addUser(requestbBody))
            } else {
                dispatch(updateUserInfo(requestbBody))
                message.success('User: ' + email + ' has been updated.', 3)
            }
            form.resetFields()
            setCurrentTab('accountInfo')
            setSelectedCompanies([])
            setVisible(false)
        })
    }

    const handleDeleteOk = (event) => {
        //handle delete request
        dispatch(deleteUser(selectedDeleteUser.email))
        setDeleteModalVisible(false)
    }

    const handleDelete = (event) => {
        // Handle delete action
        setSelectedDeleteUser(event)
        setDeleteModalVisible(true)
    }

    const handleAdd = () => {
        form.resetFields()
        setVisible(true)
        setEditMode(false)
        setAssignAll(false)
    }

    const handleButtonClick = () => {
        form.validateFields()
            .then((values) => {
                if (currentTab === 'accountInfo') {
                    if (selectedEditUser.role === SUPER_ADMIN) {
                        handleOk()
                    } else {
                        setCurrentTab('accessibility')
                        // setVisitedAccessibilityTab(true);
                    }
                } else {
                    handleOk()
                }
            })
            .catch((errorInfo) => {
                message.error('Please fill all required fields before submitting!');
            });
    };
    const roleOptions = ROLE.filter((role) => {
        if (userRole === ADMIN) {
            return role.value === ADMIN || role.value === USER || role.value === QA_SPECIALIST || role.value === INTERPRETER
        } else {
            return true
        }
    })

    const handleRoleCreation = async (role) => {
        if (role === INTERPRETER) {
            setIsLoadingTerps(true)
            setInterpreterDropdownVisible(true);
            setIsLoadingTerps(false)
        } else {
            setInterpreterDropdownVisible(false);
        }
        setAddUserAccessibilitySelect(role)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <div
                    className="name-div"
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Tooltip title={text}></Tooltip>
                    {record.profileImage ? (
                        <img
                            src={record.profileImage}
                            alt="Profile"
                            style={{
                                marginRight: 8,
                                borderRadius: '50%',
                                width: 40
                            }}
                        />
                    ) : (
                        <Avatar
                            size="large"
                            icon={<UserOutlined />}
                            style={{ width: 40 }}
                        />
                    )}
                    <span className="name-div">{text}</span>
                </div>
            ),
            ...LocalCustomFilter({
                dataIndex: 'name',
                dataSource: userInfo,
                // emptySearch: true
              }),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) =>
                (a && a.email && a.email.toString()).localeCompare(
                    b && b.email && b.email.toString()
                ),
                ...LocalCustomFilter({
                    dataIndex: 'email',
                    dataSource: userInfo,
                    // emptySearch: true
                  }),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a, b) => {
                if (a && a.role && b && b.role) {
                    return a.role.toString().localeCompare(b.role.toString())
                } else if (a && a.role) {
                    return -1
                } else if (b && b.role) {
                    return 1
                } else {
                    return 0
                }
            },
            ...LocalCustomFilter({
                dataIndex: 'role',
                dataSource: userInfo,
                emptySearch: true
              }),
        },
        {
            title: 'Monitor',
            dataIndex: 'monitorAccess',
            width: '160px',
            sorter: (a, b) => {
                if (a.monitorAccess === b.monitorAccess) return 0;
                return a.monitorAccess ? -1 : 1;
            },
            render: (monitorAccess) => (monitorAccess ? "Yes" : "No"),
        },
        {
            title: 'Action',
            className: 'action-column',

            render: (text, record) => (
                <div>
                    <Button
                        shape="round"
                        type="primary"
                        className="button-div"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        disabled={(userRole === ADMIN || userRole === MONITOR) && (record.role === ADMIN || record.role === MONITOR)}
                        shape="round"
                        className="button-div delete-btn-div"
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record)}
                    >
                        Delete
                    </Button>
                </div>
            )
        }
    ]

    const renderCriterias = () => {
        return Validator.PasswordRules.map((criteria) => {
            return (
                <Row gutter={10}>
                    <Col>
                        {password.trim() == '' ? (
                            <MinusCircleFilled />
                        ) : password.match(criteria.rule) ? (
                            <CheckCircleFilled style={{ color: '#49aa19' }} />
                        ) : (
                            <CloseCircleFilled style={{ color: '#dc4446' }} />
                        )}
                    </Col>
                    <Col>{criteria.name}</Col>
                </Row>
            )
        })
    }


    return (
        <div className="roles-container" style={{ padding: 24 }}>
            <Table
                title={() => (
                    <div className="table-title-container">
                        <span className="username user-bold">
                            {userName} ({userRole})
                        </span>
                        <div className="add-btn-div">
                            <Button
                                type="primary"
                                onClick={handleAdd}
                                shape="round"
                            >
                                <UserAddOutlined /> Add User
                            </Button>
                        </div>
                    </div>
                )}
                loading={isLoading}
                columns={columns}
                dataSource={userInfo}
                className="voyce-custom-table user-table"
                sticky
            />
            <Modal
                className="edit-modal"
                title={editMode ? 'Edit User' : 'Add User'}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                footer={[
                    <Button
                        key="back"
                        onClick={handleCancel}
                        shape="round"
                        className="button-div"
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleButtonClick}
                        shape="round"
                        className="button-div"
                    >
                        {editMode
                            ? 'Save'
                            : currentTab === 'accountInfo'
                            ? 'Next'
                            : 'Add'}
                    </Button>
                ]}
            >
                <Tabs
                    defaultActiveKey="accountInfo"
                    tabBarGutter={32}
                    activeKey={currentTab}
                    onChange={setCurrentTab}
                >
                    <TabPane tab="Account Info" key="accountInfo">
                        <br />
                        <Form form={form}>
                            <Form.Item
                                name="firstname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input first name!'
                                    },
                                    {
                                        max: 30,
                                        message:
                                            'First name cannot be longer than 30 characters'
                                    },
                                    {
                                        pattern: /^[a-zA-Z-]+(?:[a-zA-Z-]+)*$/,
                                        message:
                                            'Only alphabets and hyphens are allowed'
                                    }
                                ]}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input last name!'
                                    },
                                    {
                                        max: 30,
                                        message:
                                            'Last name cannot be longer than 30 characters'
                                    },
                                    {
                                        pattern: /^[a-zA-Z-]+(?:[a-zA-Z-]+)*$/,
                                        message:
                                            'Only alphabets and hyphens are allowed'
                                    }
                                ]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true },
                                    {
                                        max: 238,
                                        message:
                                            'Email cannot be longer than 238 characters'
                                    },
                                    {
                                        pattern:
                                            /^(?!.*?[._-]{2})[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])?$/,
                                        message: 'Invalid email local part'
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="E-mail"
                                    addonAfter="@voyceglobal.com"
                                    disabled={editMode}
                                />
                            </Form.Item>
                            {editMode ? null : (
                                <>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input the password!'
                                            },
                                            {
                                                max: 256,
                                                message:
                                                    'Password cannot be longer than 256 characters'
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        Validator.validatePassowrd(
                                                            value
                                                        )
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password entry does not meet criteria'
                                                        )
                                                    )
                                                }
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder="Password"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please confirm the password!'
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            'password'
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'The two passwords that you entered do not match!'
                                                        )
                                                    )
                                                }
                                            })
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirm Password" />
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item name="role" rules={[{ required: true }]}>
                                <Select
                                    options={roleOptions}
                                    placeholder="Select a role"
                                    onChange={handleRoleCreation}
                                />
                            </Form.Item>
                            {(interpreterDropdownVisible)&& (
                                <Form.Item 
                                name="interpreterId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select an interpreter!'
                                    }]}
                                >
                                    <Select 
                                        showSearch
                                        loading={isLoadingTerps} 
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input?.toLowerCase()) ||
                                            option?.value?.toString().includes(input)
                                        }
                                        placeholder="Select an Interpreter">
                                        {terpInfo?.map(interpreter => (
                                            <Option 
                                                key={interpreter.InterpreterId} 
                                                value={interpreter.InterpreterId}
                                                label={`${interpreter.InterpreterName} - ${interpreter.InterpreterId}`}
                                                >
                                                {interpreter.InterpreterName} - {interpreter.InterpreterId}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )} 
                            {editMode ? null : <Col>{renderCriterias()}</Col>}
                        </Form>
                    </TabPane>
                    <TabPane
                        tab="Accessibility"
                        key="accessibility"
                        disabled={selectedEditUser.role === SUPER_ADMIN}
                    >
                        <br />
                        {!assignAll ? (
                            <Select
                                mode="multiple"
                                placeholder="Select company names"
                                showSearch
                                optionFilterProp="label"
                                value={selectedCompanies}
                                onChange={setSelectedCompanies}
                                style={{ width: '100%' }}
                                disabled={
                                    (addUserAccessibilitySelect === SUPER_ADMIN || addUserAccessibilitySelect === MONITOR || addUserAccessibilitySelect === QA_SPECIALIST || addUserAccessibilitySelect === INTERPRETER)
                                }
                            >
                                {renderCompanies()
                                    .sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                    )
                                    .map((company) => (
                                        <Option
                                            key={company.value}
                                            value={company.value}
                                            label={company.label}
                                        >
                                            {company.label}
                                        </Option>
                                    ))}
                            </Select>
                        ) : (
                            <Typography.Text italic>
                                *All companies are selected; any new company
                                added to this organization will also be
                                automatically assigned to the user.
                            </Typography.Text>
                        )}
                        <div style={{ marginTop: '16px' }}>
                            <Button
                                onClick={handleSelectAll}
                                disabled={
                                    (
                                        addUserAccessibilitySelect === SUPER_ADMIN || 
                                        addUserAccessibilitySelect === MONITOR || 
                                        addUserAccessibilitySelect === QA_SPECIALIST || 
                                        addUserAccessibilitySelect === INTERPRETER
                                    )
                                }
                                icon={
                                    assignAll && (
                                        <CheckCircleFilled
                                            style={{ color: 'green' }}
                                        />
                                    )
                                }
                                type={assignAll ? 'primary' : 'default'}
                            >
                                {assignAll ? 'Deselect All' : 'Select All'}
                            </Button>{' '}
                            &nbsp;
                            <Button
                                danger
                                onClick={handleClearAll}
                                disabled={
                                    (
                                        addUserAccessibilitySelect === SUPER_ADMIN || 
                                        addUserAccessibilitySelect === MONITOR || 
                                        addUserAccessibilitySelect === QA_SPECIALIST || 
                                        addUserAccessibilitySelect === INTERPRETER
                                    )
                                }
                            >
                                Clear
                            </Button>
                        </div>
                        {(
                            addUserAccessibilitySelect === SUPER_ADMIN || 
                            addUserAccessibilitySelect === MONITOR || 
                            addUserAccessibilitySelect === QA_SPECIALIST || 
                            addUserAccessibilitySelect === INTERPRETER
                        ) && (
                            <i>
                                <p style={{ color: 'white', marginBottom: 0 }}>
                                    <span
                                        style={{
                                            color: '#6c40a6',
                                            fontSize: 24
                                        }}
                                    >
                                        *
                                    </span>{' '}
                                    Super Admins / Monitors / QA Specialists / Interpreters will be automatically assigned
                                    with ALL COMPANIES
                                </p>
                            </i>
                        )}
                    </TabPane>
                </Tabs>
            </Modal>
            <Modal
                className="delete-modal"
                title="Delete User"
                visible={deleteModalVisible}
                onCancel={handleCancel}
                onOk={handleDeleteOk}
                footer={[
                    <Button
                        key="back"
                        onClick={handleCancel}
                        shape="round"
                        className="button-div"
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleDeleteOk}
                        shape="round"
                        className="button-div"
                    >
                        Ok
                    </Button>
                ]}
            >
                Do you want to delete the user:{' '}
                <b>{selectedDeleteUser.email}</b>?
            </Modal>
        </div>
    )
}

export default RoleHierarchy
