import {
    Row,
    Col,
    theme,
    Table,
    Button,
    Input,
    Space,
    TimePicker,
    Select,
} from "antd";
import { useEffect, useRef, useState, useCallback } from "react";
import { LinkOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateColumns } from "../../store/actions/selectedColumns";
import "./transaction.scss";
import Service from "../../services";
import moment from "moment";
import "moment-timezone";
import DropDown from "../../components/dropdown";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import Formatter from "../../utils/Formatter";
import DragnDrop from "./dragndrop";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useIntervalAsync from "../../components/hooks/useInterval";
import "jspdf-autotable";
import Monitor from "./monitor";
import { SUPER_ADMIN, ADMIN, MONITOR } from "../../role";
import CustomFilter from "./customFilter";
import LocalCustomFilter from "./localCustomFilter";


dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Etc/UTC");
const { Option } = Select;

const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

const US_TIME_ZONES = [
    "Etc/UTC",
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
    "America/Anchorage",
    "Pacific/Honolulu",
];

const GetColumnSearchProps = (dataIndex, updateFilters, isLive) => {
    const uniqueId = `activity-filter-${dataIndex.dataIndex}`;

    return ({
        filterDropdown: (props) => <CustomFilter 
            dataIndex={dataIndex.dataIndex} 
            showTimeRange={dataIndex.showTimeRange} 
            updateFilters={updateFilters}
            timeZone={dataIndex.timeZone}
            isLive={true}
            id={uniqueId}
            {...props} 
            />,
        filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined,
              }}
            />
          ),
    })
  };


const expandedRowRender = (record) => {
    let data = [
        {
            InterpreterFirstName: record?.InterpreterFirstName,
            InterpreterLastName: record?.InterpreterLastName,
            ServiceStartTime: record?.ServiceStartTime,
            Timezone: record?.Timezone,
            InterpreterId: record?.InterpreterId,
        },
    ];
    const columns = [
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            width: "30%",
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            render: (text, row) =>
                text ? Formatter?.createLocalDateTime(text, row?.Timezone)?.format("HH:mm:ss") : "-",
            width: "35%",
        },
    ];
    return (
        <Table
            key={record?.Id + "_sub"}
            title={() => <b>Call Accepted By</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            width="20px"
            rowClassName={(record, index) =>
                record?.AcceptTime ? "accept-row" : "declined-row"
            }
        />
    );
};

const ActivityMonitor = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const userRole = useSelector((state) => state.profile?.user?.permissions?.role);
    const monitorAccess = useSelector((state) => state.profile?.user?.permissions?.monitorAccess);

    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        let filters = {};

        for (let i = 0; ; i++) {
            const dataIdx = searchParams?.get(`dataIdx${i}`);
            const status = searchParams?.get(`status${i}`);

            if (dataIdx && status) {
                filters[dataIdx] = status;
            } else {
                break;
            }
        }

        return filters;
    };
    const dashboardFilters = getDashboardFilters();

    const {
        token: { colorText, colorBgLayout },
    } = theme.useToken();
    const [transactionId, setTransactionId] = useState();

    const state = useSelector((state) => state);
    const columnState = useSelector((state) => state.selectedColumns);
    const userId = useSelector((state) => state?.profile?.user?.id);
    const { selectedColumns, unselectedColumns } = columnState;
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [tableLoading, setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
    });
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filterSearch, setFilterSearch] = useState(dashboardFilters);
    const [params, setParams] = useState();
    const [currentSorter, setCurrentSorter] = useState({});

    const searchInput = useRef(null);
    const abortControllerRef = useRef(null);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const timeZoneRef = useRef("America/New_York");
    const handleTimeZoneChange = (value) => {
        console.log("handleTimeZoneChange");
        setTimeZone(value);
        timeZoneRef.current = value;
    };
    const createSorter = (dataIndex) => (a, b) => {};
    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);

    const getDefaultColumns = () => [
        {
            title: "Transaction ID",
            dataIndex: "ReferenceTransactionId",
            sorter: (a, b) => a.ReferenceTransactionId - b.ReferenceTransactionId,
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'ReferenceTransactionId',
                dataSource: data,
              }),
            render: (text) => text || "-",
             width: "130px",
        },
        {
            title: "Request Date",
            dataIndex: "RequestTime",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "RequestDate" }, updateFilters),
            render: (text, record) =>
                record.ConvertedDate
                    ? record?.ConvertedDate
                    : moment(record?.RequestTime)
                          .tz("America/New_York")
                          .format("YYYY-MM-DD"),
        },
        {
            title: "Request Time",
            dataIndex: "ExtractedTime",
            align: "center",
            ...GetColumnSearchProps({
                dataIndex: "ExtractedTime",
                value: undefined,
                showTimeRange: true,
                timeZone: timeZoneRef.current
            }, updateFilters),
            render: (text, record) =>
                record.ConvertedTime
                    ? record?.ConvertedTime
                    : moment(record?.RequestTime)
                          .tz("America/New_York")
                          .format("HH:mm:ss")
        },
        {
            title: "Client Name",
            dataIndex: "ClientName",
            align: "center",
            //width: "150px",
            sorter: (a, b) => a.ClientName?.localeCompare(b.ClientName),
            ...LocalCustomFilter({
                dataIndex: 'ClientName',
                dataSource: data,
              }),
        },
        {
            title: "Time Zone",
            dataIndex: "Timezone",
            align: "center",
        },
        {
            title: "Target Language",
            dataIndex: "TargetLanguage",
            align: "center",
            width: "130px",
            sorter: (a, b) => a.TargetLanguage?.localeCompare(b.TargetLanguage),
            ...LocalCustomFilter({
                dataIndex: 'TargetLanguage',
                dataSource: data,              
              }),
        },
        {
            title: "Call Type",
            dataIndex: "VideoOption",
            align: "center",
            sorter: (a, b) => a.VideoOption?.localeCompare(b.VideoOption),
            ...LocalCustomFilter({
                dataIndex: 'VideoOption',
                dataSource: data,
              }),
            width: "150px",
        },
        {
            title: "Status",
            dataIndex: "Status",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'Status',
                dataSource: data,
              }),
        },
        {
            title: "Waiting Seconds",
            dataIndex: "WaitingSeconds",
            align: "center",
            sorter: (a, b) => a.WaitingSeconds - b.WaitingSeconds,
        },
        {
            title: "Service Minutes",
            dataIndex: "ServiceMinutes",
            align: "center",
            sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
            render: (text) => text || "-",
        },
        {
            title: "Star Rating",
            dataIndex: "CallQualityRatingStar",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'CallQualityRatingStar',
                dataSource: data,
              }),
            render: (text) => text || "-",
        },
        {       
            title: "Routing Counts",
            dataIndex: "RoutingHistoryLength",
            align: "center",
            sorter: (a, b) => a.RoutingHistoryLength - b.RoutingHistoryLength,
            ...LocalCustomFilter({
                dataIndex: 'RoutingHistoryLength',
                dataSource: data,
              }),
            render: (text, record) => {
                if (
                    record.Status === "New" ||
                    record.Status === "Canceled" ||
                    record.RouteToBackup === "Yes" ||
                    record.RoutingHistoryLength === "undefined" ||
                    record.RoutingHistoryLength === 0 || 
                    record.RoutingHistoryLength == undefined
                ) {
                    return "N/A";
                } else {
                    return (
                        <div>
                            <Link 
                                to={`/pages/terp-hud/routing-history?terp=${record.RoutingHistoryLength}&id=${record.ReferenceTransactionId}`}
                            >
                                {record.RoutingHistoryLength} <LinkOutlined />
                            </Link>
                        </div>
                    );
                }
            },
        },
        {
            title: "Caller Id",
            dataIndex: "CallerID",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'CallerID',
                dataSource: data,
              }),
            render: (text) => text || "-",
        },
    ];

    const getAdditionalColumns = () => [
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            align: "center",
            sorter: (a, b) => a.ServiceStartTime?.localeCompare(b.ServiceStartTime),
            render: (text, record) =>
                record.ConvertedServiceStartTime
                    ? record.ConvertedServiceStartTime
                    : record.ServiceStartTime
                    ? moment(text)
                          .tz("America/New_York")
                          .format("HH:mm:ss")
                    : "-",

            //width: "120px",
        },
        {
            title: "Cancel Time",
            dataIndex: "ServiceCancelTime",
            align: "center",
            sorter: (a, b) => a.ServiceCancelTime - b.ServiceCancelTime,
            //width: "120px",
            render: (text, record) =>
                record.ConvertedCancelTime
                    ? record.ConvertedCancelTime
                    : record.ServiceCancelTime
                    ? moment(record.ServiceCancelTime)
                          .tz("America/New_York")
                          .format("HH:mm:ss")
                    : "-",
        },
        {
            title: "Client User Name",
            dataIndex: "UserName",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'UserName',
                dataSource: data,
              }),
            width: "150px",
        },
        {
            title: "Route to Backup",
            dataIndex: "RouteToBackup",
            align: "center",
            sorter: (a, b) => a?.RouteToBackup?.localeCompare(b?.RouteToBackup),
            ...LocalCustomFilter({
                dataIndex: 'RouteToBackup',
                dataSource: data,
              }),
            render: (text) => text || "-",
        },
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'InterpreterId',
                dataSource: data,
              }),
            render: (text) => text || "-",
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            ...LocalCustomFilter({
                dataIndex: 'InterpreterFirstName',
                dataSource: data,
              }),
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Serial Number",
            dataIndex: "IOSSerialNumber",
            align: "center",
            width: "150px",
            ...LocalCustomFilter({
                dataIndex: 'IOSSerialNumber',
                dataSource: data,
              }),
            render: (text) => text || "-",
        },
    ];

    const getCustomColumns = async () => {
        const response = await Service.Auth().getCustomColumns(userId);
        const customColumns = response;
        let { preSelectedColumns, preUnSelectedColumns } = customColumns;
    
        const defaultColumns = getDefaultColumns();
        const additionalColumns = getAdditionalColumns();
        // create an copy of array
        let col = [...defaultColumns, ...additionalColumns];
        
        if (preSelectedColumns || preUnSelectedColumns) {
            const selectedColumns = [];
            const unselectedColumns = [];

            col.forEach((e) => {
                if (preSelectedColumns.includes(e.dataIndex)) {
                    selectedColumns.push(e);
                } else {
                    unselectedColumns.push(e);
                }
            })
            dispatch(updateColumns(selectedColumns, unselectedColumns, userId));
            return { selectedColumns, unselectedColumns };
        } else {
            let defaultCol = getDefaultColumns();
            let addCol = getAdditionalColumns();

            dispatch(updateColumns(defaultCol, addCol, userId));
            return { defaultCol, addCol };
        }
    };

    const fetchDataCB = useCallback(async () => {
        fetchDataPrivate( params?.filters);
    }, [params]);

    const fetchData = useIntervalAsync(
        fetchDataCB,
        12000
    );

    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData();
        }
    }, [params]);

    useEffect(() => {
        setTableLoading(true);
        setParams({
            filters: tableParams.filters,
            sorter: currentSorter,
        });
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.client,
        filterSearch,
        timeZone
    ]);

    const initialColumns = selectedColumns
        ? selectedColumns
        : getDefaultColumns();
    const initialUnselectedColumns = unselectedColumns
        ? unselectedColumns
        : getAdditionalColumns();
    const [columns, setColumns] = useState(initialColumns);
    const [columnsUnselected, setColumnsUnselected] = useState(
        initialUnselectedColumns
    );
   
    useEffect(() => {
        getCustomColumns().then(({ selectedColumns, unselectedColumns }) => {
            setColumns(selectedColumns);
            setColumnsUnselected(unselectedColumns);
        });
    }, [data]);

    useEffect(() => {
        const defaultColumns = getDefaultColumns();
        const additionalColumns = getAdditionalColumns();
        const newColumns =
        selectedColumns && selectedColumns.length > 0
            ? selectedColumns
            : defaultColumns;
        const newColumnsUnselected =
            unselectedColumns && unselectedColumns.length > 0
                ? unselectedColumns
                : additionalColumns;
        if (JSON.stringify(newColumns) !== JSON.stringify(columns)) {
            setColumns(newColumns);
        }
        if (
            JSON.stringify(newColumnsUnselected) !==
            JSON.stringify(columnsUnselected)
        ) {
            setColumnsUnselected(newColumnsUnselected);
        }
    }, [selectedColumns, unselectedColumns]);



    const showModal = () => {
        setModalVisible(true);
        console.log("modalVisible: ", modalVisible);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDFFunction();
        } else if (downloadType === "EXCEL") {
            downloadCSVFunction();
        }
    };

    const handleTableChange = ( filters, sorter) => {
        setCurrentSorter(sorter);
        setParams({ filters, sorter });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {

        
        if(selectedKeys.length > 0 && typeof selectedKeys[0] == "string") {
            selectedKeys[0] = selectedKeys[0]?.trim();

            if (selectedKeys[0] == '') {
                handleReset(clearFilters, dataIndex, confirm);
                return;
            }
        }

        if (selectedKeys.length == 0) {
            handleReset(clearFilters, dataIndex, confirm);
            return;
        }

        confirm();
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys,
            };
            setParams({
                filters: { ...tableParams.filters, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            return updatedFilterSearch;
        });
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        confirm();
        setSearchText("");
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = JSON.parse(
                JSON.stringify(prevState || {})
            );
            const updatedTableParams = JSON.parse(
                JSON.stringify(tableParams.filters || {})
            );
            delete updatedTableParams[dataIndex];
            delete updatedFilterSearch[dataIndex];
            setParams({
                filters: { ...updatedTableParams, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            setTableParams((pState) => {
                return { ...pState, filters: updatedTableParams };
            });
            return updatedFilterSearch;
        });
    };

    const fetchDataPrivate = async ( filters, ) => {
        let params = tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZoneRef.current
        };
        Service.Transaction()
            .getActiveTransactions(
                filter,
                loading
            )
            .then((data) => {
                setData(data?.length > 0 ? formatData(data, timeZoneRef.current) : []);
                if (data !== undefined) {
                    setTableLoading(false);
                }
            });
    };

    const formatData = (rawData, timezoneStr) => {
        return rawData?.map((obj) => {
            let convertRequestTime = moment(obj.RequestTime).tz(timezoneStr);
            let convertedCancelTime, convertedServiceStartTime;
            let convertedDate = convertRequestTime.format("YYYY-MM-DD");
            let convertedTime = convertRequestTime.format("HH:mm:ss");
            if (obj.ServiceStartTime && obj.ServiceStartTime.length > 0) {
                convertedServiceStartTime = moment(obj.ServiceStartTime)
                    .tz(timezoneStr)
                    .format("HH:mm:ss");
            }
            if (obj.ServiceCancelTime && obj.ServiceCancelTime.length > 0) {
                convertedCancelTime = moment(obj.ServiceCancelTime)
                    .tz(timezoneStr)
                    .format("HH:mm:ss");
            }

            let newObj = {
                ...obj,
                ConvertedDate: convertedDate,
                ConvertedTime: convertedTime,
                ConvertedServiceStartTime: convertedServiceStartTime,
                ...(convertedCancelTime && {
                    ConvertedCancelTime: convertedCancelTime,
                }),
            };
            return newObj;
        });
    };


    const downloadCSVFunction = ( filters) => {
        let params = tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZone,
            // filters: filterSearch,
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        if (!dataIndexValues.includes('InterpreterId')) {
            dataIndexValues.push('InterpreterId');
        }
        if (!dataIndexValues.includes('InterpreterFirstName')) {
            dataIndexValues.push('InterpreterFirstName');
        }
        Service.Transaction()
            .downloadActivityCSV(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const filename = `Activity_Monitor_Records_${downloadRangeStart}_to_${downloadRangeEnd}`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    const downloadPDFFunction = ( filters) => {
        let params = tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
            timeZone: timeZone,
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        Service.Transaction()
            .downloadActivityPDF(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const filename = `Activity_Monitor_Records_${downloadRangeStart}_to_${downloadRangeEnd}`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };


    // Create a legend to indicate row background colors
    const legend = (
        <div className="legend-container"> 
        <div className="legend-item">
            <div
            className="legend-color-box"
            style={{ backgroundColor: '#00b4e6be' }} // New transaction color
            ></div>
            <span>New Transaction</span>
        </div>
        <div className="legend-item"> 
            <div
            className="legend-color-box"
            style={{ backgroundColor: '#00cc66ba' }} // In-service color
            ></div>
            <span>In-Service Transaction</span>
        </div>
        </div>
    );

    // Get dynamic table scroll width
    const totalMinWidth = columns?.reduce((acc, column) => {
        if (column?.width) {
          return acc + parseInt(column?.width, 10);
        }
        return acc + 120;
    }, 0);

    return (
        <div className="roles-container">
            <Row className="irh-container">
                <Col flex={"100%"}>
                    <Table
                        title={() => (
                            <Row className="select-col">
                                <Col flex={"auto"}>
                                    <Button
                                        onClick={showModal}
                                        className="select-col-btn"
                                        type="primary"
                                    >
                                        Select Columns
                                    </Button>
                                </Col>
                                <Col flex={"auto"} className="legend-container">
                                    {legend}
                                </Col>
                                <Col className="dropdown-col">
                                    <Row
                                        gutter={[8, 8]}
                                        justify="end"
                                        align="middle"
                                        className="dropdown-download-row"
                                    >
                                        <Col>
                                            <DropDown
                                                className="dropdown-element"
                                                defaultValue={downloadType}
                                                dropdownOptions={
                                                    downloadDropdownType
                                                }
                                                handleTypeSelect={(
                                                    downloadType
                                                ) =>
                                                    setDownloadType(
                                                        downloadType
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                onClick={downloadFile}
                                            >
                                                Download
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        style={{
                            backgroundColor: colorBgLayout,
                        }}
                        scroll={{ x: totalMinWidth }}
                        columns={[...columns, ...(((userRole === SUPER_ADMIN || userRole === ADMIN || userRole === MONITOR) && monitorAccess)?[{
                            title: "Monitor",
                            dataIndex: "action",
                            align: "center",
                            width: "150px",
                            render: (text, record) => (
                                record?.Status == "In Service" && <Button 
                                    type="primary"
                                    onClick={() => setTransactionId(record?.ReferenceTransactionId)}
                                >
                                    Monitor
                                </Button>
                            )
                        }]: [])]}
                        rowKey={(record) => record.Id}
                        dataSource={data}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        size="small"
                        className="voyce-custom-table responsive-table"
                        expandable={{
                            expandedRowRender,
                            defaultExpandAllRows: false,
                        }}
                        sticky
                        pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100']
                        }}
                        rowClassName={(record, index) =>
                            record?.Status == "New"
                                ? "new-row"
                                : record?.Status == "In Service"
                                ? "inservice-row"
                                : record?.Status == "Pending"
                                ? "pending-row"
                                : "other-row"
                        }
                    />
                </Col>
            </Row>
            <DragnDrop
                defaultColumns={columns}
                additionalColumns={columnsUnselected}
                modalVisible={modalVisible}
                handleCancel={handleCancel}
            />
            <Monitor 
                open={transactionId}
                handleClose={() => setTransactionId()}
                transactionId={transactionId}
            />
        </div>
    );
};

export default ActivityMonitor;