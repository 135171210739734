import {
    Table,
    Spin,
} from "antd";
import { useEffect, useState } from "react";
import "./transaction.scss";
import Service from "../../services";
import moment from "moment";
import "moment-timezone";
import "jspdf-autotable";
import Formatter from "../../utils/Formatter";
import { ArrowDownOutlined, ArrowUpOutlined, CustomerServiceFilled, DownOutlined, UserOutlined } from "@ant-design/icons";
import IntakeQuestions from "./intakeQueations";

const ExpandedRowRender = ({record}) => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false)
    // useEffect(() => {
    //     setLoading(true)
    //     Service.Transaction()
    //     [
    //         record?.VideoOption == "Audio" ? "getAudioCallDetails" : "getVideoCallDetails"
    //     ](record.ReferenceTransactionId)
    //     .then((data) => {
    //         setLoading(false);
    //         setDetails(data)
    //     })
    // }, [record.ReferenceTransactionId])

    let data = [
        {
            InterpreterFirstName: record?.InterpreterFirstName,
            InterpreterLastName: record?.InterpreterLastName,
            ServiceStartTime: record?.ServiceStartTime,
            Timezone: record?.Timezone,
            InterpreterId: record?.InterpreterId
        },
    ];

    const columns = [
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            width: '30%',
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            render: (text, record) =>
                record.ConvertedServiceStartTime
                    ? record.ConvertedServiceStartTime
                    : record.ServiceStartTime
                    ? moment(text)
                          .tz("America/New_York")
                          .format("YYYY-MM-DD HH:mm:ss")
                    : "-",
            width: "35%",
        },
    ];

    const audioColumns = [
        {
            title: "Entity",
            dataIndex: "Entity",
            width: "150px",
            render: (text) => <div> {
                (text == "Provider" ? 
                    <><ArrowDownOutlined style={{color: "green" }}/> &nbsp; <CustomerServiceFilled/> &nbsp; Interpreter</>  : 
                    <><ArrowUpOutlined style={{color: "red"}}/> &nbsp; <UserOutlined/> &nbsp; Client </> )|| "-"
            } </div>,
        },
        {
            title: "Conference Start Time",
            dataIndex: "ConferenceStartTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text, 4) || "-"} </div>,
        },
        {
            title: "Audio Service Start Time",
            dataIndex: "TwilioStartTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Audio Service End Time",
            dataIndex: "TwilioEndTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Total Time Serviced",
            dataIndex: "Seconds",
            render: (text) => <div> {(text ? (text/60).toFixed(2) + " Minutes" : undefined) || "-"} </div>,
        },
        {
            title: "Call Direction",
            dataIndex: "CallDirection",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Errors",
            dataIndex: "HasError",
            render: (text) => <div> {(text ? "Yes" : "No")|| "-"} </div>,
        },
        {
            title: "Call Direction",
            dataIndex: "CallDirection",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Audio Status",
            dataIndex: "AudioStatus",
            render: (text) => <div> {Formatter.convertCamelToSpaces(text) || "-"} </div>,
        },
        {
            title: "Conference Session Status",
            dataIndex: "ConferenceSessionStatus",
            render: (text) => <div> {Formatter.convertCamelToSpaces(text) || "-"} </div>,
        },
    ];

    const videoColumns = [
        {
            title: "Entity",
            dataIndex: "Entity",
            width: "150px",
            render: (text) => <div> {
                (text == "Provider" ? 
                    <><ArrowDownOutlined style={{color: "green" }}/> &nbsp; <CustomerServiceFilled/> &nbsp; {text}</>  : 
                    <><ArrowUpOutlined style={{color: "red"}}/> &nbsp; <UserOutlined/> &nbsp; {text} </> )|| "-"
            } </div>,
        },
        {
            title: "Conference Start Time",
            dataIndex: "ConferenceStartTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text, 4) || "-"} </div>,
        },
        {
            title: "Room Create Time",
            dataIndex: "VideoRoomCreateDate",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Room End Time",
            dataIndex: "VideoRoomEndDate",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Total Room Duration",
            dataIndex: "VideoRoomDuration",
            render: (text) => <div> {(text ? (text/60).toFixed(2) + " Minutes" : undefined) || "-"} </div>,
        },
        {
            title: "Participant Status",
            dataIndex: "ParticipantStatus",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Participant Connect Time",
            dataIndex: "PaticipantConnectTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Participant Disconnect Time",
            dataIndex: "PaticipantDisconnectTime",
            render: (text) => <div> { Formatter.getTImeFromUTC(text) || "-"} </div>,
        },
        {
            title: "Total Participant Duration",
            dataIndex: "ParticipantDuration",
            render: (text) => <div> {(text ? (text/60).toFixed(2) + " Minutes" : undefined) || "-"} </div>,
        },
    ];

    return (
       <Spin spinning={loading}>
            
            <Table
                key={record?.Id + "_sub"}
                title={() => <b>Call Accepted By</b>}
                size="small"
                className="expanded-table"
                columns={columns}
                dataSource={data}
                width="20px"
                pagination={false}
                rowClassName={(record, index) =>
                    record?.AcceptTime ? "accept-row" : "declined-row"
                }
            />
            <IntakeQuestions record={record} key={record?.Id + "_sub_sub"} />
            {/*<br/>
            <Table
                // key={record?.Entity + moment().toISOString()}
                title={() => <b>Connection Details</b>}
                size="small"
                className="expanded-table"
                columns={ record?.VideoOption == "Audio" ? audioColumns : videoColumns}
                dataSource={details}
                pagination={false}
                rowClassName={(record, index) =>
                    record?.Entity == "Provider" ? "accept-row" : "declined-row"
                }
            />*/}
        
       </Spin>
    );
};

export default ExpandedRowRender;