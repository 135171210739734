import { Axios } from "axios";
import constants from "../constants";

class InvoiceService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async analytics(dateFilter) {
        try {
            let response = await this.request.post(
                '/invoices/total',
                dateFilter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getAllInvoices(dateFilter) {
        try {
            let response = await this.request.post(
                'invoices/payment-date',
                dateFilter,
                {filter: true}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

}

export default InvoiceService;
