import { useEffect } from 'react'
import { useHud } from '../store/hud.context'
import { Button, Col, Modal, Row, Spin, Typography } from 'antd'
import EvaluationForm from './form'

const Monitor = () => {
  const {
    monitorVisible,
    monitorUrl,
    loading,
    handleMonitorClose,
    evaluating,
    handleLeaveEvaluation,
    handleEvaluationSart
  } = useHud()

  return (
    <Modal
      keyboard={false}
      centered
      title={
        <Row>
          <Col flex={'auto'}>
            <Typography.Text strong>
              &nbsp;&nbsp;Activity Monitor
            </Typography.Text>
          </Col>
        </Row>
      }
      closeIcon={false}
      footer={
        <Row gutter={10}>
          <Col flex={'auto'}>
            <Button
              type="primary"
              block
              onClick={evaluating ? handleLeaveEvaluation : handleMonitorClose}
              danger
            >
              {evaluating ? 'Leave Evaluation' : 'Leave'}
            </Button>
          </Col>
          <Col hidden={evaluating} flex={'auto'}>
            <Button
              disabled={loading}
              type="primary"
              block
              onClick={handleEvaluationSart}
            >
              Start Evaluation
            </Button>
          </Col>
        </Row>
      }
      open={monitorVisible}
      onCancel={handleMonitorClose}
      destroyOnClose
      width={'95%'}
      className="monitor-modal"
      maskClosable={false}
      styles={{
        content: {
          padding: 5
        },
        footer: {
          // display: 'none'
        }
      }}
    >
      <Row justify={'center'} align={'middle'}>
        <Col flex={'auto'}>
          <Spin spinning={loading} tip="Loading Monitor..." size="large">
            <Row justify={'center'} align={'middle'} wrap={false}>
              <Col flex={'auto'}>
                <iframe
                  style={{
                    width: '100%',
                    borderRadius: 8,
                    border: '0.5px solid rgba(71, 70, 70, 0.355)',
                    height: 780
                  }}
                  allow="camera;microphone"
                  src={monitorUrl}
                >
                  {}
                </iframe>
              </Col>
              <Col hidden={!evaluating} flex={'25%'}>
                <EvaluationForm />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Modal>
  )
}

export default Monitor
