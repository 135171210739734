import { useEffect, useState } from "react";
import {
    Table,
    theme,
    Row,
    Col,
    Spin,
    Card,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import GetColumnSearchProps from "../../../components/table/search";
import "jspdf-autotable";
import { auditData, auditAggData } from "../../../store/actions/audit.action";
import "./audit.scss";
import Formatter from "../../../utils/Formatter";
import LocalCustomFilter from '../../transactions/localCustomFilter'

const expandedRowRender = (record) => {
    let data = JSON.parse(JSON.stringify(record.loginHistory));
    const columns = [
        {
            title: "Login Time",
            dataIndex: "loginTime",
            render: (text) =>
                text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-",
            sorter: (a, b) => moment(a?.loginTime) - moment(b.loginTime),
        },
        {
            title: "Logout Time",
            dataIndex: "logoutTime",
            render: (text, record) =>
                text && record.logoutSuccess
                    ? moment(text).format("YYYY-MM-DD HH:mm:ss")
                    : "-",
            sorter: (a, b) => {
                const dateA =
                    a?.logoutTime && a?.logoutSuccess
                        ? moment(a?.logoutTime)
                        : moment("1900-01-01");
                const dateB =
                    b?.logoutTime && b?.logoutSuccess
                        ? moment(b?.logoutTime)
                        : moment("1900-01-01");
                return dateA - dateB;
            },
        },
        {
            title: "Login Minutes",
            dataIndex: "usageMinute",
            sorter: (a, b) => {
                const aValue =
                    a?.usageMinute !== undefined && a?.usageMinute !== null
                        ? a.usageMinute
                        : Infinity;
                const bValue =
                    b?.usageMinute !== undefined && b?.usageMinute !== null
                        ? b.usageMinute
                        : Infinity;

                return aValue - bValue;
            },
            render: (text, record) =>
                text !== undefined && text !== null && record?.logoutSuccess
                    ? text
                    : "-",
        },
        {
            title: "IP Address",
            dataIndex: "ipAddress",
        },
        {
            title: "City",
            dataIndex: "city",
        },
        {
            title: "Region",
            dataIndex: "region",
        },
        {
            title: "Country",
            dataIndex: "country",
        },
        {
            title: "Time Zone",
            dataIndex: "timeZone",
        },
    ];
    return (
        <Table
            key={record?._id + "_sub"}
            title={() => <b>Login History</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            pagination={{ className: "custom-pagination" }}
            rowClassName={(record, index) => record?.logoutSuccess ? "logout-success" : "logout-unsuccess"}
        />
    );
};

const UserAudit = () => {
    const {
        token: { colorText, colorBgLayout },
    } = theme.useToken();

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [loading, setLoading] = useState(true);
    const userInfo = useSelector((state) => state?.audit?.data);
    const aggUserInfoRes = useSelector((state) => state?.audit?.aggData);
    const aggUserInfo = aggUserInfoRes[0];

    useEffect(() => {
        setLoading(true);
        dispatch(auditData())
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        dispatch(auditAggData());
    }, [state.filter?.date, state.company?.selected, state.company?.client]);

    // Define the columns for the table
    const columns = [
        {
            title: "Name",
            dataIndex: "username",
            key: "username",
            ...LocalCustomFilter({
                dataIndex: 'username',
                dataSource: userInfo,
                // emptySearch: true
              }),
            render: (text, record) => record?.loginHistory[0]?.username,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...LocalCustomFilter({
                dataIndex: 'email',
                dataSource: userInfo,
                // emptySearch: true
              }),
            render: (text, record) => record?.loginHistory[0]?.email,
        },
        {
            title: "Login Frequency",
            dataIndex: "loginCount",
            key: "loginCount",
            sorter: (a, b) => a.loginCount - b.loginCount,
            // ...GetColumnSearchProps({ dataIndex: "loginCount", isDate: false }),
        },
        {
            title: "Total Login Minutes",
            dataIndex: "totalUsageMinutes",
            key: "totalUsageMinutes",
            sorter: (a, b) => a.totalUsageMinutes - b.totalUsageMinutes,
            defaultSortOrder: "descend",
        },
    ];

    const RenderWidget = ({ title, value }) => {
        const headStyle = {
            backgroundColor: '#6c40a6',
            color: 'white'
        };
        return (
            <Card
                title={title}
                bordered={true}
                className="render-card"
                loading={loading}
                headStyle={headStyle} 
            >
                <p>{value}</p>
            </Card>
        );
    };

    return (
            <Row gutter={16} flex={"auto"} className="language-col">
                <Col xs={24} sm={24} md={12} lg={6} className="card-widget">
                    <RenderWidget
                        title="Total Logged Users"
                        value={
                            aggUserInfo ? Formatter.numberWithCommas(aggUserInfo?.activeUsersCount) : "-"
                        }
                    />
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} className="card-widget">
                    <RenderWidget
                        title="Total Login Counts"
                        value={aggUserInfo ? Formatter.numberWithCommas(aggUserInfo?.totalCount) : "-"}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} className="card-widget">
                    <RenderWidget
                        title="Total Usage Minutes"
                        value={
                            aggUserInfo ? Formatter.numberWithCommas(aggUserInfo?.totalUsageMinutes) : "-"
                        }
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} className="card-widget">
                    <RenderWidget
                        title="Average Usage Minutes"
                        value={
                            aggUserInfo
                                ? Formatter.numberWithCommas(Math.round(aggUserInfo?.averageUsageMinutes))
                                : "-"
                        }
                    />
                </Col>
                <Col span={24} style={{ marginTop: 30 }}>
                    {loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                            <span className="loading-text">
                                Cooking up your data...
                            </span>
                        </div>
                    ) : (
                        <Table
                            rowKey={(record) => record?._id}
                            dataSource={userInfo}
                            columns={columns}
                            expandable={{
                                expandedRowRender,
                            }}
                            pagination={{ 
                                defaultPageSize: 100,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100']
                            }}
                        />
                    )}
                </Col>
            </Row>
    );
};

export default UserAudit;