import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, message, Modal } from 'antd';
import useSocket from '../../../components/hooks/useSocket';
import events from '../socket.events';
import { useSelector } from 'react-redux';
import moment from "moment";
import { render } from '@testing-library/react';
import ScoreCard from '../score.card/score.card';
import GetColumnSearchProps from "../../../components/table/search";
import './active.session.scss'


const ActiveSession = ({interpreters, expanded, socket, ongoingSessions}) => {
  const [monitorModalVisible, setMonitorModalVisible] = useState(false);
  const [selectedInterpreter, setSelectedInterpreter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const {token} = useSelector((state) => state?.profile);
  const [showScoreCard, setShowScoreCard] = useState(false); 
  const  qaSpecialistId  = useSelector((state) => state?.profile?.user?.id);

  // Filter interpreters to only include those added to the watchlist
  const interpretersAddedToWatchlist = interpreters.filter(interpreter => interpreter.watchlist);

  // Split filtered interpreters into two arrays: added by user and the rest
  const interpretersAddedByUser = interpretersAddedToWatchlist.filter(interpreter => interpreter.watchlist?.addedByQaSpecialistId === qaSpecialistId);
  const remainingInterpreters = interpretersAddedToWatchlist.filter(interpreter => interpreter.watchlist?.addedByQaSpecialistId !== qaSpecialistId);

    // Sort each array in ascending order based on the Status field
  interpretersAddedByUser.sort((a, b) => a.Status.localeCompare(b.Status));
  remainingInterpreters.sort((a, b) => a.Status.localeCompare(b.Status));

  // Concatenate the two arrays, putting added-by-user interpreters first
  const sortedInterpreters = [...interpretersAddedByUser, ...remainingInterpreters];

  const handleInterpreterClick = (record) => {
    // Handle interpreter click and show the monitor modal
    setSelectedInterpreter(record);
    setMonitorModalVisible(true);
  };

  const handleCancel = () => {
    setMonitorModalVisible(false);
    setShowScoreCard(false)
    setSelectedInterpreter(null);
  };

  const handleMonitorModalYes = useCallback(() => {
    if (ongoingSessions[selectedInterpreter?.RequestId]) {
      Modal.info({
        title: 'Interpreter Under Evaluation',
        content: 'Please Consider Trying Another Interpreter'
      })
      handleCancel()
    } else {
      setShowScoreCard(true);
      socket.emit(events.START_INTERPRETER_MONITORING, {
        interpreterId: selectedInterpreter?.InterpreterId,
        requestId: selectedInterpreter?.RequestId,
        interpreterName: selectedInterpreter?.FirstName+ " " + selectedInterpreter?.LastName,
      });
    }
  }, [ongoingSessions, selectedInterpreter]);

  const handleMonitorModalNo = () => {
    setShowScoreCard(false);
    handleCancel();
  };

  const notExpandedColumns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      ...GetColumnSearchProps({dataIndex: 'InterpreterId', isDate:false}),
      render: (text, row) => (
        row.Status === 'In conference' ? (
          <Button size={expanded ? 'middle' : 'small'} type="link" onClick={() => handleInterpreterClick(row)}>
            {text}
          </Button>
        ) : (
          <span>{text}</span>
        )
      ),
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`.trim().toLowerCase();
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`.trim().toLowerCase();
        return aName.localeCompare(bName);
      },
      ...GetColumnSearchProps({ dataIndex: 'InterpreterName', isDate: false }),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => a?.Status?.localeCompare(b?.Status),
      ...GetColumnSearchProps({ dataIndex: 'Status', isDate: false }),
    },
    
    // Additional columns as needed
  ];


  const columns = [
    {
      title: 'ID',
      dataIndex: 'InterpreterId',
      key: 'InterpreterId',
      ...GetColumnSearchProps({ dataIndex: 'InterpreterId' }),
      sorter: (a, b) => a?.InterpreterId - b?.InterpreterId,
      render: (text, row) => (
        row.Status === 'In conference' ? (
          <Button type="link" onClick={() => handleInterpreterClick(row)}>
            {text}
          </Button>
        ) : (
          <span>{text}</span>
        )
      ),
    },
    {
      title: 'Name',
      dataIndex: 'InterpreterName',
      key: 'InterpreterName',
      sorter: (a, b) => {
        const aName = `${a?.InterpreterName || ''} ${a?.LastName || ''}`.trim().toLowerCase();
        const bName = `${b?.InterpreterName || ''} ${b?.LastName || ''}`.trim().toLowerCase();
        return aName.localeCompare(bName);
      },
      ...GetColumnSearchProps({dataIndex: 'InterpreterName', isDate:false}),
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyCode',
      key: 'CompanyCode',
      sorter: (a, b) => a?.CompanyCode.localeCompare(b?.CompanyCode),
      ...GetColumnSearchProps({dataIndex: 'CompanyCode', isDate:false}),
    },
    {
      title: 'Languages',
      dataIndex: 'Languages',
      key: 'Languages',
      render: (Languages) => (Languages ? Languages.join(', ') : ''),
      sorter: (a, b) => (a?.Languages || []).join(',').localeCompare((b?.Languages || []).join(',')),
      ...GetColumnSearchProps({dataIndex: 'Languages', isDate:false}),
    },
    {
      title: 'Add Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => {
        const aDate = a?.watchlist?.updatedAt ? moment(a.watchlist.updatedAt).format('YYYY-MM-DD') : null;
        const bDate = b?.watchlist?.updatedAt ? moment(b.watchlist.updatedAt).format('YYYY-MM-DD') : null;
        if (aDate && bDate) {
          return aDate?.localeCompare(bDate);
        }
        return 0;
      },
      render: (text, record) => {
        return record?.watchlist?.updatedAt
          ? moment(record.watchlist.updatedAt).format('YYYY-MM-DD')
          : 'No Data';
      },
      width: 150,
    },  
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => a?.Status?.localeCompare(b?.Status),
      ...GetColumnSearchProps({dataIndex: 'Status', isDate:false}),
    },
    {
      title: 'Watchlist',
      key: 'addToWatchlist',
      align: 'center',
      render: (text, record) => (
        <WatchlistButton
          key={`watchlist-button-${record.InterpreterId}`}
          record={record}
          socket={socket}
        />
      ),
    },
  ];

  const WatchlistButton = (props) => {
    const { watchlist, InterpreterId } = props.record;
    const socket = props.socket;
    const [loading, setLoading] = useState(false); // Add loading state
    const [buttonText, setButtonText] = useState(watchlist ? 'Remove' : 'Add'); // Set initial button text
  
    useEffect(() => {
      if (watchlist) {
        setLoading(false);
        setButtonText('Remove');
      } else {
        setLoading(false);
        setButtonText('Add');
      }
    }, [watchlist]);
  
    useEffect(() => {
      // Listen for the confirmation events and update loading state and button text accordingly
      const handleAddedToWatchlist = (data) => {
        if (data.interpreterId === InterpreterId) {
          setLoading(false);
          setButtonText('Remove');
        }
      };
  
      const handleRemovedFromWatchlist = (data) => {
        if (data.interpreterId === InterpreterId) {
          setLoading(false);
          setButtonText('Add');
        }
      };
  
      socket.on(events.ADDED_INTERPRETER_TO_WATCHLIST, handleAddedToWatchlist);
      socket.on(events.REMOVED_INTERPRETER_TO_WATCHLIST, handleRemovedFromWatchlist);
  
      return () => {
        // Remove event listeners when component unmounts
        socket.off(events.ADDED_INTERPRETER_TO_WATCHLIST, handleAddedToWatchlist);
        socket.off(events.REMOVED_INTERPRETER_TO_WATCHLIST, handleRemovedFromWatchlist);
      };
    }, [socket, InterpreterId]);
  
    const handleAddToWatchlist = () => {
      setLoading(true);
      console.log('Adding to watchlist:', InterpreterId);
      socket.emit(events.ADD_INTERPRETER_TO_WATCHLIST, {
        interpreterId: InterpreterId,
      });
    };
  
    const handleRemoveFromWatchlist = () => {
      setLoading(true);
      console.log('Removing from watchlist:', InterpreterId);
      socket.emit(events.REMOVE_INTERPRETER_TO_WATCHLIST, {
        interpreterId: InterpreterId,
      });
    };
  
    return (
      <Button
        size={props?.expanded ? 'middle' : 'small'}
        type={watchlist ? 'primary' : 'primary'}
        onClick={watchlist ? handleRemoveFromWatchlist : handleAddToWatchlist}
        loading={loading}
        danger={buttonText==='Remove'}
        style={{ width: '100%' }}
      >
        <span>{buttonText}</span>
      </Button>
    );
  };
  
  
  

  const useColumns = expanded ? columns : notExpandedColumns;

  const paginationOptions = expanded
  ? { defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'] }
  : false;

  const scrollOptions = expanded ? { x: 500 } : { y: 250 };

  return (
    <>
      <Table
        size={"small"}
        columns={useColumns}
        id={`active-session-table-${expanded ? "expanded" : "unexpanded"}`}
        dataSource={sortedInterpreters}
        scroll={scrollOptions}
        pagination={paginationOptions}
        rowClassName={(record, index) => {
          if (record?.watchlist?.addedByQaSpecialistId === qaSpecialistId) {
            return "added-by-user"; // Class name for rows added by user
          } else {
            return "other-rows"; // Class name for other rows
          }
        }}
      />
      <Modal
        title={`Monitor Call for Interpreter ID: ${selectedInterpreter?.InterpreterId}`}
        visible={monitorModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="yes" type="primary" onClick={handleMonitorModalYes}>
            Yes
          </Button>,
          <Button key="no" onClick={handleMonitorModalNo}>
            No
          </Button>,
        ]}
      >
        {/* Render monitor call details and options here */}
        <p>Do you want to monitor the call for Interpreter ID: {selectedInterpreter?.InterpreterId}?</p>
        {/* Add monitor call options */}
      </Modal>
      <ScoreCard
          open={showScoreCard} // Set open to true to show the modal
          transactionId={selectedInterpreter?.RequestId}
          handleClose={handleCancel}
          InterpreterId={selectedInterpreter?.InterpreterId}
          interpreter={selectedInterpreter}
          interpreterName={selectedInterpreter?.FirstName+ " " + selectedInterpreter?.LastName}
          socket={socket}
        />
    </>
  );
};

export default ActiveSession;
