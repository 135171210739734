import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';
import moment from 'moment';
import { InvoiceEnum } from '../../invoice.enum';

export function setAnalytics(analytics) {
    return {
        type: ActionType.SET_INVOICE_ANALYTICS,
        analytics
    };
}

export function setMonthInvoices(month, rawData) {
    let state = store.getState();
    let invoiceType = state.filter.invoiceType;
    let filteredInvoices = [];
    let invoices = [];
    
    // Check if rawData is passed from outside the state scope.
    if (rawData) {
        invoices = rawData?.find((e) => e?.month == month)?.rawData || []; 
    } else {
        invoices = state.invoice?.bar?.find((e) => e?.month == month)?.rawData || []; 
    }

    if (invoiceType == InvoiceEnum.ALL) {
        filteredInvoices = JSON.parse(JSON.stringify(invoices));
    } else if (invoiceType == InvoiceEnum.PAID) {
        filteredInvoices = JSON.parse(JSON.stringify(invoices.filter((e) => e.invoiceStatus == InvoiceEnum.PAID)))
    } else if (invoiceType == InvoiceEnum.PENDING) {
        filteredInvoices = JSON.parse(
            JSON.stringify(
                invoices.filter((invoice) => invoice.invoiceStatus == InvoiceEnum.PENDING)
            )
        )
    } else if (invoiceType == InvoiceEnum.OVERDUE) {
        filteredInvoices = JSON.parse(
            JSON.stringify(
                invoices.filter((invoice) => invoice.invoiceStatus == InvoiceEnum.OVERDUE)
            )
        )
    }

    return {
        type: ActionType.SET_INVOICES,
        invoices: filteredInvoices
    };
}

export function setInvoices(invoices, year) {
    let bar = Array.from({length: 12}).map((value, i) => ({
        month: moment().startOf("year").add(i, 'months').add(1, "days").format("MMMM"),
        invoices: 0,
        paid: 0,
        pending: 0,
        overdue: 0,
        total: 0,
        rawData: []
    }))

    let colors = [
        '#0a9191',
        '#6c40a6'
    ]

    let scatter = [];

    for (let i=0; i < invoices.length; i++) {
        let invoice = JSON.parse(JSON.stringify(invoices[i]));
        let monthBar = bar.find((e) => e.month === moment(invoice.TxnDate).format("MMMM"));
        monthBar.invoices = monthBar.invoices + 1;
        if (invoice?.invoiceStatus == InvoiceEnum.PAID) {
            monthBar.paid = monthBar.paid + 1;
        } else if (invoice?.invoiceStatus == InvoiceEnum.OVERDUE) {
            monthBar.overdue = monthBar.overdue + 1;
        } else if (invoice?.invoiceStatus == InvoiceEnum.PENDING) {
            monthBar.pending = monthBar.pending + 1;
        }
        if (invoice?.invoiceStatus == InvoiceEnum.PAID) {
            monthBar.total = monthBar.total + invoice?.TotalAmt;
            monthBar.total = parseFloat(monthBar.total.toFixed(2));
        }
        monthBar.rawData.push(invoice);

        // Push Payment date and Invoice Date for Scatter plot
        if (invoice?.PaymentDate) {
            scatter.push({
                name: "Invoice - " + invoice?.Id,
                value: [ invoice?.PaymentDate, invoice?.TxnDate],
                itemStyle: {
                    color: colors[i % colors.length]
                }
            })
        }
    }

    return {
        type: ActionType.SET_COOKED_INVOICES,
        scatter,
        bar,
        invoices: setMonthInvoices('January', bar).invoices, 
        rawData: invoices
    };
}

/**
 * action to get analytics.
 * @returns 
 */
export function getAnalyticsTotal() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.year?.minDate,
                end: state.filter?.year?.maxDate
            }
            let data = await Service.Invoice().analytics(filter);
            dispatch(setAnalytics(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * action to get invoices.
 * @returns 
 */
export function fetchInvoices() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.year?.minDate,
                end: state.filter?.year?.maxDate
            }
            let data = await Service.Invoice().getAllInvoices(filter);
            dispatch(setInvoices(data, moment(state.filter?.year?.minDat).format("YYYY")));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
