import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';


export function fetchData() {
    return async (dispatch) => {
        try {
            let data = await Service.Terp().getTerpPositionCalls();
            dispatch(fetchDataSuccess(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

export function fetchInterpreters() {
  return async (dispatch) => {
      try {
          let state = store.getState();
          let filter = {
              start: state.filter?.date?.minDate,
              end: state.filter?.date?.maxDate,
              languageType: state.filter.languageType
          }
          let data = await Service.Terp().getInterpreters(filter);
          return data;

      } catch(err) {
          let msg = err?.response?.data?.error?.message
          msg && message.error(msg);
      }
  };
}

export function fetchInterpretersEvaluated() {
  return async (dispatch) => {
      try {
          let state = store.getState();
          let filter = {
              month: 'March'
          }
          let data = await Service.Terp().getEvaluatedInterpreters(filter);
          console.log(data, "DATA IN ACTION")
          return data;
      } catch(err) {
          let msg = err?.response?.data?.error?.message
          msg && message.error(msg);
      }
  };
}


export const fetchDataRequest = () => {
  return {
    type: ActionType.FETCH_DATA_REQUEST
  };
};

export const fetchDataSuccess = data => {
  return {
    type: ActionType.FETCH_DATA_SUCCESS,
    payload: data
  };
};

export const fetchDataFailure = error => {
  return {
    type: ActionType.FETCH_DATA_FAILURE,
    payload: error
  };
};