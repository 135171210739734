import { message } from "antd";
import ActionType from "../action.types";

/*
    Reducer function to push data to the store in case of different profile action types
*/

export default function userManagement(
    state = {
        isLoading: false,
        token: null,
        sessionId: null,
        userList: [],
        terpList: [],
        theme: "light",
        message: "",
    },
    action
) {
    switch (action.type) {
        case ActionType.GET_USER_LIST:
            return {
                ...state,
                userList: action.userList,
            };
        case ActionType.GET_TERP_LIST:
            return {
                ...state,
                terpList: action.terpList,
            }
        case ActionType.UPDATE_USER:
            return {
                ...state,
                userList: action.userList,
            }
        case ActionType.USER_DELETE_SUCCESS:
            return {
                ...state,
                message: action.message,
                isLoading: action.isLoading,
            };
        case ActionType.USER_DELETE_ERROR:
            return {
                ...state,
                message: action.message,
                error: action.hasError,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
}
