import { Button, Card, Col, Divider, Form, Input, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import dots from '../../images/dlogo1.png';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../../store/actions/profile.action";

const Login = () => {
    const [width, setWidth] = useState(700);
    const elementRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setWidth(elementRef.current.clientWidth);
    }, [elementRef.current?.clientWidth]);

    return (
        <div className="login-container">
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{width: 800, padding: 20}}>
                <Card ref={elementRef} bordered={false}>
                    <Row gutter={20} className="title-row" justify={"center"} align={"middle"} >
                        <img src={dots} height={24}/>
                        <Col>VIP Admin</Col>
                    </Row>
                    <br/>
                    <Row align={"stretch"}>
                        <Col flex={"auto"} >
                            <p style={{textAlign: "center"}}><b >Don't have an account? </b></p>
                            <p style={{textAlign: "center"}}>If you don't have an account already, sign up here</p>
                            <Row style={{marginTop: 40}} justify={"center"}>
                            <Button 
                                style={{width: 180}}
                                onClick={() => navigate("/auth/signup")}
                                shape="round" 
                                type="primary">Create an account</Button>
                            </Row>
                        </Col>
                        <Col hidden={width < 690} flex={"10%"}>
                            <Row style={{height: "100%"}} justify={"center"}>
                                <Divider style={{height: "100%"}} type={"vertical"} />
                            </Row>
                        </Col>
                        <Col hidden={width > 690} flex={"100%"}>
                            <Row justify={"center"}>
                                <Divider style={{height: "100%"}} type={"horizontal"}>or</Divider>
                            </Row>
                        </Col>
                        <Col flex={width < 690 ? "auto" : "45%"} >
                            <Form onFinish={(creds) => dispatch(signin({
                                ...creds, 
                                email: creds.email.split('@')[0]+'@voyceglobal.com'
                            }))} >
                                <Form.Item name={"email"} rules={[{ required: true }]} >
                                    <Input placeholder="Email" addonAfter="@voyceglobal.com" />
                                </Form.Item>
                                <Form.Item name={"password"} rules={[{ required: true, message: 'Please input your password!' }]} >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                                <Row justify={"center"}>
                                    <Form.Item>
                                        <Button htmlType="submit" style={{width: 180}} shape="round" type="primary">Sign In</Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <br/>
                    <p style={{"textAlign": "center", color: "grey"}}>
                        Don't remember your password? <i><Link to="/auth/forgot-password" >forgot password</Link></i>
                    </p>
                </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Login;