import request from './axios';
import AuthService from "./auth.service";
import CompanyService from "./company.service";
import AnalyticsService from './analytics.service';
import TerpPositionService from './terp.position.service';
import InvoiceService from './invoice.service';
import TransactionService from './transaction.service';
import TerpService from './terp.service';
import AVRService from './avr.service';
import DeviceService from './device.service';
import AuditService from './audit.service';
class Service {
	static Auth() {
		return new AuthService(request);
	}

	static Company() {
		return new CompanyService(request);
	}

	static Analytics() {
		return new AnalyticsService(request);
	}

	static TerpPosition() {
		return new TerpPositionService(request);
	}

	static Invoice() {
		return new InvoiceService(request);
	}

	static Transaction() {
		return new TransactionService(request);
	}

	static Terp() {
		return new TerpService(request);
	}

	static AVR() {
		return new AVRService(request);
	}
	static Device() {
		return new DeviceService(request);
	}
	static Audit() {
		return new AuditService(request);
	}
}

export default Service;
