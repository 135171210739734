import { useEffect, useState } from "react";
import constants from "../../constants";
import { io } from 'socket.io-client';
import { store } from "../..";
import { useSelector } from "react-redux";
import { socket } from "../../socket";
import ROLE from "../../role";
import events from "../../pages/qa/socket.events";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/profile.action";
import { message } from "antd";

function useSocket() {
    const { token } = useSelector((state) => state?.profile);
    const userRole = useSelector((state) => state.profile?.user?.permissions?.role);
    const isQaSpecialist = useSelector((state) => state.profile?.user?.permissions?.isQaSpecialist);
    const dispatch = useDispatch();

    const [isConnected, setIsConnected] = useState(socket?.connected);

    useEffect(() => {
      if (token && ((userRole !== ROLE.QA_SPECIALIST) || isQaSpecialist) && !isConnected) {
        socket.connect();
      }
  
      socket.on('connect', () => {
        console.log('connection established to the socket server');
        setIsConnected(true);
      });
  
      socket.on('connect_error', () => {
        console.log('Socket Connection Issue');
        setIsConnected(false);
        dispatch(logout())
      })

      socket.on('disconnect', () => {
        console.log('Socket Connection Disconnected');
        setIsConnected(false);
      });

      socket.on(events.FORCE_LOGOUT, (data) => {
        if (data?.message) {
          message.info(data.message)
        }
        dispatch(logout())
      }) 
  
      return () => {
        socket.off('connect_error');
        socket.off('connect');
        socket.off('disconnect');
      }
    }, [token])

    return {isConnected, socket}
}

export default useSocket;