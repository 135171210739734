import { Button, Card, Col, Row, Table, Typography, theme } from 'antd'
import './non.serviced.report.scss'
import TotalBarChart from './total.bar.chart'
import { useEffect, useState } from 'react'
import {
    getNSCByTotal
} from '../../../store/actions/analytics.action'
import { useDispatch } from 'react-redux'
import HeatMap from './heat.map'
import GetColumnSearchProps from '../../../components/table/search';
import { useSelector } from 'react-redux'
import DropDown from '../../../components/dropdown'
import Formatter from '../../../utils/Formatter'
import exportAsCSV from "../../../services/exportToCVS.service"
import jsPDF from "jspdf";
import moment from 'moment'
import LocalCustomFilter from '../../transactions/localCustomFilter'
import { exportToExcel } from '../../../services/exportToExcel'
const NonServicedReport = (props) => {
    const {
        token: { colorText, colorBgLayout }
    } = theme.useToken()
    const dispatch = useDispatch()
    const [dataSource, setDataSource] = useState([]);
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [loading, setLoading] = useState(false);
    const state = useSelector(state => state);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            await dispatch(getNSCByTotal()).then((data) => setDataSource(data || []));
            setLoading(false);
        }

        getData();
    }, [
        state.filter?.date, 
        state.company?.selected, 
        state.company?.client
    ])

    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of dataSource) {
            let newList = {
                "Language": list?.TargetLanguage,
                "Serviced Calls": Formatter.numberWithCommas(list?.SuccessCalls),
                "Non Serviced Calls": Formatter.numberWithCommas(list?.FailedCalls),
                "Failure Percentage": `${Number(list?.PercentageFailedCalls).toFixed(2)}%`,
            };
            downloadArray.push(newList);
        }
      
        const headerList = [
            "Language",
            "Serviced Calls",
            "Non Serviced Calls",
            "Failure Percentage",
        ];
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Non_Serviced_Calls_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        doc.text("Non Serviced Calls Report", 30, 20);
        doc.setFontSize(12);
        doc.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
        
    };
      
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadExcelFunction();
        }
    };

    const downloadCSVFunction = (pagination, filters, sorter) => {
        let downloadArray = [];
        for (let list of dataSource) {
            let newList = {
                "Language": list?.TargetLanguage,
                "Serviced Calls": Formatter.numberWithCommas(list?.SuccessCalls),
                "Non Serviced Calls": Formatter.numberWithCommas(list?.FailedCalls),
                "Failure Percentage": `${Number(list?.PercentageFailedCalls).toFixed(2)}%`,
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Non_Serviced_Calls_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            //new Date().toISOString().split("T")[0],
            [
                "Language",
                "Serviced Calls",
                "Non Serviced Calls",
                "Failure Percentage",
            ],
            "Non Service Calls Report",
        );
    };

    const downloadExcelFunction = (pagination, filters, sorter) => {
        let downloadArray = [];
        for (let list of dataSource) {
            let newList = {
                "Language": list?.TargetLanguage,
                "Serviced Calls": Formatter.numberWithCommas(list?.SuccessCalls),
                "Non Serviced Calls": Formatter.numberWithCommas(list?.FailedCalls),
                "Failure Percentage": `${Number(list?.PercentageFailedCalls).toFixed(2)}%`,
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Non_Serviced_Calls_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
        const sheetData = [downloadArray];
        const sheetNames = ["None Serviced Call Report"];
    
        exportToExcel(sheetData, sheetNames, filename);
    };

    const columns = [
        {
          title: 'Language',
          dataIndex: 'TargetLanguage',
          ...LocalCustomFilter({
            dataIndex: 'TargetLanguage',
            dataSource: dataSource
          }),
          render: (text) => text || '-'
        },
        {
          title: 'Serviced Calls',
          dataIndex: 'SuccessCalls',
          align: 'center',
          sorter: (a, b) => b.SuccessCalls - a.SuccessCalls,
          render: (text) => Formatter.numberWithCommas(text, false) || '-'
        },
        {
          title: 'Non Serviced Calls',
          dataIndex: 'FailedCalls',
          align: 'center',
          sorter: (a, b) => b.FailedCalls - a.FailedCalls,
          render: (text) => Formatter.numberWithCommas(text, false) || '-'
        },
        {
          title: 'Failure Percentage',
          dataIndex: 'PercentageFailedCalls',
          align: 'center',
          sorter: (a, b) => b.PercentageFailedCalls - a.PercentageFailedCalls,
          render: (text) => (text ? `${Number(text).toFixed(2)}%` : '-')
        }
      ]

    const totalCalls = dataSource?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.FailedCalls + currentValue.SuccessCalls;
    }, 0);

    const totalCallCancelled = dataSource?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.FailedCalls;
    }, 0);

    const failurePercentage = ((totalCallCancelled/totalCalls)*100).toFixed(2)

    return (
        <Row className="nsr-container">
            <Col flex={'auto'}>
                <Card style={{backgroundColor: colorBgLayout}}>
                    <Row>
                        <Col flex={"auto"}>
                            <Typography.Text>Total Calls</Typography.Text> <br/>
                            <Typography.Text style={{fontSize: 28}} >{Formatter.numberWithCommas(totalCalls, false) || 0}</Typography.Text>
                        </Col>
                        <Col style={{backgroundColor: "grey", width: 1, marginRight: 20}} ></Col>
                        <Col flex={"auto"}>
                            <Typography.Text>Canceled Calls</Typography.Text> <br/>
                            <Typography.Text style={{fontSize: 28}} >{Formatter.numberWithCommas(totalCallCancelled, false) || 0}</Typography.Text>
                        </Col>
                        <Col style={{backgroundColor: "grey", width: 1, marginRight: 20}} ></Col>
                        <Col flex={""}>
                            <Typography.Text>Failure Percentage</Typography.Text> <br/>
                            <Typography.Text style={{fontSize: 28, color: '#FF6347'}} >{failurePercentage}%</Typography.Text>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <Row wrap={false} gutter={20} className="charts-row">
                    <Col flex={'40%'}>
                        <TotalBarChart
                            loading={false}
                            dataSource={dataSource?.slice(0, 10)}
                        />
                    </Col>
                    <Col flex={'60%'}>
                        <HeatMap
                            loading={false}
                            dataSource={dataSource?.slice(0, 10)}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col flex={'auto'}>
                        <Table
                            title={() => <>
                                    <Row align={"middle"} gutter={10}>
                                        <Col flex={"auto"}>
                                            <div style={{color:'white', fontSize: 18}}>{`Call Failure Analysis Report`}</div>
                                        </Col>
                                        <Col > 
                                            <DropDown
                                                defaultValue={downloadType}
                                                dropdownOptions={
                                                    downloadDropdownType
                                                }
                                                handleTypeSelect={(
                                                    downloadType
                                                ) =>
                                                    setDownloadType(
                                                        downloadType
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="primary"
                                                onClick={downloadFile}
                                            >
                                                Download
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                            loading={loading}
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            pagination={{
                                defaultPageSize: 100,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100']
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Typography.Text strong italic>* The data presented in this report reflects Eastern Standard Time (EST).</Typography.Text>
                </Row>
            </Col>
        </Row>
    )
}

export default NonServicedReport
