import { theme } from "antd";

export const DarkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorBgLayout: "#102",
        colorPrimary: "#6c40a6",
        colorBgContainer: "#000",
        fontFamily: "Montserrat, sans-serif"

    }
}

export const LightTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: "#6c40a6",
        fontFamily: "Montserrat, sans-serif"
    },
    // "components": {
    //     "Card": {
    //       "colorBorderSecondary": "rgba(0, 0, 0, 0.25)"
    //     }
    // }
}
/**
 * maroon: #590232
 * navy-blue: #034C8C
 * red: #BF0436
 * brown: #BF0436
 * black: #0D0000
 * purple: #4C55C8
 */

/**
 * #226885
 * #0f9191
 * #61636f
 */