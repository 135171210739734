export function getColorFromPercentage(percentage) {
    // Define the colors map
    const colors = [
        { percentage: 0, color: '#dc4446' },
        { percentage: 25, color: '#af4446' },
        { percentage: 50, color: '#823446' },
        { percentage: 75, color: '#552c46' },
        { percentage: 100, color: '#28a745' }
    ];

    // Ensure the percentage is within bounds
    if (percentage <= 0) return colors[0].color;
    if (percentage >= 100) return colors[4].color;

    // Find the color based on the percentage
    for (let i = 0; i < colors.length - 1; i++) {
        if (percentage >= colors[i].percentage && percentage < colors[i + 1].percentage) {
            return colors[i].color;
        }
    }

    // In case of an edge case
    return colors[4].color;
}