import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different Company action types
*/

export default function company(state = {active: [], selected: undefined, client: undefined}, action) {
    switch (action.type) {
        case ActionType.SET_COMPANIES:
            return {...state, active: action.companies};
        case ActionType.SELECT_COMPANY:
            return {...state, selected: action.selected, client: action.client};
        case ActionType.SELECT_CLIENT:
            return {...state, client: action.client};
        default:
            return state;
    }
}
