import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';

export function setLanguageAnalytics(res) {

    const volume = [];
    const distribution = {
        legends: [],
        seriesData: []
    }
    const avgTime = {
        legends: [],
        seriesData: []
    }
    const interpretationType = {
        audio: {
            legends: [],
            seriesData: []
        },
        video: {
            legends: [],
            seriesData: []
        }
    }

    res.forEach((element) => {
        volume.push({
            name: element.TargetLanguage,
            value: element.TotalCalls
        });

        distribution.seriesData.push(element.ServiceMinutes);
        distribution.legends.push(element.TargetLanguage);

        interpretationType.audio.seriesData.push(element.CountAudioMinute);
        interpretationType.audio.legends.push(element.TargetLanguage);

        interpretationType.video.seriesData.push(element.CountVideoMinute);
        interpretationType.video.legends.push(element.TargetLanguage);

        avgTime.seriesData.push(Math.round(element.ServiceMinutes/element.TotalCalls));
        avgTime.legends.push(element.TargetLanguage);
    });

    return {
        type: ActionType.SET_LANGUAGE_ANALYTICS,
        volume,
        distribution,
        avgTime,
        interpretationType,
        rawData: res,
    }
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getLanguagesDetails(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getLanguagesTotal(filter, hideLoader);
            dispatch(setLanguageAnalytics(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
