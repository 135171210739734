import { Axios } from "axios";
import constants from "../constants";

class TerpService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async getTerpDetails(filter) {
        try {
            let response = await this.request.post(
                `/agg-by-terps/`,
                {
                    ...filter,
                },
                { filter: true }
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getAllTerpDetails(filter) {
        try {
            let response = await this.request.post(
                `/agg-by-terp/total`,
                {
                    ...filter,
                },

            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getAllTerpUserManagement(filter) {
        try {
            let response = await this.request.post(
                `/role/interpreters/total`,
                {
                    ...filter,
                },

            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async downloadCSV(filter, sorter, header) {
        console.log("downloadCSV filter: ", filter);
        try {
            let response = await this.request.post(
                `/agg-by-terps/download/CSV`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            console.log("downloadCSV response", response);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getInterpreters(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/terp/monitor/hour', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getEvaluatedInterpreters(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/terp/by-month/score', filter,
                {filter: true, hideLoader}
            );
            return response.data;

        } catch(err) {
            console.log(err);
            throw err;
        }
    }

}

export default TerpService;
