import { theme } from 'antd';
import ReactECharts from 'echarts-for-react';
import Formatter from "../../../utils/Formatter";
import PropTypes from 'prop-types';

const LanguageDistributionBar = ({data, interpretation, avgTime}) => {
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();



    const getOption = () => {
        let colors = [
            '#226885',
            '#0f9191',
            '#61636f',
            '#6c40a6'
        ]
        return {
            toolbox: {        
                right: 90,        
                feature: {      
                    saveAsImage: {
                        backgroundColor: colorBgLayout, // Set background color for saved image
                        color: colorText 
                    },          
                }      
            },
            legend: {
                data: ['Total', 'Audio', 'Video', 'Avg. length'],
                align: 'left',
                top: 0,
                textStyle: {color: colorText},
            },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0], '10%'];
                },
                axisPointer: {
                  type: 'shadow',
                },
                itemStyle: {
                    color: colorText,
                }
                // formatter: ([{data,axisValueLabel}]) => {
                //   return `${axisValueLabel}</br>${Formatter.numberWithCommas(Number(data.value))} Minutes`
                // }
            },
            grid: {
                top: 40,
                left: 10,
                right: 10,
                bottom: 50,
                containLabel: true,
                background: 'black'
            },
            xAxis: [
                {
                    type: "category",
                    data: data?.legends,
                    axisTick: {
                        alignWithLabel: true,
                        color: colorText,
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    }
                },
            ],
            yAxis: [
                {
                    name: "Total",
                    type: "log",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        formatter: '{value} min'
                    },
                },
                {
                    type: 'value',
                    name: 'Avg. call length',
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        formatter: '{value} min'
                    },
                }
            ],
            series: [
                {
                    name: "Total",
                    type: "bar",
                    data: data?.seriesData,
                    itemStyle: {
                        color: '#6c40a6'
                    }
                },
                {
                    name: 'Audio',
                    type: 'bar',
                    stack: 'interpretation',
                    data: interpretation?.audio?.seriesData,
                    itemStyle: {
                        color: '#0f9191'
                    }
                },
                {
                    name: 'Video',
                    type: 'bar',
                    stack: 'interpretation',
                    data: interpretation?.video?.seriesData,
                    itemStyle: {
                        color: 'cyan'
                    }
                },
                {
                    name: 'Avg. length',
                    type: 'bar',
                    stack: 'Average Call Length',
                    yAxisIndex: 1,
                    data: avgTime?.seriesData,
                    itemStyle: {
                        color: '#226885'
                    }
                }
            ],
            dataZoom: [
                {
                    show: true,
                    start: 0,
                    left: 4,
                    right: 4,
                    end: data.seriesData?.length > 10 ? Math.round((9/data.seriesData.length)*100) : 100,
                },
                {
                    show: true,
                    type: 'inside',
                    start: 0,
                    left: 10,
                    right: 10,
                    end: data.seriesData?.length > 10 ? Math.round((9/data.seriesData.length)*100) : 100,
                },
            ],
        };
    };

  return (
    <ReactECharts
      option={getOption()}
      style={{ height: '255px' }}
    />
  );
};

LanguageDistributionBar.propTypes = {
    data: PropTypes.array.isRequired
}

export default LanguageDistributionBar;
