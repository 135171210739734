import { Tabs } from 'antd'
import React, { useState, useEffect } from 'react'
import TerpTable from './terp'
import InterpreterRoutingHistory from '../reports/interpreter.routing.history/interpreter.routing.history'
import TerpMonitor from '../cc.reports/terp.avaialbility/terp.availability.report'
import { useNavigate, useLocation } from 'react-router-dom'

const { TabPane } = Tabs

const tabConfig = [
  {
    tab: 'Dashboard',
    key: 'terp-dashboard',
    component: <TerpTable />,
    path: '/pages/terp-hud/dashboard'
  },
  {
    tab: 'Availability Report',
    key: 'terp-availability',
    component: <TerpMonitor />,
    path: '/pages/terp-hud/availability-report'
  },
  {
    tab: 'Routing History',
    key: 'terp-routing-history',
    component: <InterpreterRoutingHistory />,
    path: '/pages/terp-hud/routing-history'
  }
]
const TerpHud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState()

  useEffect(() => {
    const currentTab = tabConfig.find((tab) =>
      location.pathname.includes(tab.path)
    )
    if (currentTab) {
      setActiveTab(currentTab.key)
    } else {
      navigate(tabConfig[0].path)
    }
  }, [location, navigate])

  const handleTabChange = (key) => {
    const tab = tabConfig.find((tab) => tab.key === key)
    if (tab) {
      navigate(tab.path)
      setActiveTab(key)
    }
  }
  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        style={{ paddingLeft: '20px' }}
      >
        {tabConfig.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} />
        ))}
      </Tabs>
      <div>
        {tabConfig.map(
          (tab) => activeTab === tab.key && <div>{tab.component}</div>
        )}
      </div>
    </div>
  )
}

export default TerpHud
