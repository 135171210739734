import { Card, Col, Divider, Row, Spin, Typography, theme, Select } from 'antd'
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts/core'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTop10Languages } from '../../../store/actions/analytics.action'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const HeatMap = ({ dataSource, loading }) => {
    const {
        token: { colorText, colorBgLayout }
    } = theme.useToken()
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState([])
    const [selected, setSelected] = useState([])
    const [languages, setLanguages] = useState([])
    const state = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!top10 || (top10 && top10.legends.length == 0)) {
            dispatch(getTop10Languages(true))
        }
    }, [state.filter?.date, state.company?.client])

    const top10 = useSelector((state) => state?.analytics.top10Languages)

    const colors = ['#74a8cf', '#d2facb', '#54c9e8', '#203870', '#ff876f']

    const hours = [
        '12a',
        '1a',
        '2a',
        '3a',
        '4a',
        '5a',
        '6a',
        '7a',
        '8a',
        '9a',
        '10a',
        '11a',
        '12p',
        '1p',
        '2p',
        '3p',
        '4p',
        '5p',
        '6p',
        '7p',
        '8p',
        '9p',
        '10p',
        '11p'
    ]

    useEffect(() => {
        if (
            top10 &&
            top10.legends.length > 0 &&
            dataSource &&
            Object.keys(dataSource).length > 0
        ) {
            let lang = []
            const source = []
            const totalData = []
            
            lang = top10.legendsIdle.slice(0, 10)
            let code = top10.legends.slice(0, 10)
            for (let i = 0; i < 10; i++) {
                let lang_index = i
                for (let j = 0; j < 24; j++) {
                    let hour_index = j
                    let hour = dataSource[lang[lang_index]]?.raw[hour_index]
                    source.push([
                        hour_index,
                        lang_index,
                        hour?.minutes
                            ? Math.ceil(hour?.minutes / hour.count)
                            : 0
                    ])
                }
            }

            for (let j = 0; j < 24; j++) {
                let hour_index = j
                for (let i = 0; i < 10; i++) {
                    let lang_index = i
                    let hour = dataSource[lang[lang_index]]?.raw[hour_index]
                    if (totalData[hour_index]) {
                        totalData[hour_index][2] += hour?.minutes
                            ? Math.ceil(hour?.minutes / hour.count)
                            : 0
                    } else {
                        totalData.push([
                            hour_index,
                            lang_index,
                            hour?.minutes
                                ? Math.ceil(hour?.minutes / hour.count)
                                : 0
                        ])
                    }
                }
            }

            setData(source)
            setLanguages(lang)
            setSelected(lang)
            setTotalData(totalData)
        }
    }, [top10, dataSource])

    useEffect(() => {
        if (selected && selected.length > 0) {
            const source = []
            const totalData = []

            for (let i = 0; i < selected.length; i++) {
                let lang_index = i
                for (let j = 0; j < 24; j++) {
                    let hour_index = j
                    let hour = dataSource[selected[lang_index]]?.raw[hour_index]
                    source.push([
                        hour_index,
                        lang_index,
                        hour?.minutes
                            ? Math.ceil(hour?.minutes / hour.count)
                            : 0
                    ])
                }
            }

            for (let j = 0; j < 24; j++) {
                let hour_index = j
                for (let i = 0; i < selected.length; i++) {
                    let lang_index = i
                    let hour = dataSource[selected[lang_index]]?.raw[hour_index]
                    if (totalData[hour_index]) {
                        totalData[hour_index][2] += hour?.minutes
                            ? Math.ceil(hour?.minutes / hour.count)
                            : 0
                    } else {
                        totalData.push([
                            hour_index,
                            lang_index,
                            hour?.minutes
                                ? Math.ceil(hour?.minutes / hour.count)
                                : 0
                        ])
                    }
                }
            }

            setData(source)
            setTotalData(totalData)
        }
    }, [selected])

    const options = {
        tooltip: {
            position: 'top',
            formatter: (params) => {
                return (
                    '<b>' +
                    params.seriesName +
                    '</b>' +
                    '<br/>' +
                    'Language: ' +
                    selected[params.value[1]] +
                    '<br/>' +
                    'Hour: ' +
                    params.name +
                    '<br/>' +
                    'Avg idle time: ' +
                    params.value[2] +
                    ' minutes'
                )
            }
        },
        grid: {
            height: '90%',
            top: '20',
            right: '1%',
            left: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: hours,
            splitArea: {
                show: true
            },
            position: 'top',
            axisLabel: {
                textStyle: {
                    color: colorText,
                    fontSize: 14
                }
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'category',
            data: selected,
            splitArea: {
                show: true
            },
            position: 'right',
            axisLabel: {
                textStyle: {
                    color: colorText,
                    fontSize: 14
                },
                interval: 0
            },
            axisTick: {
                show: false
            }
        },
        visualMap: {
            min: 0,
            max: 10,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            show: false,
            inRange: {
                color: colors
            }
        },
        series: [
            {
                name: 'Interpreter Idle Minutes',
                type: 'heatmap',
                data: data,
                label: {
                    normal: {
                        show: true,
                        formatter: (param) => {
                            if (param.data[2] === 0) {
                                return ''
                            }
                        }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    normal: {
                        borderColor: colorBgLayout,
                        borderWidth: 4,
                        borderCap: 'round',
                        borderRadius: 6
                    }
                },
                progressive: 1000
            }
        ]
    }

    const combinedLegends = top10.legends.map((code, index) => ({
        code: code,
        name: top10.legendsIdle[index]
    }))

    const renderOptions = () => {
        const options = []
        const keys = Object.keys(grouped).sort()
        for (let i = 0; i < keys.length; i++) {
            options.push(
                <Select.OptGroup label={keys[i]}>
                    {grouped[keys[i]]?.map((element, index) => {
                        // unique keys
                        const uniqueKey = `${element.code}-${index}`
                        return (
                            <Select.Option key={uniqueKey} value={element.name}>
                                {`${element.name} - (${element.code}) `}
                            </Select.Option>
                        )
                    })}
                </Select.OptGroup>
            )
        }
        return options
    }

    const grouped = _.groupBy(combinedLegends, (element) =>
        element.name.charAt(0)
    )

    // Function to format tooltip information for the total chart
    const formatter2 = useCallback((params) => {
        return (
            '<b>' +
            params.seriesName +
            '</b>' +
            '<br/>' +
            'Hour: ' +
            params.name +
            '<br/>' +
            'Avg Minutes: ' +
            params.value[2]
        )
    }, [])

    const options2 = () => ({
        tooltip: {
            show: true,
            formatter: formatter2
        },
        grid: {
            height: '22.5',
            top: '5',
            right: '1%',
            left: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: hours,
            splitArea: {
                show: true
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: colorText
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: colorText
                }
            }
        },
        yAxis: {
            type: 'category',
            data: [
                'Total'.padEnd(
                    selected.reduce(
                        (max, str) => (str.length > max.length ? str : max),
                        ''
                    ).length + 28,
                    ' '
                )
            ],
            position: 'right',
            splitArea: {
                show: true
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: colorText
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: colorText
                }
            }
        },
        visualMap: {
            min: 0,
            max: 10,
            show: false,
            inRange: {
                color: colors
            }
        },
        series: [
            {
                name: 'Total calls',
                type: 'heatmap',
                data: totalData,
                label: {
                    normal: {
                        show: true
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    normal: {
                        borderColor: colorBgLayout,
                        borderWidth: 4,
                        borderCap: 'round',
                        borderRadius: 6
                    }
                },
                progressive: 1000
            }
        ]
    })

    return (
        <Spin spinning={loading}>
            <Card
                style={{
                    width: '100%',
                    borderRadius: 4,
                    backgroundColor: colorBgLayout,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                }}
                bodyStyle={{ padding: '10px' }}
            >
                <Row justify={'end'} wrap={false}></Row>
                <EChartsReact style={{ height: 250 }} option={options} />
                <Divider style={{ margin: 0 }} plain orientation={'center'}>
                    {' '}
                    Total{' '}
                </Divider>
                <EChartsReact
                    key={'total-graph'}
                    style={{ height: 33, width: '100%' }}
                    option={options2()}
                />
                <Row
                    style={{ marginTop: 10, marginLeft: 8 }}
                    align={'middle'}
                    justify={'start'}
                    gutter={10}
                >
                    <Col>
                        <Typography.Text strong>Less</Typography.Text>
                    </Col>
                    <Col
                        style={{
                            backgroundColor: colors[0],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[1],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[2],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[3],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col
                        style={{
                            backgroundColor: colors[4],
                            height: 15,
                            width: 15,
                            borderRadius: 4,
                            marginRight: 3
                        }}
                    ></Col>
                    <Col>
                        <Typography.Text strong>More</Typography.Text>
                    </Col>
                    <Divider type={'vertical'} />
                    <Col>
                        <Typography.Text strong>
                            Interpreter idle time by hour (EST)
                        </Typography.Text>
                    </Col>
                    <Col flex={'auto'}>
                        <Select
                            allowClear
                            maxTagCount="responsive"
                            mode="multiple"
                            style={{ width: '75%' }}
                            placeholder="Select Languages"
                            value={selected}
                            onChange={setSelected}
                        >
                            {renderOptions()}
                        </Select>
                    </Col>
                    {/* <Divider type={"vertical"} />
                    <Col flex={"auto"}><Typography.Text strong italic> ⚠️ click to show minutes</Typography.Text></Col> */}
                </Row>
            </Card>
        </Spin>
    )
}

export default HeatMap
