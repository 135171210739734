import { theme } from "antd";
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import { useSelector } from "react-redux";

const graphColors = [
    '#6c40a6',
    '#0f9191',
    '#226885',
    '#61636f'
]

const AVRequestAreaChart = (props) => {
    
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    const data = useSelector((state) => state.analytics?.avrBarChart);

    const options = {
        legend: {
            data: ['Total Video Call Requests', 'Converted to Audio Calls', 'Successful Video Calls'],
            align: 'right',
            top: 0,
            textStyle: {color: colorText},
            show: false
        },
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            },
            axisPointer: {
              type: 'shadow',
            },
            // formatter: function ([params]) {
            //     return `<b><span style="color: ${params.data.itemStyle.color}">${params.seriesName}</span></b><br />
            //             ${params.name}: <b>${params.data.value}</b>`;
            // } 
        },
        grid: {
            top: 10,
            left: 0,
            right: 40,
            bottom: 0,
            containLabel: true,
            background: 'black'
        },
        xAxis: [
            {
                type: "category",
                data: data?.legends,
                axisTick: {
                    alignWithLabel: true,
                    color: colorText,
                },
                axisLabel: {
                    textStyle: {
                        color: colorText,
                    },
                },
                boundarygap: false,
            },
        ],
        yAxis: [
            {
                name: "Calls",
                type: "log",
                min: 1,
                axisLine: {
                    lineStyle: {
                        color: colorText,
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: colorText,
                    },
                    formatter: '{value} calls',
                },
            },
        ],
        series: [
            {
                name: 'Video Calls',
                type: 'line',
                stack: 'Video Calls',
                data: data?.success,
                smooth: true,
                linestyle: {
                    width: 0
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: '#6c40a6'
                },
                emphasis: {
                    focus: 'series'
                },
                  
                itemStyle: {
                    color: '#6c40a6'
                }
            },
            {
                name: 'Audio Calls',
                type: 'line',
                stack: 'Audio Calls',
                smooth: true,   
                linestyle: {
                    width: 0
                  },
                  showSymbol: false,
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [
                      {
                        offset: 1,
                        color: '#6c40a6'
                      },
                      {
                        offset: 0,
                        color: '#0f9191'
                      },
                    ])
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  
                data: data?.failed || [],
                itemStyle: {
                    color: '#0f9191'
                }
            },
        ],
    };

    return (
        <EChartsReact style={{ height: 120 }} option={options}/>
    )

}

export default AVRequestAreaChart;